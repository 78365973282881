import messages from '@/componet-locale/system-message-template/messages'
import UserLink from '@/components/chats/UserLink'

export const eventMessageMixin = {
  props: ['message'],
  i18n: { messages: messages },
  components: { UserLink },
  data: () => ({
    links: []
  }),
  created () {
    this.links = JSON.parse(this.message.decryptedBody)
  }
}
