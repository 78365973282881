<template>
  <div>
    <vuedraggable
      v-model="et.aggregations"
      handle=".handle"
    >
      <v-card
        v-for="(aggregation, i) in et.aggregations"
        :key="aggregation.id"
        class="et-aggregation-card my-4 pa-3"
      >
        <v-card-actions class="pa-0 mb-2">
          <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
          <v-spacer/>
          <v-icon class="pointer" color="red" @click="remove(i)">close</v-icon>
        </v-card-actions>
        <v-text-field
          v-model="aggregation.name"
          :label="$t('name')"
          class="mb-2"
          dense
          hide-details
          outlined
        />
        <v-select
          v-model="aggregation.type"
          :items="AGGREGATION_TYPES"
          :label="$t('type')"
          class="mb-2"
          dense
          hide-details
          outlined
        >
          <template v-slot:item="{item}">{{ $t(item) }}</template>
          <template v-slot:selection="{item}">{{ $t(item) }}</template>
        </v-select>
        <v-select
          v-model="aggregation.header"
          :items="getSupportedHeaders(aggregation.type)"
          :label="$t('field')"
          class="mb-2"
          dense
          hide-details
          item-text="fieldName"
          outlined
          return
          return-object
        />
      </v-card>
    </vuedraggable>
    <v-btn outlined @click="addAggregation">
      {{ $t('button.add') }}
    </v-btn>
  </div>
</template>

<script>

import messages from '@/componet-locale/et-aggregation/messages'
import Vuedraggable from 'vuedraggable'

export default {
  name: "EtAggregationEditor",
  components: { Vuedraggable },
  i18n: { messages },
  props: {
    et: Object
  },
  data: () => ({
    AGGREGATION_TYPES: ['SUM', 'AVG', 'MAX', 'MIN']
  }),
  methods: {
    remove (index) {
      this.et.aggregations.splice(index, 1)
    },
    addAggregation () {
      this.et.aggregations.push({
        type: 'SUM'
      })
    },
    getSupportedHeaders (aggType) {
      return this.et.headers.filter(h => {
        return h.fieldType === 'NUMBER'
          || h.fieldType === 'DATE' && ['MAX', 'MIN'].includes(aggType)
      })
    }
  }
}
</script>

<style scoped>
.et-aggregation-card {
  border: 1px solid lightgray;
  box-shadow: none;
  background: #f6f4f2;
}
</style>
