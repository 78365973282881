<template>
  <v-dialog
    v-model="dialog"
    width="350">
    <v-card>
      <v-card-title primary-title>
        {{ $t('button.share') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot/>
        <qriously v-if="url" :value="url.toString()" :size="300"/>

        <v-text-field
          hide-details
          class="mt-4"
          outlined
          dense
          :readonly="true"
          @click:append-outer="copy"
          append-outer-icon="content_copy"
          id="copy_input"
          v-model="url"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/share-data-dialog/messages'
import Vue from 'vue'
import VueQriously from 'vue-qriously'

Vue.use(VueQriously)

export default {
  name: 'ShareDataDialog',
  data: () => ({
    dialog: false,
    url: null
  }),
  i18n: { messages: messages },
  methods: {
    share (url) {
      this.url = url || window.location
      this.dialog = true
    },
    copy () {
      let el = document.getElementById('copy_input')
      el.select()
      document.execCommand('copy')
      EventBus.$emit('showInfoMessage', this.$t('linkCopied'))
    }
  }
}
</script>
