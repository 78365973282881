<template>
  <vl-layer-vector
    v-if="lpStyleList"
    :declutter="false"
    :z-index="zIndex"
    render-mode="image"
  >
    <vl-source-vector ref="sourceVector" :features.sync="features" :url="getUrl()">
      <vl-style-func :factory="getStyle"/>
    </vl-source-vector>
  </vl-layer-vector>
</template>

<script>

import { createFillStyle, createStyle } from 'vuelayers/lib/ol-ext'
import { Fill, Stroke, Text } from 'ol/style'
import { EventBus } from '@/event-bus'

export default {
  name: 'ObjectLayer',
  props: { layer: Object, zIndex: Number },
  data: () => ({
    lpStyleList: null,
    features: []
  }),
  created () {
    this.loadStyles()
  },
  methods: {
    getStyle () {
      let getText = this.getText
      let map = this.getStylesById()
      return function (feature) {
        const geometryType = feature.getGeometry().getType()
        let props = feature.values_
        let style = map.get(props['style-id'])
        let text = props['map-text']
        if (style && text && text.length) {
          style = style.clone()
          style.setText(getText(text, geometryType))
        }
        return style
      }
    },
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.lpStyleList = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка при попытке получить данные по стилям'))
    },
    getText (text, geometryType = null) {
      const isPoint = geometryType && geometryType === 'Point'
      return new Text({
        font: '16px sans-serif',
        text: text,
        fill: new Fill({
          color: 'black'
        }),
        baseline: 'bottom',
        stroke: new Stroke({ color: 'white', width: 3 }),
        padding: [2, 2, 2, 2],
        offsetY: isPoint ? -15 : 0,
        offsetX: isPoint ? 13 : 0,
        textAlign: isPoint ? 'left' : ''
      })
    },
    getOlStyle (style) {
      const hexToRgb = (hex) => {
        let r = parseInt(hex.slice(1, 3), 16)
        let g = parseInt(hex.slice(3, 5), 16)
        let b = parseInt(hex.slice(5, 7), 16)
        let alpha = parseInt(hex.slice(7, 9), 16) / 255
        return [r, g, b, alpha]
      }
      return createStyle({
        strokeColor: hexToRgb(style.stroke),
        strokeWidth: style.strokeWidth,
        imageRadius: 4,
        imageSrc: style.icon,
        imageScale: style.imageScale,
        imageAnchor: style.imageAnchor && style.imageAnchor.length > 0 ? style.imageAnchor : [0.5, 0.5],
        fill: createFillStyle({
          fillColor: hexToRgb(style.fill)
        })
      })
    },
    getStylesById () {
      let stylesById = new Map()
      this.lpStyleList.forEach(it => {
        stylesById.set(it.id, this.getOlStyle(it))
      })
      return stylesById
    },
    getFeaturesInExtent (extent) {
      return this.$refs.sourceVector.createSource().getFeaturesInExtent(extent)
    },
    getExtent(){
      if(!this.$refs.sourceVector) return null
      return this.$refs.sourceVector.createSource().getExtent()
    },
    getUrl () {
      return this.isAuthenticated
        ? `${window.location.origin}/api/layer-poi/min-geo-json?layerId=${this.layer.id}`
        : `${window.location.origin}/api/public-data/get-lp-min-geo-json?layerId=${this.layer.id}`
    }
  }
}
</script>
