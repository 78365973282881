<template>
  <d-date-picker
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    class="mb-3"
    dense
    format="YYYY-MM-DD"
    hide-details
    outlined
    @input="val => $emit('update:value', val)"
  />
</template>

<script>
import DDatePicker from '@/components/utils/DDatePicker.vue'

export default {
  name: 'DateEtItem',
  components: { DDatePicker },
  props: {
    value: null,
    header: Object,
    editable: Boolean
  },
  computed: {
    readonly () {
      return this.header.isPrimaryTableKey
        || this.header.isComputedField
        || !this.editable
    }
  }
}
</script>
v
