<template>
  <div v-scroll="updateScrollBounds" v-resize="updateScrollBounds">
    <scrolling-page v-for="page in pages"
                    :key="page.pageNumber"
                    v-bind="{page, scale, clientHeight, scrollTop, focusedPage, enablePageJump}"
                    v-slot="{isPageFocused, isElementFocused, isPageVisible}"
                    @page-jump="onPageJump">
      <div class="d-flex justify-center">
        <slot v-bind="{page, isPageFocused, isElementFocused, isPageVisible}"></slot>
      </div>
    </scrolling-page>
    <div v-visible="fetchPages(currentPage)" class="observer"></div>
  </div>
</template>

<script>
import ScrollingPage from '@/components/utils/pdf/ScrollingPage'
import scroll from '@/directives/scroll'
import resize from '@/directives/resize'
import visible from '@/directives/visible'

export default {
  name: 'ScrollingDocument',
  components: { ScrollingPage },
  directives: {
    visible,
    scroll,
    resize
  },
  props: {
    pages: {
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    enablePageJump: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    focusedPage: undefined,
    scrollTop: 0,
    clientHeight: 900
  }),
  computed: {
    pagesLength () {
      return this.pages.length
    }
  },
  methods: {
    fetchPages (currentPage) {
      this.$emit('pages-fetch', currentPage)
    },
    onPageJump (scrollTop) {
      this.$emit('page-jump', scrollTop)
    },
    updateScrollBounds () {
      const { scrollTop, clientHeight } = this.$el
      this.scrollTop = scrollTop
      this.clientHeight = clientHeight
    }
  },
  watch: {
    pagesLength (count, oldCount) {
      if (oldCount === 0) this.$emit('pages-reset')
      this.$nextTick(() => this.focusedPage = this.currentPage)
    },
    currentPage (currentPage) {
      if (currentPage > this.pages.length) {
        this.fetchPages(currentPage)
      } else {
        this.focusedPage = currentPage
      }
    }
  }
}
</script>
