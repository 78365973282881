<template>
  <span>{{ $t('joinedToChat') }}</span>
</template>

<script>
import messages from '@/componet-locale/system-message-template/messages'

export default {
  name: 'JoiningEventMessage',
  i18n: { messages: messages }
}
</script>
