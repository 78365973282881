<template>
  <view-data-page
    :data="data"
    :selected-view="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
    :dialog-mode="dialogMode"
  >
    <template slot="doc">
      <v-card-text>

        <div v-if="setAbility('MODERATOR')">
          <prism-editor auto-style-line-numbers
                        language="js"
                        line-numbers
                        v-model="data.script"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
          </v-card-actions>
        </div>

        <div v-for="prop in props">
          <v-text-field :label="prop.name" class="my-3" dense hide-details outlined type="number" v-model="prop.value"/>
        </div>

        <v-card-actions>
          <v-spacer/>
          <v-btn @click="invoke" color="primary" outlined>{{ $t('calculation') }}</v-btn>
        </v-card-actions>

        <div v-if="response && response.type === 'TABLE'">
          <v-data-table :headers="table.headers" :items="table.body"/>
        </div>
        <div v-else v-html="response"/>
      </v-card-text>
    </template>
  </view-data-page>
</template>

<script>
import { EventBus } from '@/event-bus'
import ViewDataPage from '../utils/ViewDataPage'
import 'vue-prism-editor/dist/VuePrismEditor.css'
import 'prismjs/themes/prism.css'
import 'prismjs'
import PrismEditor from 'vue-prism-editor'
import messages from '../../componet-locale/calculation-view/messages'

export default {
  name: 'CalculationView',
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode:{
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  i18n: { messages: messages },
  components: { ViewDataPage, PrismEditor },
  data: () => ({
    selectedView: 'comments',
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    data: {
      owner: {
        firstName: ''
      },
      project: {
        name: ''
      }
    },
    props: [],
    response: null
  }),
  methods: {
    save () {
      this.$axios
        .post('/calc/save/', this.data)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    selectView (view) {
      this.selectedView = view
    },
    init () {
      let id = this.dataId || this.$route.params.id
      this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
          this.props = JSON.parse(this.data.props)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    },
    invoke () {
      this.$axios
        .post('calc/invoke', this.props, { params: { id: this.data.id } })
        .then(response => {
          this.response = response.data
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    }
  },
  created () {
    this.init()
  },
  computed: {
    table () {
      let headers = Object.values(this.response.headers)
      let body = Object.values(this.response.body)
      return {
        headers: headers,
        body: body
      }
    }
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
