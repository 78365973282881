<template>
  <div>
    <div class="pa-2">
      <div
        v-for="etInfo in relatedTablesInfo"
        :key="etInfo.id"
        class="overflow-hidden mb-8 et-info"
      >
        <v-list>
          <v-list-item>
            <v-list-item-title style="font-weight: bold">
              {{ findTableName(etInfo.id) }}
            </v-list-item-title>
            <v-list-item-action>
              <v-btn class="mr-5" color="primary" outlined @click="addRow(etInfo.id)">
                <v-icon>mdi-plus</v-icon>
                {{ $t('button.add') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <div v-for="(item, index) in getRelatedDataByEtId(etInfo.id).body" :key="index">
            <v-divider/>
            <v-list-item
              @click="selectItem(item,  getRelatedDataByEtId(etInfo.id))"
            >
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: break-spaces"
                  v-text="getItemTitle(item, etInfo.rowNameConstructor, getRelatedDataByEtId(etInfo.id))"
                />
                <v-list-item-subtitle>
                  <div style="width: fit-content">
                    {{ getItemBody(item, getRelatedDataByEtId(etInfo.id)) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-divider/>
        </v-list>
        <div class="d-flex flex-row align-center">
          <div>
            <v-btn :disabled="pagination[etInfo.id] <= 1" icon
                   @click="pagination[etInfo.id] = pagination[etInfo.id] - 1">
              <v-icon>mdi-menu-left</v-icon>
            </v-btn>
            {{ pagination[etInfo.id] }}
            <v-btn :disabled="etInfo.itemsCount <= pagination[etInfo.id]*rowsPerPage" icon
                   @click="pagination[etInfo.id] = pagination[etInfo.id] + 1">
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </div>
          <v-spacer/>
          <div class="mr-3">
            {{ $t('total') }}: {{ etInfo.itemsCount }}
          </div>
        </div>
      </div>
    </div>
    <et-add-row-dialog
      ref="addRowDialog"
      @save="getRelatedValues"
    />
    <et-item-dialog
      ref="etItemDialog"
      :short-mode="true"
      @tableUpdated="getRelatedValues"
    />
  </div>
</template>

<script>

import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import { etServiceMixin } from '@/mixins/et-service-mixin'
import EtAddRowDialog from '@/components/et/EtAddRowDialog.vue'
import messages from '@/componet-locale/et-relations/messages'

export default {
  name: 'RelatedInfoCard',
  components: {
    EtItemDialog: () => import('@/components/et/EtItemDialog.vue'),
    EtItemCard: () => import('@/components/et/EtItemCard.vue'),
    EtAddRowDialog
  },
  mixins: [displayServiceMixin, etServiceMixin],
  i18n: { messages },
  props: {
    et: Object,
    item: Object
  },
  data: () => ({
    relatedData: [],
    pagination: null,
    rowsPerPage: 5
  }),
  mounted () {
    this.getRelatedValues()
  },
  methods: {
    getRelatedDataByEtId (id) {
      return this.relatedData.find(el => el.tableId === id)
    },
    getRelatedValues () {
      const item = this.et.relations.map(rel => ({
        relationId: rel.id,
        value: this.item[rel.mainHeader.alias],
      }))
      const pagination = this.pagination
        ? Object.entries(this.pagination).map(([key, item]) => ({
          tableId: key,
          page: item
        }))
        : null
      this.$axios
        .post('et/select-for-related-row', {
          tableId: this.et.id,
          item,
          pagination
        })
        .then(({ data }) => {
          this.relatedData = data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    close () {
      this.dialog = false
    },
    selectItem (item, db) {
      this.$axios.get('data/get', {
        params: {
          id: db.tableId
        }
      })
        .then(res => {
          this.$refs.etItemDialog.open(res.data, item)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('searchTableError')))
    },
    getItemTitle (item, rowNameConstructor, db) {
      if (rowNameConstructor) {
        return Object.keys(item).reduce((acc, key) => {
          return acc.replaceAll(`[${key}]`, item[key])
        }, rowNameConstructor)
      } else {
        const primaryHeaders = db.headers.filter(el => el.isPrimaryTableKey)
        if (primaryHeaders.length > 0) return `${db.tableName} [${item[primaryHeaders[0].alias]}]`
        return db.tableName
      }
    },
    getItemBody (item, db) {
      const fields = []
      for (let [key, value] of Object.entries(item)) {
        fields.push(`${db.headers.filter(el => el.alias === key)[0].fieldName}: ${value}`)
      }
      return fields.join(' | ')
    },
    addRow (tableId) {
      const autoInsertItems = []
      this.et.relations.forEach(relation => {
        if (!relation.relatedHeader.primaryKey) {
          autoInsertItems.push({
            header: relation.relatedHeader,
            value: this.item[relation.mainHeader.alias]
          })
        }
      })
      this.$refs.addRowDialog.open(tableId, autoInsertItems)
    },
    findTableName (tableId) {
      const table = this.relatedTablesInfo.find(el => el.id === tableId)
      if (table) return table.name
      else return this.$t('table')
    }
  },
  computed: {
    relatedTablesInfo () {
      if (!this.relatedData) return []
      return this.relatedData
        .map(it => ({
          id: it.tableId,
          name: it.tableName,
          rowNameConstructor: it.rowNameConstructor,
          itemsCount: it.itemsCount
        }))
    }
  },
  watch: {
    relatedTablesInfo (val) {
      if (this.pagination) return
      const pagination = {}
      val.forEach(etInfo => pagination[etInfo.id] = 1)
      this.pagination = pagination
    },
    pagination: {
      handler () {
        this.getRelatedValues()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.et-info {
  border-radius: 5px;
  border: 1px solid grey
}
</style>
