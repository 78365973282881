import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueLayers from 'vuelayers'
import { globalMixin } from './mixins/global-mixin'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import websocket from './services/websocket'
import PortalVue from 'portal-vue'
import i18n from './plugins/i18n'
import './registerServiceWorker'

Vue.config.productionTip = false
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.defaults.timeout = 20000
Vue.prototype.$axios = axios
Vue.prototype.$ws = websocket

let infiniteScroll = require('vue-infinite-scroll')

Vue.mixin(globalMixin)
Vue.use(infiniteScroll)
Vue.use(VueLayers)
Vue.use(PortalVue)


async function createVue () {
  const vuetifyData = await vuetify
  new Vue({
    router: router,
    i18n,
    store,
    vuetify: vuetifyData,
    render: h => h(App)
  }).$mount('#app')
}

createVue()

axios.get('settings/get-general')
  .then(response => {
    document.title = response.data.COMPANY_NAME

    let link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = response.data.COMPANY_FAVICON
    document.getElementsByTagName('head')[0].appendChild(link)

    document.querySelector('link[rel=\'shortcut icon\']').href = response.data.COMPANY_FAVICON
    document.querySelector("link[rel*='icon']").href = response.data.COMPANY_FAVICON
  })

axios.get('settings/get-font-config', {
  responseType: 'blob'
})
  .then(res => {
    if (res.data.size === 0) return
    const fontDataUrl = URL.createObjectURL(res.data)
    const fontName = 'Custom font'
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
      @font-face {
        font-family: '${fontName}';
        src: url(${fontDataUrl});
      }

      .v-application,
      .v-application .display-4,
      .v-application .display-3,
      .v-application .display-2,
      .v-application .display-1,
      .v-application .headline,
      .v-application .title,
      .v-application .subtitle-1,
      .v-application .subtitle-2,
      .v-application .body-1,
      .v-application .body-2,
      .v-application .caption,
      .v-application .overline {
        font-family: '${fontName}' !important;
      }
    `
    document.head.appendChild(style)
  })

