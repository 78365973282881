<template>
  <div class="report-placeholder-item my-4 pa-3">
    <div class="subtitle-1 font-weight-bold pl-2 pb-3">
      {{ placeholder.string }}
    </div>
    <v-autocomplete
      v-model="placeholder.abstractData"
      :append-icon="searchInput ? 'clear' : ''"
      :items="abstractDataList"
      :label="$t('abstractData')"
      :search-input.sync="searchInput"
      class="mb-3"
      dense
      hide-details
      item-text="name"
      outlined
      return-object
      @click:append="clear"
    />
    <v-text-field
      v-model="placeholder.settings.fontSize"
      :label="$t('fontSize')"
      dense
      hide-details
      outlined
      type="number"
    />
  </div>
</template>

<script>
import messages from '@/componet-locale/report-placeholder-item/messages'

export default {
  name: "ReportPlaceholderItem",
  props: {
    placeholder: Object
  },
  i18n: { messages },
  data: () => ({
    abstractDataList: [],
    searchInput: '',
    initialAbstractData: null
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.initialAbstractData = this.placeholder.abstractData
      this.searchForAbstractData()
    },
    searchForAbstractData () {
      this.$axios.get('report/search-abstract-data', {
        params: {
          projectId: this.selectedProject.id,
          str: this.searchInput || ''
        }
      })
        .then(res => {
          if (this.initialAbstractData && res.data.every(ad => ad.id !== this.initialAbstractData)) {
            this.abstractDataList = [this.initialAbstractData, ...res.data]
          } else {
            this.abstractDataList = res.data
          }
        })
        .catch(() => this.$emit('showErrorMessage', this.$t('error')))
    },
    clear () {
      this.searchInput = ''
      this.placeholder.abstractData = null
    }
  },
  watch: {
    searchInput (v) {
      if (typeof (v) === 'string') {
        this.searchForAbstractData()
      }
    }
  }
}
</script>

<style scoped>
.report-placeholder-item {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: none;
  background: #f6f4f2;
}
</style>
