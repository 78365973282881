export const displayServiceMixin = {
  data: () => ({
    windowWidth: 1000,
    windowHeight: 1000,
    clientHeight: 1000
  }),
  mounted () {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    this.clientHeight = document.documentElement.clientHeight
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight
        this.clientHeight = document.documentElement.clientHeight
      })
    })
  },
  computed: {
    displayXS () {
      return this.windowWidth < 601
    }
  }
}
