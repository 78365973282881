import { EventBus } from '@/event-bus'
import websocket from '@/services/websocket'
import axios from 'axios'
import Vue from 'vue'
import router from '../router'

export const actions = {
  login ({ commit }, payload) {
    let data = {
      username: payload.username,
      password: payload.password
    }
    return new Promise((resolve, reject) => {
      axios
        .post('/login', data, { timeout: 5000 })
        .then(() => {
          commit('setUser')
          commit('setUserProjects')
          Vue.prototype.$ws.connect() // connect websocket
        })
        .catch(error => {
          commit('clearAuth')
          reject(error)
        })
    })
  },
  logout ({ commit }) {
    commit('clearAuth')
    commit('unsubscribeAllChats')
    websocket.disconnect()
  },
  selectProject ({ commit }, payload) {
    commit('selectProject', payload)
  },
  updateSelectedProject ({ commit }, payload) {
    axios
      .get('/project/get', { params: { id: payload } })
      .then(response => commit('updateSelectedProject', response.data))
      .catch(() => router.push('/'))
  },
  removeProject ({ commit }, payload) {
    axios
      .get('/project/remove', { params: { id: payload } })
      .then(() => {
        commit('removeProject', payload)
        commit('updateUserData', payload)
        commit('setUserProjects')
        router.push('/')
      })
  },
  updateUserData ({ commit }, payload) {
    commit('updateUserData', payload)
    commit('setUserProjects')
  },
  setWebsocketConnection ({ commit }, payload) {
    commit('setWebsocketConnection', payload)
  },
  updateLastMessageAttributes ({ commit }, payload) {
    commit('updateLastMessageAttributes', payload)
  },
  increaseNumberOfUnreadMessages ({ commit }, payload) {
    commit('increaseNumberOfUnreadMessages', payload)
  },
  setOnlineUsers ({ commit }, payload) {
    commit('setOnlineUsers', payload)
  },
  removeFromOnlineUsers ({ commit }, payload) {
    commit('removeFromOnlineUsers', payload)
  },
  setGisCredentials ({ commit }, payload) {
    commit('setGisCredentials', payload)
  },
  setChatFilter ({ commit }, payload) {
    commit('setChatFilter', payload)
  },
  refreshChatListAndSubscribe ({ commit, dispatch, getters }, payload) {
    commit('refreshChatList', payload)

    // subscribe
    Object.keys(payload).forEach(key => {
      const item = payload[key]
      if (!item.subscribed) return
      const sub = websocket.client.subscribe(`/ws-broker/topic/${item.id}`,
        it => {
          const message = JSON.parse(it.body)
          commit('updateTopRecord', { message: message, chatID: key })
          if (message.messageKey === 'DELETED') {
            EventBus.$emit('mark-chat-record-as-deleted', { message: { ...message }, chatID: item.id })
            return
          }
          commit('increaseNumberOfUnreadMessages', item.id)
          EventBus.$emit('incoming-message', { message: { ...message }, chatID: item.id })

          // TODO: think about to make it a part of system messages
          if (message.messageKey === 'RENAMED') commit('renameChat', { message: message, chatID: key })
        })
      commit('saveSubscription', { id: item.id, subscription: sub })
    })
    dispatch('setWebsocketConnection', true)
  },
  detachChat ({ commit, getters }, payload) {
    commit('unsubscribe', payload.chatID)
    const chat = getters.getChatById(payload.chatID)
    if (chat.type === 'PRIVATE') {
      commit('removeChatFromList', payload.chatID)
      if (getters.activeChatID === payload.chatID) {
        getters.chats.length && commit('setActiveChatId', getters.chats[0].id)
      }
    } else {
      chat.subscribed = false
    }
  },
  onDeleteChat ({ commit, getters }, payload) {
    commit('unsubscribe', payload.chatID)
    commit('removeChatFromList', payload.chatID)
    if (getters.activeChatID === payload.chatID) {
      if (getters.chats.length) commit('setActiveChatId', getters.chats[0].id)
    }
  },
  onInviteChat ({ commit, dispatch }, payload) {
    dispatch('refreshChatListAndSubscribe', payload)
    EventBus.$emit('refresh-messages', payload[Object.keys(payload)[0]].id)
  },
  setActiveChatId ({ commit }, payload) {
    commit('setActiveChatId', payload)
  },
  setActiveModalChat ({ commit }, payload) {
    commit('setActiveModalChat', payload)
  },
  showArchivedChats ({ commit }) {
    commit('showArchivedChats')
  },
  showAllChats ({ commit }) {
    commit('showAllChats')
  },
  unsubscribeAll ({ commit }) {
    commit('unsubscribeAll')
  },
  setReturnToSearchLpDialog ({ commit }, payload) {
    commit('setReturnToSearchLpDialog', payload)
  },
  updateMapView ({ commit }, payload) {
    commit('updateMapView', payload)
  }
}
