<template>
  <div>
    <div v-if="selectedItem">
      <v-breadcrumbs class="pl-2">
        <v-breadcrumbs-item :href="getLinkToEt()">
          <span>{{ selectedItem.et.name }}</span>
          <v-icon class="ml-1" color="primary" x-small>mdi-open-in-new</v-icon>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
      <et-item-card
        :et="selectedItem.et"
        :item="selectedItem.props"
        style="margin-top: 15px"
      />
    </div>
    <div v-if="!selectedItem">
      <div v-for="(dbInfo, index) in infoFromDb" :key="'db-' + index">
        <v-list>
          <v-hover v-for="(item, index) in dbInfo.props" :key="'item-' + index" v-slot="{hover}" :disabled="displayXS">
            <v-list-item @click="selectItem(item, dbInfo)">
              <v-list-item-content>
                <v-list-item-title style="white-space: break-spaces" v-text="getItemTitle(item, dbInfo)"/>
                <v-list-item-subtitle>
                  <div :class="hover ? 'animated-text' : ''" style="width: fit-content">
                    {{ getItemBody(item, dbInfo) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import EtItemCard from '@/components/et/EtItemCard.vue'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/feature-card/messages'

export default {
  name: "FeatureDbFields",
  components: { EtItemCard },
  i18n: { messages },
  mixins: [displayServiceMixin],
  props: {
    infoFromDb: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    selectedItem: null
  }),
  created () {
    if (this.dbFieldsTotalCount === 1) {
      this.selectItem(this.infoFromDb[0].props[0], this.infoFromDb[0])
    }
  },
  methods: {
    selectItem (item, dbInfo) {
      this.$axios.get('data/get', {
        params: { id: dbInfo.tableId }
      })
        .then(res => this.selectedItem = { props: item, et: res.data })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('getTableError')))
      if (this.dbFieldsTotalCount > 1) this.$emit('showBackBtn', true)
    },
    back () {
      if (this.dbFieldsTotalCount === 1) return
      this.selectedItem = null
      this.$emit('showBackBtn', false)
    },
    getItemTitle (item, db) {
      const primaryHeaders = db.headers.filter(el => el.isPrimaryTableKey)
      if (primaryHeaders.length > 0) return `${db.tableName} [${item[primaryHeaders[0].alias]}]`
      return db.tableName
    },
    getItemBody (item, db) {
      const fields = []
      for (let [key, value] of Object.entries(item)) {
        fields.push(`${db.headers.filter(el => el.alias === key)[0].fieldName}: ${value}`)
      }
      return fields.join(' | ')
    },
    getLinkToEt () {
      return `${window.location.origin}/project/${this.$route.params.projectId}/database/${this.selectedItem.et.tableId}`
    }
  },
  computed: {
    dbFieldsTotalCount () {
      return this.infoFromDb.reduce((acc, dbItem) => {
        return acc + dbItem.props.length
      }, 0)
    }
  }
}
</script>

<style scoped>
.animated-text {
  white-space: nowrap;
  animation: line infinite linear;
  animation-duration: 10s;
  animation-delay: 1s;
}

@keyframes line {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-100%, 0);
  }
  50.01% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
