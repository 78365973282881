<template>
  <v-data-table
    :headers="headers"
    :items="items"/>
</template>

<script>
export default {
  name: 'ModbusUnitTable',
  props: ['items'],
  data: () => ({
    headers: [
      { text: 'Адрес', value: 'address' },
      { text: 'Имя переменной', value: 'name' },
      { text: 'Тип данных', value: 'dataType' },
      { text: 'Значение', value: 'value' },
      { text: 'Ед. измерения', value: 'postfix' }
    ]
  })
}
</script>
