<template>
  <v-card
    class="ma-2 search-result-row"
    outlined
    @click.native="show(item.name)"
  >
    <v-list-item class="pt-2" style="align-items: start">
      <v-list-item-content class="pt-0">
        <span
          :class="item.name ? '' : 'grey--text'"
          class="subtitle-2 mb-0"
          v-html="highlight(item.name || template.name)"
        />
        <span v-if="item.name" class="caption" v-html="highlight(template.name)"/>
        <span
          v-if="item.id"
          class="font-weight-thin caption"
        >
          #{{ item.id }}
        </span>
      </v-list-item-content>
      <v-list-item-action class="my-0 align-self-start">
        <v-btn icon @click.stop="openLayerPoiDialog">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card-actions>
      <v-chip label outlined small>
        <v-icon left small>layers</v-icon>
        <span v-html="highlight(item.layerName)"/>
      </v-chip>
      <v-spacer/>
      <layer-poi-badge :styleId="item.styleId" :template="template"/>
    </v-card-actions>
    <layer-poi-dialog ref="layerPoiDialog" :actions-toolbar="true"/>
  </v-card>
</template>

<script>
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'

export default {
  name: 'LayerPoiSearchResult',
  components: { LayerPoiDialog, LayerPoiBadge },
  props: {
    item: Object,
    searchField: String,
    template: Object
  },
  methods: {
    show () {
      this.$axios
        .get(`${this.isAuthenticated ? 'layer-poi/find-feature-card-dto' : 'public-data/get-lp-by-id'}`, {
          params: { id: this.item.id }
        })
        .then(response => {
          let poi = response.data
          poi.dComponent = 'layer-poi-feature-card'
          this.$emit('show', poi)
        })
    },
    highlight (name) {
      if (!name) return ''
      this.template.name
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    },
    openLayerPoiDialog () {
      this.$refs.layerPoiDialog.open(null, this.item.id)
    }
  }
}
</script>
