<template>
  <div>
    <div v-if="rules.length>0">
      <v-expansion-panels
        accordion
        multiple
      >
        <vuedraggable v-model="rules" handle=".handle" style="width: 100%">
          <header-rule
            v-for="(rule, index) in rules"
            :key="index"
            :headers="headers"
            :rule="rule"
            @remove="remove(index)"
          />
        </vuedraggable>
      </v-expansion-panels>
    </div>
    <div class="ml-4" v-else>
      {{ $t('noRules') }}
    </div>
    <div class="d-flex mt-4">
      <v-spacer/>
      <v-btn outlined @click="addRule">{{ $t('button.add') }}</v-btn>
    </div>
  </div>
</template>

<script>

import Vuedraggable from 'vuedraggable'
import messages from '@/componet-locale/et-rules/messages'
import { DEFAULT_COLORS } from '@/components/et/view-settings/utils'
import HeaderRule from '@/components/et/view-settings/HeaderRule.vue'

export default {
  name: 'RuleEditor',
  components: { HeaderRule, Vuedraggable },
  i18n: { messages },
  props: {
    rules: Array,
    headers: Array
  },
  methods: {
    addRule () {
      this.rules.push({
        headerIds: [],
        backgroundMode: true,
        textColorMode: false,
        backgroundColor: DEFAULT_COLORS.BACKGROUND_COLOR,
        textColor: DEFAULT_COLORS.TEXT_COLOR
      })
    },
    remove (index) {
      this.rules.splice(index, 1)
    }
  }
}
</script>
