<template>
  <div class="d-flex">
    <component
      :is="filter.type"
      v-for="filter in filters"
      v-if="filter.main"
      v-model:selected="filter.model.value"
      :label="$t(filter.label)"
      :model="filter.model"
      :project-id="projectId"
      shortView
      @change="change"
    />
    <sorting
      v-if="!displayXS && sorting.main"
      :label="$t(sorting.label)"
      :model="sorting.model"
      @change="change"/>

    <v-dialog v-model="expandedFilterDialog" max-width="700">
      <template v-slot:activator="{on}">
        <v-btn class="mx-2" icon style="margin-top: 2px" v-on="on">
          <v-icon>
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="px-3">
          {{ $t('expandedFilter') }}
        </v-card-title>
        <v-card-text class="d-flex flex-column px-3 py-2">
          <component
            :is="filter.type"
            v-for="filter in filters"
            v-model:selected="filter.model.value"
            :label="$t(filter.label)"
            :model="filter.model"
            :project-id="projectId"
            class="full-width mb-2"
            hideDetails
            shortView
            @change="change"
          />

          <sorting
            v-if="!displayXS && !sorting.main"
            :label="$t(sorting.label)"
            :model="sorting.model"
            @change="change"/>
        </v-card-text>
        <v-card-actions class="px-3">
          <v-btn outlined @click="this.clearFilters">
            <v-icon class="mr-2" small>mdi-restore</v-icon>
            {{ $t('reset') }}
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" outlined @click="expandedFilterDialog = false">
            {{ $t('apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import TextFieldFilter from './TextFieldFilter'
import ComboBoxFilter from './ComboBoxFilter'
import Sorting from './Sorting'
import StatusFilter from './StatusFilter'
import messages from '../../componet-locale/filters-panel/messages'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'

export default {
  name: 'FiltersPanel',
  components: {
    ComboBoxFilter,
    TextFieldFilter,
    Sorting,
    StatusFilter,
    CategoryPicker
  },
  mixins: [displayServiceMixin],
  i18n: { messages },
  props: {
    filterList: {
      type: Array,
      default: []
    },
    sorting: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    showFilters: true,
    expandedFilterDialog: false
  }),
  watch: {
    windowWidth (width) {
      this.showFilters = width >= 960
    }
  },
  computed: {
    filters () {
      return this.displayXS ? this.filterList.slice(0, 1) : this.filterList
    },
    projectId () {
      return +this.$route.params.projectId
    }
  },
  methods: {
    change () {
      this.$emit('change')
    },
    clearFilters () {
      this.filters.forEach(f => f.model.value = undefined)
      this.$emit('change')
    }
  }
}
</script>
