<template>
  <d-date-picker
    v-model="date"
    :disabled="disabled"
    :hide-details="hideDetails"
    :label="label"
    :outlined="outlined"
    :readonly="readonlyMode"
    dense
    format="YYYY-MM-DD"
  />
</template>

<script>
import DDatePicker from '@/components/utils/DDatePicker.vue'
import MultipleListField from '@/components/layer-poi/MultipleListField.vue'

export default {
  name: 'DateField',
  components: { MultipleListField, DDatePicker },
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    readonlyMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    datePickerMenu: false
  }),
  computed: {
    date: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  }
}
</script>
