<template>
  <div>
    <vl-layer-vector :z-index="6">
      <vl-source-vector :features.sync="features"/>
      <vl-style-box>
        <vl-style-circle :radius="10">
          <vl-style-stroke :width="3" color="red"/>
        </vl-style-circle>
        <vl-style-stroke :width="5" color="red"/>
      </vl-style-box>
    </vl-layer-vector>

    <v-menu :position-x="position.x"
            :position-y="position.y"
            absolute
            style="z-index: 20000"
            v-model="menu">
      <v-card>
        <v-list>
          <v-list-item v-for="item in menuItems" dense @click="item.action">
            <v-list-item-avatar>
              <v-icon
                small
                :color="item.color"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="pr-0">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-snackbar
      :timeout="8000"
      transition="scale-transition"
      v-model="snackbar"
    >
      <div class="caption">
        <div class="d-block">
          {{ displayName }}
        </div>
        <div class="d-block">
          {{ coordinate[1] + ', ' + coordinate[0] }}
        </div>
      </div>
      <div>
      </div>
      <v-btn icon @click="snackbar=false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import messages from '@/componet-locale/map-context-menu/messages'

export default {
  name: 'MapContextMenu',
  i18n: { messages: messages },
  props: { mapId: Number },
  data: () => ({
    menu: false,
    snackbar: false,
    coordinate: [],
    features: [],
    position: { x: 0, y: 0 },
    displayName: ''
  }),

  computed: {
    menuItems () {
      return [
        {
          icon: 'report_problem',
          title: 'Создать задачу',
          color: 'red',
          visible: !!this.currentLoginUser && !!this.mapId,
          action: () => this.createIssue()
        },
        {
          icon: 'add',
          title: 'Создать объект',
          color: 'gray',
          visible: !!this.currentLoginUser,
          action: () => this.createObject()
        },
        {
          icon: 'panorama',
          title: 'Панорама',
          color: 'gray',
          visible: true,
          action: () => this.panorama()
        },
        {
          icon: 'help',
          title: 'Что здесь?',
          color: 'gray',
          visible: true,
          action: () => this.showAddress()
        }
      ].filter(item => item.visible)
    }
  },
  methods: {
    open (evt, coordinate) {
      this.position = { x: evt.x, y: evt.y }
      this.displayName = ''
      this.snackbar = false
      this.menu = true
      this.coordinate = coordinate
      this.features = []

      this.$nextTick(() => {
        this.menu = true
      })
    },
    panorama () {
      let url = `https://yandex.ru/maps/?panorama%5Bfull%5D=true&panorama%5Bpoint%5D=${this.coordinate[0]}%2C${this.coordinate[1]}`
      window.open(url, '_blank').focus()
    },
    createObject () {
      this.menu = false
      this.$emit('createFeature', this.coordinate)
    },
    createIssue () {
      this.$emit('createMapIssue', JSON.stringify(this.coordinate))
    },
    showAddress () {
      fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.coordinate[1]}&lon=${this.coordinate[0]}&zoom=18&addressdetails=1&format=geojson&polygon_geojson=1`)
        .then(response => response.json())
        .then(response => {
          this.features = response.features
          this.displayName = response.features[0] ? response.features[0].properties.display_name : ''
          this.snackbar = true
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  watch: {
    snackbar (val) {
      if (val === false) this.features = []
    }
  }
}
</script>
