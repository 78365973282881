<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden">
      <v-card-text class="pa-2">
        <file-pond
          ref="pond"
          :allow-multiple="false"
          :label-idle="$t('loadFile')"
          :server="myServer"
          max-files="1"
        />
        <v-btn color="primary" small text @click="getTemplate('INSERT')">
          {{ $t('getInsertTemplate') }}
        </v-btn>
        <v-btn class="ml-2" color="primary" small text @click="getTemplate('UPDATE')">
          {{ $t('getUpdateTemplate') }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!isFileUploaded" color="primary" outlined @click="makeQuery('UPDATE')">
          <v-icon class="mr-1">mdi-database-edit</v-icon>
          {{ $t('update') }}
        </v-btn>
        <v-btn :disabled="!isFileUploaded" color="primary" outlined @click="makeQuery('INSERT')">
          <v-icon class="mr-1">mdi-database-plus</v-icon>
          {{ $t('insert') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import messages from '@/componet-locale/et-import/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'EtImportDialog',
  i18n: { messages },
  data () {
    return {
      dialog: false,
      data: null,
      file: {},
      loading: false,
      isFileUploaded: false,
      fileToken: null,
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        },
        revert: this.revertFile
      },
    }
  },
  methods: {
    open (data) {
      this.data = data
      this.dialog = true
    },
    close () {
      if (this.fileName) {
        this.revertFile()
      }
      this.dialog = false
    },
    async handleFileUpload (file, load) {
      this.loading = true
      let formData = new FormData()
      formData.append('file', file)

      this.$axios.post('/files/save-user-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 240000
      })
        .then(({ data }) => {
          const userFile = {}
          userFile.type = this.determineType(file)
          userFile.path = data
          userFile.size = file.size
          userFile.isShared = true
          userFile.name = file.name
          this.saveFile(load, userFile)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason)
        })
    },
    async saveFile (load, userFile) {
      this.$axios
        .post('/files/save-records', [userFile])
        .then(({ data }) => {
          this.file = data[0]
          load()
          this.isFileUploaded = true
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    determineType (file) {
      let types = ['xls', 'xlsx', 'csv']

      let fileExt = file.name.split('.').pop().toLowerCase()
      if (types.includes(fileExt)) {
        return fileExt
      } else {
        this.revertFile()
        EventBus.$emit('showInfoMessage', 'Расширение файла недопустимо')
        throw new Error("File extension not allowed")
      }
    },
    revertFile () {
      this.$axios.post('files/remove', null, {
        params: {
          ids: this.file.id
        }
      })
      this.file = null
      this.isFileUploaded = false
      this.fileName = null
    },
    makeQuery (importType = "INSERT") {
      this.$axios.post('et/import', {
        token: this.file.token,
        dataId: this.data.id,
        importType
      })
        .then(({ data }) => {
          EventBus.$emit('showSuccessMessage',
            data.queryType === 'INSERT' ? this.$t('insertSuccess') : this.$t('updateSuccess')
          )
          this.$emit('updateTable')
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'error'))
          console.error(e.message())
        })
    },
    getTemplate (importType) {
      this.$axios.get('et/import/get-template', {
        params: { etId: this.data.id, importType },
        responseType: 'arraybuffer'
      })
        .then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.href = url
            const name = this.data.name + ' ' + new Date().toLocaleDateString() || 'external-table'
            link.download = `${name}.xlsx`
            document.body.appendChild(link)
            link.click()
          }
        )
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'error'))
        })
    }
  }
}
</script>
