<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" persistent width="700">
      <v-card style="height: 90vh">
        <v-card-title>
          {{ $t('objectsByLayers') }}
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="overflow-y-auto py-0" style="height: calc( 90vh - 114px )">
          <div
            v-for="item in items"
            :key="item.layer.id"
            class="overflow-hidden mb-8"
            style="border-radius: 5px; border: 1px solid grey"
          >
            <v-list>
              <v-list-item>
                <v-hover v-slot="{hover}">
                  <v-list-item-title
                    :style="hover ? {cursor: 'pointer', color: 'var(--v-primary-base)'} : {}"
                    style="font-weight: bold"
                    @click="e => openInNewTab(item.layer, e)"
                  >
                    {{ item.layer.name }}
                  </v-list-item-title>
                </v-hover>
              </v-list-item>
              <div
                v-for="feature in item.features"
                :key="feature.id"
              >
                <v-divider/>
                <v-list-item
                  @click="openFeatureDialog(item.layer, feature)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      style="white-space: break-spaces"
                    >
                        <span v-if="item.layer.type === 'LAYER_POI'">
                          {{ getNameOfLayerPoi(feature) }}
                        </span>
                      <span v-else-if="item.layer.type === 'WMS'">
                          {{ feature.id }}
                        </span>
                      <span v-else-if="item.layer.type === 'ZWS'">
                          {{ feature.sys.value }}
                        </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div v-if="item.layer.type === 'LAYER_POI'">
                        {{ feature.id }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="splitScreenMode"
                      class="mr-2"
                      color="primary"
                      icon
                      @click.stop="emitOpenFeature(item.layer, feature)"
                    >
                      <v-icon>mdi-map-search</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
              <v-divider/>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <layer-poi-dialog
      ref="layerPoiDialog"
      :map-widget="!splitScreenMode"
      actions-toolbar
      readonly-mode
    />
    <wms-feature-dialog
      ref="wmsFeatureDialog"
    />
    <zws-feature-dialog
      ref="zwsFeatureDialog"
    />
  </div>
</template>

<script>
import { getNameOfLayerPoi } from '../../layer-poi/layer-poi-service'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import WmsFeatureDialog from '@/components/map/geoserver/WmsFeatureDialog.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/features-by-et-item-dialog/messages'
import ZwsFeatureDialog from '@/components/map/zws/ZwsFeatureDialog.vue'
import ZwsCommandBuilder from '@/services/zws-command-builder'

export default {
  name: "FeaturesByEtItemDialog",
  components: { ZwsFeatureDialog, WmsFeatureDialog, LayerPoiBadge, LayerPoiDialog },
  i18n: { messages },
  data: () => ({
    dialog: false,
    items: []
  }),
  methods: {
    getNameOfLayerPoi,
    async open (items) {
      this.items = items.reduce((acc, item) => {
        const layerIndex = acc.findIndex(el => el.layer.id === item.layer.id)
        if (layerIndex === -1) {
          return [...acc, item]
        } else {
          acc[layerIndex].features.push(...item.features)
          return acc
        }
      }, [])
      return await this.init()
    },
    async init () {
      const featuresCount = this.items.reduce((acc, item) => acc + item.features.length, 0)
      if (featuresCount === 0) {
        EventBus.$emit('showInfoMessage', this.$t('objectNotFound'))
      } else if (featuresCount === 1 && this.splitScreenMode) {
        switch (this.items[0].layer.type) {
          case 'LAYER_POI':
            return this.emitOpenLayerPoi(this.items[0].features[0].id)
          case 'WMS':
            return this.emitOpenWmsFeature(this.items[0].features[0])
          case 'ZWS':
            return this.emitOpenZwsFeature(this.items[0].layer, this.items[0].features[0])
        }
      } else if (featuresCount === 1) {
        await this.openFeatureDialog(this.items[0].layer, this.items[0].features[0])
      } else {
        this.dialog = true
      }
    },
    close () {
      this.dialog = false
    },
    async openFeatureDialog (layer, feature) {
      if (layer.type === 'LAYER_POI') {
        await this.$refs.layerPoiDialog.open(null, feature.id)
      } else if (layer.type === 'WMS') {
        await this.$refs.wmsFeatureDialog.open(feature, layer)
      } else if (layer.type === 'ZWS') {
        await ZwsCommandBuilder.getLayerTypes(layer)
        await this.$refs.zwsFeatureDialog.open(
          await ZwsCommandBuilder.getElemsByID(layer, [feature.sys.value])
            .then(features => features[0])
        )
      }
    },
    emitOpenFeature (layer, feature) {
      switch (layer.type) {
        case 'LAYER_POI':
          return this.emitOpenLayerPoi(feature.id)
        case 'WMS':
          return this.emitOpenZwsFeature(feature)
        case 'ZWS':
          return this.emitOpenZwsFeature(layer, feature)
      }
    },
    emitOpenLayerPoi (lpId) {
      EventBus.$emit('openLayerPoi', lpId)
      this.close()
    },
    emitOpenWmsFeature (feature) {
      EventBus.$emit('openWmsFeature', feature)
      this.close()
    },
    emitOpenZwsFeature (layer, feature) {
      EventBus.$emit('showFeature', layer, feature.sys.value)
      this.close()
    }
  },
  computed: {
    splitScreenMode () {
      return this.$route.matched[0].path.includes('split-screen')
    }
  }
}
</script>
