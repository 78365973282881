<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title>
        Копирование категории
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon v-html="'close'"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          autofocus
          hide-details
          outlined
          label="Название категории"
          @keyup.enter="copy"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          @click="copy"
          color="primary"
        >
          <v-icon left>
            content_copy
          </v-icon>
          Копировать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'CopyCategoryDialog',
  data: () => ({
    dialog: false,
    name: '',
    category: null
  }),
  methods: {
    open (category) {
      this.name = ''
      this.category = category
      this.dialog = true
    },
    copy () {
      if (this.name.length < 1) {
        alert('Введите название категории')
        return
      }
      this.$axios
        .post('category/make-copy', null, {
          params: {
            categoryId: this.category.id,
            name: this.name
          }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Копирование завершено')
          this.dialog = false
        })
    }
  }
}
</script>
