import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-item/messages'

export const etServiceMixin = {
  i18n: { messages },
  methods: {
    updateRow (et, item, selectedIds) {
      if (!et.headers.find(el => el.isPrimaryTableKey)) {
        console.error('Table has no primary header')
        EventBus.$emit('showErrorMessage', this.$t('updatingTableNotPossible'))
        return
      }
      const fieldsValue = []
      for (const prop in item) {
        if (et.headers.find(el => el.alias === prop) &&
          !et.headers.find(el => el.alias === prop).isComputedField
        ) {
          fieldsValue.push({
            header: et.headers.find(it => it.alias === prop),
            value: item[prop]
          })
        }
      }
      if (fieldsValue.length === 0) {
        console.error('No fields present to update')
        EventBus.$emit('showErrorMessage', this.$t('updatingTableNotPossible'))
        return
      }
      this.$axios.post('et/query/update', {
        tableId: et.id,
        fieldsValue,
        primaryKeyValues: selectedIds
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('tableUpdatedSuccessfully'))
          this.$emit('tableUpdated')
          this.close()
        })
        .catch(e => {
          if (e.status === 403) {
            EventBus.$emit('showErrorMessage', this.$t('noPermission'))
          } else {
            EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'UPDATE_ERROR'))
          }
        })
    },
    async deleteRow (et, selectedIds) {
      if (!et.headers.find(el => el.isPrimaryTableKey)) {
        console.error('no primary header present')
        EventBus.$emit('showInfoMessage', this.$t('deleteRowNotPossible'))
        return
      }
      if (selectedIds.some(el => el === null)) {
        console.error(`wrong primary key value`)
        EventBus.$emit('showInfoMessage', this.$t('deleteRowNotPossible'))
        return
      }
      this.$axios.post('et/query/delete', {
        tableId: et.id,
        primaryKeyValues: selectedIds
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('deleteSuccess'))
          this.$emit('tableUpdated')
          this.close()
        })
        .catch(e => {
          if (e.status === 403) {
            EventBus.$emit('showErrorMessage', this.$t('noPermission'))
          } else {
            if (e.data && e.data.description) {
              console.error(e.data.description)
            }
            EventBus.$emit('showErrorMessage', this.$t(e.data.message || 'DELETE_ERROR'))
          }
        })
    }
  }
}
