<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      style="z-index: 100000"
      width="700"
    >
      <v-card height="800">
        <v-card-text class="pb-0">
          <div
            v-for="({company, userList}) in usersByCompany"
          >
            <span class="subtitle-1">{{ company ? company : $t('others') }}</span>
            <v-chip
              class="ml-2"
              label
              outlined
              small
            >
              {{ userList.length }}
            </v-chip>
            <v-layout
              class="mb-3" row
              wrap
            >
              <v-flex
                v-for="user in userList"
                :key="user.id"
                sm6
                xs12
              >
                <user-card
                  :user="user"
                  chat-view
                  class="ma-2"
                  @close="close"
                >
                  <template v-slot:delete>
                    <v-btn
                      v-if="chat.type === 'PRIVATE' && currentLoginUser.id !== user.id"
                      icon
                      @click="callConfirmation(user)"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                  <template v-if="chat.ownerID === user.id" v-slot:chat-owner>
                    {{ $t('chatCreator') }}
                  </template>
                </user-card>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined @click="close">
            {{ $t('button.back') }}
          </v-btn>
          <v-spacer/>
          <v-dialog v-model="inviteDialog" width="500">
            <template v-slot:activator="{on}">
              <v-btn color="primary" outlined v-on="on">
                {{ $t('invite') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                {{ $t('inviteToChat') }}
              </v-card-title>
              <v-card-text class="px-2">
                <v-autocomplete
                  v-model="selectedNewMembers"
                  :item-text="(item) => (`${item.firstName} ${item.lastName}`)"
                  :items="newMembersList"
                  :placeholder="$t('inviteToChat')"
                  :search-input.sync="newMembersSearch"
                  chips
                  clearable
                  deletable-chips
                  dense
                  hide-details
                  hide-no-data
                  multiple
                  outlined
                  return-object
                  style="z-index: 10000 !important;"
                  @focus="find('')"
                >
                  <template v-slot:selection="{ item, index }">
                    <template>
                      <v-chip v-if="index === 0" label>
                        <user-avatar
                          :user="item"
                          small
                        />
                        {{ item.firstName }} {{ item.lastName }}
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                            + {{ selectedNewMembers.length - 1 }}
                          </span>
                    </template>
                  </template>
                  <template v-slot:item="{ item }" style="z-index: 100000 !important;">
                    <v-list-item-avatar>
                      <user-avatar
                        :user="item"
                        dense
                      />
                    </v-list-item-avatar>
                    <v-list-item-content style="z-index: 100000 !important;">
                      <v-list-item-title v-text="getUserFullName(item)"/>
                      <v-list-item-subtitle v-text="getUserInfo(item)"/>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-btn outlined @click="inviteDialog = false">
                  {{ $t('button.back') }}
                </v-btn>
                <v-spacer/>
                <v-btn
                  :disabled="selectedNewMembers.length === 0"
                  color="primary"
                  outlined
                  @click="inviteMembers"
                >
                  {{ $t('invite') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <chat-confirmation-dialog
      ref="confirmation"
      @confirm="remove"
    />
  </div>
</template>

<script>
import messages from '../../componet-locale/chat-invitation-dialog/messages'
import UserAvatar from '../utils/UserAvatar'
import ChatConfirmationDialog from './ChatConfirmationDialog'
import { chatMixin } from './mixins/chat-mixin'
import UserCard from '@/components/utils/UserCard.vue'
import UsersByCompanyDialog from '@/components/utils/UsersByCompanyDialog.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'ChatInvitationDialog',
  components: { UsersByCompanyDialog, UserCard, UserAvatar, ChatConfirmationDialog },
  mixins: [chatMixin],
  data: () => ({
    dialog: false,
    chat: {},
    usersByCompany: [],
    userIdToRemove: null,
    newMembersSearch: '',
    newMembersList: [],
    selectedNewMembers: [],
    inviteDialog: false
  }),
  i18n: { messages },
  methods: {
    async open (chat) {
      this.chat = { ...chat }
      this.getMembers()
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.usersByCompany = []
      this.userIdToRemove = null
      this.newMembersSearch = ''
      this.newMembersList = []
      this.selectedNewMembers = []
    },
    getMembers () {
      this.$axios
        .get('chats/members', { params: { chatId: this.chat.id } })
        .then(response => {
          const usersGroupedByCompany = _.groupBy(response.data, user => user.company || '')
          this.usersByCompany = Object.entries(usersGroupedByCompany)
            .map(([company, userList]) => ({ company, userList }))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          this.close()
        })
    },
    callConfirmation ({ id, firstName, lastName }) {
      this.userIdToRemove = id
      const title = `${this.$t('button.remove')} ${firstName} ${lastName} ${this.$t('fromChat')} ${this.chat.name}?`
      const body = this.$t('userNoParticipation')
      this.$refs.confirmation.open(title, body)
    },
    find (val = '') {
      this.$axios
        .get('users/users-not-members', {
          params:
            { str: val, chat: this.chat.id } // with empty 'val' returns first 10 rows
        })
        .then(response => this.newMembersList = response.data)
    },
    inviteMembers () {
      const membersIds = this.selectedNewMembers.map(i => i.id)
      this.$axios
        .post('chats/invite', membersIds, { params: { chatId: this.chat.id } })
        .then(() => {
          this.getMembers()
          this.selectedNewMembers = []
        })
    },
    remove () {
      this.$axios
        .get('chats/detach-member', {
          params: {
            member: this.userIdToRemove,
            chat: this.chat.id
          }
        })
        .then(() => this.getMembers())
    }
  },
  watch: {
    newMembersSearch (val) {
      if (val && val.length) {
        this.find(val)
      }
    }
  }
}
</script>
