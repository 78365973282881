<template>
  <div>
    <v-card-title>
      {{$t("issueTemplates")}}
      <v-spacer/>
      <v-btn
        outlined
        color="primary"
        @click="create"
      >
        <v-icon>add</v-icon>
        {{$t("create")}}
      </v-btn>
    </v-card-title>
    <v-list>
        <v-text-field
                v-model="searchField"
                prepend-inner-icon="search"
                solo
                clearable
                hide-details
                :placeholder="$t('searchIssueTemplate')"
                class="mx-4 mb-2"
        />
      <v-list-item
        v-for="(item, index) in filteredTemplateList"
        :key="index"
        @click="open(item)"
      >
      <span class="mx-4" v-html="highlight(item.name)"></span>
      </v-list-item>
    </v-list>
    <issue-template-dialog ref="issueTemplateDialog" @onSave="loadData"/>
  </div>
</template>

<script>
import IssueTemplateDialog from '@/components/admin/issue-template-management/IssueTemplateDialog'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/issue-template-management/messages'

export default {
  name: 'IssueTemplateManagement',
  components: { IssueTemplateDialog },
  i18n: {
    messages
  },
  data: () => ({
    searchField: '',
    templateList: []
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios
        .get('/issue-template/get-all')
        .then(response => {
          this.templateList = response.data
        })
        .catch(
          () => { EventBus.$emit('showErrorMessage', this.$t('errorLoadingData')) }
        )
    },
    open (item) {
      this.$refs.issueTemplateDialog.open(item)
    },
    create () {
      this.$refs.issueTemplateDialog.open()
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    }
  },
  computed: {
    filteredTemplateList () {
      if (!this.searchField) return this.templateList
      return this.templateList
        .filter(item =>
          item.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
    }
  }
}
</script>
