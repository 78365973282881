<template>
  <div>
    <div
      v-for="value in issue.additionalFields"
      v-if="issue.additionalFields"
      class="mb-3"
    >

      <v-text-field
        v-if="value.field.type === 'STRING'"
        v-model="value.string"
        :label="value.field.name"
        dense
        hide-details
        outlined
      />

      <v-list
        v-if="value.field.type === 'LIST'"
        v-model="value.value"
        :items="getOptions(value)"
        :label="value.field.name"
        dense
        hide-details
        outlined
      />

      <v-text-field
        v-if="value.field.type === 'NUMBER'"
        v-model="value.number"
        :label="value.field.name"
        dense
        hide-details
        outlined
        type="number"
      />

      <v-switch
        v-if="value.field.type === 'BOOLEAN'"
        v-model="value.boolean"
        :label="value.field.name"
        dense
        hide-details
      />

    </div>
  </div>
</template>

<script>
export default {
  name: 'IssueAdditionalFields',
  props: {
    issue: Object,
    template: Object
  },
  methods: {
    getOptions (value) {
      return value.field.options.split(',')
    },
    initAdditionalFields () {
      this.issue.additionalFields = this.issue.template.fields.map(
        field => {
          return { field: field }
        }
      )
    }
  },
  created () {
    if (this.issue.additionalFields.length === 0) {
      this.initAdditionalFields()
    }
  },
  watch: {
    template (val) {
      if (val) this.initAdditionalFields()
    }
  }
}
</script>
