import axios from 'axios'
import store from '../store'
import { EventBus } from '@/event-bus'

const parser = new DOMParser()

let props

function getProps () {
  if (props) return props

  const GIS_USERNAME = store.getters.gisCredentials ? store.getters.gisCredentials.GIS_USERNAME : ''
  const GIS_PASSWORD = store.getters.gisCredentials ? store.getters.gisCredentials.GIS_PASSWORD : ''
  const GIS_URL = store.getters.gisCredentials ? store.getters.gisCredentials.GIS_URL : window.location.origin + '/zws'

  let url = GIS_URL
  let auth = {
    username: GIS_USERNAME,
    password: GIS_PASSWORD
  }
  let config = {
    headers: { 'Content-Type': 'text/xml' },
    timeout: 120000,
    auth
  }
  return props = { url, auth, config }
}

async function getFromBuffer (key, layerId) {
  let { data } = await axios
    .get('zws/buffer', { params: { layerId, key } })
    .catch((error) => {
      console.error(error)
      return null
    })
  return data
}

function writeToBuffer (key, layerId, value) {
  axios.post('zws/buffer', {
    id: { layerId, key },
    value: JSON.stringify(value)
  })
}

class ZwsCommandBuilder {
  async tileLoader (tile, src) {
    let props = getProps()
    axios
      .post(props.url, src, {
        auth: props.auth,
        responseType: 'blob'
      })
      .then(response => tile.getImage().src = URL.createObjectURL(response.data))
  }

  getZwsRequest (layer) {
    if (!layer) return
    let labelsIds
    let themesIds = ''
    if (layer.themes) {
      themesIds = layer.themes
        .filter(t => t.isActive)
        .map(t => t.id)
        .join(' ')
    }
    if (layer.labels) {
      labelsIds = layer.labels
        .filter(l => l.isActive)
        .map(l => l.id)
        .join(' ')
    }
    return `<zulu-server service='zws' version='1.0.0'>
            <Command>
              <GetLayerTile>
                <x>{x}</x>
                <y>{y}</y>
                <z>{z}</z>
                <ShowDirection>yes</ShowDirection>
                <Layer>${layer.layerId}</Layer>
                <Themes>${themesIds}</Themes>
                <Labels>${labelsIds}</Labels>
              </GetLayerTile>
            </Command>
          </zulu-server>`
  }

  async getElemsByID (
    layer,
    objectIds,
    query = null,
    options = {
      excludeProps: false,
      excludeGeometry: false,
      excludeQueryList: false,
      excludeModeList: false
    }
  ) {

    const bodyOfQuery = query
      ? `<Queries><Query><BaseID>${query.baseId}</BaseID><TypeID>${query.typeId}</TypeID><Name>${query.name}</Name></Query></Queries>`
      : ''

    const xmlBodyStr =
      `<?xml version="1.0" encoding="UTF-8"?>
          <zulu-server service='zws' version='1.0.0'>
            <Command>
              <GetElemsByID>
                  <Layer>${layer.layerId}</Layer>
                  <ElemID>${objectIds.join(',')}</ElemID>
                  <Geometry>${options.excludeGeometry ? 'No' : 'Yes'}</Geometry>
                  <Attr>${options.excludeProps ? 'No' : 'Yes'}</Attr>
                  <QueryList>${options.excludeQueryList ? 'No' : 'Yes'}</QueryList>
                  <ModeList>${options.excludeModeList ? 'No' : 'Yes'}</ModeList>
                  ${bodyOfQuery}
              </GetElemsByID>
            </Command>
          </zulu-server>`

    const props = getProps()
    const response = await axios.post(props.url, xmlBodyStr, props.config)
    const xmlDoc = parser.parseFromString(response.data, 'text/xml')
    const elementsNodes = Array.from(xmlDoc.getElementsByTagName("Element"))
    const features = elementsNodes.map(node => this.parseFeatureFromNode(node, options))
    features.forEach(f => f.layer = layer)
    return features
  }

  async selectElemsByXY (
    layer,
    coordinate,
    scale,
    options = {
      excludeProps: true,
      excludeGeometry: true,
      excludeQueryList: true,
      excludeModeList: true
    }
  ) {
    let xmlBodyStr =
      `<?xml version="1.0" encoding="UTF-8"?>
          <zulu-server service='zws' version='1.0.0'>
            <Command>
              <SelectElemByXY>
                <Layer>${layer.layerId}</Layer>
                <X>${coordinate[1]}</X>
                <Y>${coordinate[0]}</Y>
                <Scale>${scale}</Scale>
                <CRS>EPSG:4326</CRS>
                <Attr>${options.excludeProps ? 'No' : 'Yes'}</Attr>
                <QueryList>${options.excludeQueryList ? 'No' : 'Yes'}</QueryList>
                <ModeList>${options.excludeModeList ? 'No' : 'Yes'}</ModeList>
              </SelectElemByXY>
            </Command>
          </zulu-server>`
    let props = getProps()
    let response = await axios.post(props.url, xmlBodyStr, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    const elementsNodes = Array.from(xmlDoc.getElementsByTagName("Element"))
    const features = elementsNodes.map(node => this.parseFeatureFromNode(node, options))
    features.forEach(f => f.layer = layer)
    return features
  }

  async getElemsByPolygonCoordinates (
    layer,
    coordinates,
    options = {
      excludeProps: true,
      excludeGeometry: true,
      excludeQueryList: true,
      excludeModeList: true
    }
  ) {
    const xmlBodyStr =
      `<?xml version="1.0" encoding="UTF-8"?>
          <zulu-server service='zws' version='1.0.0'>
            <Command>
              <LayerIntersectByPolygon>
                  <Layer>${layer.layerId}</Layer>
                  <CRS>EPSG:3857</CRS>
                  <Coordinates>${coordinates}</Coordinates>
                  <Geometry>${options.excludeGeometry ? 'No' : 'Yes'}</Geometry>
                  <Attr>${options.excludeProps ? 'No' : 'Yes'}</Attr>
                  <QueryList>${options.excludeQueryList ? 'No' : 'Yes'}</QueryList>
                  <ModeList>${options.excludeModeList ? 'No' : 'Yes'}</ModeList>
              </LayerIntersectByPolygon>
            </Command>
          </zulu-server>`
    let props = getProps()
    let response = await axios.post(props.url, xmlBodyStr, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    const elementsNodes = Array.from(xmlDoc.getElementsByTagName("Element"))
    const features = elementsNodes.map(node => this.parseFeatureFromNode(node, options))
    features.forEach(f => f.layer = layer)
    return features
  }

  parseFeatureFromNode (
    node,
    options = {
      excludeProps: true,
      excludeGeometry: true,
      excludeQueryList: true,
      excludeModeList: true
    }
  ) {
    const feature = {}

    feature.elemId = node.getElementsByTagName('ElemID')[0].childNodes[0].nodeValue
    feature.typeId = node.getElementsByTagName('TypeID')[0].childNodes[0].nodeValue

    if (!options.excludeGeometry) {
      const elementPlacemark = node.getElementsByTagName('Placemark')[0]
      const type = elementPlacemark.firstChild.nextSibling.nodeName
      let coordinates = elementPlacemark.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue
        .split('\n')
        .filter(it => it)
        .map(coordinate => coordinate
          .split(',')
          .map(it => Number(it))
        )
      if (type === 'Point') {
        coordinates = coordinates.flat()
      } else if (type === 'Polygon') {
        coordinates = [coordinates]
      }
      feature.geometry = { type, coordinates }
    }

    if (!options.excludeProps) {
      let fieldNodes = Array.from(node.getElementsByTagName('Field'))
      feature.props = []
      fieldNodes.forEach(field => feature.props.push(this.getProperty(field)))
    }

    if (!options.excludeModeList) {
      feature.modeList = Array.from(node.getElementsByTagName('Mode'))
        .map(it => ({
          index: it.getElementsByTagName('Index')[0].childNodes[0].nodeValue,
          title: it.getElementsByTagName('Title')[0].childNodes[0].nodeValue,
          image: it.getElementsByTagName('Image')[0] &&
            it.getElementsByTagName('Image')[0].childNodes[0] &&
            it.getElementsByTagName('Image')[0].nodeValue
        }))
      const modeIndex = node.getElementsByTagName('ModeNum')[0].childNodes[0].nodeValue
      feature.mode = feature.modeList.find(it => it.index === modeIndex)
    }

    if (!options.excludeQueryList) {
      feature.queries = Array.from(node.getElementsByTagName('Query'))
        .map(it => ({
          baseId: it.getElementsByTagName('BaseID')[0].childNodes[0].nodeValue,
          name: it.getElementsByTagName('Name')[0].childNodes[0].nodeValue,
          typeId: feature.typeId
        }))
      feature.activeQuery = node.getElementsByTagName('QueryName')[0]
        ? node.getElementsByTagName('QueryName')[0].childNodes[0].nodeValue
        : ''
    }

    if (Object.keys(options).some(key => options[key])) {
      feature.isShortInfoFeature = true
    }

    feature.dComponent = 'zws-feature-card'

    return feature
  }

  getProperty (xmlField) {
    let property = {
      name: xmlField.getElementsByTagName('Name')[0].childNodes[0].nodeValue,
      username: xmlField.getElementsByTagName('UserName')[0].childNodes[0].nodeValue,
      type: xmlField.getElementsByTagName('Type')[0].childNodes[0].nodeValue,
      value: xmlField.getElementsByTagName('Value')[0].childNodes[0] ? xmlField.getElementsByTagName('Value')[0].childNodes[0].nodeValue : '',
      url: xmlField.getElementsByTagName('URL')[0] && xmlField.getElementsByTagName('URL')[0].childNodes[0] ? xmlField.getElementsByTagName('URL')[0].childNodes[0].nodeValue : '',
      isReadonly: !!xmlField.getElementsByTagName('ReadOnly')[0],
      isKey: !!xmlField.getElementsByTagName('Key')[0]
    }

    let list = xmlField.getElementsByTagName('Book')
    property.isList = !!list[0]

    if (property.value && !property.isList) {
      if (property.type === 'float') {
        property.value = Number.parseFloat(property.value)
      } else if (property.type === 'integer') {
        property.value = Number.parseInt(property.value)
      }
    }

    if (property.isList) {
      let records = Array.from(list[0].getElementsByTagName('Record'))
      property.list = records.map(it => {
        return {
          code: it.getElementsByTagName('Code')[0].childNodes[0].nodeValue,
          value: it.getElementsByTagName('Value')[0].childNodes[0].nodeValue
        }
      })
    }
    return property
  }

  async getLayerThemes (layer) {
    const BUFFER_KEY = 'layer-themes'
    let themes = []
    let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    if (valueFromBuffer) {
      themes = valueFromBuffer
    } else {
      let request =
        `<?xml version="1.0" encoding="UTF-8"?>
          <zulu-server service="zws" version="1.0.0">
              <Command>
                  <GetLayerThemes>
                    <Layer>${layer.layerId}</Layer>
                  </GetLayerThemes>
              </Command>
          </zulu-server>`
      let props = getProps()
      let response = await axios
        .post(props.url, request, props.config)
        .catch((error) => {
          console.error(error)
          return null
        })
      if (response === null) return
      let xmlDoc = parser.parseFromString(response.data, 'text/xml')
      Array.from(xmlDoc.getElementsByTagName('Theme'))
        .forEach(theme => {
          themes.push({
            name: theme.getElementsByTagName('UserName')[0].childNodes[0].nodeValue,
            id: theme.getElementsByTagName('ID')[0].childNodes[0].nodeValue,
            isActive: false
          })
        })
      writeToBuffer(BUFFER_KEY, layer.layerId, themes)
    }
    layer.themes = themes
  }

  async getLayerProps (layer) {
    // const BUFFER_KEY = 'layer-props'
    // let props
    // let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    // if (valueFromBuffer) {
    //   props = valueFromBuffer
    // } else {
    let typeList = await this.getLayerTables(layer)
    let props
    props = new Map()
    for (const type of typeList) {
      const value = await this.getTableHeaders(layer, type.typeId)
      props.set(type.name, value)
    }
    // writeToBuffer(BUFFER_KEY, layer.layerId, props)
    // }
    layer.props = props
  }

  async getLayerLabels (layer) {
    const BUFFER_KEY = 'layer-labels'
    let labels
    let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    if (valueFromBuffer) {
      labels = valueFromBuffer
    } else {
      let parser = new DOMParser()
      let request =
        `<?xml version="1.0" encoding="UTF-8"?>
          <zulu-server service="zws" version="1.0.0">
              <Command>
                  <GetLayerLabels>
                    <Layer>${layer.layerId}</Layer>
                  </GetLayerLabels>
              </Command>
          </zulu-server>`
      let props = getProps()
      let response = await axios
        .post(props.url, request, props.config)
        .catch((error) => {
          console.error(error)
          return null
        })
      if (response === null) return
      let xmlDoc = parser.parseFromString(response.data, 'text/xml')
      labels = []
      Array.from(xmlDoc.getElementsByTagName('LabelLayer'))
        .forEach(label => {
          labels.push({
            name: label.getElementsByTagName('UserName')[0].childNodes[0].nodeValue,
            id: label.getElementsByTagName('ID')[0].childNodes[0].nodeValue,
            isActive: false
          })
        })
      writeToBuffer(BUFFER_KEY, layer.layerId, labels)
    }
    layer.labels = labels
  }

  async getLayerBounds (layer) {
    let request = `
        <zulu-server service="zws" version="1.0.0">
            <Command>
                <GetLayerBounds>
                  <Layer>${layer.layerId}</Layer>
                </GetLayerBounds>
            </Command>
        </zulu-server>`
    let props = getProps()
    let response = await axios.post(props.url, request, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    let bbox = xmlDoc.getElementsByTagName('BoundingBox')[0].attributes
    layer.bbox = {
      minx: Number(bbox.minx.nodeValue),
      miny: Number(bbox.miny.nodeValue),
      maxx: Number(bbox.maxx.nodeValue),
      maxy: Number(bbox.maxy.nodeValue)
    }
  }

  async getTable (layer, typeId, page = 0,
                  rowsPerPage = 1000) {
    let startIndex = page * rowsPerPage
    let xmlBodyStr =
      `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service="zws" version="1.0.0">
      <Command>
        <LayerExecSql>
          <Layer>${layer.layerId}</Layer>
          <Query>SELECT * where typeid=${typeId} limit ${startIndex}, ${rowsPerPage}</Query>
          <CRS>EPSG:4326</CRS>
        </LayerExecSql>
      </Command>
    </zulu-server>`
    let props = getProps()
    let response = await axios.post(props.url, xmlBodyStr, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    let records = Array.from(xmlDoc.getElementsByTagName('Record'))
    let rows = this.getRows(records)
    let keys = Object.keys(rows[0])

    let technicalsFields = [
      'typeid',
      'typename',
      'modeid',
      'modename',
      'area',
      'length',
      'length_sph',
      'perimeter',
      'text',
      'linecolor',
      'linestyle',
      'linewidth',
      'fillcolor',
      'fillstyle',
      'patcolor',
      'selected'
    ]
    let headers = keys
      .filter(key => !technicalsFields.includes(key))
      .map(key => {
        return {
          name: key,
          field: key,
          type: 'SText',
          typeBody: 'Default',
          styleHeader: ['sortable', 'column'],
          styleCell: '',
          checked: false,
          width: 150,
          isValue: true
        }
      })
    return {
      headers: headers,
      items: rows
    }
  }

  getRows (records) {
    let rows = []
    records.forEach(label => {
      let fields = Array.from(label.getElementsByTagName('Field'))
      let row = {}
      fields.forEach(field => {
        let name = field.getElementsByTagName('Name')[0].childNodes[0].nodeValue
        let value = field.getElementsByTagName('Value')[0].childNodes[0] ? field.getElementsByTagName('Value')[0].childNodes[0].nodeValue : ''
        row[name] = value
      })
      rows.push(row)
    })
    return rows
  }

  parseSearchRecords (records) {
    let rows = []
    records.forEach(label => {
      let fields = Array.from(label.getElementsByTagName('Field'))
      let row = {}
      fields.forEach(field => {
        let name = field.getElementsByTagName('Name')[0].childNodes[0].nodeValue.toLowerCase()
        let value = field.getElementsByTagName('Value')[0].childNodes[0] ? field.getElementsByTagName('Value')[0].childNodes[0].nodeValue : ''
        row[name] = {
          userName: name,
          value: value
        }
      })
      rows.push(row)
    })
    return rows
  }

  async getLayerTables (layer) {
    const BUFFER_KEY = 'layer-tables'
    let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    let typeList
    if (valueFromBuffer) {
      typeList = valueFromBuffer
    } else {
      let xmlBodyStr =
        `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service="zws" version="1.0.0">
      <Command>
        <GetLayerTypes>
          <Layer>${layer.layerId}</Layer>
        </GetLayerTypes>
      </Command>
    </zulu-server>`
      let props = getProps()
      let response = await axios
        .post(props.url, xmlBodyStr, props.config)
        .catch((error) => {
          console.error(error)
          return null
        })
      if (response === null) return
      let xmlDoc = parser.parseFromString(response.data, 'text/xml')
      typeList = Array.from(xmlDoc.getElementsByTagName('Type'))
        .map(type => {
          return {
            typeId: type.getElementsByTagName('Id')[0].childNodes[0].nodeValue,
            name: type.getElementsByTagName('Title')[0].childNodes[0].nodeValue
          }
        })
      writeToBuffer(BUFFER_KEY, layer.layerId, typeList)
    }
    return typeList
  }

  async getLayerTypes (layer) {
    const BUFFER_KEY = 'layer-types'
    let typeList = []
    let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    if (valueFromBuffer) {
      typeList = valueFromBuffer
    } else {
      let xmlBodyStr =
        `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service="zws" version="1.0.0">
      <Command>
        <GetLayerTypes>
          <Layer>${layer.layerId}</Layer>
          <ModeImage>
            <Width>32</Width>
            <Height>32</Height>
            <Format>DataURI</Format>
          </ModeImage>
        </GetLayerTypes>
      </Command>
    </zulu-server>`
      let props = getProps()
      let response = await axios
        .post(props.url, xmlBodyStr, props.config)
        .catch((error) => {
          console.error(error)
          return null
        })
      if (response === null) return
      let xmlDoc = parser.parseFromString(response.data, 'text/xml')
      let list = Array.from(xmlDoc.getElementsByTagName('Type'))
      list.forEach(type => {
        let modes = Array.from(type.getElementsByTagName('Mode'))
          .map(mode => ({
            index: mode.getElementsByTagName('Index')[0].childNodes[0].nodeValue,
            title: mode.getElementsByTagName('Title')[0].childNodes[0].nodeValue,
            image: mode.getElementsByTagName('Image')[0] ? mode.getElementsByTagName('Image')[0].childNodes[0].nodeValue : ''
          }))
        let obj = {
          typeId: type.getElementsByTagName('Id')[0].childNodes[0].nodeValue,
          name: type.getElementsByTagName('Title')[0].childNodes[0].nodeValue,
          graphType: type.getElementsByTagName('GraphType')[0] ? type.getElementsByTagName('GraphType')[0].childNodes[0].nodeValue : '',
          modes: modes
        }
        typeList.push(obj)
      })
    }
    writeToBuffer(BUFFER_KEY, layer.layerId, typeList)
    layer.typeList = typeList
    return typeList
  }

  async getTableHeaders (layer, typeId) {
    const BUFFER_KEY = 'table-headers'
    let typeList = []
    let valueFromBuffer = await getFromBuffer(BUFFER_KEY, layer.layerId)
    if (valueFromBuffer) {
      typeList = valueFromBuffer
    } else {
      let xmlBodyStr =
        `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service="zws" version="1.0.0">
      <Command>
        <GetLayerBaseInfo>
          <Layer>${layer.layerId}</Layer>
          <TypeID>${typeId}</TypeID>
        </GetLayerBaseInfo>
      </Command>
    </zulu-server>`
      let props = getProps()
      let response = await axios
        .post(props.url, xmlBodyStr, props.config)
        .catch((error) => {
          console.error(error)
          return null
        })
      if (response === null) return
      let xmlDoc = parser.parseFromString(response.data, 'text/xml')
      let fields = Array.from(xmlDoc.getElementsByTagName('Field'))
      fields.forEach(field => {
        typeList.push({
          name: field.getElementsByTagName('Name')[0].childNodes[0].nodeValue,
          userName: field.getElementsByTagName('UserName')[0].childNodes[0].nodeValue,
          type: field.getElementsByTagName('Type')[0].childNodes[0].nodeValue
        })
      })
      // writeToBuffer(BUFFER_KEY, layer.layerId, typeList)
    }
    return typeList
  }

  updateElemAttributes (elem) {
    let { layer, props } = elem
    let body = ''

    props.forEach(p => {
      if (p.isKey) {
        body += `
            <Key>
              <Name>${p.name}</Name>
              <Value>${p.value}</Value>
            </Key>`
      } else {
        body += `
            <Field>
              <Name>${p.name}</Name>
              <Value>${p.value}</Value>
            </Field>`
      }
    })

    let request = `
      <zulu-server service="zws" version="1.0.0">
        <Command>
          <UpdateElemAttributes>
            <Layer>${layer.layerId}</Layer>
            <Element>${body}</Element>
          </UpdateElemAttributes>
        </Command>
      </zulu-server>`
    let gisProps = getProps()
    axios
      .post(gisProps.url, request, gisProps.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Saved')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async addPolygon (layer, coordinates) {
    let request = `
      <zulu-server service="zws" version="1.0.0">
        <Command>
          <LayerAddPolygon>
            <Layer>${layer.layerId}</Layer>
            <TypeID>15</TypeID>
            <ModeNum>1</ModeNum>
            <CRS>EPSG:4326</CRS>
            <coordinates>${coordinates}</coordinates>
          </LayerAddPolygon>
        </Command>
      </zulu-server>`
    let props = getProps()
    axios
      .post(props.url, request, props.config)
      .then(response => {
        return response.data
      })
  }

  async getBboxForLayerList (layerList) {
    let bbox = {
      miny: 100000.0,
      minx: 100000.0,
      maxy: -100000.0,
      maxx: -100000.0
    }
    for (let layer of layerList) {
      await this.getLayerBounds(layer)
      if (layer.bbox.minx < bbox.minx) bbox.minx = layer.bbox.minx
      if (layer.bbox.miny < bbox.miny) bbox.miny = layer.bbox.miny
      if (layer.bbox.maxx > bbox.maxx) bbox.maxx = layer.bbox.maxx
      if (layer.bbox.maxy > bbox.maxy) bbox.maxy = layer.bbox.maxy
    }
    return bbox
  }

  async layerBatchEdit (events) {
    let actions = events.map(event => this.getEditRow(event))
    for (let event in events) {
      actions.push()
    }
    let request =
      `<zulu-server service='zws' version='1.0.0'>
        <Command>
           <LayerBatchEdit>${actions}</LayerBatchEdit>
        </Command>
      </zulu-server>`
    let props = getProps()
    axios
      .post(props.url, request, props.config)
      .then(response => {
        return response.data
      })
  }

  getEditRow (event) {
    let eventType = event.isDragging ? 'LayerMoveNode' : 'LayerInsertNode'
    return `
              <${eventType}>
                  <Layer>${event.layer.layerId}</Layer>
                  <ElemID>${event.el}</ElemID>
                  <CRS>EPSG:4326</CRS>
                  <X>${event.x}</X>
                  <Y>${event.y}</Y>
                  <SegmentId>_0_0</SegmentId>
                  <NodeIdx>${event.index}</NodeIdx>
              </${eventType}>`
  }

  async search (layer, searchValue, onlyById = false, typeId = null, modeId = null) {
    let sqlQuery = ''
    let attributes = Array.from(layer.props.values())
      .flat()
      .map(it => {
        return {
          name: it.name.split('::')[1],
          userName: it.userName,
          type: it.type
        }
      })
      .filter(item => {
        let list = ['dname', 'sys']
        let obligatoryField = list.includes(item.name.toLowerCase())
        let name = item.name.split('-').pop()
        return name === 'dsearch' || obligatoryField
      })
    let data = attributes.reduce((unique, o) => {
      if (!unique.some(obj => obj.name === o.name)) unique.push(o)
      return unique
    }, [])
    if (onlyById) {
      sqlQuery = `[Sys]=${searchValue}`
    } else {
      data
        .filter(item => !(item.type === 'integer' && isNaN(+searchValue)))
        .forEach(item => {
          if (sqlQuery) sqlQuery += ' OR'
          sqlQuery += predicate(item, searchValue)
        })

      function predicate (item, searchValue) {
        return item.type === 'string' ? ` LOWER([${item.name}]) LIKE '%${searchValue.toString().trim().toLowerCase()}%'` : ` [${item.name}] = "${searchValue}"`
      }
    }

    if (typeId) {
      sqlQuery += `AND typeid=${typeId}`
    }
    if (modeId) {
      sqlQuery += ` AND modeid=${modeId}`
    }

    let listOfNames = data.filter(it => it.name != null).map(it => `[${it.name}]`).join(', ')
    let xmlBodyStr =
      `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service="zws" version="1.0.0">
      <Command>
        <LayerExecSql>
          <Layer>${layer.layerId}</Layer>
          <Query>
            SELECT ${listOfNames ? listOfNames + ', ' : ''} modename, typename, typeId, modeId, Sys
            WHERE ${sqlQuery}
            LIMIT 50
          </Query>
          <CRS>EPSG:4326</CRS>
        </LayerExecSql>
      </Command>
    </zulu-server>`
    let parser = new DOMParser()
    let props = getProps()
    let response = await axios.post(props.url, xmlBodyStr, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    let records = Array.from(xmlDoc.getElementsByTagName('Record'))

    let rows = this.parseSearchRecords(records)
    return rows.map(row => ({ row: row, layer: layer }))
  }

  async getLayerList (url) {
    let request =
      `<zulu-server service='zws' version='1.0.0'>
            <Command>
              <GetLayerList></GetLayerList>
            </Command>
        </zulu-server>`
    let parser = new DOMParser()
    let props = getProps()
    let config = Object.assign({}, getProps().config)
    props.timeout = 120000
    let response = await axios.post(props.url, request, config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    let list = []
    let layers = xmlDoc.getElementsByTagName('Layer')
    for (let layer of layers) {
      let name = layer.getElementsByTagName('Title')[0].childNodes[0].nodeValue
      let layerId = layer.getElementsByTagName('Name')[0].childNodes[0].nodeValue
      list.push({
        name: name,
        layerId: layerId
      })
    }
    return list
  }

  async updateState (feature, sys) {
    let request =
      `<zulu-server service='zws' version='1.0.0'>
            <Command>
              <SetElemState>
                  <Layer>${feature.layer.layerId}</Layer>
                  <ElemID>${sys}</ElemID>
                  <Type>${feature.typeId}</Type>
                  <Mode>${feature.mode.index}</Mode>
              </SetElemState>
            </Command>
        </zulu-server>`
    let props = getProps()
    await axios
      .post(props.url, request, props.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Saved')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async deleteFeature (feature, sys) {
    let request =
      `<zulu-server service='zws' version='1.0.0'>
            <Command>
              <LayerDeleteGroup>
                  <Layer>${feature.layer.layerId}</Layer>
                  <ElemID>${sys}</ElemID>
              </LayerDeleteGroup>
            </Command>
        </zulu-server>`
    let props = getProps()
    await axios
      .post(props.url, request, props.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Successfully deleted')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async layerAddPolygon (feature, coordinates, projection = 'EPSG:4326') {
    if (!coordinates || coordinates.length < 3) {
      EventBus.$emit('showErrorMessage', 'Error')
      return
    }

    let request = `
    <zulu-server service="zws" version="1.0.0">
    <Command>
        <LayerAddPolygon>
            <Layer>${feature.layer.layerId}</Layer>
            <TypeID>${feature.typeId}</TypeID>
            <ModeNum>${feature.mode.index}</ModeNum>
            <CRS>${projection}</CRS>
            <coordinates>${coordinates}</coordinates>
        </LayerAddPolygon>
      </Command>
    </zulu-server>`

    let props = getProps()

    await axios
      .post(props.url, request, props.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Saved')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async LayerAddPolyline (feature, coordinates, projection = 'EPSG:4326') {
    if (!coordinates || coordinates.length < 2) {
      EventBus.$emit('showErrorMessage', 'Error')
      return
    }

    let request = `
    <zulu-server service="zws" version="1.0.0">
    <Command>
        <LayerAddPolyline>
            <Layer>${feature.layer.layerId}</Layer>
            <TypeID>${feature.typeId}</TypeID>
            <ModeNum>${feature.mode.index}</ModeNum>
            <CRS>${projection}</CRS>
            <coordinates>${coordinates}</coordinates>
        </LayerAddPolyline>
      </Command>
    </zulu-server>`

    let props = getProps()

    await axios
      .post(props.url, request, props.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Saved')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async layerAddSymbol (feature, coordinate, projection = 'EPSG:4326') {
    if (!coordinate || coordinate.length < 2) {
      EventBus.$emit('showErrorMessage', 'Error')
      return
    }

    let request =
      `<zulu-server service='zws' version='1.0.0'>
            <Command>
              <LayerAddSymbol>
                  <Layer>${feature.layer.layerId}</Layer>
                  <CRS>${projection}</CRS>
                  <X>${coordinate[1]}</X>
                  <Y>${coordinate[0]}</Y>
                  <TypeID>${feature.typeId}</TypeID>
                  <ModeNum>${feature.mode.index}</ModeNum>
              </LayerAddSymbol>
            </Command>
        </zulu-server>`
    let props = getProps()

    await axios
      .post(props.url, request, props.config)
      .then(() => {
        EventBus.$emit('showSuccessMessage', 'Saved')
      })
      .catch(() => {
        EventBus.$emit('showErrorMessage', 'Error')
      })
  }

  async getLayerFieldBlobList (obj) {
    let request =
      `<zulu-server service='zws' version='1.0.0'>
            <Command>
                <GetLayerFieldBlobList>
                    <Layer>${obj.layer}</Layer>
                    <Keys>
                        <Key>
                            <Name>${obj.key.name}</Name>
                            <Value>${obj.key.value}</Value>
                        </Key>
                    </Keys>
                    <TypeID>${obj.typeId}</TypeID>
                    <Field>${obj.field}</Field>
                </GetLayerFieldBlobList>
            </Command>
        </zulu-server>`
    let props = getProps()

    let response = await axios.post(props.url, request, props.config)
    let xmlDoc = parser.parseFromString(response.data, 'text/xml')
    let items = Array.from(xmlDoc.getElementsByTagName('BlobItem'))
    return items.map(label => {
      return {
        name: label.getElementsByTagName('Name')[0].childNodes[0].nodeValue,
        fullName: label.getElementsByTagName('FullName')[0].childNodes[0].nodeValue,
        url: label.getElementsByTagName('URL')[0].childNodes[0].nodeValue
      }
    })
  }

  async getBlob (request) {
    let url = window.location.origin + '/zws' + request.substring(4)
    let config = { ...props.config }
    config.responseType = 'blob'
    let response = await axios.get(url, config)
    let reader = new FileReader()
    reader.readAsDataURL(response.data)
    let base64 = await new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
    return base64
  }

  async getHtmlPage (request) {
    let url = store.getters.gisCredentials.GIS_URL + request.substring(4)
    let config = { ...props.config }
    let response = await axios.get(url, config)
    return response.data
  }

  setHtmlPage (request, value) {
    let url = store.getters.gisCredentials.GIS_URL + request.substring(4)
    // let req = '/zws/GetElemBlob/co7%3APloshchadki/1_Ploshchadki_1::Sys:1/Ploshchadki_1%3A%3Adhtml-GrafikVivozaTKO?FileName=data.txt&BaseID=1&QueryName=%D0%9F%D0%BE%D0%BB%D0%BD%D1%8B%D0%B9+1'
    // let url = '/zws/SetElemBlob/co7%3APloshchadki/Ploshchadki_1%3A%3ASys%3A1/Ploshchadki_1%3A%3Adhtml-GrafikVivozaTKO?BaseID=1&QueryName=%D0%9F%D0%BE%D0%BB%D0%BD%D1%8B%D0%B9+1'
    axios.post(url, props.config, value)
  }

  analyzeNetworkSwitch (layerId, networkAnalyzeSwitchFeaturesDto) {
    let url = store.getters.gisCredentials.GIS_URL
    const body = `<?xml version="1.0" encoding="UTF-8"?>
        <zulu-server service='zws' version='1.0.0'>
            <Command lang='ru'>
                <NetworkAnalyzeSwitch>
                    <Layer>${layerId}</Layer>
                    <Sections>Yes</Sections>
                    <AllNodes>Yes</AllNodes>
                    ${networkAnalyzeSwitchFeaturesDto.map(el =>
      `<Element>
                          <ElemID>${el.id}</ElemID>
                          <Mode>${el.modeId}</Mode>
                      </Element>`).join()}
                </NetworkAnalyzeSwitch>
            </Command>
        </zulu-server>`

    return axios.post(url, body, getProps())
      .then(res => parser.parseFromString(res.data, 'text/xml'))
      .then(xmlDoc => Array.from(xmlDoc.getElementsByTagName('Element'))
        .map(el => ({
            id: el.getElementsByTagName('ElemID')[0].childNodes[0].nodeValue,
            state: el.getElementsByTagName('State')[0].childNodes[0].nodeValue,
          })
        )
      )
  }

  analyzeNetworkRecalc (layerId, sys, isolate = false) {
    let url = store.getters.gisCredentials.GIS_URL

    const body =
      `<?xml version="1.0" encoding="UTF-8"?>
      <zulu-server service='zws' version='1.0.0'>
        <Command lang='ru'>
          <LayerGetSwitchingNodes>
            <Layer>${layerId}</Layer>
            <ElemID>${sys}</ElemID>
            <Isolate>${isolate ? 'Yes' : 'No'}</Isolate>
          </LayerGetSwitchingNodes>
        </Command>
      </zulu-server>`

    return axios.post(url, body, getProps())
      .then(res => parser.parseFromString(res.data, 'text/xml'))
      .then(xmlDoc => Array.from(xmlDoc.getElementsByTagName('Element'))[0]
        .getElementsByTagName('Keys')[0].childNodes[0].nodeValue
        .split(' ')
        .map(el => +el)
      )
  }

  layerFindAnalyze (layerId, layerFindSimulationDto, simulationType = 'LayerFindConnected') {
    let url = store.getters.gisCredentials.GIS_URL
    const body =
      `<?xml version="1.0" encoding="UTF-8"?>
       <zulu-server service='zws' version='1.0.0'>
           <Command lang='ru'>
               <${simulationType}>
                   <Layer>${layerId}</Layer>
                   <FlagID>${layerFindSimulationDto.flagIds.join(' ')}</FlagID>
                   <StateOn>${layerFindSimulationDto.circleIds.join(' ')}</StateOn>
                   <StateOff>${layerFindSimulationDto.crossIds.join(' ')}</StateOff>
                   <IgnoreState>${layerFindSimulationDto.ignoreState}</IgnoreState>
                </${simulationType}>
           </Command>
       </zulu-server>`

    return axios.post(url, body, getProps())
      .then(res => parser.parseFromString(res.data, 'text/xml'))
      .then(xmlDoc => {
          const keysNode = Array.from(xmlDoc.getElementsByTagName(simulationType))[0]
            .getElementsByTagName('Keys')[0]
          if (!keysNode) {
            return []
          } else {
            return keysNode.childNodes[0].nodeValue
              .split(' ')
              .map(el => +el)
          }
        }
      )
  }
}

export default new

ZwsCommandBuilder()
