<template>
  <v-tooltip
    top
    color="black"
  >
    <template v-slot:activator="{ on }">
      <v-chip
        class="ml-2"
        v-on="on"
        label
        outlined
        dense
      >
        <layer-poi-badge :template="template"/>
        <span v-html="count"/>
      </v-chip>
    </template>
    <span
      v-html="template.name"
      class="caption"
    />
  </v-tooltip>
</template>

<script>
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge'
export default {
  name: 'LayerPoiChip',
  components: { LayerPoiBadge },
  props: {
    template: Object,
    count: Number
  }
}
</script>

<style scoped>

</style>
