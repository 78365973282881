<template>
  <vl-layer-tile
    :z-index="zIndex"
  >
    <vl-source-wms
      ref="wmsLayer"
      :layers="layer.layerId"
      :url="url"
      server-type="geoserver"
    />
  </vl-layer-tile>
</template>

<script>
import { WMSGetFeatureInfo } from 'ol/format'

export default {
  name: 'WmsLayer',
  props: { layer: Object, resolution: Number, zIndex: Number },
  data: () => ({
    featureTypes: null,
    targetNamespace: null,
    targetPrefix: null,
    typeName: null
  }),
  async created () {
    let response = await this.describeFeatureType()
    this.layer._ds = {
      featureTypes: response.featureTypes[0].properties,
      typeName: response.featureTypes[0].typeName,
      targetNamespace: response.targetNamespace,
      targetPrefix: response.targetPrefix
    }
  },
  methods: {
    async getFeatures (coordinate) {
      let features = []
      let source = this.$refs.wmsLayer.$source
      let url = source.getGetFeatureInfoUrl(
        coordinate,
        this.resolution,
        'EPSG:3857',
        { 'INFO_FORMAT': 'application/vnd.ogc.gml' }
      )
      let { data } = await this.$axios.get(url)
      let parser = new WMSGetFeatureInfo()
      features = parser.readFeatures(data)
      if (features && features.length) {
        features.forEach(it => {
          it.layer = this.layer
          it.featureTypes = this.layer._ds.featureTypes
          it.dComponent = 'geo-server-feature-card'
        })
      }
      return features || []
    },
    async describeFeatureType () {
      let { data } = await this.$axios
        .get(this.wfsUrl, {
          params: {
            service: 'wfs',
            version: '2.0.0',
            request: 'DescribeFeatureType',
            typeNames: this.layer.layerId,
            outputFormat: 'application/json'
          }
        })
      return data
    },
    refresh () {
      // TODO impl
    },
    getLegend () {
      // TODO impl
      // example: http://localhost:9000/geoserver/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=topp:states&format=application/json
    },
    getBBox () {
      // TODO impl
    }
  },
  computed: {
    url () {
      return window.location.origin + '/geoserver/wms'
    },
    wfsUrl () {
      return window.location.origin + '/geoserver/wfs'
    }
  }
}
</script>
