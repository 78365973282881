<template>
  <div @click="focusPage"
       v-visible.once="drawPage"
       :class="{ focused: isPageFocused }"
       class="pdf-thumbnail mt-2">
    <img v-if="src" :src="src" class="elevation-1"/>
    <div v-else class="placeholder box-shadow">
      <div class="content">
        <v-progress-circular indeterminate
                             class="my-4"
                             :size="40"
                             :width="4"
                             color="purple"/>
      </div>
    </div>
    <span class="page-number mt-1">{{ pageNumber }}</span>
  </div>
</template>

<script>
import visible from '@/directives/visible'

export default {
  name: 'PdfThumbnail',
  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true
    },
    isPageFocused: {
      type: Boolean,
      default: false
    },
    isPageVisible: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    visible
  },
  data: () => ({
    src: undefined,
    scale: 2
  }),
  computed: {
    viewport () {
      return this.page.getViewport(1.0)
    },
    pageNumber () {
      return this.page.pageNumber
    }
  },
  methods: {
    focusPage () {
      this.$emit('page-focus', this.pageNumber)
    },
    drawPage () {
      if (this.renderTask) return

      const { viewport } = this
      const canvas = document.createElement('canvas')
      const canvasContext = canvas.getContext('2d')
      const renderContext = { canvasContext, viewport }
      canvas.height = viewport.height
      canvas.width = viewport.width

      this.renderTask = this.page.render(renderContext)
      this.renderTask
        .then(() => {
          this.src = canvas.toDataURL()
          canvas.width = 0
          canvas.height = 0
        })
        .then(() => {
          this.$emit('thumbnail-rendered', {
            page: this.page,
            text: `Rendered thumbnail ${this.pageNumber}`
          })
        })
        .catch(response => {
          this.destroyRenderTask()
          this.$emit('thumbnail-errored', {
            response,
            page: this.page,
            text: `Failed to render thumbnail ${this.pageNumber}`
          })
        })
    },
    destroyPage (_newPage, page) {
      if (page) page._destroy()
      this.destroyRenderTask()
    },
    destroyRenderTask () {
      if (!this.renderTask) return

      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      this.renderTask.cancel()
      delete this.renderTask
    }
  },
  watch: {
    page: 'destroyPage'
  },
  mounted () {
  },
  beforeDestroy () {
    this.destroyPage(undefined, this.page)
  }
}
</script>

<style scoped>
.pdf-thumbnail {
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

img, .placeholder {
  width: 65%;
}

/*.pdf-thumbnail.focused img {*/
/*  border-color: rgba(68, 204, 139, 0.65);*/
/*}*/

.placeholder {
  background: white;
  background-clip: content-box;
  position: relative;
}

.placeholder:before {
  content: '';
  display: block;
  padding-top: 75%;
}

.placeholder .content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-number {
  color: grey;
  font-weight: bold;
}
</style>
