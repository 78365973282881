import moment from 'moment'
import { roleService } from '@/services/role-service'

const menuItems = [
  {
    title: 'reports',
    icon: 'description',
    value: 'REPORT',
    link: 'report',
    required: true
  },
  {
    title: 'documents',
    icon: 'book',
    value: 'DOC',
    link: 'doc',
    required: true
  },
  {
    title: 'tables',
    icon: 'table_chart',
    value: 'TABLE',
    link: 'table',
    required: true
  },
  {
    title: 'maps',
    icon: 'map',
    value: 'MAP',
    link: 'map',
    required: true
  },
  {
    title: 'layers',
    icon: 'layers',
    value: 'LAYER',
    link: 'layer',
    required: false
  },
  {
    title: 'charts',
    icon: 'insert_chart',
    value: 'CHART',
    link: 'chart',
    required: true
  },
  {
    title: 'calculations',
    icon: 'functions',
    value: 'CALC',
    link: 'functions',
    required: false
  },
  {
    title: 'modbus-units',
    icon: 'device_hub',
    value: 'CALC',
    link: 'calc'
  },
  {
    title: 'databases',
    icon: 'mdi-database',
    value: 'ET',
    link: 'database'
  }
]

export const globalMixin = {
  methods: {
    logout () {
      this.$ws.disconnect()
      this.$axios.post('logout')
      this.$store.dispatch('logout')
    },
    openInNewTab (item, event = null, query = null, poi = null) {
      let view = {
        name: this.getUrlByDataType(item.dataType).view,
        params: { id: item.id, poiProp: poi },
        query: query
      }
      if (event && (event.shiftKey || event.ctrlKey)) {
        let routeData = this.$router.resolve(view)
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(view)
      }
    },
    openIssue (issue, event = null) {
      let view = {
        name: 'view-issue',
        params: { id: issue.id }
      }
      if (event && event.shiftKey) {
        let routeData = this.$router.resolve(view)
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(view)
      }
    },
    getUrlByDataType (dataType) {
      switch (dataType) {
        case 'DOC':
          return {
            api: 'doc',
            title: 'Документы',
            view: 'view-doc',
            icon: 'book',
            table: 'docs'
          }
        case 'TABLE':
          return {
            api: 'table',
            title: 'Таблицы',
            view: 'view-table',
            icon: 'table_chart',
            table: 'table'
          }
        case 'LAYER':
          return {
            api: 'layer',
            title: 'Слои',
            view: 'view-layer',
            icon: 'layers',
            table: 'layer'
          }
        case 'MAP':
          return {
            api: 'map',
            title: 'Карты',
            view: 'map-view',
            icon: 'map',
            table: 'map'
          }
        case 'CHART':
          return {
            api: 'chart',
            title: 'Графики',
            view: 'view-chart',
            icon: 'insert_chart',
            table: 'chart'
          }
        case 'CALC':
          return {
            api: 'calc',
            title: 'Расчеты',
            view: 'view-calc',
            icon: 'functions',
            table: 'calc'
          }
        case 'REPORT':
          return {
            api: 'report',
            title: 'Отчеты',
            view: 'view-report',
            icon: 'description',
            table: 'report'
          }
        case 'MODBUS_UNIT':
          return {
            api: 'modbus-unit',
            title: 'Устройства',
            view: 'view-modbus-unit',
            icon: 'device_hub',
            table: 'modbus-unit'
          }
        case 'ET':
          return {
            api: 'database',
            title: 'База данных',
            view: 'view-et',
            icon: 'mdi-database',
            table: 'et'
          }
      }
    },
    emptyProject () {
      return {
        id: null,
        name: ''
      }
    },
    setAbility: values => roleService.setAbility(values),
    formatDate (date) {
      return date
        ? moment(new Date(date.toLocaleString()))
          .format('DD.MM.YYYY HH:mm')
        : ''
    },
    formatDateDIN: date => date ? moment(date).format('DD.MM.YYYY') : '',
    getUserFullName: user => user ? `${user.firstName} ${user.lastName}` : '',
    getUserInfo ({ position, company }) {
      let list = [position, company]
      return list.filter(it => !!it).join(' | ')
    },
    getDataTypeDetails: value => menuItems.find(it => it.value === value),
    backgroundFromString (str) {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return { 'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))` }
    }
  },
  computed: {
    dataTypeList () {
      return menuItems
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    currentLoginUser () {
      return this.$store.getters.currentLoginUser
    },
    currentLoginUserProjects () {
      return this.$store.getters.currentLoginUserProjects
    },
    selectedProject () {
      return this.$store.getters.selectedProject || this.emptyProject()
    }
  }
}
