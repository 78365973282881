<template>
  <v-navigation-drawer
    v-if="dialog"
    v-model="dialog"
    absolute
    height="100vh"
    right
    style="z-index: 1000"
    temporary
    width="80%"
  >
    <v-card>
      <v-card-text class="pa-0">
        <div
          class="full-height"
          @dragenter="dragEnter"
          @dragleave="dragLeave"
          @drop="drop=false"
          @paste="addFilesFromBuffer"
          @drop.prevent="addFiles"
          @dragover.prevent
        >
          <div
            v-show="drop"
            id="drive-drop-overlay"
          >
            <div class="overlay-hint text-center">
              <v-icon :size="100" color="blue-grey darken-1">save_alt</v-icon>
              <div class="title">{{ $t('uploadFile') }}</div>
            </div>
          </div>
          <v-container class="full-height" fluid>
            <div class="sticky-bar">
              <div class="d-flex mb-3">
                <v-text-field
                  v-model="search"
                  :flat="!solo"
                  :placeholder="$t('searchOnDisk')"
                  :solo="solo"
                  :solo-inverted="!solo"
                  class="flex-grow-1 mr-5"
                  clearable
                  hide-details
                  prepend-inner-icon="search"
                  @focusin="solo=true"
                  @focusout="solo=false"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      class="hidden-md-and-up"
                      color="primary"
                      @click="uploadFile"
                    >
                      add
                    </v-icon>
                  </template>
                </v-text-field>
                <v-btn
                  class="my-auto"
                  color="primary"
                  outlined
                  @click="uploadFile"
                >
                  <v-icon left>add</v-icon>
                  {{ $t('button.add') }}
                </v-btn>
              </div>
              <v-card class="hidden-xs-only" outlined>
                <v-card-actions>
                  <span class="ml-3">{{ $t('privateFiles') }}</span>
                  <v-spacer/>
                  <template
                    v-for="(item, i) in menuItems"
                    v-if="anyFileSelected">
                    <v-btn v-show="item.show"
                           :key="i"
                           icon
                           @click="applyAction(item.action)">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <v-divider v-if="anyFileSelected" class="mx-2" vertical/>
                  <v-btn :loading="areFilesLoading" icon @click="refresh">
                    <v-icon>refresh</v-icon>
                  </v-btn>
                  <v-btn icon @click="gridView = !gridView">
                    <v-icon>{{ gridView ? 'view_comfy' : 'view_list' }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <v-row class="d-sm-none" no-gutters>
              <v-btn class="ml-auto mr-2" icon @click="gridView = !gridView">
                <v-icon>{{ gridView ? 'view_comfy' : 'view_list' }}</v-icon>
              </v-btn>
            </v-row>

            <div class="overflow-y-auto" style="height: calc(100% - 102px)">
              <files-grid-view
                v-show="gridView"
                v-model="selected"
                :items="fileList"
                :shift-pressed="shiftPressed"
                @contextmenu="showContextMenu"
                @view="view"
                @show-action="showActions"/>

              <div v-show="!gridView">
                <v-data-table
                  :headers="headers"
                  :items="fileList"
                  class="mt-2 hidden-xs-only"
                  show-select
                  @contextmenu.prevent="showContextMenu"
                  @toggle-select-all="selectAll"
                  :value="selected"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                    <drag-select
                      ref="dragSelect"
                      attribute="id"
                      class="pr-6 drag-select"
                      @change="changeSelection">
                      <div
                        v-for="item in items"
                        :id="String(item.id)"
                        :class="{ 'item-selected': selectedIds.includes(item.id) }"
                        :style="{ height: `${rowHeight(item)}px`}"
                        @dblclick="view([item])"
                        @contextmenu.prevent="showContextMenu"
                      ></div>
                    </drag-select>

                    <tr v-for="item in items" :id="`row-${item.id}`">
                      <td>
                        <v-checkbox
                          :value="selectedIds.includes(item.id)"
                          class="ma-0 pa-0"
                          hide-details
                          @click.prevent.stop="changeSelection([`${item.id}`], true)"
                        />
                      </td>
                      <td v-for="h in headers" v-if="h.value !== 'selected'">
                        <template>
                          {{ h.format ? h.format(item) : item[h.value] }}
                        </template>
                      </td>
                    </tr>

                    </tbody>
                  </template>
                </v-data-table>

                <v-list class="mt-4 d-sm-none">
                  <template v-for="item in fileList">
                    <v-divider/>
                    <v-list-item @click="view([item])">

                      <v-list-item-avatar size="40" tile>
                        <v-img :src="`/image/${item.type}.png`"/>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>

                        <v-list-item-subtitle>
                          <span class="mr-1">{{ $t('created') }}</span>
                          {{ formatDate(item.creationDate) }}
                        </v-list-item-subtitle>

                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon @click.stop="showActions(item)">more_horiz</v-icon>
                      </v-list-item-action>

                    </v-list-item>
                  </template>
                </v-list>

              </div>
            </div>

            <new-file-dialog ref="dialog" @on-save="loadData"/>

            <share-data-dialog ref="shareDataDialog"/>

            <v-menu
              v-model="menu"
              :position-x="menuPositionX"
              :position-y="menuPositionY">
              <v-list dense>

                <template v-for="(item, i) in menuItems">
                  <v-list-item
                    v-if="item.show"
                    :key="i"
                    @click="applyAction(item.action)">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"/>
                    </v-list-item-content>
                  </v-list-item>
                </template>

              </v-list>
            </v-menu>

            <v-bottom-sheet v-if="$vuetify.breakpoint.xs" v-model="sheet">

              <v-sheet @click.native="sheet=false">
                <template v-for="(item, i) in menuItems">
                  <v-list-item
                    v-if="item.show"
                    :key="i"
                    @click="applyAction(item.action)">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"/>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-sheet>

            </v-bottom-sheet>

            <file-viewer
              ref="fileViewer"
              :storage-url="fileEntity => `files/get-user-file/${fileEntity.token}`"
            />
          </v-container>

          <v-snackbar
            v-model="isFileDownloading"
            bottom
            right>
            {{ $t('fileUpload') }}
            <v-progress-circular
              color="white"
              indeterminate
              size="20"
            />
          </v-snackbar>

          <file-removal-confirmation-dialog
            ref="confirmation"
            :title="$t('fileRemovalWarningText')"
            @confirm="removeFiles"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import NewFileDialog from '@/components/drive/NewFileDialog'
import { EventBus } from '@/event-bus'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import downloadFile from '../../services/file-downloader'
import messages from '../../componet-locale/drive/messages'
import FilesGridView from './FilesGridView'
import FileViewer from './FileViewer'
import DragSelect from 'drag-select-vue'
import FileRemovalConfirmationDialog from './FileRemovalConfirmationDialog'
import FullTextSearchResult from '@/components/search/FullTextSearchResult.vue'
import LimitedSearchResult from '@/components/search/LimitedSearchResult.vue'

export default {
  name: 'Drive',
  components: {
    LimitedSearchResult,
    FullTextSearchResult,
    FileViewer,
    FilesGridView,
    ShareDataDialog,
    NewFileDialog,
    DragSelect,
    FileRemovalConfirmationDialog
  },
  data: () => ({
    dialog: false,
    fileList: [],
    sheet: false,
    areFilesLoading: false,
    isFileDownloading: false,
    selected: [],
    space: { free: 0, total: 100 },
    menu: false,
    gridView: false,
    fileToken: '',
    menuPositionX: 0,
    menuPositionY: 0,
    search: '',
    fileViewDialog: false,
    drop: false,
    shiftPressed: false,
    solo: false
  }),
  i18n: { messages },
  created () {
    this.loadData()

    document.onkeyup = evt => {
      this.keyUp(evt)
    }
    document.onkeydown = evt => {
      this.keyDown(evt)
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    async download (files) {
      this.isFileDownloading = true
      for (const file of files) {
        await downloadFile(`files/get-user-file/${file.token}`, file.name)
          .catch(() => {
            EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
          })
      }
      this.isFileDownloading = false
    },
    uploadFile () {
      this.$refs.dialog.open()
    },
    changeSelection (item, multiselect = false) {
      let file = this.fileList.filter(file => file.id === +item[0])
      file = file.length > 0 ? file[0] : null
      if (!file) return console.error('Can not find file')
      if (this.selectedIds.includes(file.id)) {
        if (this.shiftPressed || multiselect) {
          this.selected = this.selected.filter(el => el.id !== file.id)
        }
      } else {
        if (this.shiftPressed || multiselect) {
          this.selected.push(file)
        } else {
          this.selected = [file]
        }
      }
    },
    selectAll () {
      if (this.selected.length < this.fileList.length) {
        this.selected = JSON.parse(JSON.stringify(this.fileList))
      } else {
        this.selected = []
      }
    },
    share (files) {
      let selectedFile = files[0]
      this.$axios
        .post('files/share', null, { params: { id: selectedFile.id } })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('fileShared'))
          const url = `${window.location.protocol}//${window.location.host}/drive/${selectedFile.token}`
          this.$refs.shareDataDialog.share(url)
          selectedFile.isShared = true
        })
    },
    rowHeight (item) {
      let row = document.getElementById(`row-${item.id}`)
      if (!row) return 48
      return row.offsetHeight === 0 ? 48 : row.offsetHeight
    },
    withdraw (files) {
      let sharedFile = files.filter(item => item.isShared === true)
      if (!sharedFile.length) return
      this.$axios
        .post('files/withdraw', null, { params: { ids: this.getSelectedFilesIds(files) } })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('fileNotShared'))
          sharedFile.forEach(item => item.isShared = false)
        })
    },
    resetSelection () {
      this.$refs.dragSelect.intersected = []
      this.selected = []
    },
    showActions (item) {
      this.selected = [item]
      this.showSheet()
    },
    applyAction (action) {
      action([...this.selected])
    },
    showSheet () {
      this.sheet = true
    },
    remove (files) {
      const ids = this.getSelectedFilesIds(files)
      if (files.some(f => f.isShared)) {
        this.$refs.confirmation.open(ids)
      } else this.removeFiles(ids)
    },
    removeFiles (ids) {
      this.$axios
        .post('files/remove', null, { params: { ids: ids } })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('fileDeleted'))
          this.loadData()
          this.resetSelection()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    fileSize (file) {
      let size = file.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB'];
      let i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      return (Math.round(size * 100) / 100) + ' ' + fSExt[i]
    },
    loadData () {
      this.$axios
        .get('files/get-file-entities', { params: { name: this.search } })
        .then(resposne => {
          this.fileList = resposne.data
          this.areFilesLoading = false
        })
    },
    refresh () {
      this.areFilesLoading = true
      this.loadData()
    },
    view (files) {
      this.fileToken = files[0].token
      this.$axios
        .get(`/files/get-file-entity/${files[0].token}`)
        .then(response => {
          if (response.data) {
            this.$refs.fileViewer.open(response.data)
          } else {
            EventBus.$emit('showErrorMessage', this.$t('fileNotExist'))
          }
        })
        .catch(() => {
          // TODO 403 is not intercepted correctly
          EventBus.$emit('showErrorMessage', this.$t('accessDenied'))
        })
        .finally(() => {
          this.fileToken = ''
        })

      this.fileViewDialog = true
    },
    formatFileAccess (item) {
      return item.isShared ? this.$t('shared') : this.$t('notShared')
    },
    getSelectedFilesIds (files) {
      return files.map(item => item.id).toString()
    },
    showContextMenu (e) {
      if (!this.anyFileSelected) return
      this.menuPositionX = e.pageX
      this.menuPositionY = e.pageY
      this.menu = true
    },
    addFiles (e) {
      let droppedFile = e.dataTransfer.files
      if (droppedFile) {
        this.$refs.dialog.open([...droppedFile])
      }
    },
    addFilesFromBuffer (e) {
      if (e.clipboardData.files.length > 0) this.$refs.dialog.open([])
    },
    dragEnter () {
      this.drop = true
    },
    formatItemDate (item) {
      return this.formatDate(item.creationDate)
    },
    dragLeave (e) {
      if (e.target.id === 'drive-drop-overlay') this.drop = false
    },
    keyUp (e) {
      if (e.keyCode === 16 || e.key === 'Shift') {
        this.shiftPressed = false
      }
    },
    keyDown (e) {
      if (e.keyCode === 16 || e.key === 'Shift') {
        this.shiftPressed = true
      }
    }
  },
  computed: {
    percentUsableDiskSpace () {
      return 100 - (100 / (this.space.total / this.space.free))
    },
    anyFileSelected () {
      return this.selected.length > 0
    },
    availableSpace () {
      let freeSpace = this.space.total - this.space.free
      return (freeSpace / (1024.0 * 1024 * 1024)).toFixed(1)
    },
    headers () {
      return [
        {
          text: '',
          value: 'selected',
          sortable: false
        },
        {
          text: this.$t('name'),
          align: 'start',
          value: 'name'
        },
        { text: this.$t('type'), value: 'type' },
        { text: this.$t('access'), value: 'isShared', format: this.formatFileAccess },
        { text: this.$t('created'), value: 'creationDate', format: this.formatItemDate },
        { text: this.$t('size'), value: 'size', format: this.fileSize }
      ]
    },
    oneFileSelected () {
      return this.selected.length === 1
    },
    menuItems () {
      return [
        { icon: 'visibility', text: this.$t('preview'), action: this.view, show: this.oneFileSelected },
        { icon: 'group_add', text: this.$t('grantAccess'), action: this.share, show: this.oneFileSelected },
        { icon: 'link_off', text: this.$t('restrictAccess'), action: this.withdraw, show: this.anyFileSelected },
        { icon: 'save_alt', text: this.$t('download'), action: this.download, show: this.anyFileSelected },
        { icon: 'delete', text: this.$t('remove'), action: this.remove, show: this.anyFileSelected }]
    },
    selectedIds () {
      return this.selected.map(el => el.id)
    }
  },
  watch: {
    search (value) {
      if (!value) this.search = ''
      this.loadData()
    }
  }
}
</script>

<style scoped>

.item-selected {
  background: #ACCEF7 !important;
  opacity: 20%;
}

.overlay-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c4c4c4;
}

#drive-drop-overlay {
  background-color: #000;
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.sticky-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white
}

.drag-select {
  position: absolute !important;
  width: 100%
}

</style>
