<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span v-if="this.relation.mainHeader && this.relation.relatedHeader">
        {{ this.relation.mainHeader.fieldName }}
        <v-icon>mdi-arrow-right</v-icon>
        {{ this.relation.relatedHeader.fieldName }}
      </span>
      <span v-else class="red--text">{{ $t('setRelation') }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat>
        <v-card-text class="pt-1">
          <div>
            <v-select
              v-model="relation.type"
              :items="relationTypes"
              :label="$t('relationType')"
              class="mb-2"
              dense
              hide-details
              outlined
            >
              <template v-slot:selection="{item}">
                {{ $t(item) }}
              </template>
              <template v-slot:item="{item}">
                {{ $t(item) }}
              </template>
            </v-select>
            <v-select
              v-model="relation.mainHeader"
              :items="headers"
              :label="$t('mainHeader')"
              class="mb-2"
              dense
              hide-details
              item-text="fieldName"
              outlined
              return-object
            />
          </div>
          <div>
            <v-autocomplete
              v-model="relation.relatedTable"
              :items="etList"
              :label="$t('helperTable')"
              :loading="etListIsLoading"
              :search-input.sync="etSearchString"
              class="mb-2"
              dense
              hide-details
              hide-no-data
              item-text="name"
              outlined
              return-object
            />
            <v-select
              v-if="relatedHeaders && relation.relatedTable"
              v-model="relation.relatedHeader"
              :items="relatedHeaders"
              :label="$t('relatedHeader')"
              dense
              hide-details
              item-text="fieldName"
              outlined
              return-object
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" outlined @click="$emit('remove')">
            <v-icon left>delete</v-icon>
            {{ $t('button.remove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-relations/messages'

export default {
  name: "RelationItem",
  props: {
    headers: Array,
    relation: Object
  },
  i18n: { messages },
  data () {
    return {
      relatedHeaders: [],
      etSearchString: '',
      etList: this.relation.relatedTable ? [this.relation.relatedTable] : [],
      etListIsLoading: '',
      relationTypes: ['TO_MANY', 'TO_ONE']
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getExternalTableList()
      this.getRelatedTableHeaders()
    },
    getExternalTableList () {
      this.etListIsLoading = true
      this.$axios.post(`data/find-pageable-search-result?type=ET&projectId=${this.$route.params.projectId}`, {
        desc: 'ASC',
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        criteria: this.etSearchString ? [{
          name: 'name',
          value: this.etSearchString
        }] : []
      })
        .then(res => {
            const etList = res.data.content
            if (
              this.relation.relatedTable
              && !etList.some(el => el.id === this.relation.relatedTable.id)
            ) {
              this.etList = [this.relation.relatedTable, ...etList]
            } else {
              this.etList = etList
            }
          }
        )
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('findEtListError')))
        .finally(() => this.etListIsLoading = false)
    },
    getRelatedTableHeaders () {
      if (!this.relation.relatedTable) return
      this.$axios.get('data/get', {
        params: {
          id: this.relation.relatedTable.id
        }
      })
        .then(res => this.relatedHeaders = res.data.headers)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  },
  watch: {
    'relation.relatedTable' () {
      this.getRelatedTableHeaders()
    },
    etSearchString () {
      this.getExternalTableList()
    }
  }
}
</script>
