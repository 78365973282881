<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <v-card class="pb-2" v-if="dialog">
      <v-card-title>
        <v-text-field
          autofocus
          hide-details
          autocomplete="off"
          class="pl-2"
          dense
          outlined
          prepend-inner-icon="search"
          :placeholder="$t('search')"
          v-model="search"
        />
        <v-spacer/>
        <v-icon @click="dialog=false">close</v-icon>
      </v-card-title>
      <v-card-text style="height: 600px" class="pa-0">
        <v-list dense>
          <template v-for="(data, index) in items">
            <v-list-item class="pl-6" :key="index" @click="selectData(data)" :ripple="false">
              <v-list-item-avatar tile>
                <v-icon>{{ getUrlByDataType(data.dataType).icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.description }} <span>{{ formatDate(data.creationDate) }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset v-if="(items.length>1 && index<items.length-1)"/>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '../../componet-locale/search-data-dialog/messages'

export default {
  name: 'SearchDataDialog',
  data: () => ({
    dialog: false,
    loading: false,
    items: [],
    search: null
  }),
  i18n: { messages: messages },
  methods: {
    open () {
      this.fetchData('')
      this.dialog = true
    },
    selectData (data) {
      this.$emit('select', data)
      this.dialog = false
    },
    async fetchData (val) {
      let projectId = this.selectedProject.id
      this.$axios
        .get('/data/search', { params: { str: val, projectId: projectId } })
        .then(response => {
          this.items = response.data
        })
    }

  },
  watch: {
    search (val) {
      this.fetchData(val)
    }
  }
}
</script>
