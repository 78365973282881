<template>
  <i18n path="removedFromChat">
    <span>
      <span v-for="(item,index) in links">
        <user-link
            :key="index"
            :id="item.id"
            :label="item.fullName"
        />
        <span
            v-if="index!==links.length-1"
            v-html="', '"
        />
      </span>
    </span>
  </i18n>
</template>

<script>
import { eventMessageMixin } from '@/components/chats/messages/event-templates/mixins/event-message-mixin'

export default {
  name: 'ExclusionEventMessage',
  mixins: [eventMessageMixin]
}
</script>
