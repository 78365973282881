<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t('button.attachedData') }}
          <v-spacer/>
          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-card
            v-if="!listOfData.length && !listOfAdData.length"
            class="mx-2 mb-2"
            outlined
          >
            <v-card-text>
              {{ $t('noAttachments') }}
            </v-card-text>
          </v-card>
          <div v-else>
            <lp-file-attachments
              :list-of-data="listOfData"
              @reload="reloadAttachedData"
            />
            <lp-ad-attachments
              :list-of-data="listOfAdData"
              @reload="reloadAttachedAdList"
            />
          </div>
        </v-card-text>
        <v-card-actions v-if="isAuthenticated">
          <v-btn
            color="black"
            outlined
            @click="openSearchDataDialog"
          >
            {{ $t('linkDataFromSystem') }}
            <v-icon right>attach_file</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            outlined
            @click="addFile"
          >
            {{ $t('addFile') }}
            <v-icon right>upload_file</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <new-file-dialog
      ref="newFileDialog"
      :dataModifier="dataModifier"
      :external-storage="{
        saveFileURL: '/files/save-attached-data',
        saveRecordsURL: 'files/save-attached-data-records'
      }"
      @on-save="reloadAttachedData"
    />
    <search-data-dialog ref="searchDataDialog" @select="saveToAttachedAd"/>
  </div>
</template>

<script>
import NewFileDialog from '@/components/drive/NewFileDialog.vue'
import { EventBus } from '@/event-bus'
import SearchDataDialog from '@/components/utils/SearchDataDialog.vue'
import LpFileAttachments from '@/components/layer-poi/attachments/LpFileAttachments.vue'
import LpAdAttachments from '@/components/layer-poi/attachments/LpAdAttachments.vue'
import messages from '@/componet-locale/attached-data/messages'

export default {
  name: 'LpAttachedData',
  i18n: { messages },
  components: { LpAdAttachments, LpFileAttachments, SearchDataDialog, NewFileDialog },
  data: () => ({
    dialog: false,
    listOfData: [],
    listOfAdData: []
  }),
  props: {
    lp: {
      type: Object,
      required: true
    }
  },
  mounted(){
    this.loadAttachedData()
  },
  methods: {
    dataModifier (data) {
      if (this.lp) {
        data.layerPoi = { id: this.lp.id }
      }
    },
    open () {
      this.loading = true
      this.loadAttachedData()
      this.loadAttachedAdList()
      this.dialog = true
    },
    addFile () {
      this.$refs.newFileDialog.open()
    },
    loadAttachedData () {
      this.$axios
        .get(this.isAuthenticated ? 'files/get-attached-files' : 'public-data/get-attached-files', {
          params: {
            lpId: this.lp.id
          }
        })
        .then(response => {
          this.listOfData = response.data
          this.$emit('got-attached-files', response.data)
        })
        .catch(reason => EventBus.$emit.showErrorMessage(reason))
        .finally(() => {
          this.loading = false
        })
    },
    reloadAttachedData () {
      this.loadAttachedData()
      this.$emit('on-update')
      this.$emit('data-updated')
    },
    loadAttachedAdList () {
      if (!this.isAuthenticated) {
        return
      }
      this.$axios
        .get('layer-poi/get-list-of-attached-data', {
          params: {
            lpId: this.lp.id
          }
        })
        .then(response => {
          this.listOfAdData = response.data
        })
        .catch(e => console.error(e))
    },
    reloadAttachedAdList () {
      this.loadAttachedAdList()
      this.$emit('on-update')
      this.$emit('data-updated')
    },
    openSearchDataDialog () {
      this.$refs.searchDataDialog.open()
    },
    saveToAttachedAd (ad) {
      this.$axios
        .post('layer-poi/add-attached-data', {
          lpId: this.lp.id,
          adId: ad.id
        })
        .then(() => {
          this.reloadAttachedAdList()
          this.$emit('data-updated')
          EventBus.$emit('showInfoMessage', 'Документ добавлен в список связанных данных')
        })
    }
  },
  watch: {
    lp(){
      this.loadAttachedData()
    }
  }
}
</script>
