<template>
  <abstract-data-list
    ref="abstractDataList"
    type="LAYER"
    selectable
    additional-field="type"
    view="layer-list"
    :selected-items.sync="selectedItems"
  >
    <template slot="actions">
      <v-btn outlined color="primary"
             v-if="setAbility('MODERATOR')"
             @click="showAddLayerDialog">
        {{ $t('button.create') }}
      </v-btn>
      <v-btn color="primary"
             class="ml-2"
             v-if="setAbility('MODERATOR')"
             v-show="selectedItems.length"
             @click="showLayers">
        Построить карту
      </v-btn>
      <add-layer-dialog @on-save="onSaveLayer" ref="addLayerDialog"/>
    </template>
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'
import AddLayerDialog from './helpers/AddLayerDialog'

export default {
  name: 'MapList',
  components: { AddLayerDialog, AbstractDataList },
  data: () => ({
    selectedItems: []
  }),
  methods: {
    onSaveLayer () {
      this.$refs.abstractDataList.filterData()
    },
    showLayers () {
      let layerIdList = this.selectedItems.map(layer => layer.id)
      this.$router.push({
        name: 'map-sandbox',
        query: { layerIdList: `${layerIdList}` }
      })
    },
    showAddLayerDialog () {
      this.$refs.addLayerDialog.showAddLayerDialog()
    }
  }
}
</script>
