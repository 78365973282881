<template>
  <ad-widget-container
    :dense="dense"
    :title="data && data.name || ''"
    dense-title
    @clickOnTitle="e => openInNewTab(data, e)"
  >
    <div v-if="data">
      <et-viewer :et="data" :split-screen-mode="false" widget/>
    </div>
  </ad-widget-container>
</template>

<script>
import AdWidgetContainer from '@/components/widget/AdWidgetContainer.vue'
import EtViewer from '@/components/et/EtViewer.vue'

export default {
  name: 'ExternalTableWidget',
  components: { EtViewer, AdWidgetContainer },
  props: {
    widget: {
      type: Object,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: null
  }),
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.data = await this.getEtById(this.widget.abstractData.id)
        .then(res => res.data)
    },
    async getEtById (id) {
      return this.$axios.get('data/get', { params: { id: id } })
    }
  }
}
</script>
