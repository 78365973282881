<template>
  <div>
    <component
      v-for="prop in featureProps"
      :is="getComponentByType(prop.localType)"
      v-model="feature.values_[prop.name]"
      :label="prop.name"
      :type="prop.localType === 'int' ? 'number': 'text'"
      dense
      outlined
    />
    <v-text-field v-show="false"/>
  </div>
</template>

<script>
export default {
  name: 'WfsFields',
  props: { feature: Object },
  methods: {
    getComponentByType (type) {
      switch (type) {
        case 'number':
          return 'v-text-field'
        case 'string':
          return 'v-text-field'
        default:
          return ''
      }
    }
  },
  computed: {
    featureProps () {
      let supportedTypes = ['string', 'int', 'number']
      return this.feature.featureTypes.filter(it => supportedTypes.includes(it.localType))
    }
  }
}
</script>
