<template>
  <h1>{{ $t('noRights') }}</h1>
</template>

<script>
import messages from '../componet-locale/no-rights/messages'

export default {
  name: 'NoRight',
  i18n: { messages: messages }
}
</script>
