<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card class="full-height-card">
      <v-card-text>
        <abstract-data-details :data="data"/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn @click="openInNewTab(data, $event)" color="primary" outlined>
          Просмотр
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AbstractDataDetails from './AbstractDataDetails'

export default {
  name: 'AbstractDataInfoDialog',
  components: { AbstractDataDetails },
  data: () => ({
    dialog: false,
    data: {}
  }),
  methods: {
    open (id) {
      this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
          if (this.data.dataType === 'TABLE') {
            this.getCharts()
          }
        })
      this.dialog = true
    },
    getCharts () {
      this.$axios
        .get('chart/get', {
          params: {
            tableId: this.data.id
          }
        })
        .then(response => {
          let data = { ...this.data }
          data.chartList = response.data
          this.data = data
        })
    }
  }
}
</script>
