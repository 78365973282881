<template>
  <div>
    <div v-for="header in headers">
      <v-select
        v-model="header.defaultPredicate"
        :items="header.predicates"
        :label="header.fieldName"
        class="mb-4"
        dense
        hide-details
        outlined
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultPredicateEditor",
  props: {
    headers: Array
  }
}
</script>
