<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="700">
    <v-card class="d-flex flex-column" max-height="90vh">
      <v-card-title class="px-2 pb-0 pt-3">
        {{ tableName }}
      </v-card-title>
      <v-card-text class="px-2 py-4 flex-grow-1 overflow-y-auto full-width">
        <et-item
          v-for="field in fields"
          :key="field.header.id"
          v-model:value="field.value"
          :editable="true"
          :emptyInitialValue="true"
          :header="field.header"
          :short-mode="true"
          :table-id="tableId"
        />
      </v-card-text>
      <div class="px-3">
        <v-expand-transition>
          <div v-if="showQuery && queryHtml && setAbility('ADMIN')">
            <span v-html="queryHtml"/>
          </div>
        </v-expand-transition>
      </div>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn v-if="showQuery && setAbility('ADMIN')" :disabled="queryLoading" color="primary" icon small
               @click="loadQueryHtml">
          <v-icon small>mdi-cached</v-icon>
        </v-btn>
        <v-btn v-if="setAbility('ADMIN')" :loading="queryLoading" outlined @click="changeShowQueryMode">
          {{ showQuery ? $t('hideQuery') : $t('showQuery') }}
        </v-btn>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-insert/messages'


export default {
  name: "EtAddRowDialog",
  components: { EtItem: () => import('@/components/et/utils/EtItem.vue') },
  i18n: { messages },
  data: () => ({
    dialog: false,
    fields: [],
    queryHtml: '',
    showQuery: false,
    queryLoading: false,
    tableId: null,
    tableName: "",
    autoInsertItems: []
  }),
  methods: {
    init () {
      this.$axios
        .get('/et/get-insert-info', {
          params: {
            tableId: this.tableId
          }
        })
        .then(res => {
          const fields = res.data.insertFields
          fields.forEach(el => {
            const autoInsertItem = this.autoInsertItems.filter(item => item.header.id === el.header.id)[0]
            if (autoInsertItem && autoInsertItem.value) el.value = autoInsertItem.value
          })
          this.fields = res.data.insertFields
          this.tableName = res.data.tableName
        })
        .catch(({ data: e }) => {
          EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error'))
          this.close()
        })
    },
    open (tableId, autoInsertItems = []) {
      this.dialog = true
      this.tableId = tableId
      this.autoInsertItems = autoInsertItems
      this.init()
    },
    close () {
      this.dialog = false
      this.fields = []
      this.tableId = null
      this.queryHtml = ''
      this.showQuery = false
    },
    changeShowQueryMode () {
      if (this.showQuery) {
        this.showQuery = false
        this.queryHtml = ''
      } else {
        this.loadQueryHtml()
      }
    },
    loadQueryHtml () {
      this.queryLoading = true
      this.$axios.post('/et/get-insert-query', {
        tableId: this.tableId,
        insertFields: this.fields
      })
        .then(res => {
          this.showQuery = true
          this.queryHtml = `<span>${res.data}</span>`
        })
        .catch(e => EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error')))
        .finally(() => this.queryLoading = false)
    },
    save () {
      this.$axios.post('/et/insert', {
        tableId: this.tableId,
        insertFields: this.fields
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('save')
          this.close()
        })
        .catch(e => EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error')))
    }
  }
}
</script>
