<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="600">
    <v-card style="height: 90vh">
      <v-card-title>
        {{ $t('objectsSelected') }}: {{ selectedPoisCount }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="overflow-y-auto py-0" style="height: calc( 90vh - 114px )">
        <div v-for="layer in lpsGroupedByLayers" :key="layer.id">
          <v-data-table
            :footer-props="{
              'items-per-page-text': '',
              'items-per-page-options': [10, 25, 50]
            }"
            :items="layer.lps"
            class="mb-3"
            item-key="id"
            style="border: 1px solid black"
          >
            <template v-slot:header>
              <div class="d-flex align-center py-2 pl-3 title">
                <v-simple-checkbox
                  :ripple="false"
                  :value="layer.lps.every(el => el.selected)"
                  class="ma-0"
                  @input="layerChangeSelected(layer)"
                />
                <div class="ml-2 d-flex full-width">
                  <span class="subtitle-1">{{ layer.layerName }}: {{ getPoisCountByLayer(layer) }}</span>
                </div>
              </div>
            </template>
            <template v-slot:item="{item}">
              <tr @click="openLayerPoiDialog(item)">
                <td>
                  <div class="d-flex flex-row align-center">
                    <layer-poi-badge :style-id="item.styleId" :template="getTemplateById(item.templateId)"/>
                    <span>
                      {{ item.name || `[${item.id}]` }}
                    </span>
                  </div>
                </td>
                <td style="width: 28px">
                  <v-checkbox
                    v-model="item.selected"
                    class="ma-0 pa-0"
                    dense
                    hide-details
                    @click.stop.prevent="selectPoi(item)"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
              <span>
                {{ pageStart }} - {{ pageStop }} / {{ itemsLength }}
              </span>
            </template>
          </v-data-table>
        </div>
        <div
          v-for="layer in geoserverFeaturesGroupedByLayers"
          v-if="layer.features.length"
        >
          <v-data-table
            :footer-props="{
              'items-per-page-text': '',
              'items-per-page-options': [10, 25, 50]
            }"
            :items="layer.features"
            class="mb-3"
            item-key="id"
            style="border: 1px solid black"
          >
            <template v-slot:header>
              <div class="d-flex align-center py-2 pl-3 title">
                <v-simple-checkbox
                  :ripple="false"
                  :value="layer.features.every(el => el.selected)"
                  class="ma-0"
                  @input="layerChangeSelected(layer)"
                />
                <div class="ml-2 d-flex full-width">
                  <span class="subtitle-1">{{ layer.name }}: {{ getPoisCountByLayer(layer) }}</span>
                </div>
              </div>
            </template>
            <template v-slot:item="{item}">
              <tr @click="openGeoserverFeatureDialog(item,layer)">
                <td>
                  <div class="d-flex flex-row align-center">
                    <span>
                      {{ item.id }}
                    </span>
                  </div>
                </td>
                <td style="width: 28px">
                  <v-checkbox
                    v-model="item.selected"
                    class="ma-0 pa-0"
                    dense
                    hide-details
                    @click.stop.prevent="selectPoi(item)"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
              <span>
                {{ pageStart }} - {{ pageStop }} / {{ itemsLength }}
              </span>
            </template>
          </v-data-table>
        </div>
        <div
          v-for="layer in zwsFeaturesGroupedByLayers"
          v-if="layer.features.length"
        >
          <v-data-table
            :footer-props="{
              'items-per-page-text': '',
              'items-per-page-options': [10, 25, 50]
            }"
            :items="layer.features"
            class="mb-3"
            item-key="id"
            style="border: 1px solid black"
          >
            <template v-slot:header>
              <div class="d-flex align-center py-2 pl-3 title">
                <v-simple-checkbox
                  :ripple="false"
                  :value="layer.features.every(el => el.selected)"
                  class="ma-0"
                  @input="layerChangeSelected(layer)"
                />
                <div class="ml-2 d-flex full-width">
                  <span class="subtitle-1">
                    {{ layer.name }}: {{ getPoisCountByLayer(layer) }}
                  </span>
                </div>
              </div>
            </template>
            <template v-slot:item="{item}">
              <tr class="pointer" @click="openZwsFeatureDialog(item)">
                <td>
                  <div class="d-flex flex-row align-center">
                    <span>
                      {{ item.elemId }}
                    </span>
                  </div>
                </td>
                <td style="width: 28px">
                  <v-checkbox
                    v-model="item.selected"
                    class="ma-0 pa-0"
                    dense
                    hide-details
                    @click.stop.prevent="selectPoi(item)"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
              <span>
                {{ pageStart }} - {{ pageStop }} / {{ itemsLength }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions class="px-4 elevation-2">
        <v-btn
          :disabled="selectedPoisCount === 0"
          :loading="removeLoading"
          color="red"
          outlined
          @click="$refs.deleteFeaturesConfirmationDialog.open({name: $t('deleteObjects')})"
        >
          {{ $t('button.remove') }}
        </v-btn>
        <v-btn
          :disabled="selectedLpsCount === 0 || selectedGeoserverFeaturesCount !== 0"
          class="ml-2"
          color="primary"
          outlined
          @click="openLpsExportDialog"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t('button.export') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          v-if="splitScreenMode"
          :disabled="!selectedZwsFeaturesCount"
          color="primary"
          outlined
          @click="applyExternalTableFilter"
        >
          {{ $t('openTable') }}
        </v-btn>
        <v-btn
          :disabled="selectedLpsCount === 0 || selectedGeoserverFeaturesCount !== 0"
          color="primary"
          outlined
          @click="edit"
        >
          {{ $t('button.edit') }}
        </v-btn>
        <v-btn
          :disabled="selectedPoisCount === 0"
          outlined
          @click="openPrintTemplateSelectorDialog"
        >
          {{ $t('button.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <layer-poi-dialog
      ref="layerPoiDialog"
      :actions-toolbar="false"
      :map-widget="false"
      :readonly-mode="true"
    />
    <wms-feature-dialog
      ref="geoserverFeatureDialog"
    />
    <confirmation-dialog
      ref="deleteFeaturesConfirmationDialog"
      :title="$t('doYouReallyWant')"
      @confirm="remove"
    />
    <lps-export-dialog ref="lpsExportDialog"/>
    <zws-feature-dialog ref="zwsFeatureDialog"/>
  </v-dialog>
</template>

<script>
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import messages from '@/componet-locale/mass-actions/messages'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import { EventBus } from '@/event-bus'
import LpsExportDialog from '@/components/map/helpers/LpsExportDialog.vue'
import geoserverCommandBuilder from '@/services/geoserver-command-builder'
import WmsFeatureDialog from '@/components/map/geoserver/WmsFeatureDialog.vue'
import _ from 'lodash'
import ZwsFeatureDialog from '@/components/map/zws/ZwsFeatureDialog.vue'

export default {
  name: 'FeatureSelectorDialog',
  components: {
    ZwsFeatureDialog,
    WmsFeatureDialog,
    LpsExportDialog,
    ConfirmationDialog,
    LayerPoiDialog,
    LayerPoiBadge
  },
  i18n: { messages },
  data: () => ({
    dialog: false,
    lpsGroupedByLayers: [],
    geoserverFeaturesGroupedByLayers: [],
    zwsFeaturesGroupedByLayers: [],
    lpsTemplates: [],
    removeLoading: false,
    selectedLpsCount: 0,
    selectedGeoserverFeaturesCount: 0,
    selectedZwsFeaturesCount: 0
  }),
  methods: {
    open (lpIntersectDto, geoserverIntersections, zwsIntersections) {
      if (lpIntersectDto) {
        this.lpsGroupedByLayers = lpIntersectDto.layers
        this.lpsTemplates = lpIntersectDto.templates
        this.selectedLpsCount = 0
      }

      this.geoserverFeaturesGroupedByLayers = geoserverIntersections || []
      this.selectedGeoserverFeaturesCount = 0

      this.zwsFeaturesGroupedByLayers = zwsIntersections || []

      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    selectPoi (poi) {
      poi.selected = !poi.selected
      this.$forceUpdate()
      this.updateSelectedPoisCount()
    },
    openLayerPoiDialog (lp) {
      this.$refs.layerPoiDialog.open(null, lp.id)
    },
    openGeoserverFeatureDialog (feature, layer) {
      this.$refs.geoserverFeatureDialog.open(feature, layer)
    },
    openZwsFeatureDialog (feature) {
      this.$refs.zwsFeatureDialog.openById(feature.layer, feature.elemId)
    },
    async remove () {
      if (this.selectedLpsCount && this.selectedGeoserverFeaturesCount) {
        return EventBus.$emit('showInfoMessage', this.$t('objectsOfDifferentTemplates'))
      } else if (this.selectedLpsCount) {
        const selectedIds = this.getSelectedLps().map(it => it.id)
        this.removeLoading = true
        this.$axios
          .post('layer-poi/delete-by-ids', selectedIds)
          .then(() => {
            EventBus.$emit('showInfoMessage', `${this.$t('deletedObjects')} ${selectedIds.length}`)
          })
          .catch(() => {
            EventBus.$emit('showErrorMessage', this.$t('error'))
          })
          .finally(() => {
            this.$emit('update')
            this.removeLoading = false
            this.close()
          })
      } else if (this.selectedGeoserverFeaturesCount) {
        const selectedFeatures = this.getSelectedGeoserverFeatures()
        const layers = this.geoserverFeaturesGroupedByLayers.filter(l => l.features.some(f => f.selected))
        if (layers.length > 1) return EventBus.$emit('showInfoMessage', this.$t('objectsOfDifferentTemplates'))
        this.removeLoading = true
        await geoserverCommandBuilder.removeFeatures(selectedFeatures, layers[0])
          .then(() => {
            EventBus.$emit('showInfoMessage', this.$t('deletedObjects'))
          })
          .catch(() => {
            EventBus.$emit('showErrorMessage', this.$t('error'))
          })
          .finally(() => {
            this.$emit('update')
            this.removeLoading = false
            this.close()
          })
      }
    },
    edit () {
      const lps = this.getSelectedLps()
      if (lps.some(f => f.templateId !== lps[0].templateId)) {
        return EventBus.$emit('showErrorMessage', this.$t('objectsOfDifferentTemplates'))
      }
      this.$emit('edit', lps)
      this.close()
    },
    async openPrintTemplateSelectorDialog () {
      this.$emit('openPrintTemplateSelectorDialog', await this.getPrintTemplates())
      this.close()
    },
    layerChangeSelected (layer) {
      let poisPropertyName = ''
      if (layer.type === 'LAYER_POI') {
        poisPropertyName = 'lps'
      } else {
        poisPropertyName = 'features'
      }
      if (layer[poisPropertyName].every(el => el.selected)) {
        layer[poisPropertyName].forEach(el => el.selected = false)
      } else {
        layer[poisPropertyName].forEach(el => el.selected = true)
      }
      this.$forceUpdate()
      this.updateSelectedPoisCount()
    },
    getPoisCountByLayer (layer) {
      let poisPropertyName = ''
      if (layer.type === 'LAYER_POI') {
        poisPropertyName = 'lps'
      } else {
        poisPropertyName = 'features'
      }
      return layer[poisPropertyName].filter(el => el.selected).length
    },
    openLpsExportDialog () {
      this.$refs.lpsExportDialog.open(this.getSelectedLps(), this.lpsTemplates)
    },
    getTemplateById (templateId) {
      return this.lpsTemplates.find(template => template.id === templateId)
    },
    getSelectedLps () {
      const lps = []
      this.lpsGroupedByLayers.forEach(layer => {
        lps.push(...layer.lps.filter(lp => lp.selected))
      })
      return lps
    },
    getSelectedGeoserverFeatures () {
      const geoserverFeatures = []
      this.geoserverFeaturesGroupedByLayers.forEach(layer => {
        geoserverFeatures.push(...layer.features.filter(lp => lp.selected))
      })
      return geoserverFeatures
    },
    getSelectedZwsFeatures () {
      const zwsFeatures = []
      this.zwsFeaturesGroupedByLayers.forEach(layer => {
        zwsFeatures.push(...layer.features.filter(lp => lp.selected))
      })
      return zwsFeatures
    },
    updateSelectedPoisCount () {
      this.selectedLpsCount = this.lpsGroupedByLayers.reduce((count, layer) => {
        return layer.lps.filter(lp => lp.selected).length + count
      }, 0)
      this.selectedGeoserverFeaturesCount = this.geoserverFeaturesGroupedByLayers.reduce((count, layer) => {
        return layer.features.filter(lp => lp.selected).length + count
      }, 0)
      this.selectedZwsFeaturesCount = this.zwsFeaturesGroupedByLayers.reduce((count, layer) => {
        return layer.features.filter(lp => lp.selected).length + count
      }, 0)
    },
    async getPrintTemplates () {
      const selectedGeoserverLayers = this.geoserverFeaturesGroupedByLayers.filter(layer => layer.features.some(f => f.selected))
      const selectedLps = this.getSelectedLps()

      const printTemplatesFromLps = _.intersectionBy(...this.lpsTemplates
        .filter(t => selectedLps.some(f => f.templateId === t.id))
        .map(t => t.printTemplates), t => t.id)

      const printTemplatesFromGeoserver = await this.$axios.post('print-template/get-by-layer-ids', selectedGeoserverLayers.map(l => l.id))
        .then(res => res.data)

      if (!selectedLps.length) {
        return printTemplatesFromGeoserver
      } else if (!selectedGeoserverLayers.length) {
        return printTemplatesFromLps
      } else {
        return _.intersectionBy([printTemplatesFromLps, printTemplatesFromGeoserver], t => t.id)
      }
    },
    applyExternalTableFilter () {
      const selectedZwsFeaturesGroupedByLayerId =
        _.groupBy(this.getSelectedZwsFeatures(), f => f.layer.id)
      const applyEtFilterDto = Object.keys(selectedZwsFeaturesGroupedByLayerId)
        .map(key => ({ layerId: +key, featureIds: selectedZwsFeaturesGroupedByLayerId[key].map(f => +f.elemId) }))
      if (this.splitScreenMode) {
        EventBus.$emit('applyEtFilterByFeatureIds', applyEtFilterDto)
      }
    }
  },
  computed: {
    selectedPoisCount () {
      return this.selectedLpsCount + this.selectedGeoserverFeaturesCount
    },
    splitScreenMode () {
      return this.$route.matched[0].path.includes('split-screen')
    }
  }
}
</script>
