<template>
  <v-simple-table class="data-list-table" v-bind:style="fullWidth ? '' : 'width: 280px !important'">
    <tbody>
    <tr
      v-for="item in items"
      :key="item.id"
    >
      <td
        @click="open(item)"
        class="inline-text pointer"
        style="max-width: 250px !important; text-align: left"
      >
        <span>{{ item.name }}</span>
      </td>
      <td
        @click="open(item)"
        v-show="fullWidth && !displayXS"
        class="inline-text pointer"
        style="max-width: 250px !important; text-align: left"
      >
        <span>{{ item.poi ? item.poi.name || '' : '' }}</span>
        <span>{{ item.layerPoi ? getNameOfLayerPoi(item.layerPoi) : '' }}</span>
      </td>
      <td
        @click="open(item)"
        class="inline-text"
        style="max-width: 280px !important;"
        v-show="fullWidth && !displayXS"
      >
        <v-chip class="ma-1" color="deep-purple" label outlined small
                v-for="category in item.categoryList.filter(it => !it.isDefault)">
          {{ category.name }}
        </v-chip>
      </td>
      <td @click="open(item)" class="inline-text pointer" v-show="fullWidth && !displayXS">
        {{ formatDate(item.creationDate) }}
      </td>
      <td @click="open(item)" class="inline-text pointer" v-show="fullWidth && !displayXS">
          <span v-if="item.performer">
            <user-avatar :user="item.performer" small/>
            <span style="white-space: nowrap; text-overflow: ellipsis">
              {{ item.performer.firstName + ' ' + item.performer.lastName }}
            </span>
          </span>
        <span v-else class="grey--text">
           {{ $t('notAssigned') }}
          </span>
      </td>
      <td @click="open(item)" class="pointer" style="text-align: right;" v-show="fullWidth && !displayXS">
        <v-avatar :color="getStatus(item.status).color" class="mx-3" size="20"/>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '../../componet-locale/issue-table/messages'
import { getNameOfLayerPoi } from '@/components/layer-poi/layer-poi-service'

export default {
  name: 'IssueTable',
  components: { UserAvatar },
  props: ['items', 'fullWidth'],
  mixins: [displayServiceMixin, issueMixin],
  i18n: { messages: messages },
  methods: {
    getNameOfLayerPoi: getNameOfLayerPoi,
    open (item) {
      this.$emit('open', item)
    }
  }
}
</script>
