<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="userList"
      @click="openDetails"
    >
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            :loading="exportXlsx"
            outlined
            @click="download"
          >
            <v-icon left>file_download</v-icon>
            Скачать XLSX
          </v-btn>
        </v-card-actions>
      </template>
      <template v-slot:item="{ item }">
        <tr @click="openDetails(item)">
          <td>{{ item.id }}</td>
          <td>{{ item.firstName + ' ' + item.lastName }}</td>
          <td>{{ item.login }}</td>
          <td>{{ item.email }}</td>
          <td>{{ formatDate(item.registrationDate) }}</td>
          <td>{{ item.role }}</td>
          <td>{{ item.position }}</td>
          <td>{{ item.company }}</td>
          <td>{{ item.lastLoginDate ? formatDate(item.lastLoginDate) : '-' }}</td>
          <td>{{ item.isActive ? $t('yes') : $t('no') }}</td>
          <td>{{ item.settings.gisLogin + item.settings.gisPassword ? $t('yes') : $t('no') }}</td>
          <td>{{ item.isImportedFromAD ? $t('yes') : $t('no') }}</td>
          <td>{{ item.emailNotificationsAllowed ? $t('yes') : $t('no') }}</td>
        </tr>
      </template>
    </v-data-table>
    <user-details-dialog
      ref="userDetailsDialog"
      @on-change="loadData"/>
  </div>
</template>

<script>
import UserDetailsDialog from '@/components/admin/user-management/UserDetailsDialog'
import messages from '../../../componet-locale/user-table/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'AdminPage',
  components: { UserDetailsDialog },
  i18n: { messages: messages },
  data: () => ({
    userList: [],
    exportXlsx: false
  }),
  created () {
    this.loadData()
  },
  computed: {
    headers () {
      return [
        { text: this.$t('id'), value: 'id' },
        { text: this.$t('name'), value: 'firstName' },
        { text: this.$t('login'), value: 'login' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('registrationDate'), value: 'registrationDate' },
        { text: this.$t('role'), value: 'role' },
        { text: this.$t('position'), value: 'position' },
        { text: this.$t('company'), value: 'company' },
        { text: this.$t('lastLoginDate'), value: 'lastLoginDate' },
        { text: this.$t('status'), value: 'isActive' },
        {
          text: this.$t('gisInfo'),
          value: 'settings',
          sort: (a, b) => {
            if ((a.gisLogin || '').length > (b.gisLogin || '').length) {
              return 1
            }
            return -1
          }
        },
        { text: this.$t('adUser'), value: 'isImportedFromAD' },
        { text: this.$t('emailNotificationsAllowed'), value: 'emailNotificationsAllowed' }
      ]
    }
  },
  methods: {
    openDetails (user) {
      this.$refs.userDetailsDialog.open(user)
    },
    download () {
      this.exportXlsx = true
      this.$axios
        .post(`users/export/xlsx`, null, {
          responseType: 'arraybuffer',
          timeout: 300000
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          link.download = `user-list.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
        .finally(() => this.exportXlsx = false)
    },
    loadData () {
      this.$axios
        .get('/users/get-all')
        .then(response => {
          this.userList = response.data
        })
    }
  }
}
</script>
