<template>
  <abstract-data-list type="CHART" view="chart-list"/>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'

export default {
  name: 'ChartList',
  components: { AbstractDataList }
}
</script>
