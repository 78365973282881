<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title>
        {{ field.username }}
        <v-spacer/>
      </v-card-title>
      <v-card-text>
        <wysiwyg v-model="page"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog=false"
          outlined>
          {{ $t('button.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          @click="save"
          color="primary"
          outlined>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import ZwsCommandBuilder from '@/services/zws-command-builder'

Vue.use(wysiwyg, {})

export default {
  name: 'HtmlViewDialog',
  data: () => ({
    dialog: false,
    field: {},
    page: ''
  }),
  methods: {
    async open (field) {
      this.field = field
      this.page = await ZwsCommandBuilder.getHtmlPage(field.url)
      this.dialog = true
    },
    save () {
      ZwsCommandBuilder.setHtmlPage(this.field.url, this.page)
      this.dialog = false
    }
  }
}
</script>
