<template>
  <v-dialog
    v-model="dialog"
    width="550"
  >
    <v-card>
      <v-card-title>
        Восстановление доступа для пользователя
        <v-spacer/>
        <v-btn
          icon
          @click="dialog=false"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Эта функция может быть использована в случае потери доступа к электронной почте и является альтеративным
          способом сброса пароля.
        </p>
        <p>
          Вы, как администратор системы, можете сгенерировать ссылку для восстановления пароля.

          <v-text-field
            readonly
            dense
            hide-details
            class="mt-2"
            :loading="loading"
            outlined
            v-model="url"
            id="password_token"
            append-icon="share"
            @click:append="copy"
          />

        </p>

        <v-btn
          color="primary"
          @click="generateToken">
          Получить токен
        </v-btn>

        <p class="mt-2">Скопируйте и передайте эту ссылку пользователю</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'GenerateTokenToRestorePasswordDialog',
  props: { user: Object },
  data: () => ({
    dialog: false,
    loading: false,
    url: ''
  }),
  methods: {
    open () {
      this.dialog = true
    },
    generateToken () {
      this.loading = true
      this.$axios
        .post('/users/generate-token', null, {
          params: { userId: this.user.id }
        })
        .then(response => this.url = response.data.url)
        .catch(() => EventBus.$emit('showErrorMessage'))
        .finally(() => this.loading = false)
    },
    copy () {
      let el = document.getElementById('password_token')
      el.select()
      document.execCommand('copy')
      EventBus.$emit('showInfoMessage', 'linkCopied')
    }
  }
}
</script>
