<template>
  <v-checkbox
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    class="mt-0 mb-4"
    dense
    hide-details
    style="width: fit-content"
    @change="emitChange"
  />
</template>

<script>
export default {
  name: 'BooleanEtItem',
  props: {
    value: null,
    header: Object,
    editable: Boolean
  },
  mounted () {
    if (typeof (this.value) !== 'boolean') {
      this.emitChange(false)
    }
  },
  updated () {
    if (typeof (this.value) !== 'boolean') {
      this.emitChange(false)
    }
  },
  methods: {
    emitChange (value) {
      this.$emit('update:value', value)
    }
  },
  computed: {
    readonly () {
      return this.header.isPrimaryTableKey
        || this.header.isComputedField
        || !this.editable
    }
  }
}
</script>
