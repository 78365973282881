<template>
  <v-menu :close-on-content-click="false" offset-y top>
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        <v-icon>settings</v-icon>
        {{ $t('settings') }}
      </v-btn>
    </template>
    <template>
      <v-card class="pt-4 px-3 pb-3">
        <v-select
          v-model="config.lt"
          :items="metaDataItems"
          :label="$t('lt')"
          class="mb-3"
          dense
          hide-details
          outlined
        />
        <v-select
          v-model="config.rt"
          :items="metaDataItems"
          :label="$t('rt')"
          class="mb-3"
          dense
          hide-details
          outlined
        />
        <v-select
          v-model="config.lb"
          :items="metaDataItems"
          :label="$t('lb')"
          class="mb-3"
          dense
          hide-details
          outlined
        />
        <v-select
          v-model="config.rb"
          :items="metaDataItems"
          :label="$t('rb')"
          dense
          hide-details
          outlined
        />
      </v-card>
    </template>
  </v-menu>

</template>

<script>
import messages from '@/componet-locale/map-export-dialog/messages'

export default {
  name: 'PrintMetaDataMenu',
  i18n: { messages },
  data () {
    return {
      config: {
        lt: 'SITE_INFO',
        rt: 'TEXT',
        lb: 'DATE',
        rb: 'TITLE'
      }
    }
  },
  methods: {
    getConfig () {
      return { ...this.config }
    }
  },
  computed: {
    metaDataItems () {
      return [
        { text: this.$t('empty'), value: '' },
        { text: this.$t('notes'), value: 'TEXT' },
        { text: this.$t('date'), value: 'DATE' },
        { text: this.$t('fullTitle'), value: 'TITLE' },
        { text: this.$t('siteInfo'), value: 'SITE_INFO' }
      ]
    }
  }
}
</script>
