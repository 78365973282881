<template>
  <abstract-data-list
    ref="abstractDataList"
    type="MODBUS_UNIT"
    view="modbus-unit-list">

    <template slot="actions">
      <v-btn
        @click="createNewItem"
        color="primary"
        outlined
        v-if="setAbility('MODERATOR')">
        {{ $t('button.create') }}
      </v-btn>
    </template>

    <modbus-unit-dialog ref="modbusUnitDialog"/>

  </abstract-data-list>
</template>

<script>
import AbstractDataList from '@/components/utils/AbstractDataList'
import ModbusUnitDialog from '@/components/modbus/ModbusUnitDialog'

export default {
  name: 'ModbusUnitList',
  components: { ModbusUnitDialog, AbstractDataList },
  methods: {
    createNewItem () {
      this.$refs.modbusUnitDialog.open()
    }
  }
}
</script>
