<template>
  <v-slide-group
    active-class="success"
    class="lps-images-slide-bar"
    show-arrows
  >
    <v-hover
      v-for="(image, i) in images"
      :key="i"
      v-slot="{hover}"
    >
      <v-slide-item
        style="height: 100px; width: 100px"
      >
        <v-card
          :style="{transform: hover ? 'translateY(-3px)' : ''}"
          class="ma-2"
          style="overflow: hidden; transition: transform .2s ease"
        >
          <v-row
            align="center"
            class="fill-height"
            justify="center"
          >
            <v-scale-transition>
              <lp-slide-bar-item :file-entity="image" :image-url="getImageUrl(image)"/>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-hover>
  </v-slide-group>


</template>

<script>
import LpImageCarouselItem from '@/components/map/images/LpImageCarouselItem.vue'
import LpSlideBarItem from '@/components/map/images/LpSlideBarItem.vue'

export default {
  name: "LpsImagesSlideBar",
  components: { LpSlideBarItem, LpImageCarouselItem },
  props: {
    attachedFiles: {
      required: true,
      type: Array
    },
    height: {
      type: String,
      default: '200'
    }
  },
  methods: {
    getImageUrl (image) {
      return this.isAuthenticated
        ? `files/get-attached-file/${image.id}`
        : `public-data/get-lp-attached-file/${image.id}`
    }
  },
  computed: {
    images () {
      return this.attachedFiles.filter(file => ['png', 'jpg', 'gif', 'jpeg'].includes(file.type))
    }
  }
}
</script>

<style scoped>
.lps-images-slide-bar >>> .v-slide-group__prev,
.lps-images-slide-bar >>> .v-slide-group__next {
  min-width: 0;
}
</style>
