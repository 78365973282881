<template>
  <v-combobox
    v-model="model.value"
    :item-text="(item) => $t(item[model.itemText])"
    :items="items"
    :placeholder="label"
    chips
    class="mb-2"
    clearable
    dense
    hide-details
    multiple
    outlined
    return-object
    small-chips
    @change="$emit('change')"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" :color="item.color" dark label small>
        {{ $t(item[model.itemText]) }}
      </v-chip>
      <span v-if="index === 1" class="grey--text caption">+{{ model.value.length - 1 }}</span>
    </template>
  </v-combobox>
</template>

<script>
import messages from '../../shared-locale/issue-status/messages'

export default {
  name: 'StatusFilter',
  props: {
    model: {
      type: Object,
      default: {
        value: [],
        listDataSource: '',
        itemText: ''
      }
    },
    label: {
      type: String,
      default: ''
    }
  },
  i18n: { messages: messages },
  computed: {
    items () {
      return this.model.listDataSource
    }
  }
}
</script>
