<template>
  <v-card outlined>
    <v-card-title>
      <span class="subtitle-1">Редактор правил назначения стилей</span>
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="addRule"
      >
        <v-icon left>add</v-icon>
        Добавить правило
      </v-btn>
    </v-card-title>
    <div class="pa-3">
      <v-expansion-panels
        multiple
        accordion
      >
        <vuedraggable style="width: 100%" handle=".handle" v-model="template.rules">
          <lps-rule
            v-for="(rule, i) in template.rules"
            :key="getUnicKey(rule.id, i)"
            :rule="rule"
            :template="template"
            :lpStyleList="lpStyleList"
            @remove="remove"
          />
        </vuedraggable>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import Vuedraggable from 'vuedraggable'
import LpsRule from '@/components/admin/poi-styles/LpsRule.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'LpsRuleEditor',
  components: { LpsRule, Vuedraggable },
  props: { template: Object },
  data: () => ({
    lpStyleList: []
  }),
  created () {
    this.loadStyles()
  },
  methods: {
    addRule () {
      this.template.rules.push({})
    },
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.lpStyleList = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка при попытке получить данные по стилям'))
    },
    remove (rule) {
      this.template.rules.splice(this.template.rules.indexOf(rule), 1)
    },
    getUnicKey(id, index){
      return id ? id : id + index
    }
  }
}
</script>
