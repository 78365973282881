<template>
  <div class="view-data-page">
    <div class="navigation-panel" style="height: 100vh">
      <v-treeview
        :active.sync="activeItems"
        :items="treeView"
        :open.sync="open"
        activatable
        class="pt-2"
        hoverable
        style="font-size: 12px"/>
    </div>
    <div style="margin-left: 280px; overflow-y: auto" class="router-view">
      <div id="doc-frame" style="height: 100vh; overflow: auto;">
        <doc-viewer
          :doc-body-list="docVersions"
          :open.sync="open"
          :selected-doc-body="selectedDocVersion"
          :tree-view.sync="treeView"
          :active-items.sync="activeItems"/>
      </div>
    </div>
  </div>
</template>

<script>
import messages from '../../componet-locale/help-page/messages'
import { EventBus } from '@/event-bus'
import DocViewer from '@/components/doc/DocViewer'

export default {
  name: 'Help',
  components: { DocViewer },
  data: () => ({
    docVersions: [],
    selectedDocVersion: {
      value: ''
    },
    treeView: [],
    activeItems: [],
    open: []
  }),
  i18n: { messages: messages },
  async mounted () {
    this.$axios
      .get('user-manual')
      .then(response => {
        this.docVersions = response.data
        if (this.docVersions.length > 0) {
          this.selectDocVersion(this.docVersions[0])
        } else {
          EventBus.$emit('showInfoMessage', this.$t('emptyList'))
        }
      })
  },
  methods: {
    selectDocVersion (docVersion) {
      this.docVersions.forEach(b => {
        b.isActive = false
      })
      this.selectedDocVersion = docVersion
    }
  }
}
</script>
