<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-text>
        <v-file-input
          truncate-length="15"
          v-model="sourceFile"
          label="GeoJSON"
          outlined
          show-size
        />
        <div v-if="value">
          <div v-for="[graphType, mapByColor] in Object.entries(value)">
            <span class="headline mx-3">
              {{ graphType }}
            </span>
            <template v-for="[color, data] in Object.entries(mapByColor)">
              <geo-json-field-mapping
                :data="data"
                :color="color"
                :layers="layers"
              />
            </template>
            <v-divider/>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          @click="parse"
          v-if="!value"
        >
          Далее
        </v-btn>
        <v-btn
          :loading="loading"
          @click="importGeoJson"
          v-if="value"
        >
          Импорт
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GeoJsonFieldMapping from '@/components/map/GeoJsonFieldMapping'
import { EventBus } from '@/event-bus'

export default {
  name: 'ImportFeaturesDialog',
  components: { GeoJsonFieldMapping },
  data: () => ({
    dialog: false,
    sourceFile: null,
    value: null,
    layers: null,
    loading: false
  }),
  methods: {
    open (layers) {
      this.value = null
      this.layers = layers
      this.dialog = true
    },
    async parse () {
      try {
        let sourceText = await this.sourceFile.text()
        let value = JSON.parse(sourceText)
        let features = value.features
        let map = features.reduce((acc, value) => {
          if (!acc[value.geometry.type]) {
            acc[value.geometry.type] = []
          }
          acc[value.geometry.type].push(value)
          return acc
        }, {})

        let mapByTypeAndColor = {}
        for (let [key, value] of Object.entries(map)) {
          mapByTypeAndColor[key] = value.reduce((acc, feature) => {
            let color
            let graphType
            switch (key) {
              case 'MultiLineString':
                graphType = 'MULTI_LINE_STRING'
                // color = feature.properties.stroke
                break
              case 'LineString':
                graphType = 'LINE_STRING'
                // color = feature.properties.stroke
                break
              case 'MultiPolygon':
                graphType = 'MULTI_POLYGON'
                // color = feature.properties.stroke
                break
              case 'Polygon' :
                graphType = 'POLYGON'
                // color = feature.properties.stroke
                break
              case 'Point':
                graphType = 'POINT'
                // color = feature.properties['marker-color']
                break
            }
            if (!acc[color]) {
              acc[color] = {
                layer: null,
                template: null,
                features: [],
                graphType: graphType,
                descriptionField: null,
                nameField: null
              }
            }
            acc[color].features.push(feature)
            return acc
          }, {})
        }
        this.value = mapByTypeAndColor
      } catch (e) {
        console.error(e)
      }
    },
    importGeoJson () {
      this.loading = true
      let poiList = []
      for (let list of Object.values(this.value)) {
        for (let featuresByColors of Object.values(list)) {
          featuresByColors.features.forEach(obj =>
            poiList.push({
              layer: { id: featuresByColors.layer.id },
              template: { id: featuresByColors.template.id },
              graphType: featuresByColors.graphType,
              geometry: JSON.stringify(obj.geometry),
              properties: this.getProperties(obj, featuresByColors.fieldsMapping)
            })
          )
        }
      }
      let listToSave = poiList.filter(it => !!it.layer && !!it.template)
      this.$axios
        .post('layer-poi/save-all', listToSave, {
          timeout: 600000
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Объекты успешно импортированны')
          this.dialog = false
          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.loading = false
          EventBus.$emit('showErrorMessage', 'Произошла ошибка')
        })
    },
    getProperties (obj, fieldsMapping) {
      let props = []
      for (let [key, template] of Object.entries(fieldsMapping)) {
        let value = obj.properties[key]
        let item = {
          field: template
        }
        switch (template.type) {
          case 'STRING': {
            item.string = value
          }
            break
          case 'BOOLEAN': {
            item.boolean = value
          }
            break
          case 'DATE': {
            if (value) item.date = new Date(value)
          }
            break
          case 'LIST': {
            item.value = value
          }
            break
          case 'NUMBER': {
            item.number = Number(value)
          }
            break
          case 'LINK' : {
            item.value = value
          }
            break
          default:
            item.value = value
        }
        props.push(item)
      }
      return props
    }
  }
}
</script>
