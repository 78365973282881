<template>
  <div v-if="type === 'LOCAL'">
    <v-btn :loading="backupButtonClicked" @click="backupNow" class="ml-5 mt-3">
      {{ $t('makeBackup') }}
    </v-btn>
    <v-list dense>
      <v-subheader class="pl-5" v-if="!backups.length">
        {{ $t('noBackup') }}
      </v-subheader>
      <v-list-item v-for="backup in backups">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('backupFrom') }} {{ formatDate(backup) }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon :disabled="restoreButtonClicked" @click="restore(backup)">restore</v-icon>
        </v-list-item-action>
        <v-list-item-action>
          <v-icon :disabled="deleteButtonClicked" @click="delete_(backup)">delete</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
  <div v-else>
    <div class="pa-4">
      <p> {{ $t('backupDisabled') }} </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/backup-page/messages'

export default {
  name: 'BackupPage',
  data: () => ({
    backups: undefined,
    backupButtonClicked: false,
    restoreButtonClicked: false,
    deleteButtonClicked: false,
    type: undefined
  }),
  i18n: { messages: messages },
  methods: {
    getBackups () {
      this.$axios
        .get('/backup/get-all')
        .then(response => {
          this.backups = response.data.map(it => moment(it)).sort(function (first, second) {
            return second - first
          })
        })
    },
    backupNow () {
      this.backupButtonClicked = true
      this.$axios
        .post('/backup/backup-now')
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('backupComplete'))
          this.getBackups()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('backupError')))
        .finally(() => this.backupButtonClicked = false)
    },
    restore (date) {
      this.restoreButtonClicked = true
      this.$axios
        .post('/backup/restore-by-date', date)
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('backupRestore')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('backupRestoreError')))
        .finally(() => this.restoreButtonClicked = false)
    },
    delete_ (date) {
      this.deleteButtonClicked = true
      this.$axios
        .post('/backup/delete-by-date', date)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('backupDeletion'))
          this.getBackups()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('backupDeletionError')))
        .finally(() => this.deleteButtonClicked = false)
    },
    formatDate (date) {
      return date.format('DD.MM.YYYY HH:mm:ss')
    },
    getType () {
      this.$axios
        .get('/backup/get-type')
        .then(response => this.type = response.data)
    }
  },
  created () {
    this.getBackups()
    this.getType()
  }
}
</script>
