import store from '../store'

export const roleService = {

  setAbility (requiredRole) {
    if (!store.getters.currentLoginUser) return false

    let roleHierarchy = ['USER', 'MODERATOR', 'ADMIN', 'SYSTEM_ADMIN']
    let requiredRoleIndex = roleHierarchy.indexOf(requiredRole)
    if (requiredRoleIndex === -1) {
      console.error(`Unknown role ${requiredRole}`)
      return false
    }

    let currentRole = store.getters.currentLoginUser.role
    let currentRoleIndex = roleHierarchy.indexOf(currentRole)
    if (currentRoleIndex === -1) {
      console.error(`Unknown role ${currentRole}`)
      return false
    }

    let userProjects = store.getters.currentLoginUserProjects
    let selectedProject = store.getters.selectedProject
    if (currentRole === 'USER' &&
          userProjects.length && selectedProject &&
          userProjects.find(it => it.project === selectedProject.id).moderator) {
      currentRoleIndex = roleHierarchy.indexOf('MODERATOR') // increase role
    }

    return currentRoleIndex >= requiredRoleIndex
  }

}
