<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="item.name"
      :label="$t('name')"
      :rules="[v => !!v || 'Поле должно быть заполнено']"
      class="mt-1"
      dense
      outlined
    />
    <slot/>
    <v-combobox
      :items="providerList"
      :label="$t('source')"
      :value="item.provider"
      clearable
      dense
      item-text="name"
      outlined
      return-object
      @change="changeProvider"
    />

    <v-textarea
      v-model="item.description"
      :label="$t('description')"
      auto-grow
      counter="1024"
      dense
      outlined
      rows="1"
    />

    <v-select
      v-model="item.status"
      :items="adStatusList"
      :label="$t('adStatus.label')"
      dense
      outlined
    >
      <template v-slot:selection="{item}">
        {{ $t(`adStatus.${item}`) }}
      </template>
      <template v-slot:item="{item}">
        {{ $t(`adStatus.${item}`) }}
      </template>
    </v-select>
    <keywords-picker
      v-model:selected="item.keywords"
      :project-id="+this.$route.params.projectId"
      @change="forceRender"
    />
    <category-picker
      v-model:selected="item.categoryList"
      :project-id="+this.$route.params.projectId"
      @change="forceRender"
    />
  </v-form>
</template>

<script>
import messages from '../../componet-locale/abstract-data-edit-form/messages'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'
import KeywordsPicker from '@/components/utils/KeywordsPicker.vue'

export default {
  name: 'AbstractDataEditForm',
  components: { KeywordsPicker, CategoryPicker },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  i18n: { messages },
  data: () => ({
    adStatusList: ['NO_DATA', 'DATA_RECEIVED', 'CHECKED', 'NEEDS_IMPROVEMENT'],
    valid: true,
    requiredFieldRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
    providerList: [],
    languages: [],
    categoryList: []
  }),
  mounted () {
    this.init()
  },
  methods: {
    changeProvider (val) {
      if (typeof val === 'object') {
        this.item.provider = val
      } else if (typeof val === 'string' && !!val.length) {
        if (val.length) {
          this.item.provider = {
            name: val,
            description: '_'
          }
        } else {
          this.item.provider = null
        }
      } else if (!val) {
        this.item.provider = null
      }
    },
    forceRender () {
      this.$forceUpdate()
    },
    validateForm () {
      return this.$refs.form.validate()
    },
    init () {
      let projectId = this.selectedProject.id
      this.$axios
        .get('/languages/get-all')
        .then(response => {
          this.languages = response.data
        })
      this.$axios
        .get('/provider/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.providerList = response.data
        })
      this.$axios
        .get('/category/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.categoryList = response.data
        })
    }
  }
}
</script>
