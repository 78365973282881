<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="overflow-x-hidden">
      <v-card-text class="pa-2">
        <vue-editor
          v-model="widget.text"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="close">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor/dist/vue2-editor.umd'

export default {
  name: "TextWidgetEditDialog",
  components: { VueEditor },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
