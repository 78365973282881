<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <v-card>
      <v-card-title
        class="headline lighten-2"
        primary-title>
        {{ $t('version') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="reportVersion.id">
        {{ report.name }}
      </v-card-subtitle>

      <v-card-text>
        <file-uploader v-if="dialog && !reportVersion.id" @handle="handleFileUpload"/>
        <v-text-field
          v-model="reportVersion.name"
          :label="$t('name')"
          dense
          outlined
        />
        <v-textarea
          v-model="reportVersion.changeDescription"
          :label="$t('description')"
          counter="255"
          dense
          outlined
          rows="3"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          text @click="close">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          :loading="loading"
          outlined
          primary @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mammoth from 'mammoth'
import { EventBus } from '@/event-bus'
import FileUploader from '@/components/utils/FileUploader'
import sharedMessages from '@/componet-locale/update-doc-dialog/messages'

const EMPTY_REPORT_VERSION = {
  name: '',
  changeDescription: '',
  fileName: ''
}

export default {
  name: 'ReportVersionDialog',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  i18n: { sharedMessages },
  components: { FileUploader },
  data: () => ({
    dialog: false,
    loading: false,
    reportVersion: EMPTY_REPORT_VERSION
  }),
  methods: {
    open (reportVersion = null) {
      this.reportVersion = reportVersion || { ...EMPTY_REPORT_VERSION }
      this.dialog = true
    },
    save () {
      let isNewReportVersion = !this.reportVersion.id
      this.reportVersion.report = this.report
      let url = isNewReportVersion ? 'report/add-report-version' : 'report/update-report-version'
      this.$axios
        .post(url, this.reportVersion, { timeout: 120000 })
        .then(response => {
          this.$emit(isNewReportVersion ? 'newVersionSaved' : 'versionUpdated', response.data)
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() =>
          this.dialog = false
        )
    },
    handleFileUpload (file, load) {
      this.loading = true
      let reader = new FileReader()
      let self = this
      let fileExt = file.name.split('.').pop().toLowerCase()
      this.reportVersion.name = file.name.replace(/\.[^/.]+$/, '')
      if (fileExt !== 'docx') {
        return // show error
      } else {
        this.isFileConverted = false
        reader.onload = () => {
          let arrayBuffer = reader.result
          mammoth.convertToHtml({ arrayBuffer })
            .then(result => {
              self.reportVersion.value = result.value
            })
            .catch(() => EventBus.$emit('showErrorMessage', 'readingError'))
            .done()
        }
      }
      reader.readAsArrayBuffer(file)
      this.saveFile(file, load)
    },
    saveFile (file, load) {
      let formData = new FormData()
      formData.append('file', file)
      this.$axios
        .post('files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          this.reportVersion.filePath = response.data
          this.reportVersion.fileName = file.name
          this.loading = false
          load()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
