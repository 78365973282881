<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="pa-4" style="overflow: hidden">
      <div v-if="printTemplates && printTemplates.length > 0">
        <v-card-title class="pa-0 mb-6">{{ $t('selectPrintTemplate') }}</v-card-title>
        <v-select
          v-model="selectedTemplate"
          :items="printTemplates"
          :label="$t('printTemplate')"
          item-text="name"
          outlined
          return-object
        />
        <v-row class="px-3">
          <v-btn
            outlined
            @click="close"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            v-if="printObjectFields"
            class="mr-3"
            outlined
            @click="print"
          >
            <v-icon class="pr-2 pl-0">print</v-icon>
            {{ $t('printObjectFields') }}
          </v-btn>
          <v-btn
            :disabled="!selectedTemplate"
            color="primary"
            outlined
            @click="openPrintTemplateDialog"
          >
            {{ $t('button.next') }}
          </v-btn>
        </v-row>
      </div>
      <div v-else>
        <v-card-title class="px-0">{{ $t('noPrintTemplatesByObject') }}</v-card-title>
        <v-row class="px-3">
          <v-btn
            outlined
            @click="close"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            outlined
            @click="print"
          >
            <v-icon class="pr-2 pl-0">print</v-icon>
            {{ $t('printObjectFields') }}
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/print-template/messages'

export default {
  name: 'PrintTemplateSelectorDialog',
  i18n: { messages },
  props: {
    printTemplateList: Array,
    printObjectFields: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dialog: false,
      selectedTemplate: null,
      printTemplates: this.printTemplateList
    }
  },
  methods: {
    init () {
      this.dialog = true
    },
    open (templates = null) {
      if (templates) {
        this.printTemplates = templates
        this.selectedTemplate = templates[0]
        if (!this.printObjectFields && templates.length === 1) {
          this.openPrintTemplateDialog()
          return
        }
      }
      this.init()
    },
    close () {
      this.dialog = false
    },
    openPrintTemplateDialog () {
      this.$emit('openPrintTemplateDialog', this.selectedTemplate.id)
      this.close()
    },
    print () {
      this.$emit('print')
      this.close()
    }
  }
}
</script>
