<template>
  <div>
    <vl-layer-vector :z-index="4">
      <vl-source-vector
        :features.sync="mapIssueFeatureList"
      >
        <vl-style-box>
          <vl-style-icon
            src="/image/issue.png"
            :scale="0.2"
            :anchor="[0.5, 0.4]"/>
        </vl-style-box>
      </vl-source-vector>
    </vl-layer-vector>

    <new-issue-dialog
      v-if="currentLoginUser"
      ref="newIssueDialog"
      @onSave="onMapIssueSave"
    />

  </div>
</template>

<script>
import NewIssueDialog from '@/components/issue/NewIssueDialog'

export default {
  name: 'MapIssue',
  components: { NewIssueDialog },
  props: { mapId: Number },
  data: () => ({
    mapIssueFeatureList: []
  }),
  methods: {
    async getMapIssueFeatureList () {
      let response = await this.$axios
        .get('issue/get-issues-by-map', { params: { mapId: this.mapId } })

      this.mapIssueFeatureList = response.data
        .map(issue => {
          return {
            type: 'Feature',
            id: issue.id,
            geometry: {
              type: 'Point',
              coordinates: JSON.parse(issue.coordinates)
            },
            properties: {
              issue: issue
            }
          }
        }
        )
    },
    onMapIssueSave (issue) {
      this.mapIssueFeatureList.push({
        type: 'Feature',
        id: issue.id,
        geometry: {
          type: 'Point',
          coordinates: JSON.parse(issue.coordinates)
        },
        properties: {
          issue: issue
        }
      })
    },
    createMapIssue (coordinates) {
      this.$refs.newIssueDialog.createMapIssue(coordinates, this.mapId)
    }
  },
  watch: {
    mapId (val) {
      if (!val) return
      this.getMapIssueFeatureList()
    }
  }
}
</script>
