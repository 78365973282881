<template>
  <v-img :src="image"/>
</template>

<script>
import ZwsCommandBuilder from '@/services/zws-command-builder'

export default {
  name: 'ImgFromZulu',
  props: { url: String },
  data: () => ({
    image: ''
  }),
  created () {
    this.getBlob()
  },
  methods: {
    async getBlob () {
      this.image = await ZwsCommandBuilder.getBlob(this.url)
    }
  }
}
</script>
