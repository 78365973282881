<template>
  <div>
    <v-list>
      <v-list-item
        v-for="item in listOfData"
        :key="item.id"
        @click="()=>{}"
      >
        <v-list-item-action
          class="mr-4"
          @click="viewFile(item)"
        >
          <v-img
            :src="`/image/${item.type}.png`"
            class="mx-auto"
            height="40"
            width="35">
          </v-img>
        </v-list-item-action>
        <v-list-item-content
          @click="viewFile(item)"
        >
          <div>
            {{ item.name }}
            <v-icon v-if="item.isPublic" x-small>group</v-icon>
          </div>
          <v-list-item-subtitle>
            {{ getUserFullName(item.owner) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="caption">{{ formatDate(item.creationDate) }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="isAuthenticated">
          <v-menu offset-y>
            <template v-slot:activator="{on}">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-list-item @click="changeAttachedFileIsPublic(item)">
                  <v-list-item-content>
                    {{ $t(item.isPublic ? 'hide' : 'doPublic') }}
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.isPublic ? 'mdi-account-multiple-remove' : 'group' }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="openConfirmationDialog(item)">
                  <v-list-item-content>
                    {{ $t('button.remove') }}
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="red">delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

        </v-list-item-action>
      </v-list-item>
    </v-list>
    <confirmation-dialog
      ref="confirmationDialog"
      :title="$t('wantToRemove')"
      @confirm="remove"
    />
    <file-viewer
      ref="fileViewer"
      :storage-url="fileEntity => isAuthenticated
        ? `files/get-attached-file/${fileEntity.id}`
        : `public-data/get-lp-attached-file/${fileEntity.id}`"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import FileViewer from '@/components/drive/FileViewer.vue'
import messages from '@/componet-locale/lp-file-attachments/messages'

export default {
  name: 'LpFileAttachments',
  components: { FileViewer, ConfirmationDialog },
  props: {
    listOfData: Array
  },
  i18n: { messages },
  methods: {
    viewFile (item) {
      this.$refs.fileViewer.open(item)
    },
    openConfirmationDialog (item, evt) {
      this.$refs.confirmationDialog.open(item)
      evt.preventDefault()
    },
    remove (item) {
      this.$axios
        .delete('files/attached-file', {
          params: {
            id: item.id
          }
        })
        .then(() => {
          this.$emit('reload')
          EventBus.$emit('showInfoMessage', this.$t('fileRemoved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('removeError')))
    },
    changeAttachedFileIsPublic (item) {
      this.$axios.get('files/change-public-for-attached-data', {
        params: {
          id: item.id
        }
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataChanged'))
          this.$emit('reload')
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
