<template>
  <div class="pl-2 pr-3">
    <div v-if="selectedSimulationType == null">
      <span class="subtitle-1 font-weight-bold">Выберите тип симуляции</span>
      <v-list>
        <v-list-item dense v-for="type in simulationTypes" @click="selectedSimulationType = type">
          <v-icon class="mr-3">{{ type.icon }}</v-icon>
          <span style="font-size: 0.875rem">
            {{ type.title }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="selectedSimulationType">
      <div>
        <v-btn icon @click="selectedSimulationType = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>
          {{ selectedSimulationType.title }}
        </span>
      </div>
      <component
        :is="getSimulationComponent()"
        ref="simulator"
        :selected-features="selectedFeatures"
        :simulationType="selectedSimulationType.value"
        @showRulesResult="getMapSimulationRulesFeatures"
      />
    </div>
  </div>
</template>

<script>
import NetworkAnalyzeSwitchBar from '@/components/map/zws/simulations/NetworkAnalyzeSwitchBar.vue'
import NetworkRecalcBar from '@/components/map/zws/simulations/NetworkRecalcBar.vue'
import LayerFindSimulationBar from '@/components/map/zws/simulations/LayerFindSimulationBar.vue'
import zwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'

const SIMULATIONS = [
  {
    title: 'Переключения',
    icon: 'mdi-switch',
    value: 'network-analyze-switch'
  },
  {
    title: 'Поиск арматуры',
    icon: 'mdi-paper-roll',
    value: 'network-recalc'
  },
  {
    title: 'Поиск связанных',
    icon: 'mdi-graph',
    value: 'layer-find-connected'
  },
  {
    title: 'Поиск не связанных',
    icon: 'mdi-scatter-plot',
    value: 'layer-find-disconnected'
  },
  {
    title: 'Поиск пути',
    icon: 'mdi-vector-line',
    value: 'layer-find-way'
  },
  {
    title: 'Поиск Колец',
    icon: 'mdi-vector-circle',
    value: 'layer-find-loops'
  },
]

export default {
  name: "MapSimulationBar",
  props: {
    selectedFeatures: Array,
    map: Object
  },
  data: () => ({
    simulationTypes: SIMULATIONS,
    selectedSimulationType: null
  }),
  methods: {
    getSimulationComponent () {
      switch (this.selectedSimulationType.value) {
        case 'network-analyze-switch':
          return NetworkAnalyzeSwitchBar
        case 'network-recalc':
          return NetworkRecalcBar
        case 'layer-find-connected':
        case 'layer-find-disconnected':
        case 'layer-find-way':
        case 'layer-find-loops':
          return LayerFindSimulationBar
      }
    },
    async getMapSimulationRulesFeatures (features) {
      const layer = features[0].layer
      this.$refs.simulator.startMapFeaturesLoading()

      const featuresWithProps = await zwsCommandBuilder.getElemsByID(layer, features.map(it => it.elemId), null, {
        excludeModeList: true,
        excludeQueryList: true,
        excludeGeometry: true
      })

      const simulationRule = this.map.simulationRules[0]
      const relatedFeaturesIds = featuresWithProps
        .map(f => {
          const prop = f.props.find(prop => prop.name === simulationRule.relationPropertyName)
          return prop ? prop.value : null
        })
        .filter(el => el)
      const relatedLayer = simulationRule.targetLayer

      await zwsCommandBuilder.getLayerTypes(relatedLayer)

      const relatedFeatures = await zwsCommandBuilder.getElemsByID(relatedLayer, relatedFeaturesIds, null, {
        excludeGeometry: true,
        excludeQueryList: true,
        excludeModeList: true,
        excludeProps: true
      })
      this.$refs.simulator.finishMapFeaturesLoading()

      EventBus.$emit('showFeaturesDialog', relatedFeatures)
    }
  }
}
</script>
