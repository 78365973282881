<template>
  <div>
    <v-select
      v-if="multiple"
      v-model="value.listOfValue"
      :items="filteredItems"
      :label="value.field.name"
      :readonly="readonlyMode"

      multiple
      outlined
      small-chips
    >
      <template v-slot:prepend-item>
        <v-text-field
          v-model="inputValue"
          :readonly="readonlyMode"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
        />
      </template>
    </v-select>
    <v-select
      v-else
      v-model="value.value"
      :items="filteredItems"
      :label="value.field.name"
      :readonly="readonlyMode"
      dense
      outlined
    >
      <template v-slot:prepend-item>
        <v-text-field
          v-model="inputValue"
          :readonly="readonlyMode"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
        />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'ListFromDataTableField',
  props: {
    value: Object,
    readonlyMode: Boolean,
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    inputValue: '',
  }),
  mounted () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let [tableId, header] = this.value.field.options.split(',')
      this.$axios
        .get('table/get-options', {
          params: { tableId, header }
        })
        .then(response => {
          this.items = response.data
        })
    }
  },
  computed: {
    filteredItems () {
      const items = []


      if (this.multiple && this.value.listOfValue) {
        items.push(...this.value.listOfValue)
      } else if (!this.multiple && this.value.value) {
        items.push(this.value.value)
      }

      items.push(
        ...this.items
          .filter(it =>
            it.toString().toLowerCase().trim().includes(
              (this.inputValue || '').trim().toLowerCase()
            )
          )
      )

      return items
    }
  }
}
</script>
