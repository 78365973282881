<template>
  <scrolling-document class="mt-5"
                      @pages-fetch="onPagesFetch"
                      v-bind="{pages, pageCount, currentPage, scale: 1}"
                      v-slot="{page, isPageFocused, isPageVisible}">
    <pdf-thumbnail v-bind="{page, isPageFocused, isPageVisible}"
                   @thumbnail-rendered="onThumbnailRendered"
                   @thumbnail-errored="onThumbnailErrored"
                   @page-focus="onPageFocused"/>
  </scrolling-document>
</template>

<script>
import ScrollingDocument from '@/components/utils/pdf/ScrollingDocument'
import PdfThumbnail from '@/components/utils/pdf/PdfThumbnail'
import visible from '@/directives/visible'

export default {
  name: 'PdfPreview',
  components: { PdfThumbnail, ScrollingDocument },
  directives: { visible },
  props: {
    pages: {
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onPagesFetch (currentPage) {
      this.$emit('pages-fetch', currentPage)
    },
    onPageFocused (pageNumber) {
      this.$emit('page-focus', pageNumber)
    },
    onThumbnailRendered (payload) {
      this.$el.dispatchEvent(new Event('scroll'))
      this.$emit('thumbnail-rendered', payload)
    },
    onThumbnailErrored (payload) {
      this.$emit('thumbnail-errored', payload)
    }
  }
}
</script>
