export const getters = {
  activeChat: state => state.chats.find(chat => chat.id === state.activeChatID),
  activeChatID: state => state.activeChatID,
  activeModalChat: state => state.activeModalChat,
  archivedChats: state => state.chats.filter(i => i.archived),
  archivedChatsShown: state => state.archivedChatsShown,
  chats: state => state.chats,
  getChatById: state => id => {
    return state.chats.find(chat => chat.id == id)
  },
  filterByChatType: state => state.filterByChatType,
  nonArchivedChats: state => state.chats.filter(i => !i.archived),
  establishedConnection: state => state.establishedConnection,
  totalNumberOfUnreadMessages: state => state.chats.map(i => i.numberOfUnreadMessages).reduce((a, b) => a + b, 0),
  numberOfUnreadMessagesArchivedChats: (state, getters) => {
    return getters.archivedChats.map(i => i.numberOfUnreadMessages).reduce((a, b) => a + b, 0)
  },
  currentLoginUser: state => state.currentLoginUser,
  currentLoginUserProjects: state => state.currentLoginUserProjects,
  selectedProject: state => state.selectedProject,
  isAuthenticated: state => !!state.currentLoginUser,
  onlineUsers: state => state.onlineUsers,
  gisCredentials: state => state.gisCredentials,
  collectiveData: state => state.collectiveData,
  getMapView: state => mapId => state.mapViews.find(el => el.mapId === mapId)
}
