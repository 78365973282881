<template>
  <div v-else style="text-align: center">
    <div class="py-2">{{$t('formatNotSupported')}}</div>
    <router-link to="/">{{$t('returnToHomepage')}}</router-link>
  </div>
</template>

<script>

import messages from '@/componet-locale/public-data/messages'

export default {
  i18n:{messages},
  name: 'PublicDataException'
}
</script>
