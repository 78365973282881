<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title
        class="headline lighten-2"
        primary-title>
        {{ $t('compareVersions') }}
      </v-card-title>
      <v-card-text>
        <v-combobox :items="msDocVersionsList"
                    clearable
                    item-text="name"
                    :label="$t('originalVersion')"
                    return-object
                    v-model="originalDoc">
        </v-combobox>
        <v-combobox :items="msDocVersionsList"
                    clearable
                    item-text="name"
                    :label="$t('editedVersion')"
                    return-object
                    v-model="changedDoc">
        </v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" outlined text>{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!dataSelected" @click="findDiff" outlined primary>{{ $t('compare') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import messages from '../../componet-locale/doc-diff-dialog/messages'

export default {
  name: 'DocDiffDialog',
  props: {
    docVersionsList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    changedDoc: null,
    originalDoc: null
  }),
  i18n: { messages: messages },
  methods: {
    findDiff () {
      this.$emit('findDiff', this.originalDoc, this.changedDoc)
      this.dialog = false
    },
    open () {
      this.dialog = true
    }
  },
  computed: {
    msDocVersionsList () {
      let list = this.docVersionsList.filter(v => v.type === 'MS_DOC')
      if (list.length === 1) {
        this.changedDoc = list[0]
        this.originalDoc = list[0]
      } else if (list.length > 1) {
        this.changedDoc = list[0]
        this.originalDoc = list[1]
      }
      return list
    },
    dataSelected () {
      return !!(this.changedDoc && this.originalDoc)
    }
  }
}
</script>
