<template>
  <v-row
    ref="pagination"
    :style="widget ? widgetPaginationStyle: {}"
    align="end"
    class="d-pagination mx-0 pt-1 pb-3 elevation-2"
  >
    <v-col v-if="paginationView.rowsPerPageText" class="py-0">
      <div class="d-pagination-text ws-no-wrap">{{ $t('itemsPerPage') }}:</div>
    </v-col>
    <v-col class="py-0">
      <v-select
        v-model="pagination.itemsPerPage"
        :items="itemsPerPageList"
        class="select-items-per-page"
        dense
        hide-details
      />
    </v-col>
    <v-col v-if="paginationView.currentPage" class="py-0">
      <div class="ws-no-wrap">
        <span>
          {{ paginationProps.pageStart }}
          {{ '-' }}
          {{ paginationProps.pageStop }}
          {{ ' / ' }}
          {{ paginationProps.itemsCount }}
        </span>
      </div>
    </v-col>
    <v-col class="py-0">
      <div class="d-flex">
        <v-btn
          :disabled="paginationProps.page <= 1"
          icon
          small
          @click="prevPage">
          <v-icon>mdi-menu-left</v-icon>
        </v-btn>
        <div class="pagination-page-text">{{ paginationProps.page }}</div>
        <v-btn
          :disabled="paginationProps.page >= paginationProps.pageCount"
          icon
          small
          @click="nextPage"
        >
          <v-icon> mdi-menu-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>

import messages from '@/componet-locale/et/messages'

const widgetPaginationStyle = {
  position: 'fixed',
  right: '1px',
  left: '1px',
  bottom: '1px',
  borderRadius: ' 0 0 5px 5px'
}

export default {
  name: 'DPagination',
  i18n: { messages },
  data: () => ({
    paginationView: {
      rowsPerPageText: true,
      currentPage: true
    },
    widgetPaginationStyle
  }),
  props: {
    pagination: Object,
    itemsPerPageList: Array,
    widget: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const observer = new ResizeObserver(e => {
      const width = e[0].borderBoxSize[0].inlineSize
      this.paginationView = {
        rowsPerPageText: width > 820,
        currentPage: width > 340
      }
    })
    observer.observe(this.$refs.pagination)
  },
  methods: {
    nextPage () {
      if (this.pagination.page < this.paginationProps.pageCount) {
        this.openPage(this.pagination.page + 1)
      }
    },
    prevPage () {
      if (this.pagination.page > 1) {
        this.openPage(this.pagination.page - 1)
      }
    },
    openPage (page) {
      this.$emit('openPage', page)
    }
  },
  computed: {
    paginationProps () {
      return {
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        pageStart: (this.pagination.page - 1) * this.pagination.itemsPerPage + 1,
        pageStop: this.pagination.page * this.pagination.itemsPerPage > this.pagination.itemsCount
          ? this.pagination.itemsCount
          : this.pagination.page * this.pagination.itemsPerPage,
        itemsCount: this.pagination.itemsCount,
        pageCount: Math.ceil(this.pagination.itemsCount / this.pagination.itemsPerPage)
      }
    }
  }
}
</script>

<style scoped>

.d-pagination {
  background-color: white;
  height: 46px;
}

.ws-no-wrap {
  white-space: nowrap;
}

.d-pagination-text {
  text-align: right
}

.pagination-page-text {
  width: 2em;
  text-align: center
}

.select-items-per-page {
  width: 70px;
  align-self: center;
}

</style>
