<template>
  <ad-widget-container
    :dense="dense"
    :title="data.name"
    @clickOnTitle="e => openInNewTab(data, e)"
  >
    <div v-bind:class="dense ? '' : 'pa-3'">
      <chart :chart="chart" :table="table"/>
    </div>
  </ad-widget-container>
</template>
<script>

import Chart from '../chart/Chart'
import { EventBus } from '@/event-bus'
import AdWidgetContainer from '@/components/widget/AdWidgetContainer.vue'

export default {
  name: 'ChartWidget',
  components: { AdWidgetContainer, Chart },
  props: {
    widget: {
      type: Object,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: {},
    data: {},
    chart: {}
  }),
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.$axios
        .get('data/get', { params: { id: this.widget.abstractData.id } })
        .then(response => {
          this.data = response.data
          this.chart = JSON.parse(this.data.value)
          this.loadTable(this.data.table.id)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    async loadTable (id) {
      this.$axios
        .get('/table/get-table/' + id)
        .then(response => {
          this.table = JSON.parse(response.data.table)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  }
}
</script>
