<template>
  <v-dialog v-model="dialog" width="800">

    <v-card>
      <v-card-title>
        <span>{{ user.firstName }} {{ user.lastName }}</span>
      </v-card-title>

      <v-card-text v-if="!isUserEmpty" class="pt-0">
        <p>{{ $t('registrationDate') }}: <b>{{ formatDate(user.registrationDate) }}</b></p>
        <p v-if="user.isImportedFromAD">{{ $t('importedFromAD') }}</p>

        <v-select
          v-model="user.role"
          :items="getTranslatedRoleList(roleList)"
          class="my-2"
          dense
          outlined
          @change="save"/>

        <v-text-field
          v-model="user.login"
          :disabled="user.isImportedFromAD"
          :label="$t('login')"
          append-icon="person"
          dense
          outlined
        />

        <v-text-field
          v-model="user.firstName"
          :disabled="user.isImportedFromAD"
          :label="$t('firstName')"
          append-icon="person"
          dense
          name="login"
          outlined
          type="text"/>

        <v-text-field
          v-model="user.lastName"
          :disabled="user.isImportedFromAD"
          :label="$t('surname')"
          append-icon="person"
          dense
          name="login"
          outlined
          required
          type="text"/>

        <v-text-field
          v-model="user.phoneNumber"
          :label="$t('phone')"
          append-icon="phone"
          dense
          name="login"
          outlined
          required
          type="text"/>

        <v-text-field
          v-model="user.email"
          :disabled="user.isImportedFromAD"
          :label="$t('email')"
          append-icon="alternate_email"
          dense
          name="login"
          outlined
          required
          type="text"/>

        <v-text-field
          v-model="user.position"
          :disabled="user.isImportedFromAD"
          :label="$t('position')"
          append-icon="home_work"
          dense
          name="position"
          outlined
          type="text"/>

        <v-text-field
          v-model="user.company"
          :disabled="user.isImportedFromAD"
          :label="$t('company')"
          append-icon="home_work"
          dense
          name="company"
          outlined
          type="text"/>

        <v-text-field
          v-model="user.settings.gisLogin"
          :hide-details="!showGisPassword"
          :label="$t('gisLogin')"
          append-icon="mdi-map-marker"
          dense
          outlined
          type="text"/>

        <v-text-field
          v-if="showGisPassword"
          v-model="user.settings.gisPassword"
          :label="$t('gisPassword')"
          append-icon="mdi-key"
          dense
          outlined
          type="text"
        />

        <v-btn
          v-if="!showGisPassword"
          :class="showGisPassword ? '' : 'mt-2'"
          color="primary"
          small text
          @click="showGisPassword = true"
        >
          {{ $t('showPassword') }}
        </v-btn>

        <v-switch
          v-model="user.emailNotificationsAllowed"
          :label="$t('emailNotification')"/>

      </v-card-text>

      <v-card-title>
        <v-btn v-if="user.isActive"
               color="red"
               outlined
               @click="deactivateUser">
          {{ $t('deactivate') }}
        </v-btn>

        <v-btn v-if="!user.isActive"
               color="green"
               dark
               @click="activateUser">
          {{ $t('activate') }}
        </v-btn>

        <v-btn class="ml-2"
               outlined
               @click="generateToken">
          Сброс пароля
        </v-btn>
        <v-spacer/>
        <v-btn
          :loading="userActivityLoading"
          outlined
          @click="exportActivityLog"
        >
          {{ $t('exportUserActivity') }}
        </v-btn>
        <v-btn class="ml-2"
               color="primary"
               outlined
               @click="save">
          {{ $t('save') }}
        </v-btn>
      </v-card-title>

      <v-divider/>

      <div>
        <v-card-title>
          {{ $t('projects') }}
          <v-spacer/>
          <v-btn outlined @click="$refs.userProjectCreateDialog.createUserProject(user)">
            <v-icon left>add</v-icon>
            {{ $t('button.add') }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <user-project-card v-for="item in userProjects" :is-active="user.role==='USER'"
                             :value="item"
                             @on-remove="removeUserProject"
                             @click.native="openUserProject(item)"/>

          <v-card v-if="!userProjects.length" outlined>
            <v-card-text>
              <p>{{ $t('noProjects') }}</p>
            </v-card-text>
          </v-card>
        </v-card-text>
      </div>
    </v-card>

    <generate-token-to-restore-password-dialog ref="generateTokenToRestorePasswordDialog" :user="user"/>
    <user-project-create-dialog ref="userProjectCreateDialog" @on-save="getUserProjects"/>
    <user-project-edit-dialog ref="userProjectEditDialog" @on-save="getUserProjects" @on-remove="removeUserProject"/>

  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import UserProjectCard from '@/components/admin/user-management/UserProjectCard'
import UserProjectCreateDialog from '@/components/admin/user-management/UserProjectCreateDialog'
import UserProjectEditDialog from '@/components/admin/user-management/UserProjectEditDialog'
import messages from '../../../componet-locale/user-details-dialog/messages'
import GenerateTokenToRestorePasswordDialog
  from '@/components/admin/user-management/GenerateTokenToRestorePasswordDialog'
import _ from 'lodash'

export default {
  name: 'UserDetailsDialog',
  components: { GenerateTokenToRestorePasswordDialog, UserProjectCreateDialog, UserProjectEditDialog, UserProjectCard },
  i18n: { messages },
  data: () => ({
    dialog: false,
    userActivityLoading: false,
    user: {},
    userProjects: [],
    roleList: [],
    showGisPassword: false
  }),
  methods: {
    open (user) {
      this.user = { ...user }
      this.dialog = true
      this.showGisPassword = false
      this.getUserProjects()
      this.$axios
        .get('/users/get-all-user-roles')
        .then(response => {
          this.roleList = response.data
        })
    },
    getUserProjects () {
      this.$axios
        .get('project/get-user-projects', { params: { id: this.user.id } })
        .then(response => this.userProjects = response.data.sort((first, second) => {
          if (first.project.name > second.project.name) return 1
          if (second.project.name > first.project.name) return -1
          return 0
        }))
    },
    generateToken () {
      this.$refs.generateTokenToRestorePasswordDialog.open()
    },
    save () {
      this.$axios
        .post('users/save', this.user)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('on-change')
          this.getUserProjects()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    deactivateUser () {
      this.changeStatus(false)
    },
    activateUser () {
      this.changeStatus(true)
    },
    changeStatus (active) {
      let status = this.user.active
      this.user.isActive = active
      let url = active ? 'users/activate' : 'users/deactivate'
      this.$axios
        .post(url, null, { params: { id: this.user.id } })
        .then(() => {
          let message = active ? 'userActivated' : 'userDeactivated'
          EventBus.$emit('showInfoMessage', this.$t(message))
          this.$emit('on-change')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          this.user.active = status
        })
    },
    exportActivityLog () {
      this.userActivityLoading = true
      this.$axios
        .post(`admin/activity-log/export/xlsx`, null, {
          responseType: 'arraybuffer',
          params: {
            userId: this.user.id
          },
          timeout: 300000
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          link.download = `${this.getUserFullName(this.user)} activity-log.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
        .finally(() => this.userActivityLoading = false)
    },
    removeUserProject (id) {
      this.$axios
        .post('project/remove-user-project', null, {
          params: { id: id }
        })
        .then(() => {
          this.getUserProjects()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    getTranslatedRoleList (roleList) {
      return roleList.map(el => ({ value: el, text: this.$t(el) }))
    },
    openUserProject (item) {
      if (this.user.role === 'USER') this.$refs.userProjectEditDialog.editUserProject(item)
    }
  },
  computed: {
    isUserEmpty () {
      return _.isEmpty(this.user)
    }
  }
}
</script>
