<template>
  <view-data-page
    :data="data"
    :selectedView="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
    :dialog-mode="dialogMode"
  >
    <template slot="doc">
      <data-table
        v-if="selectedView === 'structure'"
        :table="table"
        class="hidden-xs-only"
        @openItem="directToMap"
      />
      <d-map
        v-if="data && gisCredentials"
        :map="{ layer: data }"
        :poi="poi"
        ref="map"
        v-show="!(selectedView === 'structure')"
      >
        <base-layer/>
      </d-map>
      <edit-layer-dialog @layerSaved="init" ref="editLayerDialog"/>
    </template>

    <template v-slot:actions>
      <v-list-item
        v-if="data && setAbility('ADMIN') && isDataEditable(data)"
        dense @click="openEditLayerDialog"
      >
        <v-list-item-avatar>
          <v-icon>tune</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('layerSettings') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template slot="navigation">
      <div v-show="selectedView === 'structure'">
        <v-list>
          <v-list-item
            dense
            :key="item.id"
            @click="loadZwsTable(item.typeId)"
            v-for="item in tableList"
          >
            <v-list-item-content>
              <v-list-item-title
                @click=""
                v-text="item.name"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-show="selectedView === 'setting'">
        <layer-poi-template-configurations
          :layer="data"
          @update="onUpdate"
          v-if="data.type === 'LAYER_POI'"
        />
        <layer-settings :layer="data"/>
      </div>
    </template>
  </view-data-page>
</template>

<script>
import ViewDataPage from '../utils/ViewDataPage'
import DataTable from '../utils/DataTable'
import ZwsCommandBuilder from '../../services/zws-command-builder'
import LayerSettings from './helpers/LayerSettings'
import { EventBus } from '@/event-bus'
import DMap from '@/components/map/DMap'
import BaseLayer from '@/components/map/BaseLayer'
import { mapGetters } from 'vuex'
import LayerPoiTemplateConfigurations from '@/components/map/LayerPoiTemplateConfigurations'
import EditLayerDialog from '@/components/map/layer/EditLayerDialog.vue'
import messages from '@/componet-locale/layer-setting-item/messages'

export default {
  name: 'LayerView',
  i18n: { messages },
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode:{
      type: Boolean,
      default: false
    },
    dataId: Number | String,
    sysId: {
      type: String,
      default: null
    }
  },
  components: {
    EditLayerDialog,
    LayerPoiTemplateConfigurations,
    BaseLayer,
    DMap,
    LayerSettings,
    DataTable,
    ViewDataPage
  },
  data: () => ({
    selectedView: 'comments',
    layerConfigList: null,
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      },
      {
        icon: 'settings',
        view: 'setting'
      },
      {
        icon: 'table_chart',
        view: 'structure'
      }
    ],
    data: {},
    tableList: [],
    table: {},
    poi: null
  }),
  methods: {
    async init () {
      let id = this.dataId || this.$route.params.id
      let response = await this.$axios
        .get('data/get', {
          params: { id: id }
        })
      let data = response.data
      if (this.sysId) {
        this.poi = { layer: data, sys: this.sysId }
      }
      this.data = data
      if (data.type === 'ZWS') {
        await this.loadTableList()
      }
    },
    onUpdate (layer) {
      this.data = layer
    },
    selectView (view) {
      this.selectedView = view
    },
    async loadTableList () {
      this.tableList = await ZwsCommandBuilder.getLayerTables(this.data)
      if (this.splitScreenMode) this.selectedView = 'structure'
      if (this.tableList[0] && this.tableList[0].typeId) {
        await this.loadZwsTable(this.tableList[0].typeId)
      }
    },
    async loadZwsTable (typeId) {
      this.table = {}
      this.table = await ZwsCommandBuilder.getTable(this.data, typeId)
      this.table.object = this.data
    },
    directToMap (item) {
      EventBus.$emit('goToPoi', {
        sys: item['sys'], layerId: this.data.id
      })
    },
    openEditLayerDialog(){
      this.$refs.editLayerDialog.open(this.data)
    },
    isDataEditable(layer){
      if (!this.$refs.editLayerDialog) return false
      return this.$refs.editLayerDialog.isLayerSupported(layer)
    }
  },
  computed: {
    ...mapGetters([
      'gisCredentials'
    ])
  },
  created () {
    this.init()
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
