<template>
  <Pie
    :chart-data="chartData"
    :chart-options="options"
    :height="height"
    :plugins="[legend]"
  />
</template>


<script>
import { Pie } from 'vue-chartjs/legacy'
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)


export default {
  name: 'BarChart',
  components: { Pie },
  data: () => ({
    legend: {
      options: {
        position: 'bottom'
      }
    }
  }),
  props: ['options', 'chartData', 'height']
}
</script>
