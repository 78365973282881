import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import axios from 'axios'
import defaultTheme from '@/utils/default-theme'

Vue.use(Vuetify)

async function getThemeConfig () {

  const theme = !sessionStorage.getItem('custom-theme')
    ? await axios.get('/api/settings/get-theme-config')
      .then(res => {
        sessionStorage.setItem('custom-theme', JSON.stringify(res.data))
        return res.data
      })
      .catch(() => {
        console.warn('Custom theme is not found')
      })
    : JSON.parse(sessionStorage.getItem('custom-theme'))

  return theme || defaultTheme
}

export default (async () => {
  const theme = await getThemeConfig()
  return new Vuetify({
    buttons: {
      capitalize: false
    },
    iconfont: 'md',
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: theme
      }
    }
  })
})()
