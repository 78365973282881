<template>
  <div>
    <v-list>
      <v-list-item
        v-for="item in listOfData"
        :key="item.id"
        @click="()=>{}"
      >
        <v-list-item-action
          class="mr-4"
          @click="viewFile(item)"
        >
          <v-icon color="blue" size="35">{{ getUrlByDataType(item.abstractData.dataType).icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content @click="viewFile(item)">
          {{ item.abstractData.name }}
          <v-list-item-subtitle>
            {{ getUserFullName(item.abstractData.owner) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="caption">{{ formatDate(item.creationDate) }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="$refs.confirmationRemoveAttachedAdDialog.open(item)">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <confirmation-dialog
      ref="confirmationRemoveAttachedAdDialog"
      title="Вы действительно хотите удалить файл из списка связанных данных"
      @confirm="removeAttachedAd"
    />
    <abstract-data-view-dialog
      ref="adViewDialog"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'

export default {
  name: 'LpAdAttachments',
  components: {
    AbstractDataViewDialog: () => import('@/components/utils/AbstractDataViewDialog.vue'),
    ConfirmationDialog
  },
  props: {
    listOfData: Array
  },
  methods: {
    viewFile (item) {
      this.$refs.adViewDialog.open(item.abstractData)
    },
    removeAttachedAd (item) {
      this.$axios
        .delete('layer-poi/remove-attached-data', {
          params: {
            lpAttachedAd: item.id
          }
        })
        .then(() => {
          this.$emit('reload')
          EventBus.$emit('showInfoMessage', 'Документ удален из списка')
        })
    }
  }
}
</script>
