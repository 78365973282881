<template>
  <v-dialog v-if="dialog" v-model="dialog" width="700">
    <v-card>
      <v-card-title>
        {{ $t('simulationRules') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-auto">
        <div v-if="rules.length === 0">
          {{ $t('noRules') }}
        </div>
        <vuedraggable v-model="rules" handle=".handle">
          <div v-for="(rule, index) in rules" :key="index">

            <v-card class="pa-3 mt-3" outlined>
              <v-card-actions class="pa-0 mb-2">
                <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
                <v-spacer/>
                <v-icon
                  class="pointer"
                  @click="removeRule(index)"
                >
                  delete
                </v-icon>
              </v-card-actions>
              <v-card-text class="pa-3 overflow-auto">
                <v-text-field
                  v-model="rule.name"
                  :label="$t('name')"
                  class="mb-2"
                  dense
                  hide-details
                  outlined
                />
                <v-select
                  v-model="rule.sourceLayer"
                  :items="layerList"
                  :label="$t('simulationLayer')"
                  class="mb-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  outlined
                  return-object
                />
                <v-autocomplete
                  v-if="rule.sourceLayer"
                  v-model="rule.relationPropertyName"
                  :items="getLayerProps(rule.sourceLayer)"
                  :label="$t('relationField')"
                  class="mb-2"
                  dense
                  hide-details
                  item-text="userName"
                  item-value="name"
                  outlined
                />
                <v-select
                  v-model="rule.targetLayer"
                  :items="layerList"
                  :label="$t('relatedLayer')"
                  class="mb-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  outlined
                  return-object
                />
              </v-card-text>
            </v-card>
          </div>
        </vuedraggable>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" outlined @click="addRule">{{ $t('addRule') }}</v-btn>
          <v-btn color="primary" @click="save">{{ $t('button.save') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vuedraggable from 'vuedraggable'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/map-simulation-rules/messages'

export default {
  name: "MapSimulationRulesDialog",
  components: { Vuedraggable },
  i18n: { messages },
  data: () => ({
    dialog: false,
    rules: [],
    layerList: [],
    map: null
  }),
  methods: {
    async open (map) {
      this.map = map
      this.layerList = map.layerConfigList.map(it => it.layer)
      this.rules = map.simulationRules || []
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      this.$axios.post(`map/update-simulation-rules`, this.rules, {
        params: {
          mapId: this.map.id
        }
      })
        .then(() => {
          this.$emit('saved')
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    addRule () {
      this.rules.push({ name: this.$t('name'), sourceLayer: null, targetLayer: null, relationPropertyName: '' })
    },
    removeRule (index) {
      this.rules.splice(index, 1)
    },
    getLayerProps (layer) {
      return [...this.layerList.find(l => l.id === layer.id).props.values()].flat()
    }
  }
}
</script>
