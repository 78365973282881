<template>
  <div v-if="selectedTemplate">
    <v-list v-if="pages.length > 0" class="pa-0">
      <div class="pa-2 my-3">
        <v-text-field
          v-model="selectedTemplate.name"
          :label="$t('templateName')"
          dense
          hide-details
        />
        <v-checkbox
          v-model="selectedTemplate.massPrint"
          :label="$t('massPrint')"
          dense
          hide-details
        />
      </div>
      <vuedraggable v-model=pages handle=".handle" style="width: 100%">
        <v-list-item v-for="page in sortedPages" :key="page.pageNumber" @click="$refs.pageEditor.open(page)">
          <v-icon class="handle pointer">drag_indicator</v-icon>
          <div class="pl-2">
            {{ page.title ? page.title : "Страница " + page.pageNumber }}
          </div>
          <v-spacer/>
          <v-btn icon @click.stop="deletePage(page.pageNumber)">
            <v-icon color="red">
              close
            </v-icon>
          </v-btn>
        </v-list-item>
      </vuedraggable>
    </v-list>
    <div v-else>
      Нет страниц
    </div>
    <div class="d-flex flex-nowrap pt-2">
      <v-spacer/>
      <v-btn outlined @click="addPage">Добавить страницу</v-btn>
    </div>
    <print-template-page-editor-dialog
      ref="pageEditor"
      @pageUpdated="updatePage"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import PrintTemplatePageEditorDialog from '@/components/admin/print-template/PrintTemplatePageEditorDialog.vue'
import Vuedraggable from 'vuedraggable'
import messages from '@/componet-locale/print-template/messages'

export default {
  name: 'PrintTemplatePages',
  components: { Vuedraggable, PrintTemplatePageEditorDialog },
  i18n: { messages },
  props: {
    selectedTemplate: Object
  },
  data () {
    return {
      pages: [...this.selectedTemplate.pages].sort((prev, next) => prev.pageNumber - next.pageNumber)
    }
  },
  methods: {
    addPage () {
      this.pages.push({ pageNumber: this.pages.length + 1 })
    },
    deletePage (pageNumber) {
      this.pages.splice(pageNumber - 1, 1)
    },
    updatePage (page) {
      this.pages[this.pages.indexOf(this.pages.find(el => el.pageNumber === page.pageNumber))] = page
      this.addPage()
      this.pages.pop()
    },
    save () {
      let template = {
        id: this.selectedTemplate.id,
        name: this.selectedTemplate.name,
        massPrint: this.selectedTemplate.massPrint,
        pages: this.sortedPages
      }
      this.$axios.post('print-template/save', template)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('updateTemplate', this.selectedTemplate.id)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  },
  computed: {
    sortedPages () {
      const sortedPages = [...this.pages]
      sortedPages.forEach((el, i) => el.pageNumber = i + 1)
      return sortedPages
        .sort((prev, next) => prev.pageNumber - next.pageNumber)

    }
  },
}
</script>
