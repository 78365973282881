<template>
  <v-expansion-panel
    class="mx-2 mb-4"
    multiple
  >
    <v-expansion-panel-header
      style="padding-left: 13px; height: 56px"
      color="grey lighten-4"
    >

      <span>
      <v-icon color="primary">folder</v-icon>
        <span class="ml-3 bold">
        {{ folder.title }}
          </span>
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      color="grey lighten-4"
    >

      <vuedraggable
        handle=".handle"
        v-model="folder.items"
        @end="saveAll"
      >
        <v-list-item
          dense
          v-for="item in folder.items"
          @click="selectCategory(item.category)"
        >
          <v-list-item-avatar>
            <v-icon>folder</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.category.name }}
              <v-chip
                v-if="item.size"
                small
                class="ml-2"
              >
                {{ item.size }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="setAbility('ADMIN')">
            <v-icon
              small
              class="handle pointer mr-4"
              v-html="'drag_indicator'"
            />
          </v-list-item-action>
        </v-list-item>
      </vuedraggable>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vuedraggable from 'vuedraggable'

export default {
  name: 'FolderForCategories',
  props: { folder: Object },
  components: {
    Vuedraggable
  },
  methods: {
    saveAll () {
      let list = this.folder.items.map(it => it.category)
      list.forEach((it, index) => it.number = index)
      this.$axios.post('category/save-all', list)
    },
    selectCategory (category) {
      this.$emit('select', category)
    }
  }
}
</script>
