<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ name || type }}
    </template>
    <template v-slot:info>
      <zws-action-toolbar
        :actions="actions"
        :feature="feature"
        :name="name || type"
        @close="$emit('close')"
      />
    </template>

    <template v-slot:fields>
      <zws-fields :feature="feature" :read-only-mode="isAuthenticated"/>
    </template>

    <template v-slot:actions>
      <v-btn
        outlined
        @click="$refs.zwsFeatureDialog.open(feature)"
      >
        <v-icon left>description</v-icon>
        Детали
      </v-btn>
      <v-spacer/>
      <v-btn
        v-if="setAbility('MODERATOR')"
        color="primary"
        outlined
        @click="save"
      >
        {{ $t('button.save') }}
      </v-btn>

      <zws-feature-dialog ref="zwsFeatureDialog"/>
    </template>
  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import ZwsFields from '@/components/map/zws/ZwsFields'
import ZwsActionToolbar from '@/components/map/zws/ZwsActionToolbar'
import { ATTACHED_DATA, EDIT, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'

export default {
  name: 'ZwsFeatureCard',
  components: {
    ZwsActionToolbar,
    ZwsFields,
    ZwsFeatureDialog: () => import('@/components/map/zws/ZwsFeatureDialog'),
    FeatureCard
  },
  props: { feature: Object },
  methods: {
    save () {
      ZwsCommandBuilder.updateElemAttributes(this.feature)
    },
    close () {
      this.$emit('close')
    }
  },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      } else return null
    },
    type () {
      if (!this.feature.layer || !this.feature.layer.typeList) return ' - '
      let type = this.feature.layer.typeList
        .find(type => type.typeId === this.feature.typeId)
      return type ? type.name : 'Object'
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    }
  }
}
</script>
