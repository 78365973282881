<template>
  <v-dialog
    v-model="dialog"
    persistent width="600"
  >
    <v-card v-if="featureList">
      <v-card-title>
        {{ $t('objectsSelected') }}: {{ featureList.length }}
        <v-spacer/>
        <v-btn
          icon
          @click="dialog=false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="featureList.length">
        <v-select
          v-model="selectedField"
          :items="fields"
          :label="$t('chooseField')"
          item-text="name"
          outlined
          return-object
        />
        <v-text-field
          v-if="selectedField"
          v-model="newValue"
          :label="$t('newValue')"
          outlined
        />
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular color="primary" indeterminate/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="updatingLps" color="primary" outlined @click="edit">
          {{ $t('apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/mass-actions/messages'

export default {
  name: 'MassEditDialog',
  i18n: { messages },
  data () {
    return {
      dialog: false,
      featureList: [],
      selectedAction: 'openPrintTemplateSelectorDialog',
      fields: [],
      selectedField: null,
      newValue: null,
      updatingLps: false
    }
  },
  methods: {
    open (features) {
      this.$axios
        .post('layer-poi/search-by-ids', features.map(f => f.id))
        .then(res => res.data)
        .then(featuresData => {
          this.featureList = featuresData.lps
          if (featuresData.templates.length > 1) {
            return EventBus.$emit('showInfoMessage', this.$t('objectsOfDifferentTemplates'))
          }
          this.fields = featuresData.templates[0].fields
        })
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.featureList = []
      this.fields = []
      this.selectedField = null
      this.newValue = null
    },
    edit () {
      let ids = this.featureList.map(it => it.id)
      this.updatingLps = true
      this.$axios
        .post('layer-poi/mass-edit', ids, {
          params: {
            fieldId: this.selectedField.id,
            newValue: this.newValue
          }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('update')
          this.updatingLps = false
          this.close()
        })
    }
  }
}
</script>
