<template>
  <i18n path="renamedChat">
    <template v-slot:oldName>
      <span class="font-weight-bold blue-grey--text">{{ oldName }}</span>
    </template>
    <template v-slot:newName>
      <span class="font-weight-bold blue-grey--text">{{ newName }}</span>
    </template>
  </i18n>
</template>

<script>
import messages from '@/componet-locale/system-message-template/messages'

export default {
  name: 'RenamingEventMessage',
  i18n: { messages: messages },
  props: ['message'],
  data: () => ({
    oldName: null,
    newName: null
  }),
  created () {
    const [oldName, newName] = JSON.parse(this.message.decryptedBody)
    this.oldName = oldName
    this.newName = newName
  }
}
</script>
