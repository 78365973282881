<template>
  <div>
    <div
      v-if="!state.editMode && showAdditionalControls"
      class="d-map-controls d-map-controls-right d-inline-block"
      style="padding-top: 60px"
    >
      <div class="d-map-controls-block d-inline-block">
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              v-if="setAbility('MODERATOR')"
              class="d-map-controls-btn"
              @click="$emit('create')"
              v-on="on"
            >
              <v-icon :size="20">
                add_circle
              </v-icon>
            </div>
          </template>
          <span>{{ $t('addPoi') }}</span>
        </v-tooltip>
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              class="d-map-controls-btn d-inline-block"
              @click="switchMeasurer('LineString')"
              v-on="on"
            >
              <v-icon
                :color="isLinearMeasurer ? 'blue' : '' "
                :size="20"
              >
                straighten
              </v-icon>
            </div>
          </template>
          <span>{{ $t('ruler') }}</span>
        </v-tooltip>
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              class="d-map-controls-btn d-inline-block"
              @click="switchMeasurer('Polygon')"
              v-on="on"
            >
              <v-icon
                :color="isPolygonMeasurer ? 'blue' : '' "
                :size="20">
                format_shapes
              </v-icon>
            </div>
          </template>
          <span>{{ $t('selectArea') }}</span>
        </v-tooltip>
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              class="d-map-controls-btn d-inline-block"
              @click="$emit('export')"
              v-on="on"
            >
              <v-icon :size="20">
                print
              </v-icon>
            </div>
          </template>
          <span>{{ $t('print') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="displayXS && !widget"
      class="d-map-controls d-map-controls-right pt-10"
    >
      <div class="d-map-controls-block">
        <div
          class="d-map-controls-btn"
          @click="$emit('openLayersDialog')"
        >
          <v-icon
            :size="20">
            mdi-layers
          </v-icon>
        </div>
      </div>
      <div class="d-map-controls-block">
        <div
          v-if="!displayXS || isAuthenticated"
          class="d-map-controls-btn"
          @click="$emit('openSearchLpDialog')"
        >
          <v-icon :size="20">
            search
          </v-icon>
        </div>
      </div>
    </div>
    <div :style="{bottom: widget ? '10px':''}"
         class="d-map-controls d-map-controls-bottom d-map-controls-right">
      <v-fade-transition>
        <div v-show="showNorthButton" class="d-map-controls-block">
          <div
            class="d-map-controls-btn"
            @click="restoreRotation"
          >
            <v-icon :size="20">
              navigation
            </v-icon>
          </div>
        </div>
      </v-fade-transition>

      <div
        class="d-map-controls-block"
      >
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              class="d-map-controls-btn"
              @click="value.zoom++"
              v-on="!displayXS && on"
            >
              <v-icon
                :size="20"
              >
                add
              </v-icon>
            </div>
          </template>
          <span>{{ $t('zoomIn') }}</span>
        </v-tooltip>
        <v-divider/>
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              class="d-map-controls-btn"
              @click="value.zoom--"
              v-on="!displayXS && on"
            >
              <v-icon
                :size="20"
              >
                remove
              </v-icon>
            </div>
          </template>
          <span>{{ $t('zoomOut') }}</span>
        </v-tooltip>
      </div>

      <div :style="{margin: displayXS ? '80px 0 -80px 0 ' : '20px 0 0 0'}" class="d-map-controls-block">
        <v-tooltip left open-delay="150">
          <template v-slot:activator="{on}">
            <div
              v-if="!widget"
              class="d-map-controls-btn"
              @click="findUserLocation"
              v-on="!displayXS && on"
            >
              <v-progress-circular
                v-if="locationLoading"
                :size="20"
                color="grey"
                indeterminate
              />
              <v-icon v-else :size="20">
                my_location
              </v-icon>
            </div>
          </template>
          <span>{{ $t('location') }}</span>
        </v-tooltip>
      </div>
    </div>
    <user-geoloc
      v-if="showUserLocation"
      :state="state"
      @onFindPosition="onFindPosition"
    />
  </div>
</template>

<script>
import UserGeoloc from '@/components/map/UserGeoloc'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '@/componet-locale/d-map-conrols/messages'

export default {
  name: 'DMapControls',
  i18n: { messages },
  components: { UserGeoloc },
  mixins: [displayServiceMixin],
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Object,
    state: Object,
    widget: Boolean,
    showNorthButton: Boolean
  },
  data: () => ({
    showUserLocation: false,
    isCanvas: true
  }),
  methods: {
    findUserLocation () {
      if (this.state.userLocation) {
        this.value.center = this.state.userLocation
      } else {
        this.showUserLocation = true
      }
    },
    switchMeasurer (type) {
      if (!this.state.measureType || this.state.measureType !== type) {
        this.state.measureType = type
      } else {
        this.state.measureType = null
      }
    },
    onFindPosition (coordinate) {
      this.value.center = coordinate
    },
    restoreRotation () {
      this.$emit('restore-rotation')
    }
  },
  computed: {
    isPolygonMeasurer () {
      return this.state.measureType === 'Polygon'
    },
    isLinearMeasurer () {
      return this.state.measureType === 'LineString'
    },
    locationLoading () {
      return !this.state.userLocation && this.showUserLocation
    },
    showAdditionalControls () {
      return !(this.widget || this.displayXS)
    }
  }
}
</script>

<style>
.d-map-controls {
  position: absolute;
  z-index: 200;
}

.d-map-controls-bottom {
  bottom: 224px;
}

.d-map-controls-right {
  right: 20px;
}

.d-map-controls-block {
  align-content: center;
  width: 36px;
  margin-top: 15px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  background: white;
  border-radius: 8px;
}

.d-map-controls-btn {
  cursor: pointer;
  padding: 8px;
}

.d-map-controls-btn .v-icon:hover {
  color: black;
}

.d-map-controls-si {
  position: absolute;
  z-index: 200;
  bottom: 0;
  padding: 3px 5px;
  background: white;
}

</style>
