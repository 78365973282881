<template>
  <abstract-data-list ref="abstractDataList" type="REPORT" view="report-list">
    <template slot="actions">
      <v-btn
        v-if="setAbility('MODERATOR')"
        color="primary"
        outlined
        @click="createNewItem"
      >
        {{ $t('button.create') }}
      </v-btn>
    </template>
    <report-template-upload-dialog
      ref="reportTemplateUploadDialog"
      @onSave="onSave"
    />
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'
import ReportTemplateUploadDialog from '@/components/report/ReportTemplateUploadDialog.vue'

export default {
  name: 'ReportList',
  components: { ReportTemplateUploadDialog, AbstractDataList },
  methods: {
    createNewItem () {
      this.$refs.reportTemplateUploadDialog.open()
    },
    onSave () {
      this.$refs.abstractDataList.filterData()
    }
  }
}
</script>
