<template>
  <view-data-page
    :data="data"
    :selectedView="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
    :show-loader="isFileDownloading"
    :dialog-mode="dialogMode"
  >
    <template slot="actions">
      <v-list-item @click="download" dense>
        <v-list-item-avatar>
          <v-icon>cloud_download</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('download') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template slot="doc">
      <div style="position: relative; width: 100%" class="doc-frame" v-if="loading">
        <v-row justify="center">
          <v-overlay absolute color="white">
            <v-progress-circular indeterminate :size="70" :width="7" color="purple"/>
          </v-overlay>
        </v-row>
      </div>
      <data-table
        v-show="!loading"
        ref="dataTable"
        @save="save"
        @openItem="openItem"
        :table="table"
        class="hidden-xs-only"
      />
      <data-iterator v-show="!loading" :table="table" class="hidden-sm-and-up"/>
      <poi-selection-dialog ref="poiSelectionDialog"/>
    </template>
  </view-data-page>
</template>

<script>
import DataTable from '../utils/DataTable'
import DataIterator from '../utils/DataIterator'
import ViewDataPage from '../utils/ViewDataPage'
import { EventBus } from '@/event-bus'
import downloadFile from '../../services/file-downloader'
import messages from '../../componet-locale/table-view/messages'
import PoiSelectionDialog from '@/components/table/PoiSelectionDialog'

export default {
  name: 'TableView',
  components: { PoiSelectionDialog, ViewDataPage, DataIterator, DataTable },
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode:{
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  i18n: { messages: messages },
  data: () => ({
    tableList: [],
    layer: {},
    loading: true,
    selectedView: 'comments',
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    data: {},
    active: '',
    isFileDownloading: false
  }),
  created () {
    this.init()
  },
  methods: {
    openItem (item) {
      let listOfPoi = JSON.parse(item.url)
      if (listOfPoi.length === 1) {
        EventBus.$emit('goToPoi', listOfPoi[0])
      } else {
        this.$refs.poiSelectionDialog.open(listOfPoi)
      }
    },
    selectView (view) {
      this.selectedView = view
    },
    init () {
      this.loading = true
      let id = this.dataId || this.$route.params.id
      this.$axios
        .get('/data/get/', { params: { id: id }, timeout: 240000 })
        .then(response => {
          this.data = response.data
          this.table = JSON.parse(this.data.data)
          this.table.object = this.data
          this.loading = false
        })
        .catch(reason => {
          console.error(reason)
        })
    },
    save () {
      let table = this.data
      table.data = JSON.stringify({
        headers: this.table.headers,
        items: this.table.items
      })
      this.$axios
        .post('table/update', table)
        .then(() => {
          this.$refs.dataTable.refresh()
          EventBus.$emit('showSuccessMessage', 'Изменения внесены')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Произошла ошибка при изменении данных таблицы')
        })
    },
    download () {
      const extension = this.data.filePath.split('.').pop()
      const fileName = `${this.data.name}.${extension}`
      this.isFileDownloading = true
      downloadFile(`/files/get-data-table/${this.data.filePath}`, fileName)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
        })
        .finally(() => this.isFileDownloading = false)
    }
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
