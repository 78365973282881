<template>
  <v-dialog v-if="dialog" v-model="dialog" width="800px">
    <v-card class="d-flex flex-column" max-height="90vh">
      <v-card-text class="overflow-y-auto">
        <v-select
          v-model="selectedProjection"
          :items="projections"
          :label="$t('projection')"
          dense
          outlined
        />
        <div>
          <p class="subtitle-1 font-weight-bold">{{ this.$t(`geometryType.${geometry.type}`) }}</p>
          <p>{{ transformedCoordinates }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="copyGeometryInBuffer">
          <v-icon class="mr-2" small>{{ copiedSuccess ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
          {{ $t('button.copy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proj4 from 'proj4'
import _ from 'lodash'
import messages from '@/componet-locale/geometry-dialog/messages'

const projections = ['WGS84', 'MSK-23-1', 'GOOGLE', 'EPSG:3844']

export default {
  name: "GeometryDialog",
  i18n: { messages },
  data: () => ({
    dialog: false,
    geometry: Object,
    initialProjection: null,
    transformedCoordinates: null,
    copiedSuccess: false,
    selectedProjection: projections[0],
    projections
  }),
  mounted () {
    proj4.defs([
      ['MSK-23-1',
        '+proj=tmerc +lat_0=0 +lon_0=37.98333333333 +k=1 +x_0=1300000 +y_0=-4511057.628 +ellps=krass +towgs84=23.92,-141.27,-80.9,-0,0.35,0.82,-0.12 +units=m +no_defs'],
      [
        'EPSG:3844',
        '+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.17326243724756094 +units=m +no_defs +type=crs',
      ]
    ])
  },
  methods: {
    open (geometry, initialProjection = 'EPSG:4326') {
      this.geometry = geometry
      this.initialProjection = initialProjection
      this.transformedCoordinates = this.transformCoordinates(geometry.coordinates, this.initialProjection, this.selectedProjection)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.geometry = null
    },
    copyGeometryInBuffer () {
      navigator.clipboard.writeText(JSON.stringify(this.transformedCoordinates))
        .then(() => this.copiedSuccess = true)
        .then(() => new Promise(res => setTimeout(res, 1300)))
        .then(() => this.copiedSuccess = false)
        .catch(() => console.error('Copy error'))
    },
    transformCoordinates (coordinates, sourceProj, targetProj) {
      const coords = _.cloneDeep(coordinates)
      if (coords.every(el => typeof (el) === 'number')) {
        return proj4(
          sourceProj,
          targetProj,
          coords
        ).reverse()
      } else {
        coords.forEach((el, i) => coords[i] = this.transformCoordinates(el, sourceProj, targetProj))
        return coords
      }
    }
  },
  watch: {
    selectedProjection (proj) {
      this.transformedCoordinates = this.transformCoordinates(this.geometry.coordinates, this.initialProjection, proj)
    }
  }
}
</script>
