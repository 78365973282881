<template>
  <v-card outlined class="my-2">
    <v-card-text>
      <v-text-field
        label="Название поля"
        v-model="value.name"
      />
      <v-select
        label="Тип данных"
        v-model="value.type"
        :items="dataTypeList"
      />
      <v-textarea
        label="Опции"
        rows="1"
        v-if="value.type === 'LIST'"
        v-model="value.options"
      />
      <v-switch
        label="Обязательное поле"
        v-model="value.required"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'IssueTemplateField',
  props: { value: Object },
  data: () => ({
    dataTypeList: [
      'STRING',
      'BOOLEAN',
      'DATE',
      'LIST',
      'NUMBER',
      'USER'
    ]
  })
}
</script>
