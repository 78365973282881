export const CONDITIONS = {
  EQUAL: 'EQUAL',
  LIKE: 'LIKE',
  LESS: 'LESS',
  MORE: 'MORE'
}

const STRING_CONDITIONS = [CONDITIONS.EQUAL, CONDITIONS.LIKE]
const NUMBER_CONDITIONS = [CONDITIONS.EQUAL, CONDITIONS.LESS, CONDITIONS.MORE]
const BOOLEAN_CONDITIONS = [CONDITIONS.EQUAL]
const DATE_CONDITIONS = [CONDITIONS.EQUAL, CONDITIONS.LESS, CONDITIONS.MORE]
const LIST_CONDITIONS = [CONDITIONS.EQUAL, CONDITIONS.LIKE]

export const DEFAULT_COLORS = {
  BACKGROUND_COLOR: '#FFFFFF00',
  TEXT_COLOR: '#000000FF'
}

function getConditionsForType (type) {
  switch (type) {
    case 'STRING':
      return STRING_CONDITIONS
    case 'NUMBER':
      return NUMBER_CONDITIONS
    case 'BOOLEAN':
      return BOOLEAN_CONDITIONS
    case 'DATE':
      return DATE_CONDITIONS
    case 'LIST':
      return LIST_CONDITIONS
  }
}

export function getConditions (types) {

  const arrayOfConditionLists = types
    .map(type => getConditionsForType(type))

  const conditions = []

  arrayOfConditionLists.forEach(conditionsList => {
    conditionsList.forEach(condition => {
      if (arrayOfConditionLists.every(el => el.includes(condition))) {
        conditions.push(condition)
      }
    })
  })

  return conditions

}

export function getStyleForRow (rules, headers, item) {
  let style = {}
  const filteredRules = rules.filter(rule => rule.applyForRow)
  headers.forEach(header => {
    style = Object.assign(getStyleFromHeader(header, filteredRules, item[header.alias]), style)
  })
  return style
}

export function getStyleFromHeader (header, rules, value) {
  const filteredRules = rules.filter(r => r.headers.some(h => h.key === header.key))
  if (value === null) {
    return {}
  }
  var style = {}
  filteredRules.forEach(rule => {
    switch (rule.predicate) {
      case CONDITIONS.EQUAL:
        if (checkEqual(rule, header.fieldType, value)) {
          style = Object.assign(style, getStyleFromRule(rule))
        }
        return
      case CONDITIONS.LIKE:
        if (checkLike(rule, value)) {
          style = Object.assign(style, getStyleFromRule(rule))
        }
        return
      case CONDITIONS.LESS:
        if (checkLess(rule, header.fieldType, value)) {
          style = Object.assign(style, getStyleFromRule(rule))
        }
        return
      case CONDITIONS.MORE:
        if (checkMore(rule, header.fieldType, value)) {
          style = Object.assign(style, getStyleFromRule(rule))
        }
        return
    }
  })
  return style
}

function checkEqual (rule, type, value) {
  switch (type) {
    case 'STRING':
      return rule.value === value
    case 'NUMBER':
      return +rule.value === +value
    case 'BOOLEAN':
      return (rule.value === 'true') === value
    case 'DATE':
      return new Date(rule.value) === new Date(value)
    case 'LIST':
      return rule.value === value
  }
}

function checkLike (rule, value) {
  return value.includes(rule.value)
}

function checkLess (rule, type, value) {
  switch (type) {
    case 'NUMBER':
      return +value < +rule.value
    case 'DATE':
      return new Date(value) < new Date(rule.value)
  }
}

function checkMore (rule, type, value) {
  switch (type) {
    case 'NUMBER':
      return +value > +rule.value
    case 'DATE':
      return new Date(value) > new Date(rule.value)
  }
}

function getStyleFromRule (rule) {
  const style = {}
  if (rule.backgroundMode) {
    style.backgroundColor = rule.backgroundColor
  }
  if (rule.textColorMode) {
    style.color = rule.textColor
  }
  return style
}
