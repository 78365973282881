<template>
  <v-menu
      offset-y
      top
      open-on-hover
      open-delay="500"
      v-model="menu"
  >
    <template v-slot:activator="{ on }">
      <a v-on="on">{{ label }}</a>
    </template>
    <v-card>
      <v-card-text class="pa-2">
        <v-row no-gutters>
          <v-col cols="auto">
            <v-avatar
                size="100"
                tile
                :style="avatarBackground"
            >
              <span
                  style="font-size: 300%"
                  class="white--text"
              >{{ avatarLabel }}
              </span>
            </v-avatar>
          </v-col>
          <v-col
              class="mr-1 ml-4 flex-column d-flex align-start justify-space-between"
              cols="auto"
          >
            <span class="subtitle-1">{{ label }}</span>
            <v-btn
                v-if="currentLoginUser.id !== id"
                outlined
                :ripple="false"
                small
                color="primary"
                @click="openConversation"
            >
              {{ $t('write') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import messages from '@/componet-locale/user-link/messages'

export default {
  props: {
    id: { type: Number },
    label: { type: String }
  },
  i18n: { messages: messages },
  name: 'UserLink',
  data: () => ({
    menu: false
  }),
  methods: {
    openConversation () {
      this.$axios
        .post('chats/direct', null, {
          params: { userId: this.id }
        })
        .then(({ data }) => this.$store.dispatch('setActiveChatId', data))
    }
  },
  computed: {
    avatarBackground () {
      let hash = 0
      for (let i = 0; i < this.label.length; i++) {
        hash = this.label.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return { 'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))` }
    },
    avatarLabel () {
      return this.label
        .split(' ')
        .map(i => i.substring(0, 1))
        .join('')
    },
    currentLoginUser () {
      return this.$store.getters.currentLoginUser
    }
  }
}
</script>

<style scoped>
a {
  color: #5579a7 !important;
  text-decoration: none;
}

a:hover {
  color: #167dff !important;
}
</style>
