<template>
  <v-dialog v-model="dialog" width="800px">

    <v-stepper v-model="e1">

      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">{{ $t('configuration') }}</v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="e1 > 2" step="2">{{ $t('info') }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-switch
            :label="$t('line')"
            v-model="chart.showDetails"
          />
          <v-combobox
            :items="table.headers"
            item-text="name"
            :label="$t('columnSelecting')"
            return-object
            v-if="chart.showDetails"
            v-model="chart.mainColumn"
          />
          <v-text-field
            :label="$t('parameter')"
            v-if="!chart.showDetails"
            v-model="chart.name"
          />
          <v-select
            label="Тип графика"
            v-model="chart.type"
            :items="chartTypeList"
          />
          <h3>{{ $t('tableFields') }}</h3>

          <v-card-text>
            <v-layout row wrap>
              <v-flex
                v-for="header in table.headers"
                md4 sm6 xs12
              >
                <v-checkbox :label="header.name"
                            hide-details
                            v-model="header.isValue"/>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider/>

          <h3>График</h3>
          <chart :chart="chart" :table="table"/>
          <v-card-actions>
            <v-btn @click="dialog = false" outlined>{{ $t('button.cancel') }}</v-btn>
            <v-spacer/>
            <v-btn @click="e1 = 2" color="primary" outlined>{{ $t('button.next') }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="2">
          <abstract-data-edit-form :item="data" full-form/>
          <v-card-actions>
            <v-btn @click="e1 = 1" outlined text>{{ $t('button.back') }}</v-btn>
            <v-spacer/>
            <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import Chart from './Chart'
import AbstractDataEditForm from '../utils/AbstractDataEditForm'
import messages from '../../componet-locale/chart-edit-dialog/messages'

export default {
  name: 'ChartEditDialog',
  components: { AbstractDataEditForm, Chart },
  props: {
    table: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    }
  },
  i18n: { messages: messages },
  data: () => ({
    e1: 1,
    dialog: false,
    data: {},
    chartTypeList: ['LINE', 'BAR', 'PIE', 'RADAR', 'DOUGHNUT'],
    chart: {
      showDetails: false,
      name: '',
      mainColumn: undefined,
      headers: []
    }
  }),
  methods: {
    open () {
      this.dialog = true
    },
    save () {
      let project = this.selectedProject
      this.data.table = this.table.object
      this.data.value = JSON.stringify(this.chart)
      this.data.project = project
      this.$axios
        .post('/chart/save', this.data)
        .then(() => {
          this.dialog = false
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  watch: {
    table (_) {
      this.chart.mainColumn = this.table.headers[0]
    }
  }
}
</script>
