<template>
  <div>
    <div class="pa-3" v-if="layer.themes && layer.themes.length">
      <h4>Темы</h4>
      <v-checkbox :label="theme.name"
                  @change="$emit('refresh-map')"
                  class="pt-2 ma-0"
                  hide-details
                  v-for="theme in layer.themes"
                  v-model="theme.isActive"/>
    </div>
    <div class="pa-3" v-if="layer.labels && layer.labels.length">
      <h4>Надписи</h4>
      <v-checkbox :label="label.name"
                  @change="$emit('refresh-map')"
                  class="pt-1 ma-0"
                  hide-details
                  v-for="label in layer.labels"
                  v-model="label.isActive"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayerSettings',
  props: ['layer']
}
</script>
