<template>
  <v-carousel-item>
    <v-sheet v-if="!image" color="grey lighten-4">
      <v-skeleton-loader :loading="true" boilerplate class="full-width full-height"/>
    </v-sheet>
    <v-img v-else :src="image" class="responsive-image" @click="openFileViewer"/>

    <file-viewer ref="file-viewer" :storage-url="getUrl"/>
  </v-carousel-item>
</template>

<script>
import FileViewer from '@/components/drive/FileViewer.vue'

export default {
  name: "LpImageCarouselItem",
  components: { FileViewer },
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    fileEntity: Object
  },
  data: () => ({
    image: null
  }),
  mounted () {
    this.getImage()
  },
  methods: {
    async getImage () {
      let { data } = await this.$axios.get(this.imageUrl, { responseType: 'arraybuffer' })
      let blob = new Blob([data], { type: 'image/jpeg' })
      let urlCreator = window.URL || window.webkitURL
      this.image = urlCreator.createObjectURL(blob)
    },
    openFileViewer () {
      this.$refs['file-viewer'].open(this.fileEntity)
    },
    getUrl () {
      return this.imageUrl
    }
  }
}
</script>

<style>
.responsive-image {
  object-fit: cover;
  overflow-clip-margin: content-box;
  overflow: clip;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
