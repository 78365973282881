<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
      <v-card class="overflow-x-hidden">
        <v-card-text class="pt-2 px-2 pb-0">
          <span style="font-size: 1.25em" v-html="html"/>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EtHeaderEditor from '@/components/et/EtHeaderEditor.vue'
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm.vue'

export default {
  name: "SelectQueryPreview",
  components: { AbstractDataEditForm, EtHeaderEditor },
  data () {
    return {
      dialog: false,
      html: ''
    }
  },
  methods: {
    open (selectForm) {
      this.dialog = true
      this.createHtml(selectForm)
    },
    createHtml (selectForm) {
      if (selectForm.errorMessage && selectForm.errorMessage.length > 0) {
        this.html = `<p style="color: red">${selectForm.errorMessage}</p>`
      }
      this.html = [
        selectForm.select,
        selectForm.from,
        selectForm.where,
        selectForm.groupBy,
        selectForm.having,
        selectForm.sorting
      ]
        .map(it => `<p class="mb-2">${it}</p>`)
        .join(' ')
    },
    close () {
      this.dialog = false
      this.item = null
      this.html = ""
    }
  }
}
</script>
