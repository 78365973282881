<template>
  <v-dialog v-model="dialog" fullscreen style="z-index: 10001">
    <v-card color="rgba(0,0,0,0.85)" dark tile>
      <v-card-title>
        <v-btn class="mr-2" icon @click="close">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span>
      {{ $vuetify.breakpoint.xs ? shortenFileName(15) : shortenFileName(45) }}
      </span>
        <v-spacer/>
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-btn v-if="isLoggedInUserNotFileOwner"
                   icon
                   @click="addToMyFiles"
                   v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('addToPrivateFiles') }}</span>
        </v-tooltip>
        <v-btn :loading="isFileDownloading" icon @click="download">
          <v-icon>save_alt</v-icon>
        </v-btn>

      </v-card-title>

      <v-card-text class="py-0">
        <div :class="$vuetify.breakpoint.smAndUp ? 'v-overlay': ''">

          <v-img v-if="unsupportedFormat"
                 :src="`/image/${file.type}.png`"
                 class="mx-auto"
                 max-width="100"/>

          <v-img v-if="image" :max-height="imgHeight"
                 :max-width="imgWidth"
                 :src="image"
                 class="mx-auto"
                 contain/>

        </div>

        <v-card v-if="pdf" class="mx-auto my-0" color="rgba(0,0,0,0)"
                light
                max-width="1000"
                tile>
          <pdf-viewer :url="pdf"/>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import downloadFile from '@/services/file-downloader'
import PdfViewer from '@/components/utils/pdf/PdfViewer'
import messages from '@/componet-locale/file-viewer/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'FileViewer',
  props: { storageUrl: Function },
  components: { PdfViewer },
  i18n: { messages: messages },
  data: () => ({
    dialog: false,
    fileEntity: null,
    pdf: null,
    image: null,
    unsupportedFormat: false,
    isFileDownloading: false,
    file: {}
  }),
  computed: {
    isLoggedInUserNotFileOwner () {
      if (this.file.owner && this.isAuthenticated) return this.file.owner.id !== this.currentLoginUser.id
    },
    url () {
      return this.storageUrl(this.file)
    },
    imgHeight () {
      return screen.height * 0.7
    },
    imgWidth () {
      return screen.width * 0.7
    }
  },
  methods: {
    open (fileEntity) {
      this.file = fileEntity
      this.loadData()
      this.dialog = true
    },
    close () {
      this.file = {}
      this.image = null
      this.pdf = null
      this.unsupportedFormat = false
      this.$emit('close')
      this.dialog = false
    },
    shortenFileName (count) {
      if (!this.file.name) return
      const name = this.file.name
      return name.substr(0, name.lastIndexOf('.')).length > count
        ? `${name.substr(0, count)}...${this.file.type}` : name
    },
    addToMyFiles () {
      this.$axios
        .post(`/files/add-file-entity/${this.file.token}`)
        .then(() => EventBus.$emit('showInfoMessage', this.$t('fileAdded')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileAddError')))
        .finally(() => this.toDrive())
    },
    download () {
      this.isFileDownloading = true
      downloadFile(this.url, this.file.name)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
        })
        .finally(() => this.isFileDownloading = false)
    },
    async loadData () {
      this.pdf = null
      this.image = null
      this.unsupportedFormat = false

      let { data } = await this.$axios.get(this.url, { responseType: 'arraybuffer' })
      let blob = new Blob([data], { type: 'image/jpeg' })
      let urlCreator = window.URL || window.webkitURL
      let object = urlCreator.createObjectURL(blob)

      switch (this.file.type.toLowerCase()) {
        case 'pdf': {
          this.pdf = object
          break
        }
        case 'png':
        case 'jpg':
        case 'gif':
        case 'jpeg': {
          this.image = object
          break
        }
        default:
          this.unsupportedFormat = true
      }
    }
  }
}

</script>
