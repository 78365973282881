<template>
  <div>
    <component
      :is="chartComponent"
      :chartData="datacollection"
      :height="400"
      :options="options"
    />
    <div
      class="pa-2"
    >
      <v-text-field
        v-if="datacollection && datacollection.datasets && datacollection.datasets.length > 15"
        v-model="searchLabelValue"
        :label="$t('filter')"
        class="my-2"
        dense
        hide-details
        outlined
      />
      <div style="max-height: 180px; overflow-y: scroll">
        <v-chip
          v-for="item in labels"
          class="mr-2 mb-1"
          label
          outlined
        >
          <span
            :style="{backgroundColor: item.backgroundColor, border: `2px solid ${item.borderColor}`}"
            class="mr-2"
            style="width: 20px; height: 12px"
          />
          <span v-html="highlight(item.label)"/>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import messages from '@/componet-locale/charts/messages'
import LineChart from '@/components/utils/chart/LineChart.vue'
import BarChart from '@/components/utils/chart/BarChart.vue'
import PieChart from '@/components/utils/chart/PieChart.vue'
import BubbleChart from '@/components/utils/chart/BubbleChart.vue'
import RadarChart from '@/components/utils/chart/RadarChart.vue'
import ScatterChart from '@/components/utils/chart/ScatterChart.vue'
import DoughnutChart from '@/components/utils/chart/DoughnutChart.vue'

export default {
  name: 'Chart',
  components: { DoughnutChart, ScatterChart, RadarChart, BubbleChart, PieChart, BarChart, LineChart },
  i18n: { messages },
  props: {
    table: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    },
    chart: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      }
    },
    searchLabelValue: ''
  }),
  computed: {
    datacollection () {
      if (this.table.headers !== undefined && this.chart.headers !== undefined) {
        if (this.chart.headers.length === 0) {
          this.chart.headers = this.table.headers
        }
        let labels = this.chart.headers
          .filter(header => header.isValue)
          .map(header => header.name)
        let datasets = []
        if (this.chart.showDetails) {
          this.table.items.forEach(item => {
            let data = []
            this.chart.headers
              .filter(header => header.isValue)
              .forEach(header => {
                data.push(item[header.field])
              })
            var label = 'empty'
            if (this.chart.mainColumn !== undefined) {
              label = item[this.chart.mainColumn.field]
            }
            let color = this.getRandomColor()
            datasets.push({
              label: label,
              borderColor: color,
              backgroundColor: color,
              data: data,
              fill: false
            })
          })
        } else {
          let data = []
          this.chart.headers
            .filter(header => header.isValue)
            .forEach(header => {
              data.push(this.getTotal(header))
            })
          let color = this.getRandomColor()
          datasets.push({
            label: this.chart.name,
            borderColor: color,
            backgroundColor: color,
            data: data
          })
        }
        return {
          labels: labels,
          datasets: datasets
        }
      } else {
        return {}
      }
    },
    chartComponent () {
      switch (this.chart.type) {
        case 'LINE':
          return LineChart
        case 'BAR':
          return BarChart
        case 'PIE':
          return PieChart
        case 'RADAR':
          return RadarChart
        case 'DOUGHNUT':
          return DoughnutChart
      }
    },
    labels () {
      if (this.datacollection && this.datacollection.datasets && this.datacollection.datasets.length > 0) {
        return this.datacollection.datasets.filter(el => new RegExp(this.searchLabelValue, 'ig').test(el.label))
      }
      return []
    }
  },
  methods: {
    getRandomColor () {
      let letters = '0123456789ABCDEF'
      let color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    getTotal (header) {
      let sum = 0
      this.table.items.forEach(item => {
        sum += item[header.field]
      })
      if (typeof sum === 'number') {
        return sum
      }
    },
    highlight (name) {
      let re = new RegExp(this.searchLabelValue, 'ig')
      return this.searchLabelValue ? name.replace(re, `<em>$&</em>`) : name
    }
  }
}
</script>
