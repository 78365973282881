<template>
  <div>
    <div
      v-if="issue"
      v-show="panelExpanded" class="navigation-panel hidden-xs-only">

      <v-card-actions class="py-0">
        <div class="body-1 ml-2 grey--text text--darken-2">
          {{ $t('info') }}
        </div>
        <v-spacer/>
        <v-btn icon @click="panelExpanded = !panelExpanded">
          <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-actions>
        <v-btn
          v-show="issue.status !== 'DONE'" class="ml-2"
          color="green"
          outlined @click="changeStatus('DONE')">
          {{ $t('done') }}
        </v-btn>
        <v-btn
          v-show="issue.status === 'DONE'" class="ml-2"
          color="blue"
          outlined @click="changeStatus('OPEN')">
          {{ $t('reopen') }}
        </v-btn>
        <v-spacer/>
        <v-menu :nudge-right="5" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item dense @click="$refs.shareDataDialog.share()">
                <v-list-item-avatar>
                  <v-icon>share</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ $t('button.share') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense @click="remove">
                <v-list-item-avatar>
                  <v-icon>delete</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ $t('button.remove') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-card-actions>

      <v-card-text>
        <v-text-field
          :label="$t('name')"
          :value="issue.name"
          class="simple-input"
          dense @change="changeName"/>

        <v-select
          :items="statusList"
          :label="$t('status')"
          :value="issue.status"
          class="simple-input mt-6"
          dense
          item-text="title"
          item-value="key"
          @change="changeStatus">
          <template v-slot:selection="{ item }">
              <span class="mr-2">
                <v-avatar :color="item.color" size="12"/>
              </span>
            {{ $t(item.key) }}
          </template>

          <template v-slot:item="{ item }">
              <span class="mr-2">
                <v-avatar :color="item.color" size="11"/>
              </span>
            {{ $t(item.key) }}
          </template>
        </v-select>

        <v-select
          :items="userList"
          :label="$t('executor')"
          :value="issue.performer"
          cache-items
          class="simple-input mt-6"
          dense
          hide-details
          item-value="id"
          return-object
          @change="assignee">
          <template v-slot:selection="{ item }">
            <user-avatar :user="item" class="mr-2" x-small/>
            <span class="hide-text-overflow">{{ item.firstName + ' ' + item.lastName }}</span>
          </template>
          <template v-slot:item="{ item }">
            <user-avatar :user="item" class="mr-2" x-small/>
            <span class="hide-text-overflow">{{ item.firstName + ' ' + item.lastName }}</span>
          </template>
        </v-select>
        <div style="height: 16px;">
          <a v-if="!issue.performer || issue.performer.id !== currentLoginUser.id" @click="assignee(currentLoginUser)">
            {{ $t('assignMe') }}
          </a>
        </div>

        <d-date-picker
          v-model="datePicker"
          :label="$t('completionDate')"
          :placeholder="$t('DD.MM.YYYY')"
          class="mt-9"
          dense
          hide-details
          simple-input
          @focusout="saveDueDate"
          @picked="saveDueDate"
        >
          <template v-slot:append>
              <span style="margin-top: 2px;">
                <v-icon :color="overdue ? 'red' : ''">
                  {{ overdue ? 'event_busy' : 'date_range' }}
                </v-icon>
              </span>
          </template>
        </d-date-picker>

        <v-select
          v-model="issue.categoryList"
          :items="categoryList.filter(it => !it.isDefault)"
          :label="$t('categories')"
          chips
          class="simple-input mt-6"
          dense
          item-text="name"
          multiple
          return-object
          @change="updateDataCategoryList"
        >
          <template v-slot:selection="data">
            <v-chip class="my-1 hide-text-overflow" color="deep-purple" label outlined>
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-select>
      </v-card-text>
    </div>

    <div
      v-show="!panelExpanded"
      class="hidden-xs-only minimized-panel"
      style="cursor: pointer; background: rgb(248, 248, 248); position: fixed; height: 100%; width: 36px; border-right: solid 1px #d6d2d2;"
      @click="panelExpanded=true"
    >
      <v-btn class="mb-2" icon>
        <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
      </v-btn>
      <div class="body-1 minimized-panel-label">{{ $t('info') }}</div>
    </div>

    <div
      v-if="issue"
      :style="panelExpanded ? 'margin-left: 280px;' : 'margin-left: 36px;'"
      class="router-view"
    >
      <v-card-actions>
        <div class="body-1 ml-2 pr-8 inline-text">
          <v-breadcrumbs :items="breadcrumbs"/>
        </div>
        <v-spacer/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="mx-2" @click="closePage" v-on="on">close</v-icon>
          </template>
          <span>{{ $t('button.close') }}</span>
        </v-tooltip>
      </v-card-actions>
      <div id="doc-frame" class="doc-frame">
        <div v-if="issue.poi && showMap" class="issue-view-container">
          <div class="issue-view-content">
            <poi-viewer :poi="issue.poi"/>
          </div>
        </div>

        <div v-if="layerPoi && showMap" class="issue-view-container">
          <div class="issue-view-content">
            <layer-poi-map-widget :poi="layerPoi"/>
            <action-toolbar
              :actions="mapActions"
              class="pt-3"
              @directToMap="directToMap"
              @info="openPoiDetails"
            />
          </div>
        </div>

        <text-editor v-if="body" ref="textEditor" v-model="body" class="pa-2" @on-ready="isLoading=false"/>

        <div v-show="!isLoading" class="issue-view-container">

          <div class="issue-view-content">

            <issue-additional-fields v-if="issue.template" :issue="issue"/>

            <v-card-actions class="px-0" style="height: 52px">
              <span class="grey--text text--darken-1">
                {{ $t('taskCreated') }} {{ formatDate(issue.creationDate) }}
              </span>
              <v-spacer/>
              <v-btn
                v-show="bodyIsChanged"
                outlined
                @click="cancelChange"
              >
                {{ $t('button.cancel') }}
              </v-btn>
              <v-btn
                v-show="bodyIsChanged"
                color="green"
                outlined
                @click="changeDescription"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>

            <v-divider/>

            <v-card-actions class="pa-0 mt-3">
              <span class="grey--text text--darken-1">{{ $t('history') }}</span>
              <v-spacer/>
              <v-icon size="28">history</v-icon>
            </v-card-actions>

            <comments v-if="issue.chat && !issue.isLoading" ref="comments" :chat-id="issue.chat.id"/>

          </div>
        </div>
      </div>
    </div>

    <share-data-dialog ref="shareDataDialog"/>
    <layer-poi-dialog
      v-if="layerPoi "
      ref="layerPoiDialog"
      readonly-mode
    />
  </div>
</template>

<script>
import TextEditor from '@/components/utils/TextEditor'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import Comments from '@/components/chats/IssueComments'
import { EventBus } from '@/event-bus'
import UserAvatar from '@/components/utils/UserAvatar'
import moment from 'moment'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '../../componet-locale/issue-view/messages'
import statusMessages from '../../shared-locale/issue-status/messages'
import PoiViewer from '@/components/map/PoiViewer'
import IssueAdditionalFields from '@/components/issue/IssueAdditionalFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import ActionToolbar from '@/components/utils/ActionToolbar'
import { DIRECT_TO_MAP, POI_INFO, SPACER } from '@/components/map/helpers/map-actions'
import { getMapListByLayerPoi } from '@/components/layer-poi/layer-poi-service'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import DDatePicker from '@/components/utils/DDatePicker.vue'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'

export default {
  name: 'IssueView',
  components: {
    CategoryPicker,
    DDatePicker,
    LayerPoiDialog,
    ActionToolbar,
    LayerPoiMapWidget,
    IssueAdditionalFields,
    PoiViewer,
    UserAvatar,
    Comments,
    ShareDataDialog,
    TextEditor
  },
  mixins: [issueMixin],
  data: () => ({
    issue: null,
    body: null,
    layerPoi: null,
    copyOfBody: null,
    isLoading: true,
    showTextEditor: true,
    piker: false,
    showMap: true,
    userList: [],
    categoryList: [],
    panelExpanded: true,
    mapVisible: true,
    mapActions: [POI_INFO, SPACER, DIRECT_TO_MAP]
  }),
  i18n: {
    messages: messages,
    sharedMessages: statusMessages
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios
        .get('project/get-users-by-project', {
          params: { id: this.selectedProject.id }
        })
        .then(response => this.userList = response.data)

      this.$axios
        .get('/category/get-all-for-project/', {
          params: { id: this.selectedProject.id }
        })
        .then(response => this.categoryList = response.data)

      this.loadData()
    },
    loadData () {
      this.showMap = false
      this.$axios
        .get('issue/get', { params: { id: this.$route.params.id } })
        .then(({ data }) => {
          this.issue = data
          if (this.issue.layerPoi) {
            this.layerPoi = this.issue.layerPoi
          } else {
            this.layerPoi = null
          }
          this.body = JSON.parse(this.issue.description)
          this.copyOfBody = { ...this.body }
          this.showMap = true
        })
    },
    directToMap () {
      if (this.issue.layerPoi) {
        getMapListByLayerPoi(
          this.issue.layerPoi,
          response => this.openInNewTab(
            response.data[0],
            null,
            { layerPoiId: this.issue.layerPoi.id }
          )
        )
      }
    },
    print () {
      const prtHtml = this.filteredSimpleValues.map(prop => {
        if (prop.isList) {
          let value = ''
          if (prop.value) {
            value = prop.list.find(it => it.code === prop.value).value
          }
          return `<div><b>${prop.username}: ${value}</b></div>`
        } else {
          return `<div><b>${prop.username}: </b>${prop.value}</div>`
        }
      }).join('')

      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
                <style>
                    div {padding: 10px}
                </style>
            </head>
            <body>
              ${prtHtml}
            </body>
          </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    openPoiDetails () {
      if (this.issue.layerPoi) {
        this.$refs.layerPoiDialog.open(this.issue.layerPoi)
      }
    },
    updateDataCategoryList () {
      let value = this.issue.categoryList.map(it => it.id).join()
      this.$axios
        .post('issue/update-category-list', null, {
          params: { id: this.issue.id, value: value }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('categoriesChanged'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    saveDueDate () {
      let date
      if (moment(this.issue.dueDate, 'DD.MM.YYYY', true).isValid()) {
        date = moment(this.issue.dueDate, 'DD.MM.YYYY').toDate().getTime()
      } else {
        date = null
      }
      this.$axios
        .post('issue/update-due-date', null, {
          params: { id: this.issue.id, date: date }
        })
        .then(() => EventBus.$emit('showInfoMessage', this.$t('dataChanged'))
        )
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    assignee (user) {
      const prevPerformer = this.issue.performer
      this.issue.performer = user
      let message = prevPerformer
        ? `<span class="grey--text text--darken-2">${this.$t('executorAssigned')}</span> ${prevPerformer.firstName} ${prevPerformer.lastName} ⭢ ${user.firstName} ${user.lastName}`
        : `<span class="grey--text text--darken-2">${this.$t('executorAssigned')}</span> ${user.firstName} ${user.lastName}`
      this.$axios
        .post('issue/assignee', null, {
          params: { id: this.issue.id, userId: user.id }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', `${user.firstName} ${user.lastName} - назначен`)
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    cancelChange () {
      this.body = null
      this.isLoading = true
      setTimeout(() => {
        this.body = { ...this.copyOfBody }
      }, 100)
    },
    remove () {
      this.$axios
        .post('issue/remove', null, {
          params: { id: this.issue.id }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('taskRemoved', { task: this.issue.name }))
          this.closePage()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'savingError')
        })
    },
    changeStatus (val) {
      let previousStatus = this.getStatus(this.issue.status)
      let status = this.getStatus(val)

      let message = `<span class="grey--text text--darken-2">${this.$t('statusChanged')}: </span>
                        <span class="bold ${previousStatus.color}--text">${this.$t(previousStatus.key)}</span>
                        <span> \> </span>
                        <span class="bold ${status.color}--text">${this.$t(status.key)}</span>`
      this.issue.status = val
      this.$axios
        .post('issue/change-status', null, {
          params: { id: this.issue.id, status: val }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('statusChanged'))
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
          this.issue.status = previousStatus.key
        })
    },
    changeDescription () {
      this.issue.description = JSON.stringify(this.body)
      if (this.issue.layerPoi) {
        this.issue.layerPoi.geometry = JSON.stringify(this.issue.layerPoi.geometry)
      }
      let message = this.$t('userChangedDescription')
      this.$axios
        .post('issue/update', this.issue)
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('descriptionChanged'))
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    changeName (value) {
      let previousName = this.issue.name
      let message = `<span class="grey--text text--darken-2">${this.$t('userChangedName')}</span>: ${previousName} \> <b>${value}</b>`
      this.$axios
        .post('issue/update-name', null, {
          params: { id: this.issue.id, value: value }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('nameChanged'))
          this.issue.name = value
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          this.issue.name = previousName
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    closePage () {
      let route = this.$route.matched[1]
      this.$router.push({ name: route.name, params: { projectId: this.selectedProject.id } })
    }
  },
  watch: {
    '$route.params.id' () {
      this.mapVisible = false
      setTimeout(() => {
        this.mapVisible = true
      }, 20)
      this.isLoading = true
      this.body = null
      this.loadData()
    }
  },
  computed: {
    poi () {
      if (!this.issue.layer || !this.issue.sys) return null
      return {
        sys: this.issue.sys,
        layer: this.issue.layer
      }
    },
    breadcrumbs () {
      return [
        {
          text: this.issue.project.name,
          disabled: false,
          to: `/project/${this.issue.project.id}/home`
        },
        {
          text: 'Задачи',
          disabled: false,
          to: `/project/${this.issue.project.id}/ISSUE`
        },
        {
          text: this.issue.name,
          disabled: false,
          to: ''
        }
      ]
    },
    datePicker: {
      get: function () {
        return moment(this.issue.dueDate).format('DD.MM.YYYY')
      },
      set: function (str) {
        this.issue.dueDate = moment(str, 'DD.MM.YYYY').toDate()
      }
    },
    bodyIsChanged () {
      if (this.body && this.copyOfBody) {
        return JSON.stringify(this.body) !== JSON.stringify(this.copyOfBody)
      }
    },
    overdue () {
      if (this.issue.status && this.issue.status === 'DONE') return false
      if (this.issue && this.issue.dueDate) {
        return moment(this.issue.dueDate).isBefore(moment())
      }
    }
  }
}
</script>
