<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex lg4 md6 sm8 xl3 xs12>
        <v-form
          lazy-validation
          ref="form">
          <v-card outlined>
            <v-card-title>
              <v-toolbar-title> {{ $t('resetPassword') }}</v-toolbar-title>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-text-field :rules="emailRules" @keyup.enter="submitData" label="E-Mail"
                            name="login"
                            prepend-icon="person"
                            required
                            type="text"
                            v-model="email">
              </v-text-field>
              <p class="pt-2">
                {{ $t('instruction') }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn text to="/login">
                {{ $t('back') }}
              </v-btn>
              <v-spacer/>
              <v-btn @click="submitData" color="primary" :disabled="!email" outlined :loading="loading">
                {{ $t('makeRequest') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="headline lighten-2"
          primary-title>
          {{ $t('confirmation') }}
        </v-card-title>
        <v-card-text>
          {{ $t('requestCreated') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="toLoginPage" outlined> {{ $t('next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/event-bus'
import messages from '../componet-locale/reset-password/messages'
import { EMAIL_PATTERN } from '@/utils/regexp'

export default {
  name: 'ResetPassword',
  data: () => ({
    emailRules: [
      v => !!v || 'E-mail должен быть заполнен',
      v => EMAIL_PATTERN.test(v) || 'E-mail заполнен не верно'
    ],
    email: '',
    dialog: false,
    loading: false,
    errorInfo: ''
  }),
  i18n: { messages: messages },
  methods: {
    submitData () {
      this.loading = true
      this.errorInfo = ''
      if (this.$refs.form.validate()) {
        axios
          .get('/reset-password/create-request', {
            params: { email: this.email },
            timeout: 30000
          })
          .then(() => this.dialog = true)
          .catch(response => {
            this.errorInfo = response.data.message
            this.$router.push('/reset-password')
            EventBus.$emit('showErrorMessage', this.errorInfo)
          })
          .finally(() => this.loading = false)
      }
    },
    toLoginPage () {
      this.$router.push('/login')
    }
  }
}
</script>
