<template>
<v-dialog width="900" v-model="dialog">
  <v-card>
    <v-card-title>
      {{ category ? category.name : '' }}
      <v-spacer/>
      <v-btn icon @click="dialog=false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-skeleton-loader
        type="list-item"
        v-if="loading"
        :loading="loading"
      />
      <v-list v-else>
        <v-list-item v-for="item in adList" :key="item.id" :item="item" @click="openAD(item)">
          <v-list-item-title>
            {{ item ? item.name : '' }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import SimpleDataRow from '@/components/utils/SimpleDataRow.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'DataListDialog',
  components: { SimpleDataRow },
  data: () => ({
    dialog: false,
    category: null,
    adList: [],
    loading: false
  }),
  methods: {
    openAD (ad) {
      this.$router.push(`public-data/${ad.id}`)
    },
    open (category) {
      this.dialog = true
      this.category = category
      this.loading = true
      this.$axios
        .get(`public-data/categories/${category.id}`)
        .then(response => this.adList = response.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    }
  }
}
</script>
