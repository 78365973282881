<template>
  <div class="view-data-page">
    <div class="navigation-panel" style="height: 100vh">
      <v-toolbar height="56" flat>
        {{ $t('emailTemplates') }}
      </v-toolbar>
      <v-divider/>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in list"
          :key="index"
          @click="selected = item"
        >
          {{ $t(item) }}
        </v-list-item>
      </v-list>
    </div>
    <div style="margin-left: 280px; overflow-y: auto" class="router-view">
      <div id="doc-frame" style="height: 100vh; overflow: auto;" class="pa-4">
        <mail-template-editor :type="selected" v-show="selected"/>
        <p v-if="!selected">{{ $t('selectTemplate') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import MailTemplateEditor from '@/components/admin/mail-template/MailTemplateEditor'
import messages from '@/componet-locale/mail-templates/messages'

export default {
  name: 'MailTemplates',
  i18n: { messages: messages },
  components: { MailTemplateEditor },
  data: () => ({
    list: [],
    selected: null
  }),
  mounted () {
    this.$axios
      .get('mail-template/list')
      .then(response => this.list = response.data)
  }
}
</script>
