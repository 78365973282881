<template>
  <split
    @onDragEnd="onDragEnd"
    class="split-screen-mode full-height"
    direction="horizontal">

    <split-area :size="50">
      <data-viewer :data-id="dataId"/>
    </split-area>

    <split-area :size="50">
      <data-viewer :data-id="secondDataId" :list-of-related-data="listOfRelatedData"/>
    </split-area>

  </split>
</template>

<script>
import VueSplit from 'vue-split-panel'
import Vue from 'vue'
import { EventBus } from '@/event-bus'
import DataViewer from '@/components/utils/DataViewer'

Vue.use(VueSplit)

export default {
  name: 'SplitScreenPage',
  props: { dataId: Number, secondDataId: Number },
  components: { DataViewer },
  data: () => ({
    listOfRelatedData: []
  }),
  mounted () {
    if (!this.dataId) return
    this.$axios
      .get('/relations-data/get', {
        params: { id: this.dataId }
      })
      .then(response => {
        this.listOfRelatedData = response.data.map(it => it.data)
      })
      .catch(reason => {
        EventBus.$emit('showErrorMessage', 'error')
        console.error(reason.data.message)
      })
  },
  methods: {
    onDragEnd: () => EventBus.$emit('frameResize')
  }
}
</script>
