<template>
  <v-text-field
    v-model="model.value"
    :class=" displayXS ? '' : 'input-filter mr-2'"
    :dense="!displayXS"
    :outlined="!displayXS"
    :placeholder="label"
    :solo="displayXS"
    auto-grow
    background-color="grey lighten-5"
    clearable
    flat
    hide-details
    row-height="1"
    style="max-width: 100% !important;"
    @input="$emit('change')"
  />
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'TextFieldFilter',
  mixins: [displayServiceMixin],
  props: {
    model: {
      type: Object,
      default: {
        value: ''
      }
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
