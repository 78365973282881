<template>
  <div>
    <chat-type-filter/>
    <v-divider light/>
    <v-list
      class="transparent chat-list"
      dense
    >
      <div
        v-for="chat in chats"
        v-show="chat.visible"
        :key="chat.id"
        @click="onItemClick(chat)"
      >
        <chat-item
          :input-value="activeChatID===chat.id"
          :item="chat"
        >
        </chat-item>
        <v-divider light/>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatItem from './ChatItem'
import ChatTypeFilter from './ChatTypeFilter'

export default {
  name: 'ChatList',
  components: { ChatTypeFilter, ChatItem },
  props: ['primaryChat'],
  methods: {
    ...mapActions(['setActiveChatId']),
    onItemClick (item) {
      this.setActiveChatId(item.id)
      this.$emit('update:primaryChat', item.id)
    }
  },
  created () {
    if (this.primaryChat) {
      this.setActiveChatId(this.primaryChat)
    } else {
      this.chats.length && this.setActiveChatId(this.chats[0].id)
    }
  },
  computed: {
    ...mapGetters(['activeChatID', 'chats'])
  },
  watch: {
    primaryChat (chatId) {
      this.setActiveChatId(chatId)
      this.$emit('update:primaryChat', chatId)
    }
  }
}
</script>

<style>
.chat-list {
  height: calc(100vh - 111px);
  overflow: auto;
}

</style>
