<template>
  <v-card
    class="pa-0 my-3 poi-viewer"
    flat
  >
    <v-btn
      @click="goToMap"
      fab absolute right
      small
      style="top: 20px"
    >
      <v-icon>map</v-icon>
    </v-btn>
    <div
      class="map-poi-viewer"
    >
      <d-map
        style="height: 400px; width: 100%"
        widget
        v-if="poi.layer"
        :poi="poi"
        :map="{ layer: poi.layer }"
      >
        <base-layer/>
      </d-map>
    </div>
  </v-card>
</template>

<script>
import DMap from '@/components/map/DMap'
import BaseLayer from '@/components/map/BaseLayer'

export default {
  name: 'PoiViewer',
  components: { BaseLayer, DMap },
  props: {
    poi: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    zoom: 12,
    center: [0, 0]
  }),
  methods: {
    goToMap () {
      this.$router.push({
        name: 'view-layer',
        query: { sysId: this.poi.sys, dataId: this.poi.layer.id }
      })
    }
  }
}
</script>

<style>

.poi-viewer {
}

.map-poi-viewer {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  height: 300px;
}

</style>
