<template>
  <v-dialog
    v-model="dialog"
    width="800">
    <v-card>
      <v-card-title v-if="field">
        {{ field.username }}
        <v-spacer/>
        <v-btn
          @click="dialog=false"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="field">
        <img-from-zulu :url="field.url"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ImgFromZulu from '@/components/map/helpers/ImgFromZulu'

export default {
  name: 'PhotoDialog',
  components: { ImgFromZulu },
  props: ['feature'],
  data: () => ({
    dialog: false,
    field: null
  }),
  methods: {
    async open (field, feature) {
      this.dialog = true
      this.field = field
    }
  }
}
</script>
