<template>
  <v-container>
    <v-card class="elevation-0">
      <project-dashboard/>
    </v-card>

    <v-card-text class="pl-3 py-1 pr-0 mt-3" style="margin-bottom: 30px">
      <v-expansion-panels
        flat
        multiple
        accordion
      >
        <folder-for-categories
          v-for="item in categoriesByType"
          @select="selectCategory"
          :key="item.id"
          :folder="item"
        />
      </v-expansion-panels>

      <v-layout
        class="pa-3"
        row wrap
      >
        <v-flex
          md6 sm12 xl4
          v-for="item in categoriesWithoutType"
          :key="item.id"
        >
          <category-card @select="selectCategory" :item="item"/>
        </v-flex>
        <v-flex lg4 md6 sm8 xl3 xs12>
          <v-card
            class="mb-4"
            flat
          >
            <v-card-title>
              <v-btn
                @click="createCategory"
                text
                v-if="setAbility('ADMIN')"
              >
                <v-icon left>add</v-icon>
                {{ $t('button.add') }}
              </v-btn>
            </v-card-title>
          </v-card>

        </v-flex>
      </v-layout>
    </v-card-text>

    <category-details-dialog
      @on-save="getCategories"
      ref="categoryDetailsDialog"
    />
  </v-container>
</template>

<script>
import CategoryDetailsDialog from '../category/CategoryDetailsDialog'
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import ProjectDashboard from '@/components/project/ProjectDashboard'
import projectMessages from '../../shared-locale/project/messages'
import CategoryCard from '@/components/project/CategoryCard'
import FolderForCategories from '@/components/project/FolderForCategories'

export default {
  name: 'ProjectView',
  components: {
    FolderForCategories,
    CategoryCard,
    ProjectDashboard,
    CategoryDetailsDialog
  },
  i18n: {
    sharedMessages: projectMessages
  },
  mixins: [displayServiceMixin],
  data: () => ({
    editableMode: false,
    searchField: '',
    categoriesWithoutType: [],
    categoriesByType: [],
    widgets: []
  }),
  methods: {
    save () {
      this.editableMode = false
      this.$axios
        .post('widget/save-all', this.widgets)
        .then(() => {
          this.editableMode = false
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          EventBus.$emit('frameResize')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    onRemove () {
      if (!this.widgets.length) this.editableMode = false
      this.loadWidgets()
    },
    selectCategory (category) {
      this.$router.push({
        name: 'category-viewer',
        params: {
          projectId: this.selectedProject.id,
          id: category.id
        }
      })
    },
    loadWidgets () {
      this.$axios
        .get('widget/get-all', {
          params: { projectId: this.selectedProject.id }
        })
        .then(response => {
          this.widgets = response.data
        })
    },
    getCategories () {
      this.$axios
        .get('category/get-category-details-list', {
          params: { id: this.selectedProject.id }
        })
        .then(response => {
          let categories = response.data.filter(it => !it.category.isDefault)
          categories
            .forEach(it => {
              it.size = Object
                .values(it.numberOfDataByType)
                .reduce((a, b) => a + b)
            })

          let map = new Map()
          categories
            .forEach(item => {
              if (!map.has(item.category.type)) {
                map.set(item.category.type, [item])
              } else {
                map.get(item.category.type).push(item)
              }
            })

          let others = []
          this.categoriesByType = []

          map.forEach((items, key) => {
            if (!key) {
              others = others.concat(items)
              map.delete(key)
            } else {
              this.categoriesByType.push({
                title: key,
                items: items
                // size: items.reduce((a, b) => a + b.length())
              })
            }
          })
          this.categoriesByType.sort((a, b) => {
            return a.title > b.title ? 1 : -1
          })
          this.categoriesWithoutType = others
          return map
        })
    },
    init () {
      this.getCategories()
      this.loadWidgets()
    },
    createCategory () {
      this.$refs.categoryDetailsDialog.open()
    }
  },
  created () {
    this.init()
  },
  watch: {
    'selectedProject.id' () {
      this.init()
    }
  }
}
</script>

<style>
.category-card:hover {
  background: #f5f5f5;
}
</style>
