<template>
  <v-dialog v-model="dialog"
            width="500">
    <v-card>
      <v-card-title>
        {{ $t('confirmation') }}
      </v-card-title>
      <v-card-text>
        {{ title }}
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false"
               outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn @click="confirm"
               color="primary"
               outlined>
          {{ $t('button.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileRemovalConfirmationDialog',
  props: ['title'],
  data: () => ({
    dialog: false,
    ids: []
  }),
  methods: {
    confirm () {
      this.$emit('confirm', this.ids)
      this.dialog = false
    },
    open (ids) {
      this.ids = ids
      this.dialog = true
    }
  }
}
</script>
