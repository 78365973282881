<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="d-flex flex-column" height="90vh">
      <v-card-title>{{ $t('placeholders') }}</v-card-title>
      <v-card-text class="overflow-y-auto flex-grow-1">
        <report-placeholder-item
          v-for="(placeholder, i) in placeholders"
          :key="i"
          :placeholder="placeholder"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReportPlaceholderItem from '@/components/report/ReportPlaceholderItem.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/report-view/messages'

export default {
  name: "ReportPlaceholdersDialog",
  components: { ReportPlaceholderItem },
  i18n: { messages },
  data: () => ({
    dialog: false,
    reportVersion: null,
    placeholders: null
  }),
  methods: {
    open (reportVersion) {
      this.reportVersion = reportVersion
      this.dialog = true
      this.init()
    },
    init () {
      if (this.reportVersion.placeholders && this.reportVersion.placeholders.length) {
        this.placeholders = this.reportVersion.placeholders
      } else {
        this.$axios.get(`report/get-version-placeholders`, {
          params: {
            versionId: this.reportVersion.id
          }
        })
          .then(res => {
            if (res.data.length === 0) {
              EventBus.$emit('showInfoMessage', this.$t('noPlaceholders'))
              this.close()
            } else {
              this.placeholders = res.data
            }
          })
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      }
    },
    save () {
      this.$axios.post(`/report/save-placeholders-for-version/${this.reportVersion.id}`, this.placeholders)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('updated')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    close () {
      this.dialog = false
      this.placeholders = null
      this.reportVersion = null
    }
  }
}
</script>
