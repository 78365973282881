<template>
  <view-data-page
    :data="data"
    :selected-view="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
  >
    <template slot="doc">
      <v-card-text>
        <div v-if="editMode">
          <modbus-field v-for="field in data.fields" :value="field"/>
          <v-btn @click="addField">Добавить</v-btn>
        </div>
        <div v-if="!editMode">
          <modbus-unit-chart :modbus-unit="data"/>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          @click="editMode=true"
          v-show="!editMode"
          v-if="setAbility('MODERATOR')"
          color="primary" outlined>
          Редактировать
        </v-btn>

        <v-btn
          @click="save"
          v-show="editMode"
          v-if="setAbility('MODERATOR')"
          color="primary" outlined>
          Сохранить
        </v-btn>
      </v-card-actions>
    </template>
  </view-data-page>
</template>

<script>
import ViewDataPage from '@/components/utils/ViewDataPage'
import { EventBus } from '@/event-bus'
import ModbusField from '@/components/modbus/ModbusField'
import ModbusUnitTable from '@/components/modbus/ModbusUnitTable'
import ModbusUnitChart from './ModbusUnitChart'

const EMPTY_FIELD = {
  name: '',
  dataType: 'WORD',
  address: ''
}

export default {
  name: 'ModbusUnitView',
  components: { ModbusUnitChart, ModbusUnitTable, ModbusField, ViewDataPage },
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  data: () => ({
    selectedView: 'comments',
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    data: {
      owner: {
        firstName: ''
      },
      project: {
        name: ''
      }
    },
    editMode: false
  }),
  methods: {
    selectView (view) {
      this.selectedView = view
    },
    init () {
      let id = this.dataId || this.$route.params.id
      this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
        })
        .catch(reason => {
          console.error(reason.data.message)
        })
    },
    save () {
      this.$axios
        .post('modbus-unit/save', this.data)
        .then(() => {
          this.editMode = false
          this.init()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    addField () {
      this.data.fields.push({ ...EMPTY_FIELD })
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
