import { render, staticRenderFns } from "./HelpPage.vue?vue&type=template&id=4f735472&"
import script from "./HelpPage.vue?vue&type=script&lang=js&"
export * from "./HelpPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VTreeview})
