<template>
  <span class="message-body" v-html="body"/>
</template>

<script>
import messages from '@/componet-locale/chat-creation-dialog/messages'

export default {
  name: 'DefaultMessage',
  i18n: { messages: messages },
  props: {
    message: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    body () {
      if (this.message.messageKey === 'DELETED') {
        return '<div style="display:flex;"><div class="vertical"></div><span class="grey--text">Сообщение удалено</span></div>'
      } else {
        let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
        return this.message.decryptedBody.replace(exp, '<a href=\'$1\' target=\'_blank\'>$1</a>')
      }
    }
  }
}
</script>

<style scoped>
.message-body {
  white-space: pre-wrap;
}

.message-body div {
  white-space: normal;
}
</style>
