<template>
  <v-select
    dense
    outlined
    :readonly="readonlyMode"
    :label="value.field.name"
    :items="getOptions(value)"
    multiple
    v-model="selectedValues"
  />
</template>

<script>
export default {
  name: 'MultipleListField',
  props: {
    value: Object,
    readonlyMode: Boolean
  },
  methods: {
    getOptions (value) {
      return value.field.options.split(',')
    }
  },
  computed: {
    selectedValues: {
      get: function () {
        if (this.value.value) {
          return this.value.value.split(',')
        }
      },
      set: function (val) {
        this.value.value = val.join(',')
      }
    }
  }
}
</script>
