<template>
  <div class="category-picker" :class="selected && selected.length ? 'short-input' : ''">
    <div @click="openDialog">
      <v-select
        :hide-details="hideDetails"
        :items="excludeDefault(categories)"
        :label="$t('categories')"
        :value="selected"
        append-icon=""
        chips
        dense
        item-text="name"
        multiple
        outlined
        readonly
        @update="updateModel"
      >
        <template v-slot:selection="{item, index}">
          <v-chip v-if="index <= 1 || !shortView" close label small @click:close="deleteFromSelection(item)">
            {{ item.name }}
          </v-chip>
          <span v-if="index === 2 && shortView" class="grey--text text-caption">
            + {{ selected.length - 1 }}
          </span>
        </template>
      </v-select>
    </div>

    <v-dialog v-model="dialog" scrollable width="700">
      <v-card>
        <v-card-title>
          <div>
            {{ $t('categories') }}
          </div>
          <v-spacer/>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-2">
          <v-treeview>
            <v-treeview
              :items="tree"
              :value="selected"
              return-object
              selectable
              @input="updateModel"
            />
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined @click="clearCategories">
            <v-icon class="mr-2" small>mdi-restore</v-icon>
            {{ $t('reset') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/category-picker/messages'

export default {
  name: "CategoryPicker",
  i18n: { messages },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    selected: {
      type: Array
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    shortView: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    categories: [],
    tree: null,
  }),
  mounted () {
    this.$axios
      .get('/category/get-all-for-project/', { params: { id: this.projectId } })
      .then(response => {
        this.categories = response.data
        this.createTree(response.data)
      })
      .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
  },
  methods: {
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    updateModel (value) {
      this.$emit('change', value)
    },
    deleteFromSelection (category) {
      this.$emit('change', this.selected.filter(el => el.id !== category.id))
    },
    clearCategories () {
      this.$emit('change', [])
    },
    createTree (data) {
      let map = new Map()
      this.excludeDefault(data)
        .forEach(item => {
          if (!map.has(item.type)) {
            map.set(item.type, [item])
          } else {
            map.get(item.type).push(item)
          }
        })
      let array = []
      map.forEach((items, key) => {
        if (items.length === 1 || !key) {
          array = array.concat(items)
          map.delete(key)
        } else {
          array.push({
            name: key,
            selected: true,
            id: key,
            children: items
          })
        }
      })
      this.tree = array
    },
    excludeDefault (categoryList) {
      return Array.isArray(categoryList) ? categoryList.filter(it => !it.isDefault) : []
    }
  }
}
</script>

<style>
.short-input input {
  width: 10px;
}
</style>
