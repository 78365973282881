<template>
  <v-dialog v-if="dialog" v-model="dialog" width="400">
    <v-card>
      <v-card-title class="py-2">
        {{ $t('button.export') }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list class="d-flex flex-column">

          <p class="font-italic subtitle-1 mx-5 my-0 ">{{ $t('onlyProps') }}</p>
          <v-btn
            v-for="templateGroup in lpsGroupedByTemplate"
            :loading="isButtonLoading(templateGroup.template.id, false)"
            class="px-2 mt-2 mx-4 justify-start"
            dense
            outlined
            style="overflow: hidden"
            text
            @click="exportProperties(false, templateGroup.lps, templateGroup.template)"
          >
            <layer-poi-badge :template="templateGroup.template"/>
            {{ templateGroup.template.name }}
          </v-btn>

          <p class="font-italic subtitle-1 mx-5 mb-0 mt-3">{{ $t('propsWithGeometry') }}</p>
          <v-btn
            v-for="templateGroup in lpsGroupedByTemplate"
            :loading="isButtonLoading(templateGroup.template.id, true)"
            class="px-2 mt-2 mx-4 justify-start"
            dense
            outlined
            style="overflow: hidden"
            text
            @click="exportProperties(true, templateGroup.lps, templateGroup.template)"
          >
            <layer-poi-badge :template="templateGroup.template"/>
            {{ templateGroup.template.name }}
          </v-btn>

          <p class="font-italic subtitle-1 mx-5 mb-0 mt-3">{{ $t('geoJson') }}</p>
          <v-btn
            :loading="isLoadingGeoJson"
            class="px-2 my-2 mx-4 justify-start"
            dense
            outlined
            text
            @click="exportGeoJson"
          >
            <v-icon class="mr-3">mdi-file-code</v-icon>
            {{ $t('geoJson') }}
          </v-btn>

        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/mass-actions/messages'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import { template } from 'lodash'

export default {
  name: "LpsExportDialog",
  components: { LayerPoiBadge },
  data: () => ({
    dialog: false,
    lps: [],
    templates: [],
    loadingTemplates: [],
    isLoadingGeoJson: false
  }),
  i18n: { messages },
  methods: {
    template,
    open (lps, templates) {
      this.lps = lps
      this.templates = templates
      this.dialog = true
    },
    close () {
      this.lps = []
      this.templates = []
      this.dialog = false
    },
    exportProperties (withGeometry = false, lps, template) {
      const lpIds = lps.map(it => it.id)
      const name = template.name
      this.loadingTemplates.push({ id: template.id, withGeometry })
      this.$axios
        .post(`layer-poi/export-lps/xlsx${withGeometry ? '-with-geometry' : ''}`, lpIds, {
          responseType: 'arraybuffer'
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          link.download = `${name} ${this.formatDateDIN(new Date(Date.now()))}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => {
          setTimeout(() => {
            this.loadingTemplates = this.loadingTemplates.filter(el => !(el.id === template.id && el.withGeometry === withGeometry))
          }, 500)
        })
    },
    exportGeoJson () {
      const lpIds = this.lps.map(it => it.id)
      this.isLoadingGeoJson = true
      this.$axios
        .post(`layer-poi/geo-json-by-lps`, lpIds)
        .then(({ data }) => {
          let geojson = JSON.stringify(data)
          let url = window.URL.createObjectURL(new Blob([geojson]))
          let link = document.createElement('a')
          link.href = url
          let name = `${lpIds.length} ${this.$t('objects')}. ${this.formatDateDIN(new Date(Date.now()))}`
          link.download = `${name}.geojson`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => {
          setTimeout(() => {
            this.isLoadingGeoJson = false
          }, 500)
        })
    },
    isButtonLoading (templateId, withGeometry) {
      return !!this.loadingTemplates.find(el => el.id === templateId && el.withGeometry === withGeometry)
    }
  },
  computed: {
    lpsGroupedByTemplate () {
      return this.templates.map(template => ({
        template,
        lps: this.lps.filter(poi => poi.templateId === template.id)
      }))
    }
  }
}
</script>
