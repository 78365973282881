<template>
  <ad-widget-container
    :dense="dense"
    :title="data.name"
    dense-title
    @clickOnTitle="e => openInNewTab(data, e)"
  >
    <d-map
      v-if="data && data.layerConfigList"
      :map="data"
      widget
    />
    <div v-else>
      <v-icon>mdi-alert</v-icon>
    </div>
  </ad-widget-container>
</template>

<script>
import DMap from '@/components/map/DMap'
import { mapGetters } from 'vuex'
import AdWidgetContainer from '@/components/widget/AdWidgetContainer.vue'

export default {
  name: 'MapWidget',
  components: { AdWidgetContainer, DMap },
  props: {
    widget: {
      type: Object,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: {}
  }),
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters([
      'gisCredentials'
    ])
  },
  methods: {
    async init () {
      this.data = await this.getMapById(this.widget.abstractData.id)
    },
    async getMapById (id) {
      let response = await this.$axios.get('data/get', { params: { id: id } })
      let map = response.data
      map.position = map.position ? JSON.parse(map.position) : null
      return map
    }
  }
}
</script>
