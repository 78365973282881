<template>
  <v-dialog v-if="header" v-model="dialog" width="600">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="header.fieldName"
          :label="$t('title')"
          hide-details
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined @click="close">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!this.header.fieldName" color="primary" outlined @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-edit/messages'

export default {
  name: 'HeaderNameEditor',
  i18n: { messages },
  data: () => ({
    header: null,
    dialog: false
  }),
  methods: {
    open (header) {
      this.header = JSON.parse(JSON.stringify(header))
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.header = null
    },
    save () {
      this.$axios.post('et/update-header-name', {
        id: this.header.id,
        fieldName: this.header.fieldName
      })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('updated')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
