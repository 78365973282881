<template>
  <vl-layer-tile id="wmts" :z-index="zIndex">
    <vl-source-wmts
      :layer-name="params.LAYER || ''"
      :matrix-set="params.TILEMATRIXSET || 'EPSG:3857'"
      :url="layer.url"
      style-name="default"
    >
    </vl-source-wmts>
  </vl-layer-tile>
</template>

<script>
export default {
  name: 'WmtsLayer',
  props: { layer: Object, zIndex: Number },
  computed: {
    params () {
      return JSON.parse(this.layer.params)
    }
  }
}
</script>
