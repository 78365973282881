<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="900"
  >
    <v-card>
      <v-card-title>
        Дорожная карта (редактирование)
        <v-spacer/>
        <v-btn
          @click="dialog=false"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
            class="ma-4 mx-6"
          >
            <v-expansion-panel-header>
              Легенда
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <vue-editor
                class="ma-4"
                v-model="timeline.description"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <vuedraggable
            handle=".handle"
            style="width: 100%"
            v-model="timeline.steps"
          >
            <v-expansion-panel
              class="mx-2 mb-4"
              v-for="(step, index) in timeline.steps"
            >

              <v-expansion-panel-header>
                <span>
                  <v-icon
                    class="handle pointer mr-4"
                    v-html="'drag_indicator'"
                  />
                  {{ step.title || `Шаг №${index + 1}` }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-text-field
                    label="Наименование шага"
                    dense
                    v-model="step.title"
                  />
                  <vue-editor
                    class="mb-5"
                    v-model="step.description"
                  />
                  <v-text-field
                    label="Дата"
                    dense
                    v-model="step.tag"
                  />
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    label="Всплывающая подсказка"
                    v-model="step.tooltip"
                  />
                  <v-text-field
                    dense
                    label="Цвет"
                    v-model="step.color"
                  />
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </vuedraggable>
        </v-expansion-panels>

        <v-btn
          @click="removeStep"
        >
          <v-icon left>remove</v-icon>
          Удалить шаг
        </v-btn>

        <v-btn
          class="ml-2"
          @click="addStep"
          primary
        >
          <v-icon left>add</v-icon>
          Добавить шаг
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          @click="save"
          color="primary"
          primary
        >
          <v-icon left>save</v-icon>
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { VueEditor } from 'vue2-editor'
import { EventBus } from '@/event-bus'
import Vuedraggable from 'vuedraggable'

const EMPTY_STEP = {
  title: '',
  description: '',
  color: '',
  tag: '',
  tooltip: ''
}

export default {
  name: 'ProjectTimelineConfigDlg',
  components: {
    VueEditor,
    Vuedraggable
  },
  data: () => ({
    timeline: {},
    dialog: false
  }),
  methods: {
    async open () {
      let projectId = this.selectedProject.id
      let { data } = await this.$axios.get('project/get', { params: { id: projectId } })
      this.timeline = data.timeline ? JSON.parse(data.timeline) : {
        description: '',
        steps: []
      }
      this.dialog = true
    },
    addStep () {
      this.timeline.steps.push({ ...EMPTY_STEP })
    },
    removeStep () {
      this.timeline.steps.pop()
    },
    save () {
      let project = this.selectedProject
      project.timeline = JSON.stringify(this.timeline)
      this.$axios
        .post('project/update', project)
        .then((response) => {
          this.dialog = false
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('on-save')
        })
        .catch(() => {
          this.loading = false
          EventBus.$emit('showErrorMessage', this.$t('errorWritingData'))
        })
    }
  }
}
</script>

<style scoped>

</style>
