<template>
  <abstract-data-list @save="save"
                      ref="abstractDataList"
                      type="TABLE"
                      view="table-list">
    <template slot="actions">
      <v-btn @click="createNewItem"
             color="primary"
             outlined
             v-if="setAbility('MODERATOR')">
        {{ $t('button.create') }}
      </v-btn>
    </template>
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'
import { EventBus } from '@/event-bus'

export default {
  name: 'TableList',
  components: { AbstractDataList },
  methods: {
    save (item) {
      let projectId = this.selectedProject.id
      this.$axios
        .post('/table/save-for-project/' + projectId, item, { timeout: 240000 })
        .then(() => {
          this.$refs.abstractDataList.hideDialog()
          this.$refs.abstractDataList.filterData()
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    },
    createNewItem () {
      this.$refs.abstractDataList.createData()
    }
  }
}
</script>
