<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden d-flex flex-column" max-height="90vh">
      <v-card-title class="pb-0">
        <span>{{ $t('aggregation') }}</span>
        <v-spacer/>
      </v-card-title>
      <v-card-text class="pa-2 overflow-y-auto">
        <div>
          <div v-if="defaultAggregationResult && defaultAggregationResult.length" class="mb-8">
            <span class="ml-2 subtitle-1">{{ $t('defaultAggregation') }}</span>
            <v-simple-table style="border: 1px solid black">
              <thead>
              <tr>
                <th>{{ $t('field') }}</th>
                <th
                  v-for="aggregation in defaultAggregationResult[0].aggregations"
                >
                  {{ $t(aggregation.name.toString().toUpperCase()) }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="defaultAggregation in defaultAggregationResult"
              >
                <td>{{ defaultAggregation.fieldName }}</td>
                <td v-for="aggregation in defaultAggregation.aggregations">
                  {{ aggregation.value }}
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-if="aggregationResult && aggregationResult.length">
            <span class="ml-2 subtitle-1">{{ $t('customAggregation') }}</span>
            <v-simple-table style="border: 1px solid black">
              <thead>
              <tr>
                <th class="text-left">
                  {{ $t('name') }}
                </th>
                <th class="text-left">
                  {{ $t('value') }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="aggregation in aggregationResult"
                :key="aggregation.aggregationName"
              >
                <td>{{ aggregation.name }}</td>
                <td>{{ aggregation.value }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <div v-if="noData" class="ml-2">
          {{ $t('noData') }}
        </div>
        <v-checkbox v-if="!noData" v-model="useFilter" :label="$t('useFilter')" class="ml-2 mb-4" dense hide-details/>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn v-if="setAbility('MODERATOR')" outlined @click="$refs.etAggregationEditorDialog.open(et)">
          {{ $t('button.edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <et-aggregation-editor-dialog
      ref="etAggregationEditorDialog"
      @updated="updated"
    />
  </v-dialog>
</template>
<script>

import messages from '@/componet-locale/et-aggregation/messages'
import { EventBus } from '@/event-bus'
import EtAggregationCard from '@/components/et/aggregations/EtAggregationEditor.vue'
import EtAggregationEditor from '@/components/et/aggregations/EtAggregationEditor.vue'
import EtAggregationEditorDialog from '@/components/et/aggregations/EtAggregationEditorDialog.vue'

export default {
  name: 'EtAggregationDialog',
  components: { EtAggregationEditorDialog, EtAggregationEditor, EtAggregationCard },
  i18n: { messages },
  props: {
    filter: Array
  },
  data: () => ({
    dialog: false,
    et: null,
    defaultAggregationResult: null,
    aggregationResult: null,
    useFilter: false
  }),
  methods: {
    open (et) {
      this.et = et
      this.init()
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.et = null
    },
    updated () {
      this.$emit('updated')
      this.init()
    },
    init () {
      this.getAggregationData()
      this.getDefaultAggregationData()
      this.useFilter = this.filter.some(el => el.value.length && el.value[0] || el.fieldValue || !el.selectAll || !el.includeEmpty)
    },
    getDefaultAggregationData () {
      this.$axios.post('et/calculate-default-aggregation', this.useFilter ? this.filter : [], {
        params: {
          tableId: this.et.id
        }
      })
        .then(res => this.defaultAggregationResult = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    getAggregationData () {
      this.$axios.post('et/calculate-aggregation', this.useFilter ? this.filter : [], {
        params: {
          tableId: this.et.id
        }
      })
        .then(res => this.aggregationResult = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  },
  computed: {
    noData () {
      return (!this.aggregationResult || !this.aggregationResult.length) && (!this.defaultAggregationResult || !this.defaultAggregationResult.length)
    }
  },
  watch: {
    useFilter () {
      this.getDefaultAggregationData()
      this.getAggregationData()
    }
  }
}
</script>
