<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card>
      <v-card-title
        class="headline lighten-2"
        primary-title>
        {{ $t('category') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          @keydown.enter="save"
          :label="$t('name')"
          outlined
          v-model="category.name"
        />
        <v-text-field
          @keydown.enter="save"
          :label="$t('type')"
          outlined
          v-model="category.type"
        />
        <v-switch
          :label="$t('isPublic')"
          v-model="category.isPublic"
        />
        <v-switch
          label="Визуализировать прогресс загрузки данных"
          v-model="category.hasProcess"
        />
        <vue-editor v-model="category.description"/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          :loading="loading"
          @click="save"
          :disabled="!setAbility('ADMIN')"
          color="primary"
          outlined>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import messages from '../../componet-locale/category-details-dialog/messages'

export default {
  name: 'CategoryDetailsDialog',
  components: { VueEditor },
  data: () => ({
    dialog: false,
    category: {},
    loading: false
  }),
  i18n: { messages: messages },
  methods: {
    save () {
      if (!this.category.name) return
      this.loading = true
      this.category.project = this.selectedProject
      this.$axios
        .post('category/save', this.category)
        .then(response => {
          this.$emit('on-save', response.data)
          this.dialog = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    open () {
      this.category = {
        name: '',
        description: ''
      }
      this.dialog = true
    },
    edit (category) {
      this.category = category
      this.dialog = true
    }
  }
}
</script>
