<template>
  <v-carousel
    v-if="images.length > 0"
    :height="height"
    delimiter-icon="mdi-circle-medium"
    hide-delimiter-background
    show-arrows-on-hover
    :hide-delimiters="images.length === 1"
    :show-arrows="images.length > 1"
    style="border-radius: 4px; overflow: hidden"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-next</v-icon>
      </v-btn>
    </template>
    <lp-image-carousel-item v-for="image in images" :file-entity="image" :image-url="getImageUrl(image)"/>
  </v-carousel>
</template>

<script>
import LpImageCarouselItem from '@/components/map/images/LpImageCarouselItem.vue'

export default {
  name: "LpImageCarousel",
  components: { LpImageCarouselItem },
  props: {
    images: {
      required: true,
      type: Array
    },
    height: {
      type: String,
      default: '200'
    }
  },
  methods: {
    getImageUrl (image) {
      return this.isAuthenticated
        ? `files/get-attached-file/${image.id}`
        : `public-data/get-lp-attached-file/${image.id}`
    }
  }
}
</script>
