<template>
  <div class="py-2">
    <div class="subtitle-1">{{ $t('linkedData') }}</div>
    <v-list-item
      v-for="(item, index) in relationDataList"
      dense
      :key="`data-${index}`"
      @click=""
    >
      <v-list-item-avatar>
        <v-icon>{{ getUrlByDataType(item.data.dataType).icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title @click="openInNewTab(item.data, $event)">
          {{ item.data.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
            <span class="blue--text">
              {{ item.data.project.name }}
            </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="setAbility('MODERATOR')">
        <v-btn
          @click="removeRelatedData(item)"
          icon
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item
      v-for="(item, index) in data.chartList"
      dense
      :key="`data-${index}`"
      @click="openInNewTab(item, $event)"
    >
      <v-list-item-avatar>
        <v-icon>{{ getUrlByDataType(item.dataType).icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title @click="openInNewTab(item, $event)">
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
            <span class="blue--text">
              {{ item.project.name }}
            </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="data.table"
      dense
      @click="openInNewTab(data.table, $event)"
    >
      <v-list-item-avatar>
        <v-icon>{{ getUrlByDataType(data.table.dataType).icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title @click="openInNewTab(data.table, $event)">
          {{ data.table.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
            <span class="blue--text">
              {{ data.table.project.name }}
            </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        text
        @click="$refs.searchDataDialog.open()">
        <v-icon>add</v-icon>
        {{ $t('button.add') }}
      </v-btn>
    </v-card-actions>
    <!--    <confirmation-dialog @confirm="removeRelatedData"-->
    <!--                         ref="confirmationDialogremoveDocVersion"-->
    <!--                         :title="$t('confirmDeletion')"/>-->
    <search-data-dialog @select="addRelativeData" ref="searchDataDialog"/>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/relations-data/messages'
import projectMessages from '../../shared-locale/project/messages'
import SearchDataDialog from '@/components/utils/SearchDataDialog'

export default {
  name: 'RelationsData',
  components: { SearchDataDialog },
  props: ['data'],
  data: () => ({
    relationDataList: [],
    unrelatedDataList: [],
    type: ''
  }),
  watch: {
    data () {
      this.loadData()
    }
  },
  created () {
    this.loadData()
  },
  i18n: {
    messages: messages,
    sharedMessages: projectMessages
  },
  methods: {
    loadData () {
      if (!this.data) return
      this.getRelatedData()
    },
    getRelatedData () {
      this.$axios
        .get('relations-data/get', {
          params: { id: this.data.id }
        })
        .then(response => {
          this.relationDataList = response.data
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    removeRelatedData (data) {
      this.$axios
        .get('relations-data/remove', {
          params: { id: data.id }
        })
        .then(() => {
          this.loadData()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    openDialog (type) {
      this.type = type
      this.dialog = true
    },

    addRelativeData (data) {
      this.$axios
        .get('relations-data/add', { params: { idNode: this.data.id, id: data.id } })
        .then(() => {
          this.loadData()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  }
}
</script>
