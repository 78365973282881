<template>
  <v-dialog fullscreen v-if="displayXS" v-model="dialog">
    <v-card style="overflow-x: hidden">
      <div class="d-flex justify-space-between">
        <v-card-title class="pa-2">
          {{$t('search')}}
        </v-card-title>
        <div style="cursor: pointer" @click="closeWithSetReturn" class="mr-2 mt-2">
          <v-icon style="height:fit-content">close</v-icon>
        </div>
      </div>
      <slot name="search"/>
    </v-card>
  </v-dialog>
</template>

<script>

import LayerSettingItem from '@/components/map/helpers/LayerSettingItem.vue'
import {displayServiceMixin} from '@/mixins/dispay-service-mixin'
import messages from '@/componet-locale/search-lp-dialog-mobile/messages'

export default {
  name: 'SearchLpDialogMobile',
  i18n: {messages},
  components: { LayerSettingItem },
  mixins:[displayServiceMixin],
  data: () => ({
    dialog: false
  }),
  methods: {
    open() {
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
    closeWithSetReturn(){
      this.$store.dispatch('setReturnToSearchLpDialog', false)
      this.close()
    }
  }
}
</script>