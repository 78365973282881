<template>
  <div>
    <v-hover
      open-delay="200"
    >
      <v-list-item v-show="!hideLayer" class="px-1" dense @click="">
        <v-list-item-avatar class="mr-1">
          <v-icon class="handle pointer mr-1" small>drag_indicator</v-icon>
          <v-btn
            class="pointer"
            icon
            x-small
            @click="changeVisibility(layer)">
            <v-icon :color="layer.visible ? 'grey' : 'red'">
              {{ layer.visible ? 'visibility' : 'visibility_off' }}
            </v-icon>
          </v-btn>

        </v-list-item-avatar>
        <v-list-item-content>
          <span class="body-2">{{ layer.name }}</span>
        </v-list-item-content>

        <v-list-item-action class="ma-0">
          <v-btn icon x-small @click="dialog = true">
            <v-icon>tune</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

    </v-hover>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <div class="d-flex justify-space-between">
          <v-card-title class="pl-2 pt-2 pb-0">
            {{ layer.name }}
          </v-card-title>
          <div class="mr-2 mt-2" style="cursor: pointer" @click="dialog=false">
            <v-icon style="height:fit-content">close</v-icon>
          </div>
        </div>
        <v-card-subtitle class="px-2 pb-0">
          {{ layer.type }}
        </v-card-subtitle>

        <v-list v-if="layer.type === 'LAYER_POI'">
          <v-list-item v-for="template in layer.poiTemplates">
            <v-list-item-avatar class="mr-0">
              <layer-poi-badge :template="template"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="caption">{{ template.name }}</span>
            </v-list-item-content>
            <v-list-item-action v-if="setAbility('ADMIN')">
              <v-btn
                icon
                @click="$refs.poiTemplateDlg.open(template)"
              >
                <v-icon v-html="'edit'"/>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <poi-template-dialog
          ref="poiTemplateDlg"
          @onSave="refreshMap"
        />

        <v-card-text>
          <div v-if="layer.themes && layer.themes.length" class="pa-3">
            <span class="body-2">{{ $t('themes') }}</span>
            <v-checkbox v-for="theme in layer.themes"
                        v-model="theme.isActive"
                        :label="theme.name"
                        class="pt-2 ma-0"
                        dense
                        hide-details
                        @change="refreshMap"/>
          </div>
          <div v-if="layer.labels && layer.labels.length" class="pa-3">
            <span class="body-2">{{ $t('inscription') }}</span>
            <v-checkbox v-for="label in layer.labels"
                        v-model="label.isActive"
                        :label="label.name"
                        class="pt-1 ma-0"
                        dense
                        hide-details
                        @change="refreshMap"/>
          </div>
        </v-card-text>
        <v-card-actions v-if="layer.type === 'LAYER_POI' && !displayXS">
          <v-spacer/>
          <v-btn
            outlined
            small
            @click="exportGeoJson"
          >
            <v-icon
              color="blue"
              v-html="'file_download'"
            />
            {{ $t('exportGeoJson') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="isAuthenticated && !displayXS">
          <v-btn
            color="red"
            outlined
            small
            @click="remove">
            <v-icon>delete_outline</v-icon>
            {{ $t('removeFromMap') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            outlined
            small
            @click="$emit('openTable', layer)"
          >
            {{ this.layer.type === 'LAYER_POI' ? $t('openTable') : $t('openInSplitScreen') }}
          </v-btn>
          <v-btn
            outlined
            small
            @click="showLayer">
            {{ $t('showLayer') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <abstract-data-view-dialog
      ref="adViewDialog"
    />
  </div>
</template>

<script>
import messages from '@/componet-locale/layer-setting-item/messages'
import { EventBus } from '@/event-bus'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge'
import PoiTemplateDialog from '@/components/admin/poi-template-management/PoiTemplateDialog'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'LayerSettingItem',
  components: {
    AbstractDataViewDialog: () => import('@/components/utils/AbstractDataViewDialog.vue'),
    PoiTemplateDialog,
    LayerPoiBadge
  },
  props: {
    layer: {
      type: Object
    },
    hideNonpublic: {
      type: Boolean,
      default: false
    }
  },
  i18n: { messages: messages },
  mixins: [displayServiceMixin],
  data: () => ({
    dialog: false,
    expanded: false
  }),
  methods: {
    exportGeoJson () {
      this.$axios
        .get(`layer-poi/geo-json`, {
          params: { layerId: this.layer.id },
          timeout: 600000
        })
        .then(({ data }) => {
          let geojson = JSON.stringify(data)
          let url = window.URL.createObjectURL(new Blob([geojson]))
          let link = document.createElement('a')
          link.href = url
          let name = this.layer.name || 'layer-poi'
          link.download = `${name}.geojson`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
    },
    remove () {
      this.dialog = false
      this.$emit('remove', this.layer)
    },
    refreshMap () {
      EventBus.$emit('refreshMap')
    },
    changeVisibility (layer) {
      layer.visible = !layer.visible
      this.refreshMap()
    },
    showLayer () {
      this.dialog = false
      this.$refs.adViewDialog.open(this.layer)
    }
  },
  computed: {
    hideLayer () {
      return this.hideNonpublic
        && this.layer.categoryList.every(category => !category.isPublic)
        && this.layer.type === 'LAYER_POI'
    }
  }
}
</script>
