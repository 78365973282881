<template>
  <v-hover v-slot:default="{ hover }"
           :elevation="hover ? 6 : 0"
           open-delay="200">
    <v-card class="my-3" outlined>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text v-html="text"/>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'HighlightCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
