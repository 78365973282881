<script>
function getDocument (url) {
  return import('pdfjs-dist/webpack').then(pdfjs => pdfjs.getDocument(url))
}

function getPages (pdf, first, last) {
  let size = last - first + 1
  const allPages = range(size, first).map(number => pdf.getPage(number))
  return Promise.all(allPages)
}

function range (size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt)
}

const BUFFER_LENGTH = 1000

function getDefaults () {
  return {
    pages: [],
    cursor: 0
  }
}

export default {
  name: 'PdfData',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data () {
    return Object.assign(getDefaults(), {
      pdf: undefined
    })
  },
  watch: {
    url: {
      handler (url) {
        if (!url) this.pdf = undefined
        getDocument(url)
          .then(pdf => (this.pdf = pdf))
          .catch(response => {
            this.$emit('document-errored', { text: 'Failed to retrieve PDF', response })
          })
      },
      immediate: true
    },

    pdf (pdf, oldPdf) {
      if (!pdf) return
      if (oldPdf) Object.assign(this, getDefaults())

      this.$emit('page-count', this.pageCount)
      this.fetchPages()
    }
  },
  computed: {
    pageCount () {
      return this.pdf ? this.pdf.numPages : 0
    }
  },
  methods: {
    fetchPages (currentPage = 0) {
      if (!this.pdf) return
      if (this.pageCount > 0 && this.pages.length === this.pageCount) return

      const startIndex = this.pages.length
      if (this.cursor > startIndex) return

      const startPage = startIndex + 1
      const endPage = Math.min(Math.max(currentPage, startIndex + BUFFER_LENGTH), this.pageCount)
      this.cursor = endPage

      getPages(this.pdf, startPage, endPage)
        .then((pages) => {
          const deleteCount = 0
          this.pages.splice(startIndex, deleteCount, ...pages)
          this.$emit('document-rendered')
          return this.pages
        })
        .catch((response) => {
          this.$emit('document-errored', { text: 'Failed to retrieve pages', response })
        })
    },
    onPageRendered ({ text, page }) {},
    onPageErrored ({ text, response, page }) {},
    // TODO: call on fetch
    cleanup () {
      if (this.pages) {
        for (let page of this.pages) {
          page.cleanup()
        }
      }
    }
  },
  created () {
    this.$on('page-rendered', this.onPageRendered)
    this.$on('page-errored', this.onPageErrored)
    this.$on('pages-fetch', this.fetchPages)
  },
  render (h) {
    return h('div', [
      this.$scopedSlots.preview({
        pages: this.pages
      }),
      this.$scopedSlots.document({
        pages: this.pages
      })
    ])
  },
  beforeDestroy () {
    this.cleanup()
  }
}
</script>
