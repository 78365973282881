import Vue from 'vue'
import { getNameOfLayerPoi } from '@/components/layer-poi/layer-poi-service'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import { VSelect } from 'vuetify/lib'

export function componentFromString (componentString) {
  let { template, data, props, methods, computed, mounted } = JSON.parse(componentString || '{}')

  for (let i in methods) {
    methods[i] = new Function(methods[i].args, methods[i].body)
  }

  for (let i in computed) {
    computed[i] = new Function(computed[i].args, computed[i].body)
  }

  return Vue.component('PrintComponent', {
    name: 'PrintComponent',
    template,
    data: eval(data),
    props: ['value', 'printData', ...props],
    components: { VSelect },
    mixins: [mapMeasureMixin],
    methods: { ...methods, getNameOfLayerPoi },
    computed: computed,
    mounted: new Function(mounted.args, mounted.body),
  })
}
