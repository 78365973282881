<template>
  <span>
  <v-tooltip top v-if="ad.status">
    <template v-slot:activator="{ on }">
      <v-chip
        v-on="on"
        class="mr-2"
        small
        :color="color"
      />
    </template>
    <span>
      {{ $t(`adStatus.${ad.status}`) }}
    </span>
  </v-tooltip>
    </span>
</template>

<script>
import messages from '@/componet-locale/ad-status-icon/messages'

export default {
  name: 'AdStatusIcon',
  i18n: { messages: messages },
  props: {
    ad: Object
  },
  computed: {
    color () {
      switch (this.ad.status) {
        case 'DATA_RECEIVED':
          return 'yellow darken-3'
        case 'CHECKED':
          return 'green darken-3'
        case 'NEEDS_IMPROVEMENT':
          return 'red darken-2'
        case 'NO_DATA':
          return 'grey'
        default:
          return 'grey'
      }
    }
  }
}
</script>
