export const issueMixin = {
  data: () => ({
    statusList: [
      // TODO get rid of ids after Issue.status (see TODO) will be fixed
      {
        id: 0,
        key: 'BACKLOG',
        color: 'grey'
      },
      {
        id: 1,
        key: 'OPEN',
        color: 'purple'
      },
      {
        id: 2,
        key: 'IN_PROGRESS',
        color: 'blue'
      },
      {
        id: 3,
        key: 'DONE',
        color: 'green'
      }
    ]
  }),
  methods: {
    getStatus (value) {
      return this.statusList.find(it => it.key === value)
    }
  }
}
