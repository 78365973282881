<template>
  <v-dialog v-if="dialog" v-model="dialog" width="900">
    <v-card>
      <v-card-text class="pa-0">
        <v-row>

          <v-col lg="4">
            <v-card-text>
              <abstract-data-edit-form :item="item">
                <v-text-field
                  label="IP Address"
                  v-model="item.ipAddress"
                  dense
                  outlined
                />
              </abstract-data-edit-form>
            </v-card-text>
          </v-col>

          <v-col md="8" style="height: calc(100vh - 200px); overflow: auto;">
            <v-card-text>
              <modbus-field v-for="field in item.fields" :value="field"/>
              <v-btn @click="addField">Добавить</v-btn>
            </v-card-text>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog=false" outlined text>{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm'
import ModbusField from '@/components/modbus/ModbusField'

const EMPTY_FIELD = {
  name: '',
  dataType: 'WORD',
  minValue: 0,
  maxValue: 0,
  color: 'FF0000',
  address: ''
}

const EMPTY_ITEM = {
  name: '',
  fields: [{ ...EMPTY_FIELD }]
}

export default {
  name: 'ModbusUnitDialog',
  components: { ModbusField, AbstractDataEditForm },
  data: () => ({
    dialog: false,
    item: null
  }),
  methods: {
    open (item = { ...EMPTY_ITEM }) {
      this.dialog = true
      this.item = item
    },
    save () {
      this.item.project = this.selectedProject
      this.$axios
        .post('modbus-unit/save', this.item)
        .then(() => this.dialog = false)
    },
    addField () {
      this.item.fields.push({ ...EMPTY_FIELD })
    }
  }
}
</script>
