var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"height":"90vh"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('objectsSelected'))+": "+_vm._s(_vm.selectedPoisCount)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticClass:"overflow-y-auto py-0",staticStyle:{"height":"calc( 90vh - 114px )"}},[_vm._l((_vm.lpsGroupedByLayers),function(layer){return _c('div',{key:layer.id},[_c('v-data-table',{staticClass:"mb-3",staticStyle:{"border":"1px solid black"},attrs:{"footer-props":{
            'items-per-page-text': '',
            'items-per-page-options': [10, 25, 50]
          },"items":layer.lps,"item-key":"id"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center py-2 pl-3 title"},[_c('v-simple-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"value":layer.lps.every(function (el) { return el.selected; })},on:{"input":function($event){return _vm.layerChangeSelected(layer)}}}),_c('div',{staticClass:"ml-2 d-flex full-width"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(layer.layerName)+": "+_vm._s(_vm.getPoisCountByLayer(layer)))])])],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.openLayerPoiDialog(item)}}},[_c('td',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('layer-poi-badge',{attrs:{"style-id":item.styleId,"template":_vm.getTemplateById(item.templateId)}}),_c('span',[_vm._v(" "+_vm._s(item.name || ("[" + (item.id) + "]"))+" ")])],1)]),_c('td',{staticStyle:{"width":"28px"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectPoi(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)])]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_c('span',[_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" / "+_vm._s(itemsLength)+" ")])]}}],null,true)})],1)}),_vm._l((_vm.geoserverFeaturesGroupedByLayers),function(layer){return (layer.features.length)?_c('div',[_c('v-data-table',{staticClass:"mb-3",staticStyle:{"border":"1px solid black"},attrs:{"footer-props":{
            'items-per-page-text': '',
            'items-per-page-options': [10, 25, 50]
          },"items":layer.features,"item-key":"id"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center py-2 pl-3 title"},[_c('v-simple-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"value":layer.features.every(function (el) { return el.selected; })},on:{"input":function($event){return _vm.layerChangeSelected(layer)}}}),_c('div',{staticClass:"ml-2 d-flex full-width"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(layer.name)+": "+_vm._s(_vm.getPoisCountByLayer(layer)))])])],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.openGeoserverFeatureDialog(item,layer)}}},[_c('td',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',[_vm._v(" "+_vm._s(item.id)+" ")])])]),_c('td',{staticStyle:{"width":"28px"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectPoi(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)])]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_c('span',[_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" / "+_vm._s(itemsLength)+" ")])]}}],null,true)})],1):_vm._e()}),_vm._l((_vm.zwsFeaturesGroupedByLayers),function(layer){return (layer.features.length)?_c('div',[_c('v-data-table',{staticClass:"mb-3",staticStyle:{"border":"1px solid black"},attrs:{"footer-props":{
            'items-per-page-text': '',
            'items-per-page-options': [10, 25, 50]
          },"items":layer.features,"item-key":"id"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center py-2 pl-3 title"},[_c('v-simple-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"value":layer.features.every(function (el) { return el.selected; })},on:{"input":function($event){return _vm.layerChangeSelected(layer)}}}),_c('div',{staticClass:"ml-2 d-flex full-width"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(layer.name)+": "+_vm._s(_vm.getPoisCountByLayer(layer))+" ")])])],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{staticClass:"pointer",on:{"click":function($event){return _vm.openZwsFeatureDialog(item)}}},[_c('td',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',[_vm._v(" "+_vm._s(item.elemId)+" ")])])]),_c('td',{staticStyle:{"width":"28px"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectPoi(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)])]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_c('span',[_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" / "+_vm._s(itemsLength)+" ")])]}}],null,true)})],1):_vm._e()})],2),_c('v-card-actions',{staticClass:"px-4 elevation-2"},[_c('v-btn',{attrs:{"disabled":_vm.selectedPoisCount === 0,"loading":_vm.removeLoading,"color":"red","outlined":""},on:{"click":function($event){_vm.$refs.deleteFeaturesConfirmationDialog.open({name: _vm.$t('deleteObjects')})}}},[_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":_vm.selectedLpsCount === 0 || _vm.selectedGeoserverFeaturesCount !== 0,"color":"primary","outlined":""},on:{"click":_vm.openLpsExportDialog}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t('button.export'))+" ")],1),_c('v-spacer'),(_vm.splitScreenMode)?_c('v-btn',{attrs:{"disabled":!_vm.selectedZwsFeaturesCount,"color":"primary","outlined":""},on:{"click":_vm.applyExternalTableFilter}},[_vm._v(" "+_vm._s(_vm.$t('openTable'))+" ")]):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.selectedLpsCount === 0 || _vm.selectedGeoserverFeaturesCount !== 0,"color":"primary","outlined":""},on:{"click":_vm.edit}},[_vm._v(" "+_vm._s(_vm.$t('button.edit'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.selectedPoisCount === 0,"outlined":""},on:{"click":_vm.openPrintTemplateSelectorDialog}},[_vm._v(" "+_vm._s(_vm.$t('button.print'))+" ")])],1)],1),_c('layer-poi-dialog',{ref:"layerPoiDialog",attrs:{"actions-toolbar":false,"map-widget":false,"readonly-mode":true}}),_c('wms-feature-dialog',{ref:"geoserverFeatureDialog"}),_c('confirmation-dialog',{ref:"deleteFeaturesConfirmationDialog",attrs:{"title":_vm.$t('doYouReallyWant')},on:{"confirm":_vm.remove}}),_c('lps-export-dialog',{ref:"lpsExportDialog"}),_c('zws-feature-dialog',{ref:"zwsFeatureDialog"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }