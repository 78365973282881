<template>
  <v-container>
    <v-layout :style="{marginBottom: displayXS?'56px':'0px'}" align-center justify-center wrap>
      <v-flex lg10 md12 sm12 xl8 xs12>
        <v-card class="elevation-0">
          <v-card-title class="px-0" primary-title>
            <v-text-field
              v-model="searchField"
              :placeholder="$t('searchFieldPlaceholder')"
              clearable
              hide-details
              prepend-inner-icon="search"
              solo
            />
            <v-spacer/>
            <flexible-button
              v-if="setAbility('ADMIN')"
              :label="$t('createProject')"
              icon="add"
              @click="$refs.projectViewDialog.open()"/>
          </v-card-title>
        </v-card>

        <div v-if="list.length && !afterLoginLoading">
          <v-hover
            v-for="item in list"
            :key="item.id"
            v-slot:default="{ hover }"
          >
            <v-card
              :elevation="hover ? 4 : 0"
              class="mt-3 pointer"
              color="grey lighten-5"
              outlined
              @click.native="select(item.userProject.project, 'home', $event)"
            >
              <v-card-title>
                <span v-html="highlight(item.userProject.project.name)"/>
                <v-spacer/>
                <v-btn v-show="!displayXS" icon @click="favoriteProject(item.userProject, $event)">
                  <v-icon v-if="item.userProject.favorite" color="yellow ">star</v-icon>
                  <v-icon v-else color="grey">star_border</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text v-if="item.userProject.project.description" class="pt-0">
                {{ item.userProject.project.description }}
              </v-card-text>

              <v-card-text class="pt-0 px-2">
                <v-tooltip color="black" top>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-if="item.numberOfWidgets"
                      class="ma-1"
                      label
                      outlined
                      @click="select(item.userProject.project, 'dashboard', $event)"
                      v-on="on"
                    >
                      <v-icon left>dashboard</v-icon>
                      {{ item.numberOfWidgets }}
                    </v-chip>
                  </template>
                  <span>{{ $t('dashboard') }}</span>
                </v-tooltip>
                <v-tooltip v-for="type in dataTypeList" color="black" top>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-show="item.numberOfDataByType[type.value]"
                      class="ma-1"
                      label
                      outlined
                      @click="select(item.userProject.project, type.link, $event)"
                      v-on="on"
                    >
                      <v-icon left>{{ type.icon }}</v-icon>
                      {{ item.numberOfDataByType[type.value] }}
                    </v-chip>
                  </template>
                  <span>{{ $t(type.title) }}</span>
                </v-tooltip>
              </v-card-text>
            </v-card>
          </v-hover>
        </div>
        <v-skeleton-loader
          v-else-if="afterLoginLoading"
          :loading="true"
          height="150px"
          type="card"
        />
        <div v-else-if="!list.length" class="heading pl-2">
          {{ $t('noAvailableProjects') }}
        </div>
      </v-flex>
    </v-layout>
    <project-view-dialog ref="projectViewDialog" @on-save="getProjects()"/>
  </v-container>
</template>

<script>
import FlexibleButton from './utils/FlexibleButton'
import { mapActions } from 'vuex'
import ProjectViewDialog from './project/ProjectViewDialog'
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '@/componet-locale/home/messages'
import projectMessages from '@/shared-locale/project/messages'

export default {
  name: 'Home',
  components: { ProjectViewDialog, FlexibleButton },
  mixins: [displayServiceMixin],
  i18n: {
    messages,
    sharedMessages: projectMessages
  },
  props: {
    afterLogin: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchField: '',
    projectDetailsList: [],
    afterLoginLoading: true
  }),
  methods: {
    ...mapActions([
      'selectProject'
    ]),
    select (project, path, event = null) {
      this.$axios.get('project/get', {
        params: {
          id: project.id
        }
      })
        .then(res => {
          this.selectProject({ project: res.data, path })
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      if (event) event.stopPropagation()
    },
    favoriteProject (userProject, event) {
      let url = userProject.favorite ? 'project/remove-from-favorites' : 'project/add-to-favorites'
      userProject.favorite = !userProject.favorite
      this.$axios
        .post(url, null, { params: { id: userProject.id } })
        .then(() => EventBus.$emit('changeFavoriteProject'))
        .catch(() => {
          EventBus('showErrorMessage', 'error')
          userProject.favorite = !userProject.favorite
        })

      event.stopPropagation()
    },
    async getProjects () {
      return this.$axios
        .get('project/get-project-list')
        .then(response => {
          this.projectDetailsList = response.data
        })
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    },
    background (name) {
      let hash = 0
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return {
        'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))`
      }
    }
  },
  async mounted () {
    await this.getProjects()
    if (!this.afterLogin) {
      return this.afterLoginLoading = false
    }
    this.$axios.get('settings/get-general')
      .then(res => res.data)
      .then(settings => {
        const defaultProject = this.projectDetailsList.find(el => el.userProject.project.id === +settings.DEFAULT_PROJECT_ID)
        if (defaultProject) {
          this.select(defaultProject.userProject.project, 'home')
        } else if (this.projectDetailsList.length === 1) {
          this.select(this.projectDetailsList[0].userProject.project, 'home')
        } else {
          this.$router.replace('/')
        }
      })
      .finally(() => this.afterLoginLoading = false)
  },
  computed: {
    list () {
      let filteredProjectDetailsList
      if (!this.searchField) filteredProjectDetailsList = this.projectDetailsList
      filteredProjectDetailsList = this.projectDetailsList
        .filter(item =>
          item.userProject.project.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
      return filteredProjectDetailsList.sort((a, b) => {
        return a.userProject.project.name.localeCompare(b.userProject.project.name)
      })
    }
  }
}
</script>
