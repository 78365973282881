<template>
  <v-dialog v-if="dialog" v-model="dialog" width="700">
    <v-card>
      <v-card-title>
        {{ $t('etToLayerCardTitle') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-auto">
        <p>
          {{ $t('relationDescription') }}
        </p>
        <p>
          {{ $t('relationExplanation') }}
        </p>
        <div v-if="items.length">
          <vuedraggable v-model="items" handle=".handle">
            <et-to-layer-entry
              v-for="(item, index) in items"
              :et="externalTable"
              :index="index"
              :item="item"
              @remove="deleteEntry"
            />
          </vuedraggable>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" outlined @click="addEntry">{{ $t('addEntry') }}</v-btn>
        <v-btn color="primary" @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EtToLayerEntry from '@/components/et/EtToLayerEntry.vue'
import Vuedraggable from 'vuedraggable'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-to-layer-dialog/messages'

export default {
  name: 'EtToLayerDialog',
  components: { Vuedraggable, EtToLayerEntry },
  i18n: { messages },
  props: {
    externalTable: Object
  },
  data: () => ({
    dialog: false,
    items: []
  }),
  methods: {
    open () {
      this.getListOfEtToLayerByEtId()
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    getListOfEtToLayerByEtId () {
      this.$axios
        .get('/et/get-related-layers', { params: { etId: this.externalTable.id } })
        .then(response => this.items = response.data)
    },
    save () {
      this.$axios
        .post('/layer/save-link-to-et', this.items.map((item, index) => ({ ...item, index })))
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.getListOfEtToLayerByEtId()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    deleteEntry (index) {
      this.$axios.delete('/layer/remove-link-to-et-by-id', {
        params: {
          configId: this.items[index].id
        }
      })
        .then(() => {
          this.getListOfEtToLayerByEtId()
          EventBus.$emit('showSuccessMessage', this.$t('dataRemoved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    addEntry () {
      this.items.push({
        layer: null,
        lpTemplate: null,
        lpSearchField: null,
        searchField: null,
        readonly: true,
        header: null,
        externalTable: this.externalTable
      })
    }
  }
}

</script>
