<template>
  <transition name="slide">
    <div v-if="display"  class="bottom-sheet-overlay">
      <v-card :style="{height: fullscreen ? '100%' : minHeight + 'px'}" class="bottom-sheet-card">
        <div class="bottom-sheet-header" ref="header">
          <div @click="changeFullscreen" class="d-flex justify-center">
            <v-icon :style="{transform: fullscreen ? 'rotate(180deg)' : null}" large>mdi-menu-up</v-icon>
          </div>
          <div class="d-flex justify-space-between">
            <div>
              <slot name="header"/>
            </div>
            <v-icon class="pr-2" style="align-self: flex-start" @click="close" >close</v-icon>
          </div>
        </div>
        <transition name="fade">
          <div v-if="fullscreen" >
            <div :style="{maxHeight: `calc(100dvh - ${minHeight}px - ${footerHeight}px)`}" class="bottom-sheet-content">
              <slot name="content"/>
            </div>
            <div ref="footer" class="bottom-sheet-footer">
              <slot name="footer" />
            </div>
          </div>
        </transition>
      </v-card>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DBottomSheet',
  data: () => ({
    display: false,
    fullscreen: false,
    minHeight: 150,
    footerHeight: 60
  }),
  methods: {
    init () {
      this.minHeight = this.$refs.header.clientHeight
    },
    open () {
      this.display = true
      this.$nextTick(() => {
        this.init()
      })
    },
    changeFullscreen () {
      this.fullscreen = !this.fullscreen
      this.$nextTick(()=>{
        if(this.fullscreen){
          this.footerHeight = this.$refs.footer.clientHeight
        }
      })
    },
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    this.open()
  }
}
</script>

<style scoped>
.bottom-sheet-overlay{
  position: absolute;
  top: env(safe-area-inset-top) !important;
  left: 0;
  bottom: env(safe-area-inset-bottom) !important;
  right: 0;
  z-index: 201;
  pointer-events: none;
  overflow: hidden;
}
.bottom-sheet-card{
  position: fixed;
  width: 100%;
  bottom: env(safe-area-inset-bottom) !important;
  background-color: white;
  z-index: 201;
  pointer-events: all;
  transition: height .3s ease;
}
.bottom-sheet-content{
  overflow-y: scroll;
}
.bottom-sheet-footer{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}
.slide-enter-active {
  transition: all .5s ease;
}
.slide-leave-active {
  transition: all .5s ease-out;
}
.slide-enter, .slide-leave-to {
  transform: translateY(100%);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
