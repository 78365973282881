<template>
  <v-dialog v-model="dialog" max-width="850" persistent scrollable>
    <v-card style="overflow: hidden">
      <v-card-title style="z-index: 1000; box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2)">
        <div class="pl-2">
          Изменение страницы
        </div>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-content class="pa-0 ma-0 overflow-y-auto" style="height: calc(100vh - 112px)">
        <v-text-field v-model="page.title" class="ma-3" label="Page title" outlined/>
        <v-textarea v-model="componentForm.template" auto-grow class="ma-3" label="Template" outlined/>
        <v-textarea v-model="componentForm.props" auto-grow class="ma-3" label="Props" outlined/>
        <v-textarea v-model="componentForm.data" auto-grow class="ma-3" label="Data" outlined/>
        <v-textarea v-model="componentForm.methods" auto-grow class="ma-3" label="Methods" outlined/>
        <v-textarea v-model="componentForm.computed" auto-grow class="ma-3" label="Computed" outlined/>
        <v-textarea v-model="componentForm.mounted" auto-grow class="ma-3" label="Mounted" outlined/>
      </v-content>
      <v-card-actions class="px-6 elevation-2" style="z-index: 1000;">
        <v-spacer/>
        <v-btn
          color="primary"
          outlined
          @click="save"
        >
          {{ $t('button.next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: 'PrintTemplatePageEditorDialog',
  data: () => ({
    dialog: false,
    page: {
      title: ''
    },
    componentForm: {
      template: '',
      props: '',
      data: '',
      methods: '',
      computed: '',
      mounted: ''
    }
  }),
  methods: {
    init () {
      const component = JSON.parse(this.page.template || '{}')
      this.componentForm.template = component.template
      this.componentForm.props = JSON.stringify(component.props, null, 4)
      this.componentForm.data = component.data
      this.componentForm.methods = JSON.stringify(component.methods, null, 4)
      this.componentForm.computed = JSON.stringify(component.computed, null, 4)
      this.componentForm.mounted = JSON.stringify(component.mounted, null, 4)
    },
    open (page) {
      this.dialog = true
      this.page = { ...page }
      this.init()
    },
    close () {
      this.dialog = false
    },
    save () {
      try {
        const page = {
          title: this.page.title,
          pageNumber: this.page.pageNumber,
          template: JSON.stringify({
            template: this.componentForm.template,
            props: JSON.parse(this.componentForm.props),
            data: this.componentForm.data,
            methods: JSON.parse(this.componentForm.methods),
            computed: JSON.parse(this.componentForm.computed),
            mounted: JSON.parse(this.componentForm.mounted)
          })
        }
        this.$emit('pageUpdated', page)
        this.close()
      } catch (e) {
        EventBus.$emit('showErrorMessage', 'Ошибка компиляции страницы')
      }
    }
  }
}
</script>

<style scoped>

</style>
