<template>
  <div v-if="!header.excludeFromForm" class="d-flex flex-row flex-nowrap">
    <div class="flex-grow-1">
      <component
        :is="getItemComponent()"
        :editable="editable"
        :emptyInitialValue="emptyInitialValue"
        :header="header"
        :shortMode="shortMode"
        :tableId="tableId"
        :value="value"
        @update:value="val => {$emit('update:value', val)}"
      />
    </div>
    <div v-if="relations
      && relations.length
      && !shortMode
      && hasToOneRelation()"
    >
      <v-menu bottom left offset-x offset-y>
        <template v-slot:activator="{on}">
          <v-btn :disabled="!value" icon @click="getRelationBody" v-on="on">
            <v-icon>
              mdi-database-search
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list max-width="500">
            <v-list-item
              v-for="etInfoItem in etInfoItems"
              dense
              @click="openEtItemDialog(etInfoItem)"
            >
              <v-list-item-content class="ellipsis-text">
                {{ getEtInfoItemName(etInfoItem) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <et-item-dialog
      ref="etItemDialog"
      :short-mode="true"
    />
  </div>
</template>

<script>
import HelperValuesEtItem from '@/components/et/utils/HelperValuesEtItem.vue'
import ListEtItem from '@/components/et/utils/ListEtItem.vue'
import BooleanEtItem from '@/components/et/utils/BooleanEtItem.vue'
import DateEtItem from '@/components/et/utils/DateEtItem.vue'
import NumberEtItem from '@/components/et/utils/NumberEtItem.vue'
import StringEtItem from '@/components/et/utils/StringEtItem.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-item/messages'

export default {
  name: 'EtItem',
  components: { EtItemDialog: () => import('@/components/et/EtItemDialog.vue') },
  model: {
    props: 'value',
    event: 'update:value'
  },
  i18n: { messages },
  props: {
    value: {
      type: null,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    tableId: {
      type: Number,
      required: true
    },
    shortMode: {
      type: Boolean,
      default: false
    },
    relations: {
      type: Array,
      default: () => []
    },
    emptyInitialValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    etInfoItems: []
  }),
  methods: {
    getItemComponent () {
      if (this.header.updateFromParentTable) {
        return HelperValuesEtItem
      }
      switch (this.header.fieldType) {
        case 'LIST':
          return ListEtItem
        case 'BOOLEAN':
          return BooleanEtItem
        case 'DATE':
          return DateEtItem
        case 'NUMBER':
          return NumberEtItem
        default:
          return StringEtItem
      }
    },
    hasToOneRelation () {
      return this.relations
        .filter(rel => rel.mainHeader.id === this.header.id)
        .some(rel => rel.type === 'TO_ONE')
    },
    getRelationBody () {
      this.$axios.get('et/select-for-one-related-row', {
        params: {
          headerId: this.header.id,
          value: this.value
        }
      })
        .then(res => {
          const itemsCount = res.data.reduce((acc, etInfoItem) => acc + etInfoItem.body.length, 0)
          if (itemsCount === 0) {
            EventBus.$emit('showInfoMessage', this.$t('noRelatedInfo'))
          } else if (itemsCount === 1) {
            this.openEtItemDialog(res.data[0])
          } else {
            this.etInfoItems = res.data.filter(el => el.body.length)
          }
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    openEtItemDialog (etInfoItem) {
      this.$axios.get('data/get', {
        params: {
          id: etInfoItem.tableId
        }
      })
        .then(res => {
          this.$refs.etItemDialog.open(res.data, etInfoItem.body[0])
        })
    },
    getEtInfoItemName (etInfoItem) {
      const name = etInfoItem.headers
        .reduce((acc, header) => {
          return acc.replace(`[${header.alias}]`, etInfoItem.body[0][header.alias])
        }, etInfoItem.rowNameConstructor)
      return name || etInfoItem.tableName
    }
  }
}
</script>

<style scoped>
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block
}
</style>
