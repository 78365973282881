<template>
  <abstract-data-list
    ref="abstractDataList"
    type="ET"
    view="database"
  >
    <template slot="actions">
      <v-btn
        @click="createNewItem"
        color="primary"
        outlined
        v-if="setAbility('SYSTEM_ADMIN')">
        {{ $t('button.create') }}
      </v-btn>
    </template>
    <et-create-dialog @save="onSave" ref="etEditDlg"/>
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '@/components/utils/AbstractDataList.vue'
import EtCreateDialog from '@/components/et/EtCreateDialog.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'EtList',
  components: { EtCreateDialog, AbstractDataList },
  mounted() {
    EventBus.$on('etCopySaved', this.onSave)
  },
  methods: {
    createNewItem () {
      this.$refs.etEditDlg.open()
    },
    onSave () {
      this.$refs.abstractDataList.filterData()
      this.$refs.abstractDataList.hideDialog()
    }
  }
}
</script>
