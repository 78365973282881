<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title class="px-3">
        Пользователи по компаниям
      </v-card-title>
      <v-card-text class="pt-0 pb-2 px-3">
        <div v-for="(listOfUser, company, index) in filteredUsersByCompany">
          {{ ++index }}. {{ company }} - {{ listOfUser.length }}
        </div>
        <div v-if="usersWithoutCompany && usersWithoutCompany.length > 0">
          {{ companiesCount + 1 }}. Другие пользователи - {{ usersWithoutCompany.length }}
        </div>
        <div class="mt-1 font-weight-bold">
          Всего: {{ usersCount }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">
          {{ $t('button.back') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UsersByCompanyDialog',
  props: ['usersByCompany'],
  data: () => ({
    dialog: false
  }),
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  },
  computed: {
    usersWithoutCompany () {
      return this.usersByCompany['null']
    },
    filteredUsersByCompany () {
      const usersByCompany = JSON.parse(JSON.stringify(this.usersByCompany))
      delete usersByCompany['null']
      return usersByCompany
    },
    usersCount () {
      let usersCount = 0
      for (let company in this.usersByCompany) {
        usersCount += this.usersByCompany[company].length
      }
      return usersCount
    },
    companiesCount () {
      let companiesCount = 0
      for (let company in this.usersByCompany) {
        if (company !== 'null') companiesCount += 1
      }
      return companiesCount
    }
  }
}
</script>
