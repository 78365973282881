<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="600"
  >
    <issues-card
      :issues="issues"
      :loading="loading"
      @close="close"
      @createIssue="createIssue"
    />
    <new-issue-dialog
      v-if="isAuthenticated"
      ref="newIssueDialog"
      :map-widget="false"
      @on-save-issue="onSaveIssue"
    />
  </v-dialog>
</template>

<script>

import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '@/componet-locale/layer-poi-issues-dialog/messages'
import IssuesDialog from '@/components/issue/IssuesCard.vue'
import IssuesCard from '@/components/issue/IssuesCard.vue'
import { EventBus } from '@/event-bus'

export default {
  name: "LayerPoiIssuesDialog",
  components: { IssuesCard, IssuesDialog, UserAvatar, NewIssueDialog },
  data: () => ({
    poi: {},
    dialog: false,
    issues: [],
    loading: false
  }),
  i18n: { messages },
  mixins: [issueMixin],
  methods: {
    init (id) {
      this.loading = true
      this.$axios
        .get('issue/get-by-lp-id', {
          params: { lpId: id }
        })
        .then(res => {
          this.issues = res.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    },
    open (poi) {
      this.poi = { ...poi }
      this.dialog = true
      this.init(this.poi.id)
    },
    close () {
      this.dialog = false
    },
    createIssue () {
      this.$refs.newIssueDialog.createLayerPoiIssue(this.poi)
    },
    onSaveIssue () {
      this.$emit('on-save-issue')
      this.init(this.poi.id)
    }
  }
}
</script>
