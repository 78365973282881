<template>
  <v-select
    v-model="model.value"
    :items="items"
    :placeholder="label"
    class="mb-2"
    dense
    hide-details
    outlined
    @change="$emit('change')"
  >
    <template v-slot:selection="{ item }">
      <v-icon class="position-absolute" color="black">{{ item.icon }}</v-icon>
      <span class="black--text ml-2">{{ $t(item.text) }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-icon class="position-absolute">{{ item.icon }}</v-icon>
      <span class="ml-2">{{ $t(item.text) }}</span>
    </template>
  </v-select>
</template>

<script>
import messages from '../../componet-locale/sorting/messages'

export default {
  name: 'Sorting',
  props: {
    model: {
      type: Object,
      default: {}
    },
    label: {
      type: String,
      default: ''
    }
  },
  i18n: { messages: messages },
  data: () => ({
    items: [
      {
        text: 'byDate',
        icon: 'keyboard_arrow_up',
        value: {
          sortBy: 'creationDate',
          desc: 'ASC'
        }
      },
      {
        text: 'byDate',
        icon: 'keyboard_arrow_down',
        value: {
          sortBy: 'creationDate',
          desc: 'DESC'
        }
      },
      {
        text: 'byName',
        icon: 'mdi-sort-alphabetical-ascending',
        value: {
          sortBy: 'name',
          desc: 'ASC'
        }
      },
      {
        text: 'byName',
        icon: 'mdi-sort-alphabetical-descending',
        value: {
          sortBy: 'name',
          desc: 'DESC'
        }
      }
    ]
  })
}
</script>
