var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticStyle:{"z-index":"100000"},attrs:{"scrollable":"","width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"800"}},[_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.usersByCompany),function(ref){
var company = ref.company;
var userList = ref.userList;
return _c('div',[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(company ? company : _vm.$t('others')))]),_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(userList.length)+" ")]),_c('v-layout',{staticClass:"mb-3",attrs:{"row":"","wrap":""}},_vm._l((userList),function(user){return _c('v-flex',{key:user.id,attrs:{"sm6":"","xs12":""}},[_c('user-card',{staticClass:"ma-2",attrs:{"user":user,"chat-view":""},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"delete",fn:function(){return [(_vm.chat.type === 'PRIVATE' && _vm.currentLoginUser.id !== user.id)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.callConfirmation(user)}}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()]},proxy:true},(_vm.chat.ownerID === user.id)?{key:"chat-owner",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('chatCreator'))+" ")]},proxy:true}:null],null,true)})],1)}),1)],1)}),0),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.back'))+" ")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":""}},on),[_vm._v(" "+_vm._s(_vm.$t('invite'))+" ")])]}}]),model:{value:(_vm.inviteDialog),callback:function ($$v) {_vm.inviteDialog=$$v},expression:"inviteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('inviteToChat'))+" ")]),_c('v-card-text',{staticClass:"px-2"},[_c('v-autocomplete',{staticStyle:{"z-index":"10000 !important"},attrs:{"item-text":function (item) { return (((item.firstName) + " " + (item.lastName))); },"items":_vm.newMembersList,"placeholder":_vm.$t('inviteToChat'),"search-input":_vm.newMembersSearch,"chips":"","clearable":"","deletable-chips":"","dense":"","hide-details":"","hide-no-data":"","multiple":"","outlined":"","return-object":""},on:{"update:searchInput":function($event){_vm.newMembersSearch=$event},"update:search-input":function($event){_vm.newMembersSearch=$event},"focus":function($event){return _vm.find('')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [[(index === 0)?_c('v-chip',{attrs:{"label":""}},[_c('user-avatar',{attrs:{"user":item,"small":""}}),_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" + "+_vm._s(_vm.selectedNewMembers.length - 1)+" ")]):_vm._e()]]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('user-avatar',{attrs:{"user":item,"dense":""}})],1),_c('v-list-item-content',{staticStyle:{"z-index":"100000 !important"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getUserFullName(item))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getUserInfo(item))}})],1)]}}]),model:{value:(_vm.selectedNewMembers),callback:function ($$v) {_vm.selectedNewMembers=$$v},expression:"selectedNewMembers"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.inviteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.back'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.selectedNewMembers.length === 0,"color":"primary","outlined":""},on:{"click":_vm.inviteMembers}},[_vm._v(" "+_vm._s(_vm.$t('invite'))+" ")])],1)],1)],1)],1)],1)],1),_c('chat-confirmation-dialog',{ref:"confirmation",on:{"confirm":_vm.remove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }