import throttle from 'lodash/throttle'

function inserted (el, binding) {
  const callback = binding.value
  if (binding.modifiers.immediate) {
    callback()
  }
  const throttledScroll = throttle(callback, 300)
  window.addEventListener('resize', throttledScroll, true)
}
// TODO: fix
// window.removeEventListener('resize', this.throttledOnResize, true)
export default {
  inserted
}
