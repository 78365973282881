<template>
  <et-viewer
    ref="etViewer"
    :et="data"
  />
</template>


<script>
import EtViewer from '@/components/et/EtViewer.vue'

export default {
  components: { EtViewer },
  props: {
    data: Object
  }
}
</script>
