export default {
  primary: '#3949AB',
  'primary-bar': '#2e2f35',
  'primary-bar-text': '#ffffff',
  secondary: '#b0bec5',
  'secondary-bar': '#38393f',
  'secondary-bar-text': '#e0e0e0',
  accent: '#6A1B9A',
  error: '#b71c1c',
  red: '#f44336',
  pink: '#e91e63',
  purple: '#9c27b0',
  deepPurple: '#673ab7',
  indigo: '#3f51b5',
  blue: '#2196f3',
  lightBlue: '#03a9f4',
  cyan: '#00bcd4',
  teal: '#009688',
  green: '#4caf50',
  lightGreen: '#8bc34a',
  lime: '#cddc39',
  yellow: '#ffeb3b',
  amber:  '#ffc107',
  orange: '#ff9800',
  deepOrange: '#ff5722',
  brown:  '#795548',
  blueGrey:  '#607d8b',
  grey: {
    base: '#9e9e9e',
    lighten5: '#fafafa',
    lighten4: '#f5f5f5',
    lighten3: '#eeeeee',
    lighten2: '#e0e0e0',
    lighten1: '#bdbdbd',
    darken1: '#757575',
    darken2: '#616161',
    darken3: '#424242',
    darken4: '#212121'
  },
  shades: {
    black: '#000000', white: '#ffffff', transparent: 'transparent'
  }
}
