<template>
  <v-card-actions class="pa-0">
    <template
      v-for="item in actions"
    >
      <v-spacer v-if="item.spacer"/>
      <v-tooltip
        v-else
        v-show="item.userRole ? setAbility(item.userRole) : true"
        :bottom="bottom"
        :disabled="item.primary"
        :top="!bottom"
        color="black"
      >
        <template v-slot:activator="{ on }">
          <div
            :class="`d-action-bar-btn ${item.primary ? '' : 'mr-2'}`"
            :style="{background: item.background, border: item.border}"
            @click="$emit(item.action, $event)"
            v-on="on"
          >
            <v-badge
              :content="counters.get(item.title)"
              :value="counters.get(item.title) > 0"
              bordered
              color="primary"
            >
              <v-progress-circular
                v-if="item.isLoading"
                :color="item.color"
                indeterminate
                size="24"
              />
              <v-icon v-else :color="item.color">
                {{ item.icon }}
              </v-icon>
              <span
                v-if="item.primary"
                class="mx-1"
              >
                {{ $t(item.title) }}
              </span>
            </v-badge>
          </div>
        </template>
        <span>
          {{ $t(item.title) }}
        </span>
      </v-tooltip>
    </template>
  </v-card-actions>
</template>

<script>
import messages from '@/componet-locale/feature-card/messages'

export default {
  name: 'ActionToolbar',
  props: {
    actions: Array,
    bottom: false,
    counters: {
      type: Map,
      default () {
        return new Map()
      }
    }
  },
  i18n: { messages }
}
</script>

<style scoped>
.d-action-bar-btn {
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-action-bar-btn:hover {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}
</style>
