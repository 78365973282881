<template>
  <v-card class="d-flex flex-column" style="max-height: 90vh">
    <v-card-title>
      {{ $t("tasks") }}
    </v-card-title>
    <v-card-text class="pa-0">
      <div v-if="!loading">
        <v-list v-if="issues && issues.length!==0" dense subheader>
          <template v-for="issue in issues">
            <v-list-item :key="issue.id" @click="() => goToTask(issue.id)">
              <v-list-item-avatar>
                <v-avatar :color="getStatus(issue.status).color" class="mx-3" size="20"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ issue.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getUserFullName(issue.reporter) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
                {{ formatDate(issue.creationDate) }}
              </v-list-item-action-text>
            </v-list-item>
          </template>
        </v-list>
        <v-card
          v-else
          class="mx-2 mb-2"
          outlined
        >
          <v-card-text>
            {{ $t('noTasksForTheObject') }}
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-skeleton-loader
          :loading="loading"
          class="px-3"
          type="text@2"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="black"
        outlined
        @click="close"
      >
        {{ $t("cancel") }}
      </v-btn>
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="createIssue"
      >
        {{ $t("create") }}
        <v-icon right>create</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '@/componet-locale/layer-poi-issues-dialog/messages'

export default {
  name: "IssuesCard",
  components: { UserAvatar, NewIssueDialog },
  props: {
    issues: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  i18n: { messages },
  mixins: [issueMixin],
  methods: {
    close () {
      this.$emit('close')
    },
    createIssue () {
      this.$emit('createIssue')
    },
    goToTask (isId) {
      this.$router.push(`/project/${this.selectedProject.id}/issue/${isId}`)
    }
  }
}
</script>
