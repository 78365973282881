<template>
  <v-runtime-template :template="'<div>' + template + '</div>'" class="report-body-viewer"/>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'
import mammoth from 'mammoth'

export default {
  name: "ReportBodyViewer",
  props: ['body'],
  data: () => ({
    template: ''
  }),
  components: { VRuntimeTemplate },
  mounted(){
    this.updateBody()
  },
  methods: {
    updateBody () {
      const blob = new Blob([this.body])
      let reader = new FileReader()
      let self = this
      reader.onload = () => {
        let arrayBuffer = reader.result
        mammoth.convertToHtml({ arrayBuffer })
          .then(result => {
            self.template = result.value
          })
          .done()
      }
      reader.readAsArrayBuffer(blob)
      // todo insert ad-report-widget
    }
  },
  watch: {
    body () {
      this.updateBody()
    }
  }
}
</script>

<style>
.report-body-viewer {
  margin: 40px
}

.report-body-viewer img {
  max-width: 100%;
}
</style>
