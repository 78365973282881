<template>
  <ad-widget-container
    :title="data.name"
    @clickOnTitle="e => openInNewTab(data, e)"
  >
    <data-table :table="table" widget/>
  </ad-widget-container>
</template>

<script>
import DataTable from '../utils/DataTable'
import { EventBus } from '@/event-bus'
import AdWidgetContainer from '@/components/widget/AdWidgetContainer.vue'

export default {
  name: 'TableWidget',
  components: { AdWidgetContainer, DataTable },
  props: {
    widget: {
      type: Object,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: {},
    data: {}
  }),
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.$axios
        .get('data/get', { params: { id: this.widget.abstractData.id } })
        .then(response => {
          this.data = response.data
          this.loadTable(response.data.id)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    async loadTable (id) {
      this.$axios
        .get('/table/get-table/' + id)
        .then(response => {
          this.table = JSON.parse(response.data.table)
          this.table.object = this.data
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  }
}
</script>
