<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-card class="d-flex flex-column" max-height="90vh">
      <v-card-title>
        {{ $t('uploadReportTemplate') }}
      </v-card-title>
      <v-card-text class="overflow-y-auto flex-grow-1">
        <file-pond
          v-if="!item"
          ref="pond"
          :label-idle="$t('pressOrDragForLoadFile')"
          :server="myServer"
        />
        <div v-else>
          <abstract-data-edit-form
            v-if="tab === 'edit'"
            :item="item"
            class="pa-3"
            validate-on-create
          />
          <div v-if="tab === 'placeholders'">
            <report-placeholder-item
              v-for="(placeholder, i) in item.firstVersion.placeholders"
              :key="i"
              :placeholder="placeholder"
            />
            <div v-if="!item.firstVersion.placeholders || !item.firstVersion.placeholders.length">
              {{$t('noPlaceholdersFound')}}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <div v-if="item">
          <v-btn v-if="tab === 'placeholders'" color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
          <v-btn
            v-if="tab === 'edit'"
            color="primary"
            outlined
            @click="tab='placeholders'"
          >
            {{ $t('button.next') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm.vue'
import messages from '@/componet-locale/report-view/messages'
import sharedMessages from '@/componet-locale/doc-upload-dialog/messages'
import ReportPlaceholderItem from '@/components/report/ReportPlaceholderItem.vue'

export default {
  name: "ReportTemplateUploadDialog",
  components: { ReportPlaceholderItem, AbstractDataEditForm },
  i18n: { messages, sharedMessages },
  data () {
    return {
      dialog: false,
      item: null,
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      },
      fileTemplate: null,
      tab: 'edit'
    }
  },
  methods: {
    open () {
      this.item = null
      this.fileTemplate = null
      this.tab = 'edit'
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      let projectId = this.selectedProject.id
      this.$axios.post('report/save-for-project/' + projectId, this.item, { timeout: 120000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataSaved')))
        .catch(reason => {
          console.error(reason.data.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('onSave')
          this.dialog = false
        })
    },
    getPlaceholders () {
      this.$axios.get('report/get-file-placeholders', {
        params: {
          filePath: this.item.filePath,
          projectId: this.selectedProject.id
        }
      })
        .then(res => this.item.firstVersion.placeholders = res.data)
    },
    handleFileUpload (file, load) {
      let item = {}
      let tempFile = file
      let reader = new FileReader()
      let fileExt = tempFile.name.split('.').pop().toLowerCase()
      item.name = tempFile.name.replace(/\.[^/.]+$/, '')
      if (fileExt !== 'docx') {
        this.item = null
        this.fileTemplate = null
        return console.error("Unsupported file type")
      }
      reader.readAsArrayBuffer(tempFile)
      item.firstVersion = {}
      this.saveFile(load, tempFile, item)
    },
    saveFile (load, tempFile, item) {
      let formData = new FormData()
      formData.append('file', tempFile)
      this.$axios
        .post('/files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          item.filePath = response.data
          item.firstVersion.fileName = tempFile.name
          load()
          this.item = item
          this.getPlaceholders()
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(e)
        })
    },
  }
}
</script>
