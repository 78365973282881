<template>
      <router-view/>
</template>

<script>

export default {
  name: 'ListOfTablesByLP'
}
</script>
