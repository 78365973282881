<template>
  <v-card flat v-if="template">

    <vue-editor v-model="template.value"/>

    <v-switch
      v-model="template.disabled"
      :label="$t('disabled')"/>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="save">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import messages from '@/componet-locale/mail-template-editor/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'MailTemplateEditor',
  i18n: { messages: messages },
  props: ['type'],
  components: { VueEditor },
  data: () => ({
    template: null
  }),
  methods: {
    loadTemplate () {
      if (!this.type) return

      this.$axios
        .get('mail-template/get', {
          params: { type: this.type }
        })
        .then(response => this.template = response.data)
    },
    save () {
      if (!this.template) return

      this.$axios
        .post('mail-template/save', this.template)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('saveSuccessMessage'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('saveErrorMessage'))
        })
    }
  },
  watch: {
    type () {
      this.loadTemplate()
    }
  }
}
</script>
