<template>
  <v-navigation-drawer
    v-if="dialog"
    v-model="dialog"
    absolute
    height="100vh"
    right
    style="z-index: 1000"
    temporary
    width="80%"
  >
    <div>
      <div class="project-expanded">
        <div id="project-data-bar" class="secondary-bar">
          <v-card-title style="height: 79px; color: var(--v-secondary-bar-text-base)">
            {{ $t('knowledgeBase') }}
            <v-spacer/>
            <v-btn color="secondary-bar-text" icon @click="create">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider/>

          <v-treeview
            id="article-tree"
            :active.sync="active"
            :items="rootArticles"
            activatable
            color="secondary-bar-text"
            dense
            hoverable
            item-key="node.id"
            item-text="node.currentTitle"
            style="color: var(--v-secondary-bar-text-base)"
            transition
            @update:active="onUpdate"
          />
        </div>
      </div>

      <div style="padding-left: 300px; width: 100%">
        <div class="pa-3">
          <v-card-actions v-if="article.id">
            <v-spacer/>
            <v-btn v-if="setAbility('ADMIN')"
                   color="red"
                   outlined
                   @click="remove">
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              outlined
              @click="getAllVersions">
              <v-icon>history</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              outlined
              @click="openParentArticleDialog">
              <v-icon>link</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              outlined
              @click="edit">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-card-actions>
          <div v-if="historyMode">
            <v-data-table :headers="headers"
                          :items="historyItems"
                          class="elevation-1"
                          disable-sort
                          hide-default-footer>
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center"><a @click="showVersion(item)">{{ item.version }}</a></td>
                  <td class="text-center">{{ item.title }}</td>
                  <td class="text-center">{{ formatDate(item.updatedAt) }}</td>
                  <td class="text-center">{{ fullName(item.updatedBy) }}</td>
                  <td class="text-center">
                    <a @click="compare(item)">{{ $t('compare') }}</a>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <div v-show="editMode">
              <v-container>
                <v-text-field
                  v-model="article.title"
                  :label="$t('header')"
                  outlined
                />
                <v-text-field
                  v-model="article.indexNumber"
                  :label="$t('index')"
                  outlined
                  type="number"
                />
                <editor ref="editor"
                        height="600px"
                        initialEditType="wysiwyg"/>
              </v-container>
              <v-btn outlined style="float: right" @click="save">{{ $t('button.save') }}</v-btn>
            </div>
            <div v-show="!editMode" class="ml-3">
              <div v-if="article.id">
                <h1>{{ article.title }}</h1>
                <div class="text--secondary caption"> {{ $t('createdBy') }}: {{ author }}, {{ createdAt }}</div>
                <div class="text--secondary caption mb-5"> {{ $t('updatedBy') }}: {{ updatedBy }},{{
                    updatedAt
                  }}
                </div>
              </div>
              <div id="article" v-html="article.content"/>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="diffDialog" width="1000">
        <v-card>
          <v-card-text v-html="articlesDiff"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="primary"
              outlined
              @click="diffDialog=false">
              {{ $t('button.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="chooseParentArticleDialog" width="300">
        <v-card>
          <v-card-text>
            <v-autocomplete
              v-model="parentArticle"
              :items="articles"
              :label="$t('selectArticle')"
              :search-input.sync="search"
              hide-no-data
              item-text="title"
              return-object>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn outlined @click="setParentArticle">{{ $t('button.save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-navigation-drawer>
</template>

<script>

import { Editor } from '@toast-ui/vue-editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import { visualDomDiff } from 'visual-dom-diff'
import moment from 'moment'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/article-editor/messages'

export default {
  components: { Editor },
  name: 'ArticleEditor',
  data: () => ({
    dialog: false,
    parentArticle: undefined,
    article: {
      content: ''
    },
    currentVersion: null,
    rootArticles: [],
    articles: [],
    active: [],
    editMode: false,
    chooseParentArticleDialog: false,
    panelExpanded: true,
    search: '',
    articlesDiff: '',
    historyItems: [],
    historyMode: false,
    diffDialog: false
  }),
  i18n: { messages: messages },
  watch: {
    search (val) {
      if (val) {
        this.searchArticles(val)
      }
    }
  },
  computed: {
    author () {
      if (this.article.article.author) {
        return this.fullName(this.article.article.author)
      }
    },
    updatedBy () {
      if (this.article.updatedBy) {
        return this.fullName(this.article.updatedBy)
      }
    },
    headers () {
      return [
        {
          text: this.$t('version'),
          align: 'center'
        },
        {
          text: this.$t('name'),
          align: 'center'
        },
        {
          text: this.$t('publicationDate'),
          align: 'center'
        },
        {
          text: this.$t('publishedBy'),
          align: 'center'
        }
      ]
    },
    createdAt () {
      if (this.article) {
        return this.formatDate(this.article.article.createdAt)
      }
    },
    updatedAt () {
      if (this.article) {
        return this.formatDate(this.article.updatedAt)
      }
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    fullName (item) {
      const { firstName, lastName } = item
      return `${firstName} ${lastName}`
    },
    async compare (item) {
      let first = await this.loadVersion(item)
      this.showDiff(first, this.currentVersion)
    },
    createNewArticle () {
      this.historyMode = false
      this.article.content = this.getHtml()
      this.$axios
        .post('article/save', this.article)
        .then(response => {
          this.article = response.data
          this.editMode = false
          this.active[0] = response.data.article.id // TODO doesn't work
          this.getArticles()
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'savingErro'))
    },
    emptyArticle () {
      return {
        content: ''
      }
    },
    async loadVersion (item) {
      let result = await this.$axios.get(`article/get-article-version/${item.id}`)
      return result.data
    },
    async showVersion (item) {
      this.article = await this.loadVersion(item)
      this.historyMode = false
    },
    async getArticle (id) {
      this.editMode = false
      this.historyMode = false
      let requestedArticle = await this.getArticleById(id)
      if (requestedArticle) this.article = requestedArticle
    },
    getArticles () {
      this.$axios
        .get('article/get-articles-tree')
        .then(response => this.rootArticles = response.data)
    },
    searchArticles (title) {
      this.$axios
        .get('article/get-unrelated-articles', {
          params: {
            title: title,
            node: this.article.article.id
          }
        })
        .then(response => {
          this.articles = response.data
        })
    },
    edit () {
      this.editMode = true
      this.setHtml()
    },
    save () {
      if (this.article.id) {
        this.addNewVersion()
      } else {
        this.createNewArticle()
      }
    },
    addNewVersion () {
      this.article.content = this.getHtml()
      this.article.id = undefined
      this.$axios
        .post(`article/add-new-version/${this.article.article.id}`, this.article)
        .then(response => {
          this.article = response.data
          this.editMode = false
          this.getArticles()
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'savingError'))
    },
    setParentArticle () {
      this.chooseParentArticleDialog = false
      const parent = this.parentArticle ? this.parentArticle.article.id : null
      const child = this.article.article.id
      this.$axios
        .get('article/set-parent', {
          params: {
            parentId: parent,
            childId: child
          }
        })
        .then(() => {
          this.getArticles()
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'savingError'))
    },
    create () {
      this.article = this.emptyArticle()
      this.setHtml()
      this.editMode = true
      this.parentArticle = null
    },
    getHtml () {
      return this.$refs.editor.invoke('getHtml')
    },
    setHtml () {
      this.$refs.editor.invoke('setHtml', this.article.content)
    },
    getAllVersions () {
      this.currentVersion = { ...this.article }
      this.$axios
        .get(`article/get-all-versions/${this.article.article.id}`)
        .then(response => {
          this.historyItems = response.data
          this.historyMode = true
        })
    },
    async onUpdate (activeIds) {
      const activeId = activeIds[0] ? String(activeIds[0]) : null
      if (activeId !== null) {
        this.article = await this.getArticleById(activeId)
        this.editMode = false
        this.historyMode = false
      }
    },
    showDiff (first, second) {
      const parser = new DOMParser()
      let originalNode = parser.parseFromString(first.content, 'text/html')
      let changedNode = parser.parseFromString(second.content, 'text/html')
      let diff = visualDomDiff(originalNode, changedNode)
      this.articlesDiff = new XMLSerializer().serializeToString(diff)
      this.diffDialog = true
    },
    async getArticleById (id) {
      let response = await this.$axios.get(`article/get-last-version-by-article/${id}`)
      return response.data
    },
    openParentArticleDialog () {
      this.chooseParentArticleDialog = true
      this.searchArticles('')
      this.parentArticle = null
    },
    remove () {
      this.$axios
        .post(`article/delete/${this.article.article.id}`)
        .then(() => this.getArticles())
        .catch(() => EventBus.$emit('showErrorMessage', 'deletionError'))
      this.article = this.emptyArticle()
    }
  },
  mounted () {
    this.getArticles()
  }
}
</script>

<style>
#article >>> table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

#article >>> th {
  background-color: #7b8184;
  color: #ffffff;
}

#article >>> table {
  border-collapse: collapse;
}

#article >>> th, td {
  padding: 0 5px 5px;
}

#article-tree .v-icon {
  color: var(--v-secondary-bar-text-base);
}

</style>
