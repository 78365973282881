<template>
  <abstract-data-list
    ref="abstractDataList"
    type="DOC"
    view="doc-list"
  >
    <template slot="actions">
      <v-btn
        @click="createNewItem"
        color="primary"
        outlined
        v-if="setAbility('MODERATOR')">
        {{ $t('button.create') }}
      </v-btn>
      <doc-upload-dialog
        @onSave="onSave"
        ref="docUploadDlg"
      />
    </template>
  </abstract-data-list>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'
import DocUploadDialog from '@/components/doc/DocUploadDialog'

export default {
  name: 'DocList',
  components: { DocUploadDialog, AbstractDataList },
  methods: {
    onSave () {
      this.$refs.abstractDataList.filterData()
      this.$refs.abstractDataList.hideDialog()
    },
    createNewItem () {
      this.$refs.docUploadDlg.open()
    }
  }
}
</script>
