<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex lg4 md6 sm8 xl3 xs12>
        <v-form
          lazy-validation
          ref="form">
          <v-card outlined v-if="resetLog">
            <v-card-title>
              <v-toolbar-title>
                {{ $t('newPassword') }}
              </v-toolbar-title>
            </v-card-title>
            <v-card-text class="pt-0">
              <p>
                {{ resetLog.user.firstName + ' ' + resetLog.user.lastName }},
                {{ $t('hint') }}
              </p>
              <v-text-field :rules="passwordRules" @keyup.enter="submitData"
                            id="password"
                            :label="$t('password')"
                            name="password"
                            prepend-icon="lock"
                            required
                            type="password"
                            v-model="password">
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="submitData"
                     color="primary"
                     outlined>
                {{ $t('next') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import { EventBus } from '../event-bus'
import messages from '../componet-locale/new-password/messages'

export default {
  name: 'NewPassword',
  props: ['token'],
  data: () => ({
    resetLog: null,
    password: '',
    passwordRules: [
      v => !!v || 'Пароль должен быть заполнен'
    ],
    dialog: false
  }),
  i18n: { messages: messages },
  mounted () {
    if (this.token) {
      axios
        .get('reset-password/get-log', { params: { token: this.token } })
        .then(response => {
          this.resetLog = response.data
        })
        .catch(reason => {
          this.$router.push('/reset-password')
          EventBus.$emit('showErrorMessage', reason.response.data.message)
        })
    }
  },
  methods: {
    submitData () {
      if (this.$refs.form.validate()) {
        axios
          .post('/reset-password/set-password', { token: this.token, password: this.password })
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('passwordSet'))
            this.$router.push('/login')
          })
          .catch(reason => {
            console.error(reason)
            EventBus.$emit('showErrorMessage', reason.data)
          })
      }
    }
  }
}
</script>
