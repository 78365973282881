var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"max-height":"100vh"},attrs:{"footer-props":{itemsPerPageOptions: [15,50,100]},"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.busy,"options":_vm.options,"server-items-length":_vm.totalElements,"item-key":"id","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userEntity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userEntity.firstName + ' ' + item.userEntity.lastName)+" ")]}},{key:"item.abstractData",fn:function(ref){
var item = ref.item;
return [(item.abstractData)?_c('a',{attrs:{"href":("/project/" + (item.abstractData.project.id) + "/" + (item.abstractData.dataType.toLowerCase()) + "/" + (item.abstractData.id)),"target":"_blank"},on:{"click":function($event){return _vm.openData(item.abstractData)}}},[_vm._v(" "+_vm._s(item.abstractData.name)+" ")]):_vm._e()]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.abstractData.categoryList.filter(function (it) { return !it.isDefault; })),function(category){return _c('v-chip',{staticClass:"ma-1",attrs:{"color":"deep-purple","label":"","outlined":"","small":""},on:{"click":function($event){return _vm.goToCategory(category)}}},[_vm._v(" "+_vm._s(category.name)+" ")])})}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.activityType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.activityType))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }