<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent>
    <v-card>
      <v-card-title
        class="headline lighten-2"
        primary-title>
        {{ $t('version') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        {{ doc.name }}
      </v-card-subtitle>

      <v-card-text>
        <file-uploader v-if="dialog && !docVersion.id" @handle="handleFileUpload"/>

        <v-text-field
          dense
          outlined
          :label="$t('name')"
          v-model="docVersion.name"/>

        <v-textarea
          dense
          counter="255"
          rows="3"
          outlined
          :label="$t('description')"
          v-model="docVersion.description"/>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog=false"
          outlined text>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          @click="save"
          :loading="loading"
          outlined primary>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mammoth from 'mammoth'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/update-doc-dialog/messages'
import FileUploader from '@/components/utils/FileUploader'

const EMPTY_DOC_VERSION = {
  name: '',
  description: '',
  value: undefined,
  fileName: '',
  type: 'OTHER'
}

export default {
  name: 'DocVersionDialog',
  i18n: { messages: messages },
  props: ['doc'],
  components: { FileUploader },
  data: () => ({
    dialog: false,
    loading: false,
    docVersion: EMPTY_DOC_VERSION
  }),
  methods: {
    open (docVersion = null) {
      this.docVersion = docVersion || { ...EMPTY_DOC_VERSION }
      this.dialog = true
    },
    save () {
      let isNewDocVersion = !this.docVersion.id
      this.docVersion.doc = this.doc
      let url = isNewDocVersion ? 'docs/add-doc-version' : 'docs/update-doc-version'
      this.$axios
        .post(url, this.docVersion, { timeout: 120000 })
        .then(response => {
          if (isNewDocVersion) this.$emit('newVersionSaved', response.data)
        })
        .catch((error) =>
          console.error(error)
        )
        .finally(() =>
          this.dialog = false
        )
    },
    handleFileUpload (file, load) {
      this.loading = true
      let type = 'OTHER'
      let reader = new FileReader()
      let self = this
      let fileExt = file.name.split('.').pop().toLowerCase()
      this.docVersion.name = file.name.replace(/\.[^/.]+$/, '')
      if (fileExt === 'pdf') {
        type = 'PDF'
      }
      if (fileExt === 'docx') {
        this.isFileConverted = false
        type = 'MS_DOC'
        reader.onload = () => {
          let arrayBuffer = reader.result
          mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
            .then(result => {
              self.docVersion.value = result.value
            })
            .catch(() => EventBus.$emit('showErrorMessage', 'readingError'))
            .done()
        }
      }
      reader.readAsArrayBuffer(file)
      this.docVersion.type = type
      this.saveFile(file, load)
    },
    saveFile (file, load) {
      let formData = new FormData()
      formData.append('file', file)
      this.$axios
        .post('files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          this.docVersion.filePath = response.data
          this.docVersion.fileName = file.name
          this.loading = false
          load()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    }
  }
}
</script>
