<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="600"
  >
    <issues-card
      :issues="issues"
      :loading="loading"
      @close="close"
      @createIssue="createIssue"
    />
    <new-issue-dialog
      v-if="isAuthenticated"
      ref="newIssueDialog"
      :map-widget="false"
      @on-save-issue="onSaveIssue"
    />
  </v-dialog>
</template>

<script>

import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '@/componet-locale/layer-poi-issues-dialog/messages'
import IssuesCard from '@/components/issue/IssuesCard.vue'
import { EventBus } from '@/event-bus'

export default {
  name: "ZwsFeatureIssuesDialog",
  components: { IssuesCard, UserAvatar, NewIssueDialog },
  data: () => ({
    dialog: false,
    issues: [],
    loading: false
  }),
  i18n: { messages },
  mixins: [issueMixin],
  methods: {
    init () {
      this.loading = true
      this.$axios
        .get('issue/get-by-feature-sys', {
          params: {
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(res => {
          this.issues = res.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    },
    open (feature) {
      this.feature = feature
      this.init()
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    createIssue () {
      let poi = {
        name: this.name,
        sys: this.sys,
        layer: this.feature.layer
      }
      this.$refs.newIssueDialog.open(poi)
    },
    onSaveIssue () {
      this.$emit('on-save-issue')
      this.init()
    }
  },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      } else {
        return this.feature.layer.name
      }
    },
    sys () {
      let key = this.feature.props.find(prop => prop.isKey)
      if (key && key.value) {
        return key.value
      }
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    }
  }
}
</script>
