<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden">
      <v-card-text class="pa-0">
        <v-row>
          <v-col lg="4">
            <v-card-text>
              <abstract-data-edit-form ref="dataEditForm" :item="item"/>
            </v-card-text>
          </v-col>
          <v-col class="overflow-auto" md="8" style="height: calc(100vh - 200px)">
            <et-query-editor
              @save="() => {close();$emit('save')}"
              ref="etQueryEditor"
              :item="item"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn outlined @click="() => $refs.advicesDialog.open()">
          Помощь
        </v-btn>
        <v-btn outlined @click="showQueryExample">
          {{ $t('showQuery') }}
        </v-btn>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <et-advices-dialog ref="advicesDialog"/>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm.vue'
import EtHeaderEditor from '@/components/et/EtHeaderEditor.vue'
import messages from '@/componet-locale/et-edit/messages'
import SelectQueryPreview from '@/components/et/SelectQueryPreview.vue'
import EtQueryEditor from '@/components/et/EtQueryEditor.vue'
import EtAdvicesDialog from "@/components/et/EtAdvicesDialog.vue";

export default {
  name: 'EtCreateDialog',
  components: { EtAdvicesDialog, EtQueryEditor, SelectQueryPreview, EtHeaderEditor, AbstractDataEditForm },
  i18n: { messages },
  data: () => ({
    dialog: false,
    item: null
  }),
  methods: {
    open (item = { headers: [] }) {
      this.item = item
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      if (this.isFormValid()) this.$refs.etQueryEditor.save()
    },
    isFormValid () {
      return this.$refs.dataEditForm.validateForm()
    },
    showQueryExample () {
      this.$refs.etQueryEditor.showQueryExample()
    }
  }
}

</script>
