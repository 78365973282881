<template>
  <div>
    <v-select
      v-if="feature.mode"
      v-model="feature.mode"
      :items="feature.modeList"
      :readonly="!isAuthenticated"
      append-icon=""
      class="simple-input px-3 mb-2"
      dense
      hide-details
      item-text="title"
      return-object
      @change="updateState">
      <template v-slot:selection="{ item }">
        <img :src="item.image" class="mr-2" width="18"/>
        {{ item.title }}
      </template>

      <template v-slot:item="{ item }">
        <img :src="item.image" class="mr-2" width="18"/>
        {{ item.title }}
      </template>
    </v-select>

    <action-toolbar
      :actions="actions"
      :counters="counters"
      class="ma-3"
      @attachData="openAttachments"
      @openGeometry="$refs.geometryDialog.open(feature.geometry)"
      @openIssues="openIssues"
      @print="$emit('print')"
      @remove="$refs.confirmationDialog.open(feature)"
      @share="share"
    />

    <confirmation-dialog
      ref="confirmationDialog"
      :title="`Вы действительно хотите удалить объект ${name}`"
      @confirm="remove"
    />
    <share-data-dialog ref="shareDataDialog"/>
    <zws-feature-issues-dialog ref="issuesDialog"/>
    <zws-feature-attached-data-dialog ref="attachedDataDialog"/>
    <geometry-dialog ref="geometryDialog"/>

  </div>
</template>

<script>
import ActionToolbar from '@/components/utils/ActionToolbar'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import NewIssueDialog from '@/components/issue/NewIssueDialog'
import ZwsFeatureIssuesDialog from '@/components/issue/ZwsFeatureIssuesDialog.vue'
import ZwsFeatureAttachedDataDialog from '@/components/map/zws/ZwsFeatureAttachedDataDialog.vue'
import { ATTACHED_DATA, OPEN_ISSUES } from '@/components/map/helpers/map-actions'
import GeometryDialog from '@/components/map/helpers/GeometryDialog.vue'

export default {
  name: 'ZwsActionToolbar',
  components: {
    GeometryDialog,
    ZwsFeatureAttachedDataDialog,
    ZwsFeatureIssuesDialog,
    NewIssueDialog,
    ShareDataDialog,
    ConfirmationDialog,
    ActionToolbar
  },
  props: {
    feature: Object,
    name: String,
    actions: Array
  },
  data: () => ({
    featureMetadata: null
  }),
  mounted () {
    this.getFeatureMetadata()
  },
  methods: {
    share () {
      let url = window.location + `?layerId=${this.feature.layer.id}&sys=${this.sys}`
      this.$refs.shareDataDialog.share(url)
    },
    openIssues () {
      this.$refs.issuesDialog.open(this.feature)
    },
    openAttachments () {
      this.$refs.attachedDataDialog.open(this.feature)
    },
    getFeatureMetadata () {
      if (this.feature.props) {
        if (!this.sys || !this.isAuthenticated) return
        this.$axios
          .get('/layer/get-feature-metadata', {
            params: {
              featureSys: this.sys,
              layerId: this.feature.layer.id
            }
          })
          .then(res => this.featureMetadata = res.data)
      }
    },
    async remove () {
      await ZwsCommandBuilder.deleteFeature(this.feature, this.sys)
      EventBus.$emit('refreshMap')
      this.$emit('close')
    },
    async updateState () {
      await ZwsCommandBuilder.updateState(this.feature, this.sys)
      EventBus.$emit('refreshMap')
    }
  },
  computed: {
    sys () {
      let key = this.feature.props.find(prop => prop.isKey)
      if (key && key.value) {
        return key.value
      }
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    },
    counters () {
      const counters = new Map()
      counters.set(OPEN_ISSUES.title, this.featureMetadata ? this.featureMetadata.issueCount : 0)
      counters.set(ATTACHED_DATA.title, this.featureMetadata ? this.featureMetadata.attachmentsCount : 0)
      return counters
    }
  }
}
</script>
