import axios from 'axios'
import { GeoJSON, GML, WFS } from 'ol/format'
import { Feature } from 'ol'

async function getFeaturesByBbox (bbox, layers) {
  const requests = layers.map(
    layer => axios.get(`${window.location.origin}/geoserver/wfs`, {
      params: {
        service: 'wfs',
        version: '2.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeNames: layer.layerId,
        bbox: bbox.join(',')
      }
    })
      .then(res => ({
        ...layer,
        features: res.data.features
      }))
  )
  return Promise.all(requests)
}

async function removeFeatures (features, layer) {
  const formatWFS = new WFS()
  const geoJsonReader = new GeoJSON()
  const gml = new GML({
    featureNS: layer._ds.targetNamespace,
    featurePrefix: layer._ds.targetPrefix,
    featureType: layer._ds.typeName,
    srsName: 'EPSG:3857'
  })
  const featuresExtendsByOl = features.map(f => {
    return f instanceof Feature
      ? f
      : geoJsonReader.readFeature(f)
  })
  let node = formatWFS.writeTransaction(null, null, featuresExtendsByOl, gml)
  let s = new XMLSerializer()
  let value = s.serializeToString(node)
  return axios
    .post(window.location.origin + '/geoserver/wfs', value, {
      headers: {
        'Content-Type': 'text/xml'
      },
      params: {
        service: 'wfs',
        outputFormat: ''
      }
    })
}

export default {
  getFeaturesByBbox,
  removeFeatures
}
