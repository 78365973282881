<template>
  <div>
    <v-card-title>
      {{ $t("objectTemplates") }}
      <v-spacer/>
      <v-btn
        v-if="selected.length"
        class="mr-2"
        color="light-gray"
        outlined
        @click="exportTemplates"
      >
        <v-icon class="mr-2">mdi-export</v-icon>
        {{ $t('button.export') }}
      </v-btn>
      <v-btn
        class="mr-2"
        color="light-gray"
        outlined
        @click="importTemplates"
      >
        <v-icon class="mr-2">mdi-import</v-icon>
        {{ $t('import') }}
      </v-btn>
      <v-btn
        color="primary"
        outlined
        @click="create"
      >
        <v-icon>add</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-card-title>
    <v-list>
      <v-text-field
        v-model="searchField"
        :placeholder="$t('searchObject')"
        class="mx-4 mb-2"
        clearable
        hide-details
        prepend-inner-icon="search"
        solo
      />
      <v-list-item
        v-for="(item, index) in filteredTemplateList"
        :key="index"
        @click=""
      >
        <v-list-item-action class="mr-0">
          <v-checkbox v-model="item.selected" dense mr-3/>
        </v-list-item-action>
        <v-list-item-content @click="open(item)">
          <v-row align="center" class="px-6">
            <span v-html="highlight(item.name)"></span>
            <v-spacer/>
            <v-icon large>{{ getIcon(item.graphType) }}</v-icon>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <poi-template-dialog
      ref="poiTemplateDialog"
      @onSave="loadData"
    />
    <import-templates-dialog
      ref="importTemplatesDialog"
      @imported="loadData"
    />
  </div>
</template>

<script>
import PoiTemplateDialog from '@/components/admin/poi-template-management/PoiTemplateDialog'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/poi-template-management/messages'
import sharedMessages from '@/componet-locale/poi-styles/messages'
import ImportTemplatesDialog from '@/components/admin/poi-template-management/ImportTemplatesDialog.vue'

export default {
  name: 'PoiTemplateManagement',
  components: { ImportTemplatesDialog, PoiTemplateDialog },
  data: () => ({
    searchField: '',
    templateList: []
  }),
  mounted () {
    this.loadData()
  },
  i18n: { messages, sharedMessages },
  methods: {
    getIcon (type) {
      switch (type) {
        case 'POINT':
          return 'mdi-map-marker-outline'
        case 'POLYGON':
          return 'mdi-pentagon-outline'
        case 'LINE_STRING':
          return 'mdi-chart-timeline-variant'
        case 'MULTI_POLYGON':
          return 'mdi-vector-polygon'
        case 'MULTI_LINE_STRING':
          return 'mdi-chart-multiline'
        default:
          return ''
      }
    },
    loadData () {
      this.$axios
        .get('layer-poi-template')
        .then(response => {
          this.templateList = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('errorLoadingData')))
    },
    open (item) {
      this.$refs.poiTemplateDialog.open(item)
    },
    create () {
      this.$refs.poiTemplateDialog.open()
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    },
    exportTemplates () {
      this.$axios.post('layer-poi-template/export', this.selected.map(it => it.id))
        .then(res => {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([JSON.stringify(res.data)]))
          link.download = `${this.selected.map(it => it.id).join(' | ')}.json`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.templateList.forEach(it => it.selected = false)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    importTemplates () {
      this.$refs.importTemplatesDialog.open()
    }
  },
  computed: {
    filteredTemplateList () {
      if (!this.searchField) return this.templateList
      return this.templateList
        .filter(item =>
          item.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
    },
    selected () {
      return this.templateList.filter(el => el.selected)
    }
  }
}
</script>
