<template>
  <div>
    <div class="pb-2">
      <v-select
        v-if="baseLayers.length"
        v-model="selectedBaseLayers"
        :items="baseLayers"
        clearable
        dense
        hide-details
        item-text="name"
        label="Выберите слой-подложку"
        multiple
        outlined
        prepend-inner-icon="layers"
        return-object
      />
    </div>
    <div class="d-map-widget">
      <div style="width: 100%; height: 0; text-align: right">
        <v-btn
          class="mr-3 mt-3"
          fab
          small
          style="z-index: 20; padding: 10px"
          @click="dialog = true">
          <v-icon>fullscreen</v-icon>
        </v-btn>
      </div>
      <zws-feature-map-viewer
        :feature="feature"
        :selected-base-layers="selectedBaseLayers"
        :layer="layer"
      />
    </div>

    <v-dialog v-model="dialog" fullscreen>
      <div style="background: white; width: 100%; height: 100vh">
        <v-btn
          absolute
          fab
          right
          small
          style="top: 20px"
          @click="dialog=false"
        >
          <v-icon>fullscreen_exit</v-icon>
        </v-btn>
        <zws-feature-map-viewer
          :feature="feature"
          :selected-base-layers="selectedBaseLayers"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ZwsFeatureMapViewer from '@/components/map/zws/ZwsFeatureMapViewer.vue'

export default {
  name: "ZwsFeatureMapWidget",
  props: {
    feature: Object,
    layer: Object
  },
  components: { ZwsFeatureMapViewer },
  data: () => ({
    dialog: false,
    baseLayers: [],
    selectedBaseLayers: []
  }),
  created () {
    this.loadBaseLayers()
  },
  methods: {
    loadBaseLayers () {
      this.$axios
        .get('/layer/get-xyz-layers', {
          params: {
            projectId: this.selectedProject.id
          }
        })
        .then(response => {
          this.baseLayers = response.data
        })
    }
  }
}
</script>

<style>
.d-map-widget {
  width: 100%;
  height: 300px;
}

.d-map-widget .ol-viewport {
  border-radius: 8px
}
</style>
