<template>
  <Radar
    :chart-data="chartData"
    :chart-options="options"
    :height="height"
    :plugins="[legend]"
  />
</template>


<script>
import { Radar } from 'vue-chartjs/legacy'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip
} from 'chart.js'

ChartJS.register(LineElement, Title, RadialLinearScale, Tooltip, Legend, PointElement, CategoryScale, LinearScale)


export default {
  name: 'BarChart',
  components: { Radar },
  data: () => ({
    legend: {
      options: {
        position: 'bottom'
      }
    }
  }),
  props: ['options', 'chartData', 'height']
}
</script>
