<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <v-card>
      <v-list>
        <v-list-item v-for="item in items">
          <v-list-item-title>
            {{ item }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ListViewDialog',
  data: () => ({
    items: [],
    dialog: false
  }),
  methods: {
    open (data) {
      this.dialog = true
    }
  }
}
</script>
