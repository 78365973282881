<template>
  <v-dialog v-model="dialog" max-width="850" persistent scrollable>
    <v-card v-if="printTemplate" style="overflow: hidden">
      <v-card-title style="z-index: 1000; box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2)">
        <div class="pl-2">
          {{ printTemplate.name }}
        </div>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-content class="pa-0 ma-0 overflow-y-auto overflow-x-hidden" style="height: calc(100vh - 112px)">
        <print-template
          v-if="printTemplate"
          ref="printTemplate"
          :selectedTemplate="printTemplate"
          :templateProps="printTemplateProps"
        />
      </v-content>
      <v-card-actions class="px-6 elevation-2" style="z-index: 1000;">
        <v-spacer/>
        <v-btn
          :loading="printLoading"
          outlined
          @click="print"
        >{{ $t('button.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import PrintTemplate from '@/components/admin/print-template/PrintTemplate.vue'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/print-template/messages'

export default {
  name: 'PrintTemplateDialog',
  components: { VueFileToolbarMenu, PrintTemplate },
  i18n: { messages },
  data () {
    return {
      dialog: false,
      printTemplate: null,
      printTemplateProps: null,
      printLoading: false
    }
  },
  methods: {
    init (id) {
      this.dialog = true
      this.$axios.get('print-template/get-by-id', {
        params: { id }
      })
        .then(res => this.printTemplate = res.data)
    },
    open (id, templateProps = null) {
      if (templateProps) {
        this.printTemplateProps = templateProps
      }
      this.init(id)
    },
    close () {
      this.dialog = false
    },
    print () {
      const html = this.$refs.printTemplate.getTemplates()
      const poiIds = this.printTemplateProps.pois
        ? this.printTemplateProps.pois.map(el => el.id).filter(id => typeof id === 'number')
        : []
      const geoserverFeatureIds = this.printTemplateProps.pois
        ? this.printTemplateProps.pois.map(el => el.id).filter(id => typeof id === 'string')
        : []
      this.printLoading = true
      this.$axios.post('print-template/print', {
        printTemplateId: this.printTemplate.id,
        printInstanceHtml: html,
        poiIds,
        geoserverFeatureIds
      })
        .then(res => {
          EventBus.$emit('showSuccessMessage', this.$t('printRequestSaved'))
          this.$refs.printTemplate.print(res.data)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.printLoading = false)
    }
  }
}
</script>
