<template>
  <abstract-data-list ref="abstractDataList" type="MAP" view="map-list"/>
</template>

<script>
import AbstractDataList from '../utils/AbstractDataList'

export default {
  name: 'MapList',
  components: { AbstractDataList }
}
</script>
