<template>
  <vl-layer-vector-tile
    :z-index="zIndex"
  >
    <vl-source-vector-tile
      cross-origin="Anonymous"
      :url="layer.url"
    />
  </vl-layer-vector-tile>
</template>

<script>
export default {
  name: 'VectorTileLayer',
  props: { layer: Object, zIndex: Number }
}
</script>
