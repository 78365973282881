<template>
    <div>
        <v-card-title>{{ $t('objects') }}</v-card-title>
        <v-text-field
            v-model="searchField"
            prepend-inner-icon="search"
            solo
            clearable
            hide-details
            :placeholder="$t('objectSearch')"
            class="mx-4"
        />
        <div class="pa-4">
            <v-card
                    v-for="{layer, countByPoiTemplates} in filteredTemplateList"
                    @click="select(layer)"
                    class="mt-3"
                    outlined
                    hover
            >
                <v-card-title>
                    <span v-html="highlight(layer.name)"></span>
                    <v-spacer/>
                    <layer-poi-chip
                            v-for="template in layer.poiTemplates"
                            :template="template"
                            :count="countByPoiTemplates[template.id]"
                    />
                </v-card-title>
                <v-card-text
                        v-html="layer.description"
                        v-if="layer.description"
                />
            </v-card>
        </div>
    </div>
</template>

<script>

import LayerPoiChip from '@/components/layer-poi/LayerPoiChip.vue'
import messages from '@/componet-locale/poi-table/messages'

export default {
  name: 'PoiTable',
  components: { LayerPoiChip },
  i18n: { messages },
  methods: {
    select (layer) {
      this.$router.push({
        name: `layer-poi-table`,
        params: {
          id: layer.id
        }
      })
    },
    highlight (name) {
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    }
  },
  data: () => ({
    searchField: '',
    layerDetailsList: []
  }),
  created () {
    this.$axios
      .get('layer/get-layers-with-poi', {
        params: {
          projectId: this.selectedProject.id
        }
      })
      .then(response => this.layerDetailsList = response.data)
  },
  computed: {
    filteredTemplateList () {
      if (!this.searchField) return this.layerDetailsList
      return this.layerDetailsList
        .filter(item =>
          item.layer.name.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        )
    }
  }
}
</script>
