<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden" height="90vh">
      <v-card-text class="pa-0 overflow-auto" style="height: calc(90vh - 52px)">
        <v-card-text>
          <et-query-editor
            ref="etQueryEditor"
            :item="item"
            @save="$emit('save')"
          />
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn outlined @click="() => $refs.advicesDialog.open()">
          Помощь
        </v-btn>
        <v-btn outlined @click="showQueryExample">
          {{ $t('showQuery') }}
        </v-btn>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <et-advices-dialog ref="advicesDialog"/>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm.vue'
import EtHeaderEditor from '@/components/et/EtHeaderEditor.vue'
import messages from '@/componet-locale/et-edit/messages'
import EtQueryEditor from '@/components/et/EtQueryEditor.vue'
import EtAdvicesDialog from "@/components/et/EtAdvicesDialog.vue";

export default {
  name: 'EtEditQueryDialog',
  components: { EtAdvicesDialog, EtQueryEditor, EtHeaderEditor, AbstractDataEditForm },
  i18n: { messages },
  data: () => ({
    dialog: false,
    item: null
  }),
  methods: {
    open (item) {
      this.dialog = true
      this.item = JSON.parse(JSON.stringify(item))
      this.item.headers = this.item.headers.sort((prev, next) => prev.showIndex - next.showIndex)
    },
    save () {
      this.$refs.etQueryEditor.save()
    },
    close () {
      this.data = null
      this.dialog = false
    },
    showQueryExample () {
      this.$refs.etQueryEditor.showQueryExample()
    }
  }
}
</script>
