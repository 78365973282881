<template>
  <file-pond
    :allow-multiple="false"
    :server="myServer"
    :label-idle="$t('hint')"
    max-files="1"
    ref="pond"/>
</template>

<script>
import 'filepond/dist/filepond.min.css'
import vueFilePond from 'vue-filepond'
import messages from '@/componet-locale/file-uploader/messages'

const FilePond = vueFilePond()

export default {
  name: 'FileUploader',
  components: { FilePond },
  i18n: { messages: messages },
  data () {
    return {
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      }
    }
  },
  methods: {
    handleFileUpload (file, load) {
      this.$emit('handle', file, load)
    }
  }
}
</script>
