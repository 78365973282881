<template>
  <v-dialog v-if="dialog && setAbility('ADMIN')" v-model="dialog" persistent width="900">
    <v-card class="overflow-x-hidden" height="90vh">
      <v-card-text class="overflow-auto" style="height: calc(90vh - 52px)">
        <v-card-title>
          {{ $t('relationsSettings') }}
        </v-card-title>
        <et-relations
          :headers="headers"
          :relations="relations"
        />
        <v-card-title>
          {{ $t('ruleEditor') }}
        </v-card-title>
        <rule-editor :headers="headers" :rules="rules"/>
        <v-card-title>
          {{ $t('defaultPredicates') }}
        </v-card-title>
        <default-predicate-editor
          :headers="headers"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import HeaderRule from '@/components/et/view-settings/HeaderRule.vue'
import Vuedraggable from 'vuedraggable'
import messages from '@/componet-locale/et-rules/messages'
import { EventBus } from '@/event-bus'
import RuleEditor from '@/components/et/view-settings/RuleEditor.vue'
import DefaultPredicateEditor from '@/components/et/view-settings/DefaultPredicateEditor.vue'
import EtRelations from '@/components/et/view-settings/Relations.vue'

export default {
  name: 'EtViewSettingsDialog',
  components: { EtRelations, DefaultPredicateEditor, RuleEditor, Vuedraggable, HeaderRule },
  i18n: { messages },
  data: () => ({
    dialog: false,
    etId: null,
    headers: [],
    rules: [],
    relations: []
  }),
  methods: {
    open (etId) {
      this.dialog = true
      this.etId = etId
      this.init()
    },
    init () {
      this.$axios.get('et/get-view-settings', {
        params: { etId: this.etId }
      })
        .then(({ data }) => {
          this.headers = data.headers
          this.rules = data.rules
          this.relations = data.relations
        })
    },
    close () {
      this.data = null
      this.dialog = false
    },
    save () {
      const viewSettings = {
        externalTableId: this.etId,
        rules: this.rules,
        headers: this.headers,
        relations: this.relations
      }
      this.$axios.post('et/save-view-settings', viewSettings)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('rulesChanged'))
          this.$emit('save')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('errorChangingRules')))
    }
  }
}
</script>
