<template>
  <v-card class="my-4 pa-3" style="border: 1px solid gray; box-shadow: none">

    <v-card-actions class="pa-0 mb-2">
      <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
      <v-spacer/>
      <v-icon class="pointer" color="red" @click="remove">close</v-icon>
    </v-card-actions>

    <v-card-text class="pa-0">
      <v-text-field
        class="my-0"
        :label="$t('fieldName')"
        v-model="value.name"
      />
      <v-select
        :label="$t('dataType')"
        v-model="value.type"
        :items="dataTypeList"
      >
        <template v-slot:selection="{item}">
          {{ $t(item) }}
        </template>
        <template v-slot:item="{item}">
          {{ $t(item) }}
        </template>
      </v-select>
      <v-textarea
        :label="$t('options')"
        auto-grow
        :placeholder="$t('enterOptionsForDropDownList')"
        rows="1"
        v-if="value.type === 'LIST' || value.type === 'MULTIPLE_LIST'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('commaIndex')"
        :placeholder="$t('noRounding')"
        v-if="value.type === 'NUMBER'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('prefix')"
        auto-grow
        v-if="value.type === 'CUSTOM_LINK'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('link')"
        auto-grow
        v-if="value.type === 'PARAMETRIC_LINK'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('formula')"
        auto-grow
        v-if="value.type === 'COMPUTED_FIELD'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('tableIdAndFieldName')"
        v-if="value.type === 'DATA_TABLE'"
        v-model="value.options"
      />
      <v-text-field
        :label="$t('tableIdAndFieldName')"
        v-if="value.type === 'DATA_TABLE_MULTIPLE'"
        v-model="value.options"
      />
      <v-checkbox
        class="pa-0 ma-0"
        dense
        hide-details
        :label="$t('useAsTitle')"
        v-if="value.type === 'STRING' || value.type === 'NUMBER'"
        v-model="value.partOfName"
      />
      <v-checkbox
        class="pa-0 ma-0"
        dense
        hide-details
        :label="$t('displayLabelOnTheMap')"
        v-model="value.showOnMap"
      />
      <v-checkbox
        class="pa-0 ma-0"
        dense
        hide-details
        :label="$t('excludeFromTable')"
        v-model="value.excludeFromTableView"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import messages from '@/componet-locale/poi-template-field/messages'

export default {
  name: 'PoiTemplateField',
  i18n: { messages },
  props: { value: Object },
  computed:{
    dataTypeList(){
      return [
        'STRING',
        'BOOLEAN',
        'DATE',
        'LIST',
        'MULTIPLE_LIST',
        'NUMBER',
        'LINK',
        'CUSTOM_LINK',
        'PARAMETRIC_LINK',
        'COMPUTED_FIELD',
        'DATA_TABLE',
        'DATA_TABLE_MULTIPLE'
      ]
    }
  },
  methods: {
    remove () {
      this.$emit('remove', this.value)
    }
  }
}
</script>
