<template>
  <v-dialog v-if="dialog && setAbility('MODERATOR')" v-model="dialog" persistent width="700">
    <v-card class="overflow-x-hidden d-flex flex-column" max-height="90vh">
      <v-card-title>
        <span>{{ $t('aggregation') }}</span>
        <v-spacer/>
      </v-card-title>
      <v-card-text class="pa-2 overflow-y-auto">
        <et-aggregation-editor
          :et="et"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import messages from '@/componet-locale/et-aggregation/messages'
import { EventBus } from '@/event-bus'
import EtAggregationCard from '@/components/et/aggregations/EtAggregationEditor.vue'
import EtAggregationEditor from '@/components/et/aggregations/EtAggregationEditor.vue'

export default {
  name: 'EtAggregationEditorDialog',
  components: { EtAggregationEditor, EtAggregationCard },
  i18n: { messages },
  data: () => ({
    dialog: false,
    et: null
  }),
  methods: {
    open (et) {
      this.et = JSON.parse(JSON.stringify(et))
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.et = null
    },
    save () {
      this.et.aggregations.forEach((aggregation, i) => {
        aggregation.showIndex = i
      })
      this.$axios.post('et/save', this.et)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('updated')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
