<template>
  <v-dialog v-model="dialog" width="300">
    <v-card>
      <v-card-title>
        {{ $t('showOnMap') }}
      </v-card-title>
      <v-list-item v-for="item in items" @click="goToPoi(item)">
        <v-list-item-avatar>
          <v-icon>map</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/poi-selection-dialog/messages'

export default {
  name: 'PoiSelectionDialog',
  i18n: { messages: messages },
  data: () => ({
    dialog: false,
    items: []
  }),
  methods: {
    open (listOfPoi) {
      this.items = listOfPoi
      this.dialog = true
    },
    goToPoi (poi) {
      EventBus.$emit('goToPoi', poi)
      this.dialog = false
    }
  }
}
</script>
