<template>
  <v-dialog
    v-model="dialog"
    width="800">
    <v-card>
      <v-card-text>
        <div v-for="item in items">
          <img-from-zulu :url="item.url"/>
          <span>{{ item.fullName }}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ZwsCommandBuilder from '@/services/zws-command-builder'
import ImgFromZulu from '@/components/map/helpers/ImgFromZulu'

export default {
  name: 'PhotoListDialog',
  components: { ImgFromZulu },
  props: ['feature'],
  data: () => ({
    dialog: false,
    items: []
  }),
  methods: {
    async open (field, feature) {
      let sys = feature.props.find(prop => prop.isKey)
      this.dialog = true
      this.items = await ZwsCommandBuilder
        .getLayerFieldBlobList({
          layer: feature.layer.layerId,
          typeId: feature.typeId,
          field: field.name,
          key: {
            name: sys.name,
            value: sys.value
          }
        })
    }
  }
}
</script>
