<template>
  <v-card
    class="ma-2 search-result-row"
    @click.native="showPkkFeature"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <span>
          {{ feature.attrs.cn }}
        </span>
        <v-list-item-subtitle>
          <span>{{ feature.attrs.address }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-chip label outlined small>
        <v-icon left small>layers</v-icon>
        <span>pkk</span>
      </v-chip>
      <v-spacer/>
      <v-chip v-if="!hasGeometry" color="orange darken-2" label outlined small>
        <span>{{ $t('noCoordinates') }}</span>
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>

import { toLonLat } from 'ol/proj'
import messages from '@/componet-locale/pkk-search-result/messages'

export default {
  name: 'PkkSearchResult',
  i18n: { messages },
  props: {
    feature: Object,
    searchField: String
  },
  methods: {
    showPkkFeature () {
      const e = this.feature.extent
      this.$emit('showPkkFeature', this.feature)
      this.$emit('showBbox', [...toLonLat([e.xmin, e.ymin]), ...toLonLat([e.xmax, e.ymax])])
    }
  },
  computed: {
    hasGeometry () {
      return !!this.feature.extent
    }
  }
}
</script>
