<template>
  <v-dialog
    v-model="value"
    hide-overlay
    width="300">
    <v-card>
      <v-card-text>
        <span> {{ text }} </span>
        <v-progress-linear indeterminate class="mt-3" height="7" color="purple"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SimpleLoader',
  props: ['value', 'text']
}
</script>
