<template>
  <div>
    <div>
      <v-list>
        <v-list-item
          v-for="(attachment, index) in attachedData"
          :key="`data-${index}`"
          @click="showAttachedData(attachment.abstractData)"
        >
          <v-list-item-avatar>
            <v-icon>{{ getUrlByDataType(attachment.abstractData.dataType).icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ attachment.abstractData.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="blue--text">{{ attachment.abstractData.project.name }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="detachData(attachment)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          v-for="item in attachedFiles"
          :key="item.id"
          @click="$refs.fileViewer.open(item)"
        >
          <v-list-item-action
            class="mr-4"
          >
            <v-img
              :src="`/image/${item.type}.png`"
              class="mx-auto"
              height="40"
              width="35">
            </v-img>
          </v-list-item-action>
          <v-list-item-content>
            <div>
              {{ item.name }}
              <v-icon v-if="item.isPublic" x-small>group</v-icon>
            </div>
            <v-list-item-subtitle>
              {{ getUserFullName(item.owner) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="caption">{{ formatDate(item.creationDate) }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="removeFile(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
    <v-card
      v-if="attachedData.length + attachedFiles.length === 0"
      class="mx-2 mb-2"
      outlined
    >
      <v-card-text>
        {{ $t('noAttachments') }}
      </v-card-text>
    </v-card>
    <file-viewer
      ref="fileViewer"
      :storage-url="fileEntity => isAuthenticated
        ? `files/get-attached-et-file/${fileEntity.id}`
        : isAuthenticated ? `files/get-attached-et-file` :  `public-data/get-attached-et-file/${fileEntity.id}`"
    />
    <new-file-dialog
      ref="newFileDialog"
      :dataModifier="dataModifier"
      :external-storage="{
        saveFileURL: 'files/save-attached-data',
        saveRecordsURL: 'files/save-attached-et-files'
      }"
      @on-save="getAttachedFiles"
    />
    <search-data-dialog ref="searchDataDialog" @select="attachData"/>
  </div>
</template>

<script>
import NewFileDialog from '@/components/drive/NewFileDialog.vue'
import FileViewer from '@/components/drive/FileViewer.vue'
import SearchDataDialog from '@/components/utils/SearchDataDialog.vue'
import { EventBus } from '@/event-bus'
import sharedMessages from '@/componet-locale/attached-data/messages'

export default {
  name: "EtItemAttachments",
  i18n: { sharedMessages },
  components: { SearchDataDialog, FileViewer, NewFileDialog },
  props: {
    et: Object,
    item: Object
  },
  data: () => ({
    attachedData: [],
    attachedFiles: []
  }),
  mounted () {
    this.getAttachedData()
    this.getAttachedFiles()
  },
  methods: {
    getAttachedData () {
      if (!this.isAuthenticated) return
      this.$axios.get('et/get-attached-data-by-row', {
        params: {
          tableId: this.et.id,
          rowPrimaryKey: this.primaryKeyValue
        }
      })
        .then(res => this.attachedData = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    openSearchDataDialog () {
      this.$refs.searchDataDialog.open()
    },
    attachData (data) {
      this.$axios.post('et/attach-data-to-row', null, {
        params: {
          tableId: this.et.id,
          abstractDataId: data.id,
          rowPrimaryKey: this.primaryKeyValue
        }
      })
        .then(() => this.getAttachedData())
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    showAttachedData (data) {
      EventBus.$emit('showData', data)
    },
    detachData (attachment) {
      this.$axios.post('et/detach-data', null, {
        params: {
          attachmentId: attachment.id
        }
      })
        .then(() => {
          this.getAttachedData()
          EventBus.$emit('showInfoMessage', this.$t('dataRemoved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    dataModifier (data) {
      if (this.et) {
        data.externalTable = { id: this.et.id }
        data.rowPrimaryKey = this.primaryKeyValue
      }
    },
    getAttachedFiles () {
      this.$axios.get(this.isAuthenticated ? 'files/get-attached-et-files' : 'public-data/get-attached-et-files', {
        params: {
          etId: this.et.id,
          rowPrimaryKey: this.primaryKeyValue
        }
      })
        .then(res => this.attachedFiles = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    removeFile (file) {
      this.$axios.delete('files/attached-et-file', {
        params: {
          id: file.id
        }
      })
        .then(() => {
          this.getAttachedFiles()
          EventBus.$emit('showInfoMessage', this.$t('dataRemoved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    openNewFileDialog () {
      this.$refs.newFileDialog.open()
    }
  },
  computed: {
    primaryKeyValue () {
      return this.item[this.et.headers.find(h => h.isPrimaryTableKey).alias]
    }
  }
}
</script>
