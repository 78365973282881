<template>
  <v-bottom-sheet inset v-model="dialog" max-width="750">
    <v-card class="full-height-card elevation-0">
      <div @click="close" class="d-flex justify-center">
        <v-icon large>mdi-menu-down</v-icon>
      </div>
      <v-card-title class="pt-1">
        <search-bar @search="processSearch" ref="searchBar"/>
      </v-card-title>
      <v-card-text class="pa-0" style="height: calc(100vh - 224px) !important;">
        <limited-search-result v-show="!isFullTextSearch"
                               :search-query="searchQueryLimited"
                               @close="close"
                               ref="limitedSearch"/>
        <full-text-search-result v-show="isFullTextSearch"
                                 :search-query="searchQueryFullText"
                                 @close="close"
                                 ref="fullTextSearch"/>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import SearchBar from './SearchBar'
import FullTextSearchResult from './FullTextSearchResult'
import LimitedSearchResult from './LimitedSearchResult'

export default {
  name: 'GlobalSearch',
  components: { SearchBar, LimitedSearchResult, FullTextSearchResult },
  data: () => ({
    dialog: false,
    searchQueryFullText: '',
    searchQueryLimited: '',
    isFullTextSearch: false
  }),
  methods: {
    open () {
      this.dialog = true
      this.resetAll()
    },
    resetAll () {
      if (this.$refs.searchBar) this.$refs.searchBar.clean()
      if (this.$refs.limitedSearch) {
        this.$refs.limitedSearch.clean()
        this.$refs.limitedSearch.init()
      }
      if (this.$refs.fullTextSearch) this.$refs.fullTextSearch.clean()
    },
    close () {
      this.dialog = false
    },
    processSearch (searchQuery, fullTextSearch) {
      this.isFullTextSearch = fullTextSearch
      fullTextSearch ? this.searchQueryFullText = searchQuery : this.searchQueryLimited = searchQuery
    }
  }
}

</script>
