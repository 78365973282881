<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card v-if="dialog">
      <v-card-title>
        {{ $t('dataLoading') }}
        <v-spacer/>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <keywords-picker
          v-if="!showItemList"
          v-model:selected="selectedKeywords"
          :project-id="+this.$route.params.projectId"
        />
        <category-picker
          v-if="!showItemList"
          v-model:selected="selectedCategoryList"
          :project-id="+this.$route.params.projectId"
        />
        <file-pond
          v-if="!showItemList"
          ref="pond"
          :allow-multiple="true"
          :label-idle="$t('pressOrDragForLoadFile')"
          :server="myServer"
          max-files="100"
          @addfilestart="isFileUploaded = false"
          @processfiles="isFileUploaded = true"
        />

        <v-expansion-panels
          v-else-if="items.length > 1"
          class="py-2"
          focusable
          multiple
        >
          <v-expansion-panel
            v-for="(item,i) in items"
            v-if="showExpansionPanel"
            :key="'ad-' + i"
          >
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-icon v-if="item.providerIsEmpty" color="error">
                  error
                </v-icon>
                <v-icon v-else>
                  tune
                </v-icon>
              </template>
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <abstract-data-edit-form
                :item="item"
                class="pa-3"
                validate-on-create
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div
          v-else-if="items.length === 1"
        >
          <abstract-data-edit-form
            :item="items[0]"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="showItemList"
          color="primary"
          outlined
          @click="saveAll"
        >
          {{ $t('button.save') }}
        </v-btn>
        <v-btn
          v-else-if="items.length > 0"
          :disabled="!isFileUploaded && !isFileConverted"
          color="primary"
          outlined
          @click="next"
        >
          {{ $t('button.next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond from 'vue-filepond'
import mammoth from 'mammoth'
import { EventBus } from '@/event-bus'
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'
import messages from '@/componet-locale/doc-upload-dialog/messages'
import KeywordsPicker from '@/components/utils/KeywordsPicker.vue'

const FilePond = vueFilePond()

export default {
  name: 'DocUploadDialog',
  components: { KeywordsPicker, CategoryPicker, AbstractDataEditForm, FilePond },
  i18n: { messages },
  props: {
    defaultCategory: Object
  },
  data: function () {
    return {
      dialog: false,
      isFileUploaded: false,
      categoryList: [],
      selectedCategoryList: [],
      selectedKeywords: [],
      isFileConverted: false,
      showExpansionPanel: true,
      showItemList: false,
      items: [],
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      }
    }
  },
  methods: {
    open () {
      this.showItemList = false
      this.isFileUploaded = false
      this.isFileConverted = true
      this.items = []
      this.selectedCategoryList = this.defaultCategory ? [this.defaultCategory] : []
      this.selectedKeywords = []
      this.dialog = true
    },
    handleFileUpload (file, load) {
      let item = {}
      let tempFile = file
      let type = 'OTHER'
      let reader = new FileReader()
      let fileExt = tempFile.name.split('.').pop().toLowerCase()
      item.name = tempFile.name.replace(/\.[^/.]+$/, '')
      if (fileExt === 'pdf') {
        type = 'PDF'
      }
      let self = this
      if (fileExt === 'docx') {
        this.isFileConverted = false
        type = 'MS_DOC'
        reader.onload = () => {
          let arrayBuffer = reader.result
          mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
            .then(function (result) {
              item.firstVersion.value = result.value
              self.isFileConverted = true
            })
            .catch(() => {
              EventBus.$emit('showErrorMessage', this.$t('readingFileError'))
            })
            .done()
        }
      }
      reader.readAsArrayBuffer(tempFile)
      item.firstVersion = { type: type }
      this.saveFile(load, tempFile, item)
    },
    saveFile (load, tempFile, item) {
      let formData = new FormData()
      formData.append('file', tempFile)
      this.$axios
        .post('/files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          item.filePath = response.data
          item.firstVersion.fileName = tempFile.name
          load()
          this.items.push(item)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    async next () {
      this.items.forEach(it => {
        it.categoryList = this.selectedCategoryList
        it.keywords = this.selectedKeywords
      })
      let providerResponse = await this.$axios.get('/provider/get-all-for-project/', { params: { id: this.selectedProject.id } })
      let providerList = providerResponse.data

      if (providerList.length > 0) {
        this.items.forEach(item => {
          item.provider = providerList[0]
        })
      }
      this.showItemList = true
    },
    saveAll () {
      let projectId = this.selectedProject.id
      this.$axios
        .post('/docs/save-all/' + projectId, this.items, { timeout: 120000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataSaved')))
        .catch(reason => {
          console.error(reason.data.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('onSave')
          this.dialog = false
        })
    },
    removeChips (item, array) {
      array.splice(array.indexOf(item), 1)
    }
  }
}
</script>
