<template>
  <div
    class="abstract-data-widget-container"
  >
    <div v-if="title" class="pl-2">
      <a
        :class="dense ? 'subtitle-1' : 'headline'"
        class="hide-text-overflow simple-link subtitle-1"
        @click="e=> $emit('clickOnTitle', e)"
      >
        {{ title }}
      </a>
    </div>
    <div style="height: calc(100% - 24px); overflow-y: auto">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdWidgetContainer',
  props: {
    title: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.abstract-data-widget-container {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #a2a2a2
}
</style>
