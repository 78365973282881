import {
  OPEN_ISSUES,
  ATTACHED_DATA
} from '@/components/map/helpers/map-actions'

export const poiMixin = {
  data: () => ({
    issuesCount: 0,
    attachmentsCount: 0
  }),
  methods: {
    populateCounters (lpId) {
      this.$axios
        .get(this.isAuthenticated ? 'layer-poi/get-metadata' : 'public-data/get-lp-metadata', {
          params: { lpId: lpId }
        })
        .then(result => {
          this.issuesCount = result.data.issueCount
          this.attachmentsCount = result.data.attachedDataCount + result.data.attachedFilesCount
        })
    }
  },
  computed: {
    counters () {
      const counters = new Map()
      counters.set(OPEN_ISSUES.title, this.issuesCount)
      counters.set(ATTACHED_DATA.title, this.attachmentsCount)
      return counters
    }
  }
}
