<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card class="full-height-card">
      <v-card-title class="headline lighten-2">
        {{ $t('saveMap') }}
      </v-card-title>
      <v-card-text>
        <abstract-data-edit-form ref="abstractDataEditForm" :item="map" full-form/>
        <map-position :position="map.position"/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :loading="loading" @click="save" outlined primary>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from '../utils/AbstractDataEditForm'
import messages from '../../componet-locale/create-map-dialog/messages'
import MapPosition from '@/components/map/MapPosition'
import { EventBus } from '@/event-bus'

export default {
  name: 'CreateMapDialog',
  components: { MapPosition, AbstractDataEditForm },
  i18n: { messages: messages },
  data: () => ({
    dialog: false,
    map: {},
    loading: false
  }),
  methods: {
    create (map) {
      this.map = map
      this.dialog = true
    },
    save () {
      if (!this.$refs.abstractDataEditForm.validateForm()) return
      this.loading = true
      this.map.position = JSON.stringify(this.map.position)
      this.$axios
        .post('/map/save', this.map)
        .then(response => {
          this.dialog = false
          EventBus.$emit('loadData')
          this.openInNewTab(response.data)
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
