<template>
  <div class id="codex-editor"/>
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import Checklist from '@editorjs/checklist'
import SimpleImage from '@editorjs/simple-image'

export default {
  name: 'TextEditor',
  props: ['value'],
  methods: {
    save (data) {
      this.$emit('input', data)
    },
    onReady () {
      this.$emit('on-ready')
    },
    init () {
      let self = this
      window.editor = new EditorJS({
        holder: 'codex-editor',
        autofocus: false,
        initialBlock: 'paragraph',
        data: this.value,
        logLevel: 'ERROR',
        tools: {
          header: {
            class: Header,
            shortcut: 'CMD+SHIFT+H'
          },
          list: {
            class: List
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true
          },
          image: {
            class: SimpleImage
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: 'Опишите задачу'
            }
          },
          table: {
            class: Table
          }
        },
        onChange (api) {
          api.saver.save().then(data => self.save(data))
        },
        onReady () {
          self.onReady()
        }
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>
