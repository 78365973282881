<template>
  <div class="pl-2 pr-3">
    <div v-for="layer in lpLayers" class="mt-3">
      <div class="subtitle-1" style="font-weight: bold">{{ layer.name }}</div>
      <div v-for="template in layer.poiTemplates">
        <div class="subtitle-2 py-3" style="text-decoration: underline">{{ template.name }}</div>
        <p class="d-flex align-center flex-nowrap body-2">
          <layer-poi-badge :template="template"/>
          {{ template.name }}
        </p>
        <p v-for="rule in template.rules" class="d-flex align-center flex-nowrap">
          <layer-poi-badge :style-id="rule.layerPoiStyle.id" :template="template" style="min-width: 20px"/>
          <span class="body-2" v-html="createRuleTitle(rule)"/>
        </p>
      </div>
      <v-divider class="my-4"/>
    </div>
    <div v-for="layer in zwsLayers" class="mt-3">
      <div class="subtitle-1" style="font-weight: bold">{{ layer.name }}</div>
      <div v-for="type in layer.typeList" v-if="type.typeId !== -1 && type.typeId !== '-1' ">
        <div class="subtitle-2 py-3" style="text-decoration: underline">{{ type.name }}</div>
        <div v-for="mode in type.modes">
          <p class="d-flex align-center flex-nowrap">
            <img v-if="mode.image" :src="mode.image" width="20"/>
            <span class="ml-2">{{ mode.title }}</span>
          </p>
        </div>
      </div>
    </div>
    <v-divider class="my-4"/>
  </div>
</template>

<script>
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import messages from '@/componet-locale/map-legend/messages'

export default {
  name: "MapLegend",
  components: { LayerPoiBadge },
  i18n: { messages },
  props: {
    layers: {
      type: Array,
      required: true
    }
  },
  methods: {
    createRuleTitle (rule) {
      return `<i>${rule.field.name}</i> ${this.predicateToString(rule.predicate)} <i>"${rule.thresholdValue}"</i>`
    },
    predicateToString (predicate) {
      switch (predicate) {
        case "EQUAL":
          return "="
        case "LIKE":
          return this.$t('LIKE')
        case "LESS":
          return "<"
        case "MORE":
          return ">"
        default:
          return predicate
      }
    }
  },
  computed: {
    lpLayers () {
      return this.layers.filter(it => it.type === 'LAYER_POI')
    },
    zwsLayers () {
      return this.layers.filter(it => it.type === 'ZWS')
    }
  }
}
</script>
