<template>
  <component :is="dComponent" v-model="sheet">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">attach_file</v-icon>
    </template>
    <v-card>
      <v-card-title class="subtitle-1">{{ $t('attachFile') }}</v-card-title>
      <v-list>
        <v-list-item v-for="(item,index) in uploadOptions" :key="index"
                     @click="onUploadOptionClick(item.value)">
          <v-list-item-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <new-file-dialog ref="uploader" share-once @on-save="sendFile"/>
      <file-list ref="file_list" @on-choose="sendFile"/>
    </v-card>
  </component>
</template>

<script>
import NewFileDialog from '@/components/drive/NewFileDialog'
import FileList from '@/components/drive/FileList'
import { VBottomSheet, VMenu } from 'vuetify/lib'
import messages from '@/componet-locale/chat-attachments/messages'

export default {
  name: 'ChatAttachments',
  props: ['chat'],
  components: { FileList, NewFileDialog },
  i18n: { messages: messages },
  data: () => ({
    uploadOptions: [
      { icon: 'attachment', title: 'uploadFile', value: 'NEW_FILE' },
      { icon: 'cloud_queue', title: 'selectFile', value: 'FROM_CLOUD' }
    ],
    sheet: false,
    drop: false
  }),
  methods: {
    callUploader (files) {
      this.$refs.uploader.open(files)
    },
    onUploadOptionClick (key) {
      if (key === 'NEW_FILE') { this.callUploader() } else if (key === 'FROM_CLOUD') { this.$refs.file_list.open() }

      this.sheet = false
    },
    sendFile (files) {
      files.forEach(file => {
        delete file.owner
        const chatID = this.chat.id
        const jsonFileEntity = `{"file": ${JSON.stringify(file)}}`
        this.$ws.send(`/ws-prefix/notify/${chatID}`, jsonFileEntity)
      })
    }
  },
  computed: {
    dComponent () {
      return this.displayXS ? VBottomSheet : VMenu
    }
  }
}
</script>
