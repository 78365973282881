import i18n from '../plugins/i18n'

export function getCurrentLocale () {
  return localStorage.getItem('locale')
}

export function setLocale (locale) {
  i18n.locale = locale
  localStorage.setItem('locale', locale)
}

export const languages = [
  { title: 'English', value: 'en' },
  { title: 'Русский', value: 'ru' },
  { title: 'Español', value: 'es' }
]
