<template>
  <div>
    <div v-if="relations.length > 0">
      <v-expansion-panels
        v-if="headers && headers.length"
        accordion
        multiple
      >
        <relation-item
          v-for="(relation, index) in relations"
          :key="index"
          :headers="headers"
          :relation="relation"
          @remove="remove(index)"
        />
      </v-expansion-panels>
    </div>
    <div v-else class="ml-4">{{ $t('noRelations') }}</div>
    <div class="d-flex mt-4">
      <v-spacer/>
      <v-btn outlined @click="addRelation">{{ $t('button.add') }}</v-btn>
    </div>
  </div>
</template>

<script>
import RelationItem from '@/components/et/view-settings/RelationItem.vue'
import messages from '@/componet-locale/et-relations/messages'

export default {
  name: "Relations",
  components: { RelationItem },
  i18n: { messages },
  props: {
    headers: Array,
    relations: Array
  },
  methods: {
    addRelation () {
      this.relations.push({
        type: 'TO_MANY',
        mainHeader: null,
        relatedHeader: null
      })
    },
    remove (index) {
      this.relations.splice(index, 1)
    }
  }
}
</script>
