<template>
  <div class="elevation-2 position-relative my-3"
       :style="styles">
    <pdf-canvas v-if="isPageVisible"
                :page="page"
                :viewport="viewport"
                v-on="$listeners"/>
    <pdf-text-layer v-if="isPageVisible"
                    :page="page"
                    :viewport="viewport"/>
  </div>
</template>

<script>
import PdfTextLayer from './PdfTextLayer.vue'
import PdfCanvas from './PdfCanvas.vue'

export default {
  name: 'PdfPage',
  components: {
    PdfCanvas,
    PdfTextLayer
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    isPageFocused: {
      type: Boolean,
      default: false
    },
    isElementFocused: {
      type: Boolean,
      default: false
    },
    isPageVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    viewport () {
      return this.page.getViewport({ scale: this.scale })
    },
    styles () {
      const { width, height } = this.viewport

      return {
        width: `${width}px`,
        height: `${height}px`
      }
    }
  },
  watch: {
    isElementFocused (isElementFocused) {
      if (!isElementFocused || this.isPageFocused) return
      this.$emit('page-focus', this.page.pageNumber)
    }
  }
}
</script>
