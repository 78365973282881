<template>
  <div>
    <feature-card @close="close">
      <template v-slot:name>
        <v-skeleton-loader v-if="loading" type="card-heading"/>
        <span v-else>{{ attrs.cn }}</span>
      </template>
      <template v-slot:fields>
        <v-skeleton-loader v-if="loading" type="paragraph"/>
        <div v-else>
          <v-textarea
            v-for="(value, key, i) in attrs"
            v-if="showAttribute(value)"
            :key="i"
            :label="$t(key)"
            :value="value"
            auto-grow
            class="mb-2"
            dense
            outlined
            readonly
            rows="1"
          />
        </div>
      </template>
    </feature-card>
  </div>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard.vue'
import messages from '@/componet-locale/selected-pkk-feature/messages'
import { EventBus } from '@/event-bus'

export default {
  name: "SelectedPkkFeature",
  i18n: { messages },
  components: { FeatureCard },
  props: {
    pkkId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    attrs: null,
    loading: true
  }),
  mounted () {
    this.getPkkFeatureAttrs()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getPkkFeatureAttrs () {
      this.loading = true
      this.$axios
        .get(`https://pkk.rosreestr.ru/api/features/1/${this.pkkId}`, {
          withCredentials: false
        })
        .then(res => res.data.feature)
        .then(feature => this.attrs = feature.attrs)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          this.close()
        })
        .finally(() => this.loading = false)
    },
    showAttribute (value) {
      if (value === null) return false
      if (typeof value === 'string') return value.length > 0
      return true
    }
  },
  watch: {
    pkkId () {
      this.getPkkFeatureAttrs()
    }
  }
}
</script>
