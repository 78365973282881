<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-select :label="$t('project')"
                    v-model="project"
                    :items="allProjects"
                    item-text="name"
                    return-object
                    :no-data-text="$t('noData')"/>

          <div v-if="user.role==='USER'">
            <v-select :items="allCategories.filter(it => !it.isDefault)"
                      chips
                      item-text="name"
                      :label="$t('categories')"
                      multiple
                      :disabled="!project.id"
                      return-object
                      v-model="categories">
              <template v-slot:selection="data">
                <v-chip color="deep-purple" label outlined class="my-1 hide-text-overflow">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>

            <v-switch :label="$t('moderator')"
                      v-model="isModerator"
                      :disabled="!project.id"/>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!project.id"
               color="primary" outlined
               @click="addUserProject">
          {{ $t('button.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../../componet-locale/user-project-create-dialog/messages'

export default {
  name: 'UserProjectCreateDialog',
  data: () => ({
    user: {},
    dialog: false,
    project: {},
    allProjects: [],
    isModerator: false,
    categories: [],
    allCategories: []
  }),
  i18n: { messages: messages },
  methods: {
    createUserProject (user) {
      this.user = user
      this.getProjects()
      this.cleanData()
      this.dialog = true
    },
    cleanData () {
      if (this.$refs.form) this.$refs.form.resetValidation()
      this.project = {}
      this.isModerator = false
      this.categories = []
      this.allCategories = []
    },
    getProjects () {
      this.$axios
        .get('project/get-all-unassigned', { params: { id: this.user.id } })
        .then(response => this.allProjects =
          response.data.sort((first, second) => {
            if (first.name > second.name) return 1
            if (second.name > first.name) return -1
            return 0
          }))
    },
    addUserProject () {
      if (!this.isValidData()) return

      this.$axios
        .post('project/add-user-project',
          {
            user: this.user.id,
            project: this.project.id,
            categoryList: this.categories.map(it => it.id),
            moderator: this.isModerator
          }
        )
        .then(() => {
          this.dialog = false
          this.$emit('on-save')
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    deleteCategory (categeory) {
      this.categories.splice(this.categories.indexOf(categeory), 1)
    },
    isValidData () {
      return this.$refs.form.validate()
    }
  },
  watch: {
    'project.id' (projectId) {
      if (!projectId) return

      this.$axios
        .get('/category/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.allCategories = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
