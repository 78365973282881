<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>
        Объект
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="item">
        <div v-for="header in table.headers">
          <div v-if="header.type === 'hyperlink'">
            <v-text-field :label="`${header.name} (text)`"
                          :value="getHyperlink(item, header).text"
                          @change="val => changeItemLink(val, item, header, 'text')"/>
            <v-text-field :label="`${header.name} (link)`"
                          :value="getHyperlink(item, header).link"
                          @change="val => changeItemLink(val, item, header, 'link')"/>
          </div>
          <v-text-field
            v-else
            v-model="item[header.field]"
            :label="header.name"
          />
        </div>
      </v-card-text>
      <v-card-actions v-if="setAbility('MODERATOR')">
        <v-spacer/>
        <v-btn
          v-show="!isNewFile"
          color="red"
          outlined
          @click="remove"
        >
          Удалить
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DataTableItemDetailsDialog',
  props: ['table'],
  data: () => ({
    dialog: false,
    item: null,
    isNewFile: false
  }),
  methods: {
    save () {
      if (this.isNewFile) {
        this.table.items.push(this.item)
      }
      this.$emit('save')
      this.dialog = false
    },
    open (item) {
      this.isNewFile = false
      this.item = item
      this.dialog = true
    },
    createItem () {
      this.isNewFile = true
      this.item = {}
      this.dialog = true
    },
    remove () {
      this.table.items.splice(this.table.items.indexOf(this.item), 1)
      this.$emit('save')
      this.dialog = false
    },
    getHyperlink (item, header) {
      const value = item[header.field]
      return JSON.parse(value)
    },
    changeItemLink (newValue, item, header, type) {
      const value = this.getHyperlink(item, header)
      if (type === 'text') {
        value.text = newValue
      }
      if (type === 'link') {
        value.link = newValue
      }
      this.item[header.field] = JSON.stringify(value)
    }
  }
}
</script>
