<template>
  <LineComponent
    :chart-data="chartData"
    :chart-options="options"
    :height="height"
    :plugins="[legend]"
  />
</template>


<script>
import { Line as LineComponent } from 'vue-chartjs/legacy'
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, Title, Tooltip } from 'chart.js'

ChartJS.register(Title, LineElement, Tooltip, Legend, CategoryScale, LinearScale)


export default {
  name: 'BarChart',
  components: { LineComponent },
  data: () => ({
    legend: {
      options: {
        position: 'bottom'
      }
    }
  }),
  props: ['options', 'chartData', 'height']
}
</script>
