<template>
  <view-data-page
    :data="data"
    :dialog-mode="dialogMode"
    :selectedView="selectedView"
    :show-loader="isFileDownloading"
    :split-screen-mode="splitScreenMode"
    :tab-list="tabList"
    @selectView="selectView"
  >
    <template v-slot:actions>
      <v-list-item dense @click="download">
        <v-list-item-avatar>
          <v-icon>cloud_download</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('download') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:navigation>
      <div v-show="selectedView === 'versions'">
        <v-list>
          <v-list-item v-for="item in docVersions"
                       :key="item.id"
                       :style="item.isActive ? 'background: #d6d6d6' : ''"
                       class="pr-1"
                       @click="selectDocVersion(item)">
            <v-list-item-content>
              <v-list-item-title @click="" v-text="item.name"/>
              <v-list-item-subtitle v-if="item.author">
                <b>{{ getUserFullName(item.author) }}</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ formatDate(item.creationDate) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="mx-0">
              <v-menu
                :nudge-width="200"
                offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>more_horiz</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$refs.confirmationDialogRemoveDocVersion.open(item.id)">
                    <v-list-item-title>
                      {{ $t('button.remove') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDocVersionDialog(item)">
                    <v-list-item-title>
                      {{ $t('button.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn v-if="docType === 'MS_DOC' && docVersions.length > 1"
                 color="primary" text
                 @click="$refs.docDiffDialog.open()">
            <v-icon left>compare_arrows</v-icon>
            {{ $t('compare') }}
          </v-btn>
          <v-spacer/>
          <v-btn v-if="data.readonly && setAbility('ADMIN') || !data.readonly && setAbility('MODERATOR')"
                 color="primary" text @click="openDocVersionDialog(null)">
            {{ $t('button.add') }}
            <v-icon right>add</v-icon>
          </v-btn>
        </v-card-actions>
        <doc-diff-dialog ref="docDiffDialog" :doc-versions-list="docVersions" @findDiff="findDiff"/>

        <confirmation-dialog ref="confirmationDialogRemoveDocVersion"
                             :title="$t('confirmDeletion')"
                             @confirm="removeDocVersion"/>

        <doc-version-dialog ref="updateDialog" :doc="data" @newVersionSaved="addNewDocVersion"/>
      </div>

      <div v-show="selectedView === 'structure'">
        <portal-target name="doc-structure"/>
        <v-treeview v-if="docType === 'MS_DOC'" :active.sync="activeItems" :items="treeView" :open.sync="open"
                    activatable
                    class="pt-2"
                    hoverable
                    style="font-size: 12px"/>
      </div>

      <div v-show="selectedView === 'search'">
        <v-text-field v-model="searchField"
                      :placeholder="$t('search')"
                      class="mx-2 mt-2"
                      clearable
                      dense
                      hide-details
                      outlined
                      @keyup.enter="search">
          <template slot="append">
            <v-icon @click="search">search</v-icon>
          </template>
        </v-text-field>
        <v-card v-for="(item, index) in searchResults" class="mx-2 my-2 search-result-row"
                outlined
                @click.native="jumpToItem(index)">
          <v-card-text class="pointer" v-html="item.html"/>
        </v-card>
      </div>
    </template>

    <template v-slot:tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="download()" v-on="on">
            <v-icon>
              cloud_download
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('download') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:doc>
      <doc-viewer v-if="docType === 'MS_DOC'"
                  ref="docViewer"
                  :active-items.sync="activeItems"
                  :doc-body-list="docVersions"
                  :open.sync="open"
                  :search-field="searchField"
                  :search-results.sync="searchResults"
                  :selected-doc-body="selectedDocVersion"
                  :tree-view.sync="treeView"
                  @selectDocVersion="selectDocVersion"/>

      <pdf-viewer v-if="docType === 'PDF'" :url="url"/>

      <v-img v-if="isImage" :src="url" max-width="100%"/>

      <div v-if="docType && isNotSupportedFormat" class="pa-4 text-center">
        <div class="headline">{{ selectedDocVersion.fileName }}</div>
        <p class="mb-5 subtitle-2">
          {{ $t('unsupportedDocumentFormat') }}
        </p>
        <img v-if="fileType" :src="`/image/${fileType}.png`" class="mb-5"/>
        <br>
        <v-btn color="primary" large outlined @click="download">
          <v-icon left>file_download</v-icon>
          {{ $t('download') }}
        </v-btn>
      </div>
    </template>
  </view-data-page>
</template>

<script>
import DocVersionDialog from './DocVersionDialog'
import ConfirmationDialog from '../utils/ConfirmationDialog'
import DocViewer from './DocViewer'
import FlexibleButton from '../utils/FlexibleButton'
import DocDiffDialog from './DocDiffDialog'
import ViewDataPage from '../utils/ViewDataPage'
import AbstractDataDetails from '../utils/AbstractDataDetails'
import { EventBus } from '@/event-bus'
import PdfViewer from '@/components/utils/pdf/PdfViewer'
import PdfPreview from '@/components/utils/pdf/PdfPreview'
import downloadFile from '../../services/file-downloader'
import messages from '../../componet-locale/doc-view/messages'

const TAB = {
  STRUCTURE: {
    icon: 'view_headline',
    view: 'structure'
  },
  SEARCH: {
    icon: 'search',
    view: 'search'
  },
  VERSIONS: {
    icon: 'history',
    view: 'versions'
  },
  COMMENTS: {
    icon: 'message',
    view: 'comments'
  }
}

export default {
  name: 'DocView',
  components: {
    PdfPreview,
    PdfViewer,
    AbstractDataDetails,
    ViewDataPage,
    DocDiffDialog,
    FlexibleButton,
    DocViewer,
    ConfirmationDialog,
    DocVersionDialog
  },
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode: {
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  i18n: { messages: messages },
  data: () => ({
    searchResults: [],
    selectedView: '',
    searchField: '',
    showDoc: true,
    open: [],
    treeView: [],
    activeItems: [],
    docVersions: [],
    selectedDocVersion: {
      value: ''
    },
    data: {},
    isFileDownloading: false,
    active: 0
  }),
  methods: {
    search () {
      this.$refs.docViewer.search()
    },
    jumpToItem (index) {
      this.$refs.docViewer.jumpTo(index)
    },
    findDiff (originalDoc, changedDoc) {
      this.$refs.docViewer.findDiff(originalDoc, changedDoc)
    },
    removeDocVersion (docVersionId) {
      this.$axios
        .post(`docs/remove-doc-version/${docVersionId}`)
        .then(() => {
          this.loadDocVersions()
        })
        .catch(error => {
          console.error(error)
        })
    },
    openDocVersionDialog (version = null) {
      this.$refs.updateDialog.open(version)
    },
    selectView (view) {
      this.selectedView = view
    },
    download () {
      const extension = this.selectedDocVersion.fileName.split('.').pop()
      const version = this.selectedDocVersion.name ? `(${this.selectedDocVersion.name})` : ''
      const fileName = `${this.data.name} ${version}.${extension}`
      this.isFileDownloading = true
      downloadFile(`/files/get-doc-version-file/${this.selectedDocVersion.id}`, fileName)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
        })
        .finally(() => this.isFileDownloading = false)
    },
    addNewDocVersion (docVersion) {
      this.docVersions.unshift(docVersion)
      this.selectDocVersion(docVersion)
    },
    selectDocVersion (docVersion) {
      this.docVersions.forEach(b => {
        b.isActive = false
      })
      this.selectedDocVersion = docVersion
      this.selectedView = TAB.STRUCTURE.view
    },
    loadDocVersions () {
      this.$axios
        .get(`/docs/get-all-versions/${this.data.id}`, { timeout: 30000 })
        .then(response => {
          this.docVersions = response.data
          if (this.docVersions.length > 0) {
            this.selectDocVersion(this.docVersions[0])
          } else {
            this.selectDocVersion(null)
            EventBus.$emit('showInfoMessage', this.$t('emptyList'))
          }
        })
    },
    init () {
      this.active = 0
      this.selectedView = this.tabList[0].view
      let id = this.dataId || this.$route.params.id
      this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
          this.loadDocVersions()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
    }
  },
  created () {
    this.init()
  },
  computed: {
    fileType () {
      if (this.selectedDocVersion && this.selectedDocVersion.fileName) {
        let types = ['doc', 'jpg', 'pdf', 'png', 'ppt', 'txt', 'xls', 'zip']

        let fileExt = this.selectedDocVersion.fileName.split('.').pop().toLowerCase()
        if (types.includes(fileExt)) return fileExt

        switch (fileExt) {
          case 'csv':
          case 'xlsx':
            return 'xls'
          case 'jpeg':
            return 'jpg'
          case 'rtf':
          case 'docx':
            return 'doc'
          case 'pptx':
            return 'ppt'
          case 'zip':
          case 'rar':
          case '7z':
          case 'gz':
            return 'archive'
        }
      }
      return 'unknown-icon'
    },
    url () {
      if (this.selectedDocVersion && this.selectedDocVersion.filePath) {
        return this.$axios.defaults.baseURL + '/files/get-doc-version-file/' + this.selectedDocVersion.id
      } else {
        return ''
      }
    },
    docType () {
      if (this.selectedDocVersion && this.selectedDocVersion.type) {
        return this.selectedDocVersion.type
      }
    },
    docFormat () {
      if (this.selectedDocVersion && this.selectedDocVersion.type) {
        return this.selectedDocVersion.fileName.split('.').pop().toLowerCase()
      }
    },
    isNotSupportedFormat () {
      let supportedTypes = ['MS_DOC', 'PDF']
      return !this.isImage && !supportedTypes.includes(this.docType)
    },
    isImage () {
      let imageFormats = ['png', 'jpeg', 'jpg', 'gif', 'bmp']
      return imageFormats.includes(this.docFormat)
    },
    tabList () {
      switch (this.docType) {
        case 'MS_DOC':
          return [TAB.STRUCTURE, TAB.SEARCH, TAB.VERSIONS, TAB.COMMENTS]
        case 'PDF':
          return [TAB.STRUCTURE, TAB.VERSIONS, TAB.COMMENTS]
        default:
          return [TAB.VERSIONS, TAB.COMMENTS]
      }
    }
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
