<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span><v-icon small class="handle pointer pr-3">drag_indicator</v-icon><span v-html="title"/></span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat>
        <v-card-text>
          <v-select
            label="Поле"
            v-model="rule.field"
            @change="clearRule(rule)"
            return-object
            item-text="name"
            :items="template.fields"
          />
          <div v-if="rule.field">
            <v-select
              label="Условие"
              v-model="rule.predicate"
              :items="comparisonPredicates"
              @change="buildTitle"
            />
            <v-text-field
              label="Контрольное значение"
              v-model="rule.thresholdValue"
              @change="buildTitle"
            />
            <v-select
              label="Стиль"
              v-model="rule.layerPoiStyle"
              return-object
              item-text="name"
              @change="buildTitle"
              :items="lpStyleList"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined @click="remove()" color="red">
            <v-icon left>delete</v-icon>
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'LpsRule',
  props: {
    template: Object,
    rule: Object,
    lpStyleList: Array
  },

  data: () => ({
    title: '<span class="red--text">Правило не установлено</span>'
  }),
  created () {
    this.buildTitle()
  },
  methods: {
    clearRule (rule) {
      rule.predicate = null
      rule.thresholdValue = null
      rule.layerPoiStyle = null
      this.buildTitle()
    },
    buildTitle () {
      if (this.rule.layerPoiStyle) {
        this.title = `<b>${this.rule.field.name}</b> ${this.rule.predicate} <b>${this.rule.thresholdValue}</b> = ${this.rule.layerPoiStyle.name}`
      } else {
        this.title = '<span class="red--text">Правило не установлено</span>'
      }
    },
    remove () {
      this.$emit('remove', this.rule)
    }
  },
  computed: {
    comparisonPredicates () {
      if (this.rule.field.type === 'NUMBER') {
        return ['EQUAL', 'LESS', 'MORE']
      } else {
        return ['EQUAL', 'LIKE']
      }
    }
  }
}
</script>
