<template>
  <vl-style-box>
    <vl-style-stroke :width="5" color="#ffcc33" :line-dash="[10, 10]"/>
    <vl-style-circle :radius="7">
      <vl-style-fill color="rgba(255, 255, 255, 0.2)"/>
    </vl-style-circle>
  </vl-style-box>
</template>

<script>
export default {
  name: 'MeasureStyleBox'
}
</script>
