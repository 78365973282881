<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-card>
      <v-card-title>
        {{ $t('importStyles') }}
      </v-card-title>
      <v-card-text>
        <file-pond
          ref="pond"
          :label-idle="$t('pressOrDragForLoadFile')"
          :server="myServer"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">
          {{ $t('button.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!styles" color="primary" outlined @click="importStyles">
          {{ $t('import') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/poi-styles/messages'

export default {
  name: "ImportStylesDialog",
  i18n: { messages },
  data () {
    return {
      dialog: false,
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      },
      styles: null
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.styles = null
      this.dialog = false
      this.$refs.pond.removeFiles()
    },
    handleFileUpload (file, load) {
      let tempFile = file
      let reader = new FileReader()
      reader.readAsText(tempFile)
      reader.onload = () => {
        this.styles = JSON.parse(reader.result)
        load()
      }
    },
    importStyles () {
      this.$axios
        .post('/layer-poi-style/import', this.styles)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('imported')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
