<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span><v-icon class="handle pointer pr-3" small>drag_indicator</v-icon>
        <span v-html="title"/>
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat>
        <v-card-text class="pt-1">
          <v-select
            v-model="rule.headerIds"
            :items="headers"
            :label="$t('field')"
            class="mb-3"
            dense
            hide-details
            item-text="fieldName"
            item-value="id"
            multiple
            outlined
          />
          <div v-if="rule.headerIds.length > 0">
            <v-select
              v-model="rule.predicate"
              :items="predicates"
              :label="$t('condition')"
              class="mb-3"
              dense
              hide-details
              outlined
            >
              <template v-slot:item="{item}">
                {{ $t(item) }}
              </template>
              <template v-slot:selection="{item}">
                {{ $t(item) }}
              </template>
            </v-select>
            <v-text-field
              v-model="rule.value"
              :label="$t('value')"
              class="mb-3"
              dense
              hide-details
              outlined
            />
            <div>
              <v-menu :close-on-content-click="false" max-width="648" offset-y>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot="{hover}">
                    <div
                      :class="hover ? 'elevation-1' : ''"
                      :style="{
                          border: `1px solid black`,
                          backgroundColor: rule.backgroundMode ? rule.backgroundColor : DEFAULT_COLORS.BACKGROUND_COLOR,
                          color: rule.textColorMode ? rule.textColor : DEFAULT_COLORS.TEXT_COLOR,
                          borderRadius: '4px'
                        }"
                      class="px-3 py-2 pointer transition-fast-in-fast-out"
                      v-on="on"
                    >
                      {{ $t('pickColor') }}
                    </div>
                  </v-hover>
                </template>
                <v-card>
                  <div class="d-flex">
                    <div class="ma-3">
                      <div class="d-flex">
                        <v-switch
                          v-model="rule.backgroundMode"
                          :label="$t('background')"
                          class="mt-0 mb-2"
                          dense
                          hide-details
                        />
                      </div>
                      <v-color-picker
                        v-model="rule.backgroundColor"
                        :disabled="!rule.backgroundMode"
                        hide-canvas
                        hide-inputs
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="120"
                      />
                    </div>
                    <div class="ma-3">
                      <div class="d-flex">
                        <v-switch
                          v-model="rule.textColorMode"
                          :label="$t('textColor')"
                          class="mt-0 mb-2"
                          dense
                          hide-details
                        />
                      </div>
                      <v-color-picker
                        v-model="rule.textColor"
                        :disabled="!rule.textColorMode"
                        hide-canvas
                        hide-inputs
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="120"
                      />
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </div>
            <v-checkbox
              v-model="rule.applyForRow"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('applyForRow') }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" outlined @click="remove">
            <v-icon left>delete</v-icon>
            {{ $t('button.remove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { CONDITIONS, DEFAULT_COLORS, getConditions } from '@/components/et/view-settings/utils'
import messages from '@/componet-locale/et-rules/messages'
import sharedMessages from '@/componet-locale/et-filter/messages'

export default {
  name: 'HeaderRule',
  i18n: { messages, sharedMessages },
  props: {
    rule: Object,
    headers: Array
  },
  methods: {
    remove () {
      this.$emit('remove')
    }
  },
  computed: {
    DEFAULT_COLORS () {
      return DEFAULT_COLORS
    },
    predicates () {
      return getConditions(this.selectedHeaders.map(el => el.fieldType))
    },
    selectedHeaders () {
      return this.headers
        .filter(header => this.rule.headerIds.includes(header.id))
    },
    title () {
      if (this.rule.headerIds.length === 0
        || this.rule.predicate === ''
        || this.rule.value === ''
        || !this.rule.value
        || !this.rule.predicate
      ) {
        return `<span style="color: red">${this.$t('ruleNotSet')}</span>`
      }
      var res = [
        `<span style="font-weight: bold">${this.selectedHeaders.map(h => h.key).join(', ')}</span>`,
        `<span>${this.rule.predicate} </span>`,
        `<span style="font-weight: bold">${this.rule.value}</span>`
      ].join('  ')
      return res
        .replace(CONDITIONS.EQUAL, '=')
        .replace(CONDITIONS.MORE, '>')
        .replace(CONDITIONS.LESS, '<')
        .replace(CONDITIONS.LIKE, this.$t('like'))
    }
  }
}
</script>
