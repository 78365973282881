<template>
    <div>
        <v-dialog
                width="1000"
                persistent
                v-model="dialog"
                scrollable
        >
            <v-card>
                <v-card-title>
                    {{$t("historyOfChanges")}}
                    <v-spacer/>
                    <v-btn icon @click="dialog=false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-expansion-panels>
                        <v-expansion-panel
                                v-for="item in changelog" :key="item.id"
                        >
                            <v-expansion-panel-header class="py-0">
                                <v-row align="center">
                                    <v-col>
                                        <v-row align="center">
                                            <user-avatar small :user="item.author"/>
                                            {{ getUserFullName(item.author) }}
                                        </v-row>
                                    </v-col>
                                    <v-col>
                                        <v-row align="center">
                                            {{ $t(item.changeType) }}
                                        </v-row>
                                    </v-col>
                                    <v-col>{{ formatDate(item.date) }}</v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-for="change in item.changeListByFields">
                                    <p class="ma-2">{{change.fieldName}}:  {{change.value}}</p>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import messages from '@/componet-locale/layer-changelog/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'LayerPoiChangelogTable',
  components: { UserAvatar },
  i18n: {
    messages: messages
  },
  data: () => ({
    dialog: false,
    changelog: null
  }),
  methods: {
    async open (layerId) {
      this.dialog = true
      let { data } = await this.$axios.get('layer-poi/changelog', {
        params: { layerId }
      }).catch(() => {
        EventBus.$emit('showErrorMessage', this.$t('errorLoadingData'))
      })
      this.changelog = data
    }
  }
}
</script>
