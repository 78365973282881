<template>
  <div>
    <vl-layer-tile :opacity="0.35" v-if="kadNum" :z-index="zIndex">
      <vl-source-arcgis-rest
        :tile-load-function="pkkTileFeatureLoadingFunction"
        :url="'ANY_STRING_REQUIRED'"
      />
    </vl-layer-tile>
    <vl-layer-tile :z-index="zIndex + 0.5">
      <vl-source-arcgis-rest
        :tile-load-function="pkkTileLoadingFunction"
        :url="'ANY_STRING_REQUIRED'"
      />
    </vl-layer-tile>
  </div>
</template>

<script>
import TileState from 'ol/TileState'

export default {
  name: "PkkLayer",
  props: {
    layer: {
      type: Object,
      required: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    kadNum: {
      type: String,
      default: null
    }
  },
  methods: {
    pkkTileLoadingFunction (tile, src) {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.addEventListener('loadend', function (evt) {
        const data = this.response
        if (data !== undefined) {
          tile.getImage().src = URL.createObjectURL(data)
        } else {
          tile.setState(TileState.ERROR)
        }
      })
      xhr.addEventListener('error', function () {
        tile.setState(TileState.ERROR)
      })
      const url = 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?'
      const bbox = src.match(/BBOX=.[^&]*&|BBOX=.*$/)[0].replace(/&/, '').toLowerCase()
      const imageSR = 'imageSR=102100'
      const bboxSR = 'bboxSR=102100'
      const size = 'size=1024%2C1024'
      const format = 'format=PNG32'
      const f = 'f=image'
      const dpi = `dpi=256`
      const transparent = 'transparent=true'

      let resultURL = `${url}${bbox}&${imageSR}&${bboxSR}&${size}&${format}&${f}&${dpi}&${transparent}`

      xhr.open('GET', resultURL)
      xhr.send()
    },
    pkkTileFeatureLoadingFunction (tile, src) {
      if (!this.kadNum) return
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.addEventListener('loadend', function (evt) {
        const data = this.response
        if (data !== undefined) {
          tile.getImage().src = URL.createObjectURL(data)
        } else {
          tile.setState(TileState.ERROR)
        }
      })
      xhr.addEventListener('error', function () {
        tile.setState(TileState.ERROR)
      })
      const url = 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?'
      const bbox = src.match(/BBOX=.[^&]*&|BBOX=.*$/)[0].replace(/&/, '').toLowerCase()
      const imageSR = 'imageSR=102100'
      const bboxSR = 'bboxSR=102100'
      const size = 'size=1024%2C1024'
      const format = 'format=PNG32'
      const f = 'f=image'
      const dpi = `dpi=256`
      const transparent = 'transparent=true'
      const layer = "layers=" + encodeURIComponent("show:6,7,8,9")
      const layerDefs = "layerDefs=" + encodeURIComponent(`{"6":"ID = '${this.kadNum}'","7":"ID = '${this.kadNum}'","8":"ID = '${this.kadNum}'","9":"ID = '${this.kadNum}'"}`)

      let resultURL = `${url}${bbox}&${imageSR}&${bboxSR}&${size}&${format}&${f}&${dpi}&${transparent}`
      resultURL += `&${layer}&${layerDefs}`

      xhr.open('GET', resultURL)
      xhr.send()
    }
  }
}
</script>
