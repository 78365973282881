<template>
  <div>
    <v-divider/>
    <div class="title mb-3">Position</div>
    <v-text-field outlined dense label="Center" v-model="position.center"/>
    <v-text-field outlined dense label="Zoom" v-model="position.zoom"/>
    <v-text-field outlined dense label="Extent" v-model="position.extent"/>
  </div>
</template>

<script>

export default {
  name: 'MapPosition',
  props: { position: Object }
}
</script>
