<template>
  <v-card
    :style="getStyle(color)"
    class="ma-3"
    outlined
  >
    <v-card-text>
      <v-select
        v-model="data.layer"
        :items="layers"
        dense
        item-text="name"
        label="Слой"
        outlined
        return-object
      />
      <v-select
        v-if="data.layer"
        v-model="data.template"
        :items="templateList"
        dense
        item-text="name"
        label="Шаблон"
        no-data-text="Нет доступных шаблонов"
        outlined
        return-object
      />
      <v-select
        v-for="item in properties"
        v-if="data.template && data.features.length && data.fieldsMapping"
        v-model="data.fieldsMapping[item]"
        :items="data.template.fields"
        :label="item"
        clearable
        dense
        item-text="name"
        outlined
        return-object
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'GeoJsonFieldMapping',
  props: {
    data: Object,
    layers: Array,
    graphType: String,
    color: String
  },
  data: () => ({
    properties: []
  }),
  methods: {
    getStyle (color) {
      return `border: 4px ${color} solid;`
    }
  },
  mounted () {
    if (this.data && this.data.features[0].properties) {
      this.properties = Object.keys(this.data.features[0].properties)
      this.data.fieldsMapping = {}
    }
  },
  computed: {
    templateList () {
      if (!this.data.layer.poiTemplates.length) return

      return this.data.layer.poiTemplates
        .filter(it => {
          if (this.data.graphType === 'POLYGON' || this.data.graphType === 'MULTI_POLYGON') {
            return it.graphType === 'POLYGON' || it.graphType === 'MULTI_POLYGON'
          } else if (this.data.graphType === 'LINESTRING' || this.data.graphType === 'MULTI_LINESTRING') {
            return it.graphType === 'LINESTRING' || it.graphType === 'MULTI_LINESTRING'
          } else {
            return it.graphType === this.data.graphType
          }
        })
    }
  },
  watch: {
    'data.layer' () {
      this.data.template = null
      this.data.nameField = null
      this.data.descriptionField = null
    },
    'data.template' () {
      this.data.nameField = null
      this.data.descriptionField = null
    }
  }
}
</script>
