<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t('button.attachedData') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!loading" class="pa-0">
        <v-list v-if="attachedData && attachedData.length" dense subheader>
          <template v-for="(data, index) in attachedData" v-if="isAuthenticated">
            <v-list-item :key="`data-${index}`" @click="showData(data)">
              <v-list-item-avatar>
                <v-icon>{{ getUrlByDataType(data.dataType).icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="blue--text">{{ data.project.name }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click.native="removeRelatedData(data, $event)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <v-card
          v-else
          class="mx-2 mb-2"
          outlined
        >
          <v-card-text>
            {{ $t('noAttachments') }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
          :loading="loading"
          type="text@2"
        />
      </v-card-text>
      <v-card-actions v-if="isAuthenticated">
        <v-btn
          v-if="setAbility('MODERATOR')"
          outlined
          @click="$refs.searchDataDialog.open()">
          <v-icon>attach_file</v-icon>
          {{ $t('linkDataFromSystem') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          v-if="setAbility('MODERATOR')"
          color="primary"
          outlined
          @click="createNewDoc"
        >
          <v-icon>add</v-icon>
          {{ $t('button.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <search-data-dialog ref="searchDataDialog" @select="attachData"/>
    <abstract-data-details-dialog ref="itemDetailsDialog" @save="saveNewDoc"/>

  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/attached-data/messages'
import { EventBus } from '@/event-bus'
import SearchDataDialog from '@/components/utils/SearchDataDialog.vue'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog.vue'

export default {
  name: 'ZwsFeatureAttachedDataDialog',
  components: { AbstractDataDetailsDialog, SearchDataDialog },
  i18n: { messages },
  data: () => ({
    dialog: false,
    loading: false,
    feature: null,
    attachedData: null
  }),
  methods: {
    init () {
      this.loading = true
      this.$axios
        .get('layer/get-attached-data', {
          params: {
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(res => {
          this.attachedData = res.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    },
    open (feature) {
      this.feature = feature
      this.dialog = true
      this.init()
    },
    close () {
      this.dialog = false
    },
    showData (data) {
      EventBus.$emit('showData', data)
    },
    removeRelatedData (item, event) {
      event.stopPropagation()
      if (!this.sys) return
      this.$axios
        .get('/layer/unbind-data-to-feature', {
          params: {
            abstractDataId: item.id,
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.init())
    },
    attachData (item) {
      if (!this.sys) return
      this.$axios
        .get('/layer/bind-data-to-feature', {
          params: {
            abstractDataId: item.id,
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.init())
    },
    createNewDoc () {
      this.$refs.itemDetailsDialog.createData()
    },
    saveNewDoc (doc) {
      let projectId = this.selectedProject.id
      this.$axios
        .post('/docs/save-for-project/' + projectId, doc, { timeout: 120000 })
        .then(res => {
          this.attachData(res.data)
        })
        .catch(reason => {
          console.error(reason.data.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$refs.itemDetailsDialog.hideDialog()
        })
    }
  },
  computed: {
    sys () {
      let key = this.feature.props.find(prop => prop.isKey)
      if (key && key.value) {
        return key.value
      }
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    }
  }
}
</script>
