import en from './en'
import ru from './ru'
import es from './es'

export default {
  en: en,
  ru: ru,
  es: es
}

