<template>
  <v-list-item dense>
    <v-list-item-avatar class="mr-0">
      <user-avatar
        v-if="chat.type === 'DIRECT' && user"
        :user="user"
        online-dot
      />
      <v-avatar
        v-else
        :color="avatar.color"
        class="mr-2"
        color="grey"
        dark
        size="40"
      >
        <v-icon dark>
          {{ avatar.icon }}
        </v-icon>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <div v-if="!targetData" class="px-0 blue--text text--darken-2">
          {{ chat.name }}
        </div>

        <div v-if="targetData">
          <div class="px-0 blue--text text--darken-2">
            <a @click="select">{{ targetData.project.name }}</a> /
            <a @click="openData" class="font-weight-bold">{{ targetData.name }}</a>
          </div>
        </div>
      </v-list-item-title>

      <v-list-item-subtitle v-if="chat.type === 'DIRECT'">
        {{ companionStatusTitle }}
      </v-list-item-subtitle>

      <v-list-item-subtitle v-if="targetData">
        {{ getUserFullName(targetData.owner || targetData.reporter) }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <slot/>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'ChatHeader',
  components: { UserAvatar },
  props: {
    chat: Object
  },
  data: () => ({
    targetData: null,
    user: null,
    task: undefined,
    lastSeenTitle: ''
  }),
  methods: {
    ...mapActions(['selectProject']),
    select () {
      this.selectProject({
        project: this.targetData.project,
        path: 'home'
      })
    },
    openData () {
      let path
      if (this.chat.type === 'DATA') { path = `/project/${this.targetData.project.id}/${this.targetData.dataType}/${this.targetData.id}` }
      if (this.chat.type === 'ISSUE') { path = `/project/${this.targetData.project.id}/issue/${this.targetData.id}` }
      this.$router.push(path)
    },
    init () {
      this.targetData = null
      switch (this.chat.type) {
        case 'DATA': {
          this.$axios
            .get('data/get-by-chat', { params: { chatId: this.chat.id } })
            .then(response => this.targetData = response.data)
          break
        }
        case 'ISSUE': {
          this.$axios
            .get('issue/get-by-chat', { params: { chatId: this.chat.id } })
            .then(response => this.targetData = response.data)
          break
        }
        case 'DIRECT': {
          this.updateLastSeenTitle()
          break
        }
        default:
          clearInterval(this.task)
      }
    },
    updateLastSeenTitle () {
      if (!this.companionOnline) {
        this.createLastSeenTitle()
        this.task = setInterval(() => {
          this.createLastSeenTitle()
        }, 60000)
      }
    },
    createLastSeenTitle () {
      const diff = moment().diff(this.chat.companionLogoutTime, 'seconds')
      const title = diff < 60 ? 'только что' : moment(this.chat.companionLogoutTime).fromNow()
      this.lastSeenTitle = `Был(-а) в сети ${title}`
    }
  },
  computed: {
    avatar () {
      switch (this.chat.type) {
        case 'PUBLIC':
          return {
            icon: 'supervisor_account',
            color: 'indigo darken-2'
          }
        case 'PRIVATE':
          return {
            icon: 'lock',
            color: 'grey darken-2'
          }
        case 'DIRECT':
          return {
            icon: 'account_circle',
            color: 'blue'
          }
        case 'ISSUE':
          return {
            icon: 'bug_report',
            color: 'purple darken-1'
          }
        case 'DATA':
          return {
            icon: 'description',
            color: 'blue'
          }
        default:
          return { icon: '', color: 'indigo darken-2' }
      }
    },
    companionStatusTitle () {
      return this.companionOnline ? 'В сети' : this.lastSeenTitle
    },
    companionOnline () {
      return this.chat.type === 'DIRECT' ? this.$store.getters.onlineUsers.includes(this.chat.companionID) : false
    }
  },
  watch: {
    chat (val) {
      if (val) this.init()
    },
    companionOnline (val) {
      if (this.chat.type === 'DIRECT' && !val) {
        this.updateLastSeenTitle()
      } else {
        clearInterval(this.task)
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
